import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import "./LoginStyles.css";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import BGIMAGE from "../../assets/images/background.jpg";
import LOGO from "../../assets/images/logo_main_img.png";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import {
  setIsLogin,
  setPosConnect,
  setUserDetails,
  setUserObject,
} from "../../store/reducers/AuthReducer";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import GoogleImage from "../../assets/images/google.png";
import axios from "axios";
import { Grid, TextField, Typography, Button, Box } from "@mui/material";

let UserID: any;
function Login() {
  const navigate = useNavigate();
  const [email, setEmail]: any = useState("");
  const [emailError, setEmailError] = useState(false)
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    sessionStorage.removeItem("timer");
  }, []);

  const isValidEmail = (email: any) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const HandleLoginInitial = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage("");
    if (email === "") {
      return setErrorMessage("This field is required"), setEmailError(true), setLoading(false);
    }else if (!isValidEmail(email)){
      return setErrorMessage("Email format invalid"), setEmailError(true), setLoading(false);
    }
    setEmailError(false)
    try {
      const response = await HelperModule().getMethod(
        ApiServices.base_URL + ApiServices.validate_email + email
      );
      if (response.status === true && response.email_verification === true) {
        navigate("/password", { state: { email } });
        localStorage.setItem("email", email);
        console.log(response);
      } else if (
        response.status === true &&
        response.email_verification === false
      ) {
        localStorage.setItem("UserCode", response.user_code);
        dispatch(
          setUserDetails({
            first_name: "",
            last_name: "",
            phone: "",
            email: email,
            back: false,
          })
        );
        console.log(email);
        navigate("/otp", { state: { email } });
      } else if (response.status === true) {
        dispatch(
          setUserDetails({
            first_name: "",
            last_name: "",
            phone: "",
            email: email,
            back: false,
          })
        );
        navigate("/createprofile", { state: { email } });
        console.log(email, "createprofile");
      } else if (
        response.status === false &&
        response.message === "Email does not exist"
      ) {
        dispatch(
          setUserDetails({
            first_name: "",
            last_name: "",
            phone: "",
            email: email,
            back: false,
          })
        );
        navigate("/createprofile");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => handleGoogleLogin(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  const handleGoogleLogin = async (user: any) => {
    console.log(user);
    try {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          // setProfile(res.data);
          HandleLogin(res.data);
          console.log(res);
        })
        .catch((err) => console.log(err));
    } catch (error: any) {
      console.log(error);
    }
  };

  const HandleLogin = async (user: any) => {
    const Params = {
      login_type: "google",
      email: user.email,
      name: user.name,
      google_id: user.id,
      profile_image: user.picture,
    };

    try {
      const response = await HelperModule().postMethod(
        ApiServices.base_URL + ApiServices.login,
        Params
      );
      console.log("USER===>", response?.user);
      if (response.status === true) {
        localStorage.setItem("referralCode", response.user?.referral_code);
        localStorage.setItem("IsLogin", response);
        localStorage.setItem("UserName", response.user?.name);
        localStorage.setItem("Email", response.user?.email);
        localStorage.setItem("UserCode", response.user?.user_code);
        dispatch(setPosConnect(response.user?.pos_account_status));
        dispatch(setUserObject(response?.user));
        console.log(response?.user, "PASSWORD");
        // localStorage.setItem("PosAccount", response.user?.pos_account_status);
        localStorage.setItem(
          "Subscription",
          response.user?.subscription_status
        );
        localStorage.setItem("UserID", response.user?.id);
        localStorage.setItem("Token", response.token);
        dispatch(setIsLogin(true));
      } else if (response.status === false) {
        enqueueSnackbar(response.message, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
      } else {
        enqueueSnackbar(response.message, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={5}
          xl={5}
          alignContent="center"
          className="grid-container-item"
        >
          <div className="container-hide">
            <img src={LOGO} alt="logo" style={{ width: "150px", display: "block" }} />
          </div>
          <div className="header-form-container">
            <div className="header-text">
              <Typography
                variant="h4"
                sx={{
                  typography: {
                    xs: "h5",
                    sm: "h4",
                    md: "h4",
                    lg: "h4",
                    xl: "h4",
                  },
                  color: "#201C1C",
                  fontFamily: "Poppins",
                }}
              >
                Miniosk for business
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ color: "#201C1CB8", fontFamily: "Poppins" }}
              >
                Create a new business account
              </Typography>
            </div>
            <div
              className="form-container"
            >
              <div>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#201C1C",
                    fontFamily: "Poppins",
                    textAlign: "left",
                  }}
                  mb={1}
                  mt={2}
                  className="email-header"
                >
                  Email
                </Typography>
                <TextField
                  error={emailError}
                  placeholder="example@gmail.com"
                  id="outlined-basic"
                  variant="outlined"
                  onChange={(e) => setEmail(e.target.value)}
                  className="email-textfield"
                  helperText={emailError ? "Invalid email." : ""}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "4rem",
                      borderRadius: "8px",
                    },
                  }}
                />
              </div>
              <Button
                type="submit"
                variant="contained"
                onClick={HandleLoginInitial}
                sx={{
                  marginTop: "1rem",
                  height: "4rem",
                  fontSize: "1.2rem",
                  backgroundColor: "#8B5CFF",
                  borderRadius: "8px",
                  ":hover": {
                    bgcolor: "#8B5CFF"
                  },
                }}
                className="continue-button-width"
              >
                {loading ? "Loading..." : "Continue"}
              </Button>

              <Button
                onClick={() => {
                  login();
                }}
                variant="outlined"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "4rem",
                  marginTop: "1rem",
                  fontSize: "1rem",
                  borderRadius: "8px",
                  borderColor: "#201C1C",
                  color: "#201C1C",
                }}
                className="continue-button-width"
              >
                <img
                  src={GoogleImage}
                  alt="bg"
                  style={{
                    maxWidth: "80%",
                    height: "40%",
                    objectFit: "cover",
                    marginRight: "2%",
                  }}
                />
                Continue with Google
              </Button>
            </div>
          </div>
          <div className="kiosk_image_logo">
            <img src={LOGO} alt="logo" style={{ width: "150px", display: "block", margin: "auto" }} />
          </div>
        </Grid>
        <Grid
          item
          xs={0}
          sm={6}
          md={6}
          lg={7}
          xl={7}
          className="container-image"
        >
          <img
            src={BGIMAGE}
            alt="bg"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Login;
