import { ChangeEvent, Fragment, useEffect, useMemo, useState } from "react";
import "./InsightsStyles.css";
import Coin from "../../assets/images/coin.png";
import AverageTicket from "../../assets/images/AverageTicket.png";
import Total from "../../assets/images/Total.png";
import SubTotal from "../../assets/images/Subtotal.png";
import Taxes from "../../assets/images/Taxs.png";
import Transactions from "../../assets/images/Transactions.png";
import { DashboardFilter, DownloadSummary, SalesTable } from "../../components";
import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import ApiServices from "../../helpers/ApiServices";
import HelperModule from "../../helpers/HelperModule";
import { useDispatch, useSelector } from "react-redux";
import {
  setInsightsList,
  setSalesTableList,
} from "../../store/reducers/InsightsReducer";
import { useStyles } from "../../components/UseStyles";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { enqueueSnackbar } from "notistack";
import {
  ClearIcon,
  DateCalendar,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { setLocationsList } from "../../store/reducers/LocationsReducer";
import axios from "axios";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { visuallyHidden } from "@mui/utils";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Pagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import Modal from "@mui/material/Modal";
import Download from "../../assets/images/Download.png";
import Cross from "../../assets/images/Cross.png";
import { useAppSelector } from "../../Hooks";
import Filter from "../../assets/images/Filter.png";
import {
  setCategoryId,
  setItemsId,
  setMenuId,
  setMenuListDetails,
  setModifiersId,
  setPaymentModeId,
} from "../../store/reducers/MenuListReducer";
import DataTable from "../../components/DataTable";
let UserCodeData: any;
let AuthTokenId: any;
let GlobalMenuCodeID: any;

interface DashboardFilterProps {
  onSelectMenuCode: (menuCodes: string[]) => void;
}
function createData(
  created_at: string,
  reference_id: string,
  item_count: number,
  net_amount: string,
  tax_amount: string,
  discount_amount: string,
  tip: string,
  total: string,
  payment_mode: string
): Data {
  return {
    created_at: created_at.toString(), // Ensure created_at is a string
    reference_id,
    item_count,
    net_amount,
    tax_amount,
    discount_amount,
    tip,
    total,
    payment_mode,
  };
}

interface Data {
  created_at: string;
  reference_id: string;
  item_count: number;
  net_amount: string;
  tax_amount: string;
  discount_amount: string;
  tip: string;
  total: string;
  payment_mode: string;
}

const characters = "Heading";
function generateString(length: any) {
  let result = " ";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
function generateNumber(length: any) {
  let result = " ";
  const charactersLength = "0123456789".length;
  for (let i = 0; i < length; i++) {
    result += "0123456789".charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

// This function returns a comparator function based on the order ("asc" or "desc") and the property to sort by (orderBy).
// The comparator function compares two objects (a and b) based on the specified property.
function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  // If the order is "desc", return a comparator that sorts in descending order.
  // Otherwise, return a comparator that sorts in ascending order.
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  // Create an array of tuples where each tuple contains an element from the original array and its index
  const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);

  // Sort the array of tuples using the provided comparator function
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    // If the comparator returns 0 (elements are equal), sort by the original index to maintain stability
    return a[1] - b[1];
  });

  // Extract and return the sorted elements from the tuples
  return stabilizedThis?.map((el) => el[0]);
}

const headCells: any = [
  {
    id: "created_at",
    disablePadding: true,
    label: "Date",
  },
  {
    id: "reference_id",
    disablePadding: false,
    label: "order ID",
  },
  {
    id: "item_count",
    disablePadding: false,
    label: "items",
  },
  {
    id: "net_amount",
    disablePadding: false,
    label: "Subtotal ($)",
  },
  {
    id: "tax_amount",
    disablePadding: false,
    label: "Taxes ($)",
  },
  {
    id: "discount_amount",
    disablePadding: false,
    label: "Discount (%)",
  },
  {
    id: "tip",
    disablePadding: false,
    label: "Tips ($)",
  },
  {
    id: "total",
    disablePadding: false,
    label: "Total ($)",
  },
  {
    id: "payment_mode",
    disablePadding: false,
    label: "Payment Mode",
  },
];

function EnhancedTableHead(props: any) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells?.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              className={classes.TableHeadTitle}
              active={orderBy === headCell.id} // orderBy === headCell.id
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              IconComponent={(event) => (
                <ArrowDropDownRoundedIcon
                  {...event}
                  sx={{ color: "#201C1C52" }}
                />
              )}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
function Dashboard() {
  const { InsightsList } = useSelector((state: any) => state.insightsDetails);
  const { LocationsList } = useSelector((state: any) => state.locationDetails);
  const dispatch = useDispatch();
  const classes = useStyles();
  const Navigation = useNavigate();
  const { palette }: any = useTheme();
  const [onRange, setRange] = useState<null | HTMLElement>(null);
  const [onLocation, setLocation] = useState<null | HTMLElement>(null);
  const [onVendor, setVendor] = useState<null | HTMLElement>(null);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [selectedStartDate, setSelectedStartDate]: any = useState(null);
  const [selectedEndDate, setSelectedEndDate]: any = useState(null);
  const [selectedLocationNames, setSelectedLocationNames] = useState<string[]>(
    []
  );
  const [tokenData, setToken] = useState("");
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [locationSearch, setLocationSearch] = useState("");
  const [vendorSearch, setVendorSearch] = useState("");
  const { salesTableList } = useSelector((state: any) => state.insightsDetails);
  const { posConnection, location_added } = useSelector(
    (state: any) => state?.Auth
  );
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("created_at");
  const [SalesTableData, setSalesTableData]: any = useState([]);
  const [selectedMenuCode, setSelectedMenuCode] = useState<string | null>(null);
  const [globalMenuCode, setGlobalMenuCode]: any = useState(null);
  const [globalCategoryCode, setGlobalCategoryCode]: any = useState(null);
  const [globalItemCode, setGlobalItemCode]: any = useState(null);
  const [globalModifierCode, setGlobalModifierCode]: any = useState(null);
  const [selectedRow, setSelectedRow]: any = useState(null);

  const [initLoading, setInitLoading] = useState(true);

  const [recordsCount, setRecordsCount] = useState(0);

  const [controller, setController] = useState({
    page: 1,
    rowsPerPage: 10,
    field: "",
    order: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = salesTableList;

  const nPages = Math.ceil(recordsCount / controller.rowsPerPage);
  const [search, setSearch] = useState("");
  const [filteredValues, setFilteredValues] = useState([]);

  const [loading, setLoading] = useState(true);
  const [isOrderLoading, setOrderLoading] = useState(false);
  const [webSettingsModal, setWebSettingsModal] = useState(false);
  const [ordersDetails, setOrdersDetails]: any = useState({});
  const [orderId, setOrderId] = useState("");

  const [open, setOpen] = useState<null | HTMLElement>(null);
  const [selectedMenu, setSelectedMenu] = useState<readonly string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<readonly string[]>(
    []
  );
  const [visibleRows, setVisibleRows] = useState<Data[]>([]);

  const [globalMenuId, setGlobalMenuId] = useState<string[]>([]);
  const [selectedItem, setSelectedItem] = useState<readonly string[]>([]);
  const [selectedModifier, setSelectedModifier] = useState<readonly string[]>(
    []
  );
  const [selectedPaymentMode, setSelectedPaymentMode] = useState<
    readonly string[]
  >([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  useEffect(() => {
    // const PosAccount = localStorage.getItem("PosAccount");
    if (posConnection === 0 || !posConnection || location_added === 0) {
      Navigation("/linkaccount");
    }
    // else {
    //   Navigation("/dashboard");
    // }
  }, []);

  useEffect(() => {
    const UserCode = localStorage.getItem("UserCode");
    UserCodeData = UserCode;
  }, []);

  useEffect(() => {
    const TokenData: any = localStorage.getItem("Token");
    setToken(TokenData);
  }, []);

  const InsightsInitialDisplayData = async () => {
    try {
      const response = await HelperModule().getMethod(
        ApiServices.base_URL +
          ApiServices.insights_data +
          `?user_code=${UserCodeData}`
      );
      if (response.status === true) {
        dispatch(setInsightsList(response.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    InsightsInitialDisplayData();
    handleSelectAll();
  }, []);

  const handleRange = (event: any) => {
    setRange(event.currentTarget);
  };

  const handleLocation = (event: any) => {
    setLocation(event.currentTarget);
  };

  const handleSelectAll = () => {
    const allLocationIds = LocationsList.map(
      (location: { id: any }) => location.id
    );
    const newSelectedLocations =
      selectedLocations?.length === allLocationIds?.length
        ? []
        : allLocationIds;
    setSelectedLocations(newSelectedLocations);
    const newSelectedLocationNames = newSelectedLocations.map(
      (locationId: any) => {
        const selectedLocation = LocationsList.find(
          (location: { id: any }) => location.id === locationId
        );
        return selectedLocation ? selectedLocation.name : "";
      }
    );
    setSelectedLocationNames(newSelectedLocationNames);
  };

  const handleTypeSelection = (type: string) => {
    const isSelected = selectedTypes.includes(type);
    if (isSelected) {
      setSelectedTypes((prevSelected) =>
        prevSelected.filter((t) => t !== type)
      );
    } else {
      setSelectedTypes((prevSelected) => [...prevSelected, type]);
    }
  };

  const handleVendor = (event: any) => {
    setVendor(event.currentTarget);
  };

  const handleSelectAllTypes = () => {
    const allTypeValues = Type.map((type) => type.value);
    const newSelectedTypes =
      selectedTypes.length === allTypeValues.length ? [] : allTypeValues;
    setSelectedTypes(newSelectedTypes);
  };

  const handleClick = (locationId: string, locationName: string): void => {
    const updatedLocations = [...selectedLocations];
    const updatedLocationNames = [...selectedLocationNames];
    if (updatedLocations.includes(locationId)) {
      const index = updatedLocations.indexOf(locationId);
      updatedLocations.splice(index, 1);
      updatedLocationNames.splice(index, 1);
    } else {
      updatedLocations.push(locationId);
      updatedLocationNames.push(locationName);
    }
    setSelectedLocations(updatedLocations);
    setSelectedLocationNames(updatedLocationNames);
  };

  const HandleGetFilteredData = async () => {
    if (!selectedStartDate || !selectedEndDate) {
      enqueueSnackbar("Please select the start and end date", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
    } else if (selectedLocations.length === 0) {
      enqueueSnackbar("Please select the location", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
    } else if (selectedTypes.length === 0) {
      enqueueSnackbar("Please select the Type", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
    } else {
      setLoading(true);
      try {
        const headers = {
          Authorization: `Bearer ${tokenData}`,
        };
        const formattedStartDate =
          dayjs(selectedStartDate).format("YYYY-MM-DD");
        const formattedEndDate = dayjs(selectedEndDate).format("YYYY-MM-DD");
        const response = await HelperModule().getMethodWithHeaders(
          ApiServices.base_URL +
            ApiServices.insights_data +
            `?user_code=${UserCodeData}&start_date=${formattedStartDate}&end_date=${formattedEndDate}&location_ids=${selectedLocations.join(
              ","
            )}&type=${selectedTypes}`,
          headers
        );
        if (response.status === true) {
          setLoading(false);
          dispatch(setInsightsList(response.data));
          enqueueSnackbar("Data sync successfull", {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            style: { fontFamily: "Poppins", fontWeight: "500" },
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const Locations = () => {
    if (!tokenData) {
      console.error("Token is not available yet.");
      return;
    }
    const apiEndpoint =
      ApiServices.base_URL +
      ApiServices.vendor_locations +
      `?user_code=${UserCodeData}`;
    const token = tokenData;
    axios
      .get(apiEndpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response: any) => {
        dispatch(setLocationsList(response.data.data));
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  };

  useEffect(() => {
    handleSelectAll();
  }, [LocationsList]);

  useEffect(() => {
    handleSelectAllTypes();
  }, [Type]);

  useEffect(() => {
    if (tokenData) {
      Locations();
    }
  }, [tokenData]);

  const formatDate = (date: any) => {
    return date ? new Intl.DateTimeFormat("en-US").format(date) : "";
  };

  const handleDelete = (vendorId: any) => {
    const vendorIndex = selectedLocations.indexOf(vendorId);
    if (vendorIndex !== -1) {
      const updatedSelectedLocations = [...selectedLocations];
      updatedSelectedLocations.splice(vendorIndex, 1);
      const updatedSelectedVendorNames = [...selectedLocationNames];
      updatedSelectedVendorNames.splice(vendorIndex, 1);
      setSelectedLocations(updatedSelectedLocations);
      setSelectedLocationNames(updatedSelectedVendorNames);
    }
  };

  const handleDeleteType = (index: number) => {
    const updatedTypes = [...selectedTypes];
    updatedTypes.splice(index, 1);
    setSelectedTypes(updatedTypes);
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    const UserCode = localStorage.getItem("UserCode");
    UserCodeData = UserCode;
  }, []);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setController({
      ...controller,
      order: isAsc ? "desc" : "asc",
      field: property,
    });
  };

  useEffect(() => {
    InsightsSalesTable(globalMenuCode, 1);
  }, [controller]);

  const OrderDetails = async (orderId: string) => {
    try {
      setOrderLoading(true);
      const response = await HelperModule().getMethod(
        ApiServices.base_URL +
          ApiServices.Order_details +
          `?order_id=${orderId}`
      );
      setOrderLoading(false);
      if (response.status === true) {
        setOrdersDetails(response);
      } else {
        console.log("OrderDetails Error");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    OrderDetails(orderId);
  }, [orderId]);

  const downloadInvoice = async () => {
    try {
      const response = await fetch(
        `https://api.miniosk.com/api/order/download-order-receipt/${orderId}`
      );
      if (!response.ok) {
        throw new Error(
          `Failed to download the invoice. Response status: ${response.status}`
        );
      }
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Invoice_${orderId}.pdf`);
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        link.parentNode?.removeChild(link);
      }, 100);
    } catch (error) {
      console.error("Error occurred while downloading the invoice:", error);
    }
  };

  const HandleWebSettings = (orderId: string) => () => {
    setWebSettingsModal(true);
    setOrderId(orderId);
  };

  const InsightsSalesTable = async (selectedMenuCode: any, page: any) => {
    setLoading(true);
    try {
      const menuCodes = (await selectedMenuCode) || "";
      const response = await HelperModule().getMethod(
        ApiServices.base_URL +
          ApiServices.insights_sales_table +
          `?user_code=${UserCodeData}&menu_codes=${globalMenuCode || ""}&page=${
            controller.page
          }&search=${search}&order_by=${controller.field}&order_in=${
            controller.order
          }&location_ids=${selectedLocations}&type_ids=${selectedTypes}&category_ids=${
            globalCategoryCode || ""
          }&item_ids=${globalItemCode || ""} &modifier_ids=${
            globalModifierCode || ""
          }`
      );
      if (response.status === true) {
        let data: Data[] = response?.result?.data.map((it: any) =>
          createData(
            it?.created_at,
            it?.reference_id,
            it?.item_count,
            it?.net_amount,
            it?.tax_amount,
            it?.discount_amount,
            it?.tip,
            it?.total,
            it?.payment_mode
          )
        );
        dispatch(setSalesTableList(data));
        setSalesTableData(response.result?.data);
        setRecordsCount(response?.result?.total);
        setLoading(false);
      } else {
        console.log("Error Response", response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (globalMenuCode === null || globalMenuCode === "") {
      InsightsSalesTable(globalMenuCode, 1);
      setLoading(false);
    }
    if (globalMenuCode !== null) {
      InsightsSalesTable(globalMenuCode, 1);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const getData = setTimeout(() => {
      if (initLoading) {
        setInitLoading(false);
        return;
      }
      setController({ ...controller, page: 1 });
    }, 1000);
    return () => clearTimeout(getData);
  }, [search]);

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
    setController({
      ...controller,
      page: value,
    });
  };

  useEffect(() => {
    if (loading) {
      return;
    }
    InsightsSalesTable(selectedMenuCode, controller.page);
  }, [controller]);

  useEffect(() => {
    const dataToDisplay =
      filteredValues.length > 0 ? filteredValues : salesTableList;

    const comparator = getComparator(order, orderBy);
    const sortedData = stableSort(dataToDisplay, comparator);

    setVisibleRows(sortedData as Data[]); // Ensure the type is Data[]
  }, [order, orderBy, salesTableList, filteredValues]);

  // const visibleRows = useMemo(() => {
  //   const dataToDisplay =
  //     filteredValues.length > 0 ? filteredValues : salesTableList;
  //   const sortedData = stableSort(dataToDisplay, getComparator(order, orderBy));
  //   return sortedData; // Use sorted data
  // }, [order, orderBy, salesTableList, filteredValues]);

  // const [Menu, setMenuListDetails] = useState({});
  const [isPopover, setIsPopover]: any = useState({
    Menu: null,
    Category: null,
    Items: null,
    Modifiers: null,
    PaymentMode: null,
  });

  useEffect(() => {
    const UserCode = localStorage.getItem("UserCode");
    UserCodeData = UserCode;
  }, []);

  useEffect(() => {
    const TokenData: any = localStorage.getItem("Token");
    AuthTokenId = TokenData;
  }, []);

  const { Menu } = useAppSelector((state) => state.menuListDetails);
  // const { CatalogList } = useAppSelector((state) => state.catalogDetails);

  const handleSearch = (event: any, popover: string) => {
    setSearchQuery(event.target.value);
  };

  const MenusListData = async () => {
    try {
      let apiEndpoint =
        ApiServices.base_URL +
        ApiServices.dashboard_filter +
        `?user_code=${UserCodeData}`;
      if (selectedMenu.length > 0) {
        const selectedMenuString: any = selectedMenu.join(",");
        setGlobalMenuId(selectedMenuString);
        GlobalMenuCodeID = selectedMenuString;
        const selectedCategoryString = selectedCategory.join(",");
        const selectedItemString = selectedItem.join(",");
        const selectedModifierString = selectedModifier.join(",");
        const selectedPaymentModeString = selectedPaymentMode.join(",");
        apiEndpoint += `&menu_codes=${GlobalMenuCodeID}&category_ids=${selectedCategoryString}&item_ids=${selectedItemString}&modifier_list_ids=${selectedModifierString}&payment_mode_ids=${selectedPaymentModeString}`;
      }
      const response = await HelperModule().getMethod(apiEndpoint);
      if (response.status === true) {
        dispatch(setMenuListDetails(response));
      } else {
        console.log("Error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    MenusListData();
  }, [selectedMenu, selectedCategory, selectedItem]);

  // const response = await HelperModule().getMethod(ApiServices.base_URL + ApiServices.dashboard_filter + `?user_code=${UserCodeData}&menu_codes=${"SI05Y60mWK "}&category_ids=${293}`);

  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
    setIsPopover({
      ...isPopover,
      Menu: null,
      Category: null,
      Items: null,
      Modifiers: null,
      PaymentMode: null,
    });
  };

  const handleSelectAllClick = (
    event: ChangeEvent<HTMLInputElement>,
    popover: string
  ) => {
    let menuData: any[] = [];
    switch (popover) {
      case "Menu":
        menuData = Menu?.menus || [];
        if (event.target.checked) {
          const updatedSelectedMenu =
            menuData.map((value: any) => value.menu_code) || [];
          setSelectedMenu(updatedSelectedMenu);
          // handleMenuCodeSelection(updatedSelectedMenu);
          InsightsSalesTable(globalMenuCode, 1);
        } else {
          setSelectedMenu([]);
          // handleMenuCodeSelection([]);
        }
        break;
      case "Category":
        menuData = Menu?.categories || [];
        if (event.target.checked) {
          const updatedSelectedMenu =
            menuData.map((value: any) => value?.id) || [];
          setSelectedCategory(updatedSelectedMenu);
          // handleMenuCodeSelection(updatedSelectedMenu);
          InsightsSalesTable(globalMenuCode, 1);
        } else {
          setSelectedCategory([]);
          // handleMenuCodeSelection([]);
        }
        break;
      case "Items":
        menuData = Menu?.items || [];
        if (event.target.checked) {
          const updatedSelectedMenu =
            menuData.map((value: any) => value.id) || [];
          setSelectedItem(updatedSelectedMenu);
          // handleMenuCodeSelection(updatedSelectedMenu);
          InsightsSalesTable(globalMenuCode, 1);
        } else {
          setSelectedItem([]);
          // handleMenuCodeSelection([]);
        }
        break;
      case "Modifiers":
        menuData = Menu?.modifiers || [];
        if (event.target.checked) {
          const updatedSelectedMenu =
            menuData.map((value: any) => value.id) || [];
          setSelectedModifier(updatedSelectedMenu);
          // handleMenuCodeSelection(updatedSelectedMenu);
          InsightsSalesTable(globalMenuCode, 1);
        } else {
          setSelectedModifier([]);
          // handleMenuCodeSelection([]);
        }
        break;
      case "PaymentMode":
        menuData = Menu?.paymentmode || [];
        break;
      default:
        break;
    }
  };

  const handleClickData = (name: any, key: string) => {
    if (name) {
      if (key === "menu") {
        const selectedIndex = selectedMenu.indexOf(name);
        let newSelected: string[] = [];
        if (selectedIndex === -1) {
          newSelected = [...selectedMenu, name];
        } else {
          newSelected = selectedMenu.filter((item) => item !== name);
        }
        setSelectedMenu(newSelected);
        dispatch(setMenuId(newSelected));
        InsightsSalesTable(globalMenuCode, 1);
        // handleMenuCodeSelection(newSelected);
      } else if (key === "category") {
        const selectedIndex = selectedCategory.indexOf(name);
        let newSelected: string[] = [];
        if (selectedIndex === -1) {
          newSelected = [...selectedCategory, name];
        } else {
          newSelected = selectedCategory.filter((item) => item !== name);
        }
        setSelectedCategory(newSelected);
        dispatch(setCategoryId(newSelected));
        // handleMenuCodeSelection(newSelected);
        InsightsSalesTable(globalMenuCode, 1);
      } else if (key === "items") {
        const selectedIndex = selectedItem.indexOf(name);
        let newSelected: string[] = [];
        if (selectedIndex === -1) {
          newSelected = [...selectedItem, name];
        } else {
          newSelected = selectedItem.filter((item) => item !== name);
        }
        setSelectedItem(newSelected);
        dispatch(setItemsId(newSelected));
        InsightsSalesTable(globalMenuCode, 1);
        // handleMenuCodeSelection(newSelected);
      } else if (key === "modifier") {
        const selectedIndex = selectedModifier.indexOf(name);
        let newSelected: string[] = [];
        if (selectedIndex === -1) {
          newSelected = [...selectedModifier, name];
        } else {
          newSelected = selectedModifier.filter((item) => item !== name);
        }
        setSelectedModifier(newSelected);
        dispatch(setModifiersId(newSelected));
        InsightsSalesTable(globalMenuCode, 1);
        // handleMenuCodeSelection(newSelected);
      } else if (key === "paymentmode") {
        const selectedIndex = selectedPaymentMode.indexOf(name);
        let newSelected: string[] = [];
        if (selectedIndex === -1) {
          newSelected = [...selectedPaymentMode, name];
        } else {
          newSelected = selectedPaymentMode.filter((item) => item !== name);
        }
        setSelectedPaymentMode(newSelected);
        dispatch(setPaymentModeId(newSelected));
        // handleMenuCodeSelection(newSelected);
        InsightsSalesTable(globalMenuCode, 1);
      } else return;
    }
  };

  const isSelectedMenu = (id: any, popover: string) => {
    let menuData: any[] = [];
    let selectedData;
    switch (popover) {
      case "Menu":
        menuData = Menu?.menus || [];
        selectedData =
          selectedMenu.indexOf(id) !== -1 ||
          selectedMenu.length === menuData.length;
        break;
      case "Category":
        menuData = Menu?.categories || [];
        selectedData =
          selectedCategory.indexOf(id) !== -1 ||
          selectedCategory.length === menuData.length;
        break;
      case "Items":
        menuData = Menu?.items || [];
        selectedData =
          selectedItem.indexOf(id) !== -1 ||
          selectedItem.length === menuData.length;
        break;
      case "Modifiers":
        menuData = Menu?.modifiers || [];
        selectedData =
          selectedModifier.indexOf(id) !== -1 ||
          selectedModifier.length === menuData.length;
        break;
      case "PaymentMode":
        menuData = Menu?.paymentmode || [];
        selectedData =
          selectedPaymentMode.indexOf(id) !== -1 ||
          selectedPaymentMode.length === menuData.length;
        break;
      default:
        break;
    }

    return selectedData;
  };

  return (
    <>
      <div className="dashboard-container">
        <h1 className="insight-title-heading">Insights</h1>
        <div className="sub-container">
          <h2 className="insight-sub-title">Overview</h2>
        </div>
        <div className="details-container">
          <Box className={classes.OverviewMain}>
            <Box className={classes.TableMain}>
              <Fragment>
                <Grid
                  container
                  justifyContent={"space-between"}
                  rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                  columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={3}
                    xl={3}
                    onClick={(event: any) => {
                      handleRange(event);
                    }}
                  >
                    <Box className={classes?.OverviewMenu}>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: 500, lineHeight: "16px" }}
                      >
                        {selectedStartDate && selectedEndDate
                          ? `${formatDate(selectedStartDate)} - ${formatDate(
                              selectedEndDate
                            )}`
                          : "Date Range"}{" "}
                      </Typography>
                      <KeyboardArrowDownRoundedIcon
                        sx={{ width: "20px", height: "20px", color: "grey" }}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    onClick={handleLocation}
                    item
                    xs={12}
                    sm={6}
                    md={3.5}
                    lg={3.5}
                    xl={3.5}
                  >
                    <Box
                      className={classes?.OverviewMenu}
                      sx={{
                        display: "flex",
                        // justifyContent: "center",
                        flexWrap: "nowrap",
                        listStyle: "none",
                        p: 0,
                        m: 0,
                        overflowX: "auto",
                        scrollbarWidth: "none", // This property is supported in Firefox
                        "-ms-overflow-style": "none", // This property is supported in Internet Explorer and Edge
                        "&::-webkit-scrollbar": {
                          display: "none", // This property is supported in Webkit browsers like Chrome and Safari
                        },
                      }}
                    >
                      {selectedLocations.length > 0 && (
                        <>
                          {selectedLocations.map((locationId, index) => (
                            <Chip
                              key={index}
                              label={selectedLocationNames[index]}
                              onDelete={() => handleDelete(locationId)}
                              sx={{
                                margin: "0px",
                                height: "25px",
                                padding: "0px",
                                color: "#8B5CFF",
                              }}
                            />
                          ))}
                        </>
                      )}
                      {selectedLocations.length === 0 && (
                        <>
                          <Typography
                            variant="subtitle2"
                            sx={{
                              color: palette?.common?.menuItem,
                              fontWeight: 500,
                              lineHeight: "16px",
                              marginLeft: "10px",
                            }}
                          >
                            Select Location
                          </Typography>
                          <KeyboardArrowDownRoundedIcon
                            sx={{
                              width: "20px",
                              height: "20px",
                              color: palette?.text?.greyIcon,
                            }}
                          />
                        </>
                      )}
                    </Box>
                  </Grid>
                  <Grid
                    onClick={(event: any) => {
                      handleVendor(event);
                    }}
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={3}
                    xl={3}
                  >
                    <Box
                      className={classes?.OverviewMenu}
                      sx={{
                        display: "flex",
                        // justifyContent: "center",
                        flexWrap: "nowrap",
                        listStyle: "none",
                        p: 0,
                        m: 0,
                        overflowX: "auto",
                        scrollbarWidth: "none", // This property is supported in Firefox
                        "-ms-overflow-style": "none", // This property is supported in Internet Explorer and Edge
                        "&::-webkit-scrollbar": {
                          display: "none", // This property is supported in Webkit browsers like Chrome and Safari
                        },
                      }}
                    >
                      {selectedTypes.length > 0 ? (
                        <>
                          {selectedTypes.map((type, index) => (
                            <Chip
                              key={index}
                              label={type}
                              onDelete={() => handleDeleteType(index)}
                              sx={{
                                margin: "4px",
                                height: "25px",
                                padding: "0px",
                                color: "#8B5CFF",
                              }}
                            />
                          ))}
                        </>
                      ) : (
                        <>
                          <Typography
                            variant="subtitle2"
                            sx={{
                              fontWeight: 500,
                              lineHeight: "16px",
                              color: palette?.text?.greyIcon,
                              marginLeft: "10px",
                            }}
                          >
                            Select Type
                          </Typography>
                          <KeyboardArrowDownRoundedIcon
                            sx={{
                              width: "20px",
                              height: "20px",
                              color: palette?.text?.greyIcon,
                            }}
                          />
                        </>
                      )}
                    </Box>
                  </Grid>

                  <Box
                    style={{
                      display: "flex",
                      padding: "10px 16px",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "8px",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                  >
                    <button
                      onClick={HandleGetFilteredData}
                      style={{
                        width: "120px",
                        height: "40px",
                        justifyContent: " center",
                        alignItems: "center",
                        borderRadius: "8px",
                        backgroundColor: "#8B5CFF",
                        color: "#FFFFFF",
                        borderWidth: "0px",
                        fontWeight: "500",
                        fontSize: "12px",
                        fontFamily: "Poppins",
                        transition: "background-color 0.3s",
                      }}
                      onMouseEnter={(e) => {
                        (e.target as HTMLButtonElement).style.backgroundColor =
                          "#6d43e57D";
                      }}
                      onMouseLeave={(e) => {
                        (e.target as HTMLButtonElement).style.backgroundColor =
                          "#8B5CFF";
                      }}
                    >
                      {loading ? (
                        <CircularProgress color="inherit" size={25} />
                      ) : (
                        "Apply"
                      )}
                    </button>
                  </Box>
                </Grid>
              </Fragment>
              <Grid
                container
                rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                sx={{ justifyContent: "flex-start", alignItems: "center" }}
              >
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <Box
                    className={classes.CardMain}
                    // sx={{ border: `1px solid #201C1C0A` }}
                  >
                    <Box className={classes.CardInner}>
                      <Box className={classes.IconOuter}>
                        <img
                          src={Coin}
                          alt="Miniosk"
                          style={{ height: "24px", width: "24px" }}
                        />
                      </Box>
                      <Box className={classes.TextOuter}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            gap: "5px",
                          }}
                        >
                          <Typography
                            variant="h1"
                            sx={{
                              color: "#201C1C",
                              textAlign: "center",
                              fontFamily: "Poppins",
                              fontSize: "24px",
                              fontStyle: "normal",
                              fontWeight: 600,
                              lineHeight: "24px",
                            }}
                          >
                            {InsightsList.total_orders}
                          </Typography>
                        </Box>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            textAlign: "center",
                            textTransform: "capitalize",
                            fontWeight: 400,
                            lineHeight: "16px",
                          }}
                        >
                          Total Orders
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={4} xl={4}>
                  <Box
                    className={classes.CardMain}
                    // sx={{ border: `1px solid #201C1C0A` }}
                  >
                    <Box className={classes.CardInner}>
                      <Box className={classes.IconOuter}>
                        <img
                          src={AverageTicket}
                          alt="Miniosk"
                          style={{ height: "24px", width: "24px" }}
                        />
                      </Box>
                      <Box className={classes.TextOuter}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            gap: "5px",
                          }}
                        >
                          <Typography
                            variant="h1"
                            sx={{
                              color: "#201C1C",
                              textAlign: "center",
                              fontFamily: "Poppins",
                              fontSize: "24px",
                              fontStyle: "normal",
                              fontWeight: 600,
                              lineHeight: "24px",
                            }}
                          >
                            $ {InsightsList.average_order_value}
                          </Typography>
                          {/* <Typography variant='subtitle2' sx={{ color: "#0038FF", fontWeight: 500, }}>+ 256</Typography> */}
                        </Box>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            textAlign: "center",
                            textTransform: "capitalize",
                            fontWeight: 400,
                            lineHeight: "16px",
                          }}
                        >
                          Average Order Value
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={4} xl={4}>
                  <Box
                    className={classes.CardMain}
                    // sx={{ border: `1px solid #201C1C0A` }}
                  >
                    <Box
                      className={`${classes.CardInner} `}
                      sx={{ width: "100%" }}
                    >
                      {InsightsList?.best_selling_item?.item_image
                        ?.thumbnail && (
                        <img
                          src={
                            InsightsList?.best_selling_item?.item_image
                              ?.thumbnail
                          }
                          alt="Miniosk"
                          style={{ height: "80px", width: "80px" }}
                        />
                      )}
                      <Box className={classes.TextOuter}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            gap: "1px",
                          }}
                        >
                          <Typography
                            title={InsightsList?.best_selling_item?.name || "-"}
                            noWrap
                            variant="h1"
                            sx={{
                              color: "#201C1C",
                              textAlign: "center",
                              fontFamily: "Poppins",
                              fontSize: "24px",
                              fontStyle: "normal",
                              fontWeight: 600,
                              overflow: "hidden",
                              //   textOverflow: "ellipsis",
                              //   whiteSpace: "nowrap",
                              // width: 120,
                            }}
                          >
                            {InsightsList?.best_selling_item?.name || "N/A"}
                          </Typography>
                          {/* <Typography variant='subtitle2' sx={{ color: "#0038FF", fontWeight: 500, }}>+ 256</Typography> */}
                        </Box>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            textAlign: "center",
                            textTransform: "capitalize",
                            fontWeight: 400,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            // whiteSpace: "nowrap",
                            width: "100%",
                          }}
                        >
                          Best Selling Item
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                  <Box
                    className={classes.CardMain}
                    // sx={{ border: `1px solid #201C1C0A` }}
                  >
                    <Box className={classes.CardInner}>
                      <Box className={classes.IconOuter}>
                        <img
                          src={Total}
                          alt="Miniosk"
                          style={{ height: "24px", width: "24px" }}
                        />
                      </Box>
                      <Box className={classes.TextOuter}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            gap: "5px",
                          }}
                        >
                          <Typography
                            variant="h1"
                            sx={{
                              color: "#201C1C",
                              textAlign: "center",
                              fontFamily: "Poppins",
                              fontSize: "24px",
                              fontStyle: "normal",
                              fontWeight: 600,
                              lineHeight: "24px",
                            }}
                          >
                            $ {InsightsList.total}
                          </Typography>
                        </Box>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            textAlign: "center",
                            textTransform: "capitalize",
                            fontWeight: 400,
                            lineHeight: "16px",
                          }}
                        >
                          total
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                  <Box
                    className={classes.CardMain}
                    // sx={{ border: `1px solid #201C1C0A` }}
                  >
                    <Box className={classes.CardInner}>
                      <Box className={classes.IconOuter}>
                        <img
                          src={SubTotal}
                          alt="Miniosk"
                          style={{ height: "24px", width: "24px" }}
                        />
                      </Box>
                      <Box className={classes.TextOuter}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            gap: "5px",
                          }}
                        >
                          <Typography
                            variant="h1"
                            sx={{
                              color: "#201C1C",
                              textAlign: "center",
                              fontFamily: "Poppins",
                              fontSize: "24px",
                              fontStyle: "normal",
                              fontWeight: 600,
                              lineHeight: "24px",
                            }}
                          >
                            $ {InsightsList.sub_total}
                          </Typography>
                          {/* <Typography variant='subtitle2' sx={{ color: "#0038FF", fontWeight: 500, }}>+ 256</Typography> */}
                        </Box>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            textAlign: "center",
                            textTransform: "capitalize",
                            fontWeight: 400,
                            lineHeight: "16px",
                          }}
                        >
                          Sub Total
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                  <Box
                    className={classes.CardMain}
                    // sx={{ border: `1px solid #201C1C0A` }}
                  >
                    <Box className={classes.CardInner}>
                      <Box className={classes.IconOuter}>
                        <img
                          src={Taxes}
                          alt="Miniosk"
                          style={{ height: "24px", width: "24px" }}
                        />
                      </Box>
                      <Box className={classes.TextOuter}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            gap: "5px",
                          }}
                        >
                          <Typography
                            variant="h1"
                            sx={{
                              color: "#201C1C",
                              textAlign: "center",
                              fontFamily: "Poppins",
                              fontSize: "24px",
                              fontStyle: "normal",
                              fontWeight: 600,
                              lineHeight: "24px",
                            }}
                          >
                            $ {InsightsList.tax}
                          </Typography>
                          {/* <Typography variant='subtitle2' sx={{ color: "#0038FF", fontWeight: 500, }}>+ 256</Typography> */}
                        </Box>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            textAlign: "center",
                            textTransform: "capitalize",
                            fontWeight: 400,
                            lineHeight: "16px",
                          }}
                        >
                          Taxes
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                  <Box
                    className={classes.CardMain}
                    // sx={{ border: `1px solid #201C1C0A` }}
                  >
                    <Box className={classes.CardInner}>
                      <Box className={classes.IconOuter}>
                        <img
                          src={Transactions}
                          alt="Miniosk"
                          style={{ height: "24px", width: "24px" }}
                        />
                      </Box>
                      <Box className={classes.TextOuter}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            gap: "5px",
                          }}
                        >
                          <Typography
                            variant="h1"
                            sx={{
                              color: "#201C1C",
                              textAlign: "center",
                              fontFamily: "Poppins",
                              fontSize: "24px",
                              fontStyle: "normal",
                              fontWeight: 600,
                              lineHeight: "24px",
                            }}
                          >
                            $ {InsightsList.tips}
                          </Typography>
                          {/* <Typography variant='subtitle2' sx={{ color: "#0038FF", fontWeight: 500, }}>+ 256</Typography> */}
                        </Box>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            textAlign: "center",
                            textTransform: "capitalize",
                            fontWeight: 400,
                            lineHeight: "16px",
                          }}
                        >
                          Tips
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </div>
      </div>
      <div>
        <div style={{ backgroundColor: "#FFFFFF", borderRadius: "8px" }}>
          <>
            <>
              <div
                className="sub-container"
                style={{ backgroundColor: "#FBFBFB" }}
              >
                <h2 className="sales-header">Sales</h2>
                <div className="sales-btn-container">
                  <div className="btn-pad">
                    {/* <DashboardFilter
                                            onSelectMenuCode={handleMenuCodeSelectionWrapper}
                                        /> */}
                    <Fragment>
                      <button
                        onClick={(event: any) => handleOpen(event)}
                        className="filter-btn"
                      >
                        <img
                          src={Filter}
                          alt="Delete"
                          style={{
                            height: "14px",
                            width: "14px",
                            marginRight: "10px",
                          }}
                        />
                        Filter
                      </button>

                      <Popover
                        open={Boolean(open)}
                        anchorEl={open}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        PaperProps={{
                          sx: { borderRadius: "8px", padding: "10px" },
                        }}
                      >
                        {MenuList?.map((value, index) => (
                          <Box
                            key={index}
                            onClick={(event) =>
                              setIsPopover({
                                ...isPopover,
                                [value?.popover]: event?.currentTarget,
                              })
                            }
                          >
                            <MenuItem
                              sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                gap: "50px",
                              }}
                            >
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  color: palette?.common?.menuItem,
                                  fontWeight: 500,
                                  lineHeight: "16px",
                                }}
                              >
                                {value?.Title}
                              </Typography>
                              <KeyboardArrowDownRoundedIcon
                                sx={{ width: "20px", height: "20px" }}
                              />
                            </MenuItem>
                          </Box>
                        ))}
                      </Popover>

                      <Popover
                        open={Boolean(isPopover?.Menu)}
                        anchorEl={isPopover?.Menu}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        PaperProps={{
                          sx: {
                            width: `${isPopover?.Menu?.clientWidth + 20}px`,
                          },
                          className: classes.popoverContainer,
                        }}
                      >
                        <Box className={classes.contentContainer}>
                          <Box className={classes.headerContainer}>
                            <Typography
                              variant="subtitle1"
                              className={classes.menuHeaderTitle}
                            >
                              Menu
                            </Typography>
                            <IconButton
                              disableFocusRipple
                              disableRipple
                              onClick={() =>
                                setIsPopover({
                                  ...isPopover,
                                  Menu: null,
                                  Category: null,
                                  Items: null,
                                  Modifiers: null,
                                  PaymentMode: null,
                                })
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M15 12.5L10 7.5L5 12.5"
                                  stroke="#201C1C"
                                  stroke-opacity="0.72"
                                  stroke-width="1.42281"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </IconButton>
                          </Box>

                          <Box className={classes.menuContainer}>
                            <TextField
                              type="text"
                              placeholder="Search"
                              onChange={(event) => handleSearch(event, "Menu")}
                              value={searchQuery}
                              InputProps={{
                                style: {
                                  borderRadius: "6px",
                                  backgroundColor: "#FFFFFF",
                                },
                                sx: {
                                  ".MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "6px",
                                  },
                                  input: {
                                    borderRadius: "6px",
                                    backgroundColor: "#FFFFFF",
                                    padding: "8px 8px",
                                  },
                                },
                                endAdornment: searchQuery && (
                                  <IconButton
                                    onClick={() => setSearchQuery("")}
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                ),
                              }}
                              size="medium"
                              fullWidth
                            />
                            <Box className={classes.scrollContainer}>
                              {Menu?.menus?.length > 0 ? (
                                <Box className={classes.scrollSubContainer}>
                                  <Box className={classes.containerMenu}>
                                    <Checkbox
                                      disableRipple
                                      indeterminate={
                                        selectedMenu?.length > 0 &&
                                        selectedMenu?.length <
                                          Menu?.menus?.length
                                      }
                                      checked={
                                        Menu?.menus?.length > 0 &&
                                        selectedMenu?.length ===
                                          Menu?.menus?.length
                                      }
                                      onChange={(event) =>
                                        handleSelectAllClick(event, "Menu")
                                      }
                                    />
                                    <Typography
                                      variant="subtitle1"
                                      className={classes.menuTitle}
                                    >
                                      Select All
                                    </Typography>
                                  </Box>
                                  {Menu?.menus
                                    ?.filter((value: any) =>
                                      value.menu_name
                                        .toLowerCase()
                                        .includes(searchQuery.toLowerCase())
                                    )
                                    .map((value: any, index: any) => {
                                      const isItemSelected = isSelectedMenu(
                                        value.menu_code,
                                        "Menu"
                                      );
                                      return (
                                        <Box
                                          key={index}
                                          className={classes.containerMenu}
                                        >
                                          <Checkbox
                                            disableRipple
                                            checked={isItemSelected}
                                            onChange={() => {
                                              handleClickData(
                                                value.menu_code,
                                                "menu"
                                              );
                                              setGlobalMenuCode(
                                                value.menu_code
                                              );
                                            }}
                                          />
                                          <Typography
                                            variant="subtitle1"
                                            className={classes.menuTitle}
                                          >
                                            {value.menu_name}
                                          </Typography>
                                        </Box>
                                      );
                                    })}
                                </Box>
                              ) : (
                                <Box
                                  sx={{
                                    textAlign: "center",
                                    marginTop: "16px",
                                  }}
                                >
                                  <Typography variant="subtitle1">
                                    No Menus Found
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Popover>

                      <Popover
                        open={Boolean(isPopover?.Category)}
                        anchorEl={isPopover?.Category}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        PaperProps={{
                          sx: {
                            width: `${
                              isPopover?.Menu?.menus?.clientWidth + 20
                            }px`,
                          },
                          className: classes.popoverContainer,
                        }}
                      >
                        <Box className={classes.contentContainer}>
                          <Box className={classes.headerContainer}>
                            <Typography
                              variant="subtitle1"
                              className={classes.menuHeaderTitle}
                            >
                              Category
                            </Typography>
                            <IconButton
                              disableFocusRipple
                              disableRipple
                              onClick={() =>
                                setIsPopover({
                                  ...isPopover,
                                  Menu: null,
                                  Category: null,
                                  Items: null,
                                  Modifiers: null,
                                  PaymentMode: null,
                                })
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M15 12.5L10 7.5L5 12.5"
                                  stroke="#201C1C"
                                  stroke-opacity="0.72"
                                  stroke-width="1.42281"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </IconButton>
                          </Box>
                          <Box className={classes.menuContainer}>
                            <TextField
                              type="text"
                              placeholder="Search"
                              onChange={(event) =>
                                handleSearch(event, "Category")
                              }
                              value={searchQuery}
                              InputProps={{
                                style: {
                                  borderRadius: "6px",
                                  backgroundColor: "#FFFFFF",
                                },
                                sx: {
                                  ".MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "6px",
                                  },
                                  input: {
                                    borderRadius: "6px",
                                    backgroundColor: "#FFFFFF",
                                    padding: "8px 8px",
                                  },
                                },
                                endAdornment: searchQuery && (
                                  <IconButton
                                    onClick={() => setSearchQuery("")}
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                ),
                              }}
                              size="medium"
                              fullWidth
                            />
                            <Box className={classes.scrollContainer}>
                              {Menu?.categories?.length > 0 ? (
                                <Box className={classes.scrollSubContainer}>
                                  <Box className={classes.containerMenu}>
                                    <Checkbox
                                      disableRipple
                                      indeterminate={
                                        selectedMenu?.length > 0 &&
                                        selectedMenu?.length <
                                          Menu?.categories?.length
                                      }
                                      checked={
                                        Menu?.categories?.length > 0 &&
                                        selectedCategory?.length ===
                                          Menu?.categories?.length
                                      }
                                      onChange={(event) =>
                                        handleSelectAllClick(event, "Category")
                                      }
                                    />
                                    <Typography
                                      variant="subtitle1"
                                      className={classes.menuTitle}
                                    >
                                      Select All
                                    </Typography>
                                  </Box>

                                  {Menu?.categories
                                    ?.filter((value: any) =>
                                      value.name
                                        .toLowerCase()
                                        .includes(searchQuery.toLowerCase())
                                    )
                                    .map((value: any, index: any) => {
                                      const isItemSelected = isSelectedMenu(
                                        value?.id,
                                        "Category"
                                      );
                                      return (
                                        <Box
                                          key={index}
                                          className={classes.containerMenu}
                                        >
                                          <Checkbox
                                            disableRipple
                                            checked={isItemSelected}
                                            onChange={() => {
                                              handleClickData(
                                                value.id,
                                                "category"
                                              );
                                              setGlobalCategoryCode(value.id);
                                            }}
                                          />
                                          <Typography
                                            variant="subtitle1"
                                            className={classes.menuTitle}
                                          >
                                            {value?.name}
                                          </Typography>
                                        </Box>
                                      );
                                    })}
                                </Box>
                              ) : (
                                <Box
                                  sx={{
                                    textAlign: "center",
                                    marginTop: "16px",
                                  }}
                                >
                                  <Typography variant="subtitle1">
                                    No Categories Found
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Popover>

                      <Popover
                        open={Boolean(isPopover?.Items)}
                        anchorEl={isPopover?.Items}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        PaperProps={{
                          sx: {
                            width: `${isPopover?.Menu?.clientWidth + 20}px`,
                          },
                          className: classes.popoverContainer,
                        }}
                      >
                        <Box className={classes.contentContainer}>
                          <Box className={classes.headerContainer}>
                            <Typography
                              variant="subtitle1"
                              className={classes.menuHeaderTitle}
                            >
                              Items
                            </Typography>
                            <IconButton
                              disableFocusRipple
                              disableRipple
                              onClick={() =>
                                setIsPopover({
                                  ...isPopover,
                                  Menu: null,
                                  Category: null,
                                  Items: null,
                                  Modifiers: null,
                                  PaymentMode: null,
                                })
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M15 12.5L10 7.5L5 12.5"
                                  stroke="#201C1C"
                                  stroke-opacity="0.72"
                                  stroke-width="1.42281"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </IconButton>
                          </Box>

                          <Box className={classes.menuContainer}>
                            <TextField
                              type="Text"
                              placeholder="Search"
                              onChange={(event) => handleSearch(event, "Items")}
                              value={searchQuery}
                              InputProps={{
                                style: {
                                  borderRadius: "6px",
                                  backgroundColor: "#FFFFFF",
                                },
                                sx: {
                                  ".MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "6px",
                                  },
                                  input: {
                                    borderRadius: "6px",
                                    backgroundColor: "#FFFFFF",
                                    padding: "8px 8px",
                                  },
                                },
                                endAdornment: searchQuery && (
                                  <IconButton
                                    onClick={() => setSearchQuery("")}
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                ),
                              }}
                              size="medium"
                              fullWidth
                            />
                            <Box className={classes.scrollContainer}>
                              {Menu?.items?.length > 0 ? (
                                <Box className={classes.scrollSubContainer}>
                                  <Box className={classes.containerMenu}>
                                    <Checkbox
                                      disableRipple
                                      indeterminate={
                                        selectedMenu?.length > 0 &&
                                        selectedMenu?.length <
                                          Menu?.items?.length
                                      }
                                      checked={
                                        Menu?.items?.length > 0 &&
                                        selectedItem?.length ===
                                          Menu?.items?.length
                                      }
                                      onChange={(event) =>
                                        handleSelectAllClick(event, "Items")
                                      }
                                    />
                                    <Typography
                                      variant="subtitle1"
                                      className={classes.menuTitle}
                                    >
                                      Select All
                                    </Typography>
                                  </Box>

                                  {Menu?.items
                                    ?.filter((value: any) =>
                                      value.name
                                        .toLowerCase()
                                        .includes(searchQuery.toLowerCase())
                                    )
                                    .map((value: any, index: any) => {
                                      const isItemSelected = isSelectedMenu(
                                        value?.id,
                                        "Items"
                                      );
                                      return (
                                        <Box
                                          key={index}
                                          className={classes.containerMenu}
                                        >
                                          <Checkbox
                                            disableRipple
                                            checked={isItemSelected}
                                            onChange={() => {
                                              handleClickData(
                                                value.id,
                                                "items"
                                              );
                                              setGlobalItemCode(value.id);
                                            }}
                                          />
                                          <Typography
                                            variant="subtitle1"
                                            className={classes.menuTitle}
                                          >
                                            {value?.name}
                                          </Typography>
                                        </Box>
                                      );
                                    })}
                                </Box>
                              ) : (
                                <Box
                                  sx={{
                                    textAlign: "center",
                                    marginTop: "16px",
                                  }}
                                >
                                  <Typography variant="subtitle1">
                                    No Items Found
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Popover>

                      <Popover
                        open={Boolean(isPopover?.Modifiers)}
                        anchorEl={isPopover?.Modifiers}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        PaperProps={{
                          sx: {
                            width: `${isPopover?.Menu?.clientWidth + 20}px`,
                          },
                          className: classes.popoverContainer,
                        }}
                      >
                        <Box className={classes.contentContainer}>
                          <Box className={classes.headerContainer}>
                            <Typography
                              variant="subtitle1"
                              className={classes.menuHeaderTitle}
                            >
                              Modifiers
                            </Typography>
                            <IconButton
                              disableFocusRipple
                              disableRipple
                              onClick={() =>
                                setIsPopover({
                                  ...isPopover,
                                  Menu: null,
                                  Category: null,
                                  Items: null,
                                  Modifiers: null,
                                  PaymentMode: null,
                                })
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M15 12.5L10 7.5L5 12.5"
                                  stroke="#201C1C"
                                  stroke-opacity="0.72"
                                  stroke-width="1.42281"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </IconButton>
                          </Box>

                          <Box className={classes.menuContainer}>
                            <TextField
                              type="Text"
                              placeholder="Search"
                              onChange={(event) =>
                                handleSearch(event, "Modifiers")
                              }
                              value={searchQuery}
                              InputProps={{
                                style: {
                                  borderRadius: "6px",
                                  backgroundColor: "#FFFFFF",
                                },
                                sx: {
                                  ".MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "6px",
                                  },
                                  input: {
                                    borderRadius: "6px",
                                    backgroundColor: "#FFFFFF",
                                    padding: "8px 8px",
                                  },
                                },
                                endAdornment: searchQuery && (
                                  <IconButton
                                    onClick={() => setSearchQuery("")}
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                ),
                              }}
                              size="medium"
                              fullWidth
                            />
                            <Box className={classes.scrollContainer}>
                              {Menu?.modifiers?.length > 0 ? (
                                <Box className={classes.scrollSubContainer}>
                                  <Box className={classes.containerMenu}>
                                    <Checkbox
                                      disableRipple
                                      indeterminate={
                                        selectedMenu?.length > 0 &&
                                        selectedMenu?.length <
                                          Menu?.modifiers?.length
                                      }
                                      checked={
                                        Menu?.modifiers?.length > 0 &&
                                        selectedModifier?.length ===
                                          Menu?.modifiers?.length
                                      }
                                      onChange={(event) =>
                                        handleSelectAllClick(event, "Modifiers")
                                      }
                                    />
                                    <Typography
                                      variant="subtitle1"
                                      className={classes.menuTitle}
                                    >
                                      Select All
                                    </Typography>
                                  </Box>
                                  {Menu?.modifiers
                                    ?.filter((value: any) =>
                                      value.name
                                        .toLowerCase()
                                        .includes(searchQuery.toLowerCase())
                                    )
                                    .map((value: any, index: any) => {
                                      const isItemSelected = isSelectedMenu(
                                        value?.id,
                                        "Modifiers"
                                      );
                                      return (
                                        <Box
                                          key={index}
                                          className={classes.containerMenu}
                                        >
                                          <Checkbox
                                            disableRipple
                                            checked={isItemSelected}
                                            onChange={() => {
                                              handleClickData(
                                                value.id,
                                                "modifier"
                                              );
                                              setGlobalItemCode(value.id);
                                            }}
                                          />
                                          <Typography
                                            variant="subtitle1"
                                            className={classes.menuTitle}
                                          >
                                            {value?.name}
                                          </Typography>
                                        </Box>
                                      );
                                    })}
                                </Box>
                              ) : (
                                <Box
                                  sx={{
                                    textAlign: "center",
                                    marginTop: "16px",
                                  }}
                                >
                                  <Typography variant="subtitle1">
                                    No Modifiers Found
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Popover>

                      <Popover
                        open={Boolean(isPopover?.PaymentMode)}
                        anchorEl={isPopover?.PaymentMode}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        PaperProps={{
                          sx: {
                            width: `${isPopover?.Menu?.clientWidth + 20}px`,
                          },
                          className: classes.popoverContainer,
                        }}
                      >
                        <Box className={classes.contentContainer}>
                          <Box className={classes.headerContainer}>
                            <Typography
                              variant="subtitle1"
                              className={classes.menuHeaderTitle}
                            >
                              Payment Mode
                            </Typography>
                            <IconButton
                              disableFocusRipple
                              disableRipple
                              onClick={() =>
                                setIsPopover({
                                  ...isPopover,
                                  Menu: null,
                                  Category: null,
                                  Items: null,
                                  Modifiers: null,
                                  PaymentMode: null,
                                })
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M15 12.5L10 7.5L5 12.5"
                                  stroke="#201C1C"
                                  stroke-opacity="0.72"
                                  stroke-width="1.42281"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </IconButton>
                          </Box>

                          <Box className={classes.menuContainer}>
                            <TextField
                              type="Text"
                              placeholder="Search"
                              onChange={(event) =>
                                handleSearch(event, "PaymentMode")
                              }
                              value={searchQuery}
                              InputProps={{
                                style: {
                                  borderRadius: "6px",
                                  backgroundColor: "#FFFFFF",
                                },
                                sx: {
                                  ".MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "6px",
                                  },
                                  input: {
                                    borderRadius: "6px",
                                    backgroundColor: "#FFFFFF",
                                    padding: "8px 8px",
                                  },
                                },
                                endAdornment: searchQuery && (
                                  <IconButton
                                    onClick={() => setSearchQuery("")}
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                ),
                              }}
                              size="medium"
                              fullWidth
                            />
                            <Box className={classes.scrollContainer}>
                              {Menu?.paymentmode?.length > 0 ? (
                                <Box className={classes.scrollSubContainer}>
                                  <Box className={classes.containerMenu}>
                                    <Checkbox
                                      disableRipple
                                      indeterminate={
                                        selectedMenu?.length > 0 &&
                                        selectedMenu?.length <
                                          Menu?.paymentmode?.length
                                      }
                                      checked={
                                        Menu?.paymentmode?.length > 0 &&
                                        selectedMenu?.length ===
                                          Menu?.paymentmode?.length
                                      }
                                      onChange={(event) =>
                                        handleSelectAllClick(
                                          event,
                                          "PaymentMode"
                                        )
                                      }
                                    />
                                    <Typography
                                      variant="subtitle1"
                                      className={classes.menuTitle}
                                    >
                                      Select All
                                    </Typography>
                                  </Box>
                                  {Menu?.paymentmode
                                    ?.filter((value: any) =>
                                      value
                                        .toLowerCase()
                                        .includes(searchQuery.toLowerCase())
                                    )
                                    .map((value: any, index: any) => {
                                      const isItemSelected = isSelectedMenu(
                                        value,
                                        "PaymentMode"
                                      );
                                      return (
                                        <Box
                                          key={index}
                                          className={classes.containerMenu}
                                        >
                                          <Checkbox
                                            disableRipple
                                            checked={isItemSelected}
                                            onChange={() => {
                                              handleClickData(
                                                value,
                                                "paymentmode"
                                              );
                                            }}
                                          />
                                          <Typography
                                            variant="subtitle1"
                                            className={classes.menuTitle}
                                          >
                                            {value}
                                          </Typography>
                                        </Box>
                                      );
                                    })}
                                </Box>
                              ) : (
                                <Box
                                  sx={{
                                    textAlign: "center",
                                    marginTop: "16px",
                                  }}
                                >
                                  <Typography variant="subtitle1">
                                    No Payment Modes Found
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Popover>
                    </Fragment>
                  </div>
                  <DownloadSummary />
                </div>
              </div>
            </>
            <DataTable
              order={order}
              orderBy={orderBy}
              nPages={nPages}
              currentRecords={currentRecords}
              visibleRows={visibleRows}
              controller={controller}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
              setController={setController}
              setCurrentPage={setCurrentPage}
              headCells={headCells}
              loading={loading}
            />
          </>
        </div>
      </div>

      <Popover
        open={Boolean(onRange)}
        anchorEl={onRange}
        onClose={() => setRange(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          ".css-1q04gal-MuiDateCalendar-root": {
            borderRadius: "8px",
            width: "280px",
            "@media (max-width: 1400px)": {
              width: "250px",
            },
            "@media (max-width: 700px)": {
              width: "200px",
            },
          },
        }}
      >
        <Box sx={{ p: 0.8, width: "100%" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              onChange={(date: any) => {
                if (
                  !selectedStartDate ||
                  (selectedStartDate && selectedEndDate)
                ) {
                  setSelectedStartDate(date);
                  setSelectedEndDate(null);
                } else if (date.isAfter(selectedStartDate)) {
                  setSelectedEndDate(date);
                  setRange(null);
                } else {
                  setSelectedStartDate(date);
                  setSelectedEndDate(null);
                }
              }}
              disableFuture={true}
              showDaysOutsideCurrentMonth={true}
              renderLoading={() => (
                <span data-mui-test="loading-progress">...</span>
              )}
            />
          </LocalizationProvider>
        </Box>
      </Popover>

      <Popover
        open={Boolean(onLocation)}
        anchorEl={onLocation}
        onClose={() => setLocation(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        PaperProps={{
          sx: { width: `${onLocation?.clientWidth}px`, borderRadius: "8px" },
        }}
      >
        <Box sx={{ p: 0.8, width: "100%", height: "200px", overflow: "auto" }}>
          <TextField
            type="text"
            value={onLocation ? locationSearch : vendorSearch}
            onChange={(e) => {
              onLocation
                ? setLocationSearch(e.target.value)
                : setVendorSearch(e.target.value);
            }}
            placeholder="Search"
            InputProps={{
              style: { borderRadius: "6px", backgroundColor: "#FFFFFF" },
              sx: {
                ".MuiOutlinedInput-notchedOutline": { borderRadius: "6px" },
                input: {
                  borderRadius: "6px",
                  backgroundColor: "#FFFFFF",
                  padding: "8px 8px",
                },
              },
              endAdornment: locationSearch && (
                <IconButton onClick={() => setLocationSearch("")}>
                  <ClearIcon />
                </IconButton>
              ),
            }}
            size="medium"
            fullWidth
          />
          <Box className={classes.DasboardFilterMain} sx={{ pt: 0.5 }}>
            {/* Select All Checkbox */}
            <Box className={classes.DasboardFilterItem}>
              <Checkbox
                disableRipple
                checked={selectedLocations?.length === LocationsList?.length}
                onChange={handleSelectAll}
              />
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                Select All
              </Typography>
            </Box>

            {/* Display all selected locations */}
            {LocationsList?.map((location: any, index: any) => {
              const isVisible =
                location.name
                  .toLowerCase()
                  .includes(locationSearch.toLowerCase()) ||
                selectedLocations.includes(location.id);

              if (!isVisible) return null;

              return (
                <Box key={index} className={classes.DasboardFilterItem}>
                  <Checkbox
                    disableRipple
                    checked={selectedLocations.includes(location.id)}
                    onChange={() => handleClick(location.id, location.name)}
                  />
                  <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                    {location.name}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Popover>

      <Popover
        open={Boolean(onVendor)}
        anchorEl={onVendor}
        onClose={() => setVendor(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        PaperProps={{
          sx: { width: `${onVendor?.clientWidth}px`, borderRadius: "8px" },
        }}
      >
        <Box sx={{ p: 0.8, width: "100%", height: "200px", overflow: "auto" }}>
          <TextField
            value={vendorSearch}
            onChange={(e) => setVendorSearch(e.target.value)}
            type="text"
            placeholder="Search"
            InputProps={{
              style: { borderRadius: "6px", backgroundColor: "#FFFFFF" },
              sx: {
                ".MuiOutlinedInput-notchedOutline": { borderRadius: "6px" },
                input: {
                  borderRadius: "6px",
                  backgroundColor: "#FFFFFF",
                  padding: "8px 8px",
                },
              },
              endAdornment: vendorSearch && (
                <IconButton onClick={() => setVendorSearch("")}>
                  <ClearIcon />
                </IconButton>
              ),
            }}
            size="medium"
            fullWidth
          />
          <Box className={classes.DasboardFilterMain} sx={{ pt: 0.5 }}>
            {/* Select All Checkbox */}
            <Box className={classes.DasboardFilterItem}>
              <Checkbox
                disableRipple
                checked={selectedTypes.length === Type.length}
                onChange={handleSelectAllTypes}
              />
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                Select All
              </Typography>
            </Box>

            {/* Display filtered types */}
            {Type.filter((type) =>
              type.value.toLowerCase().includes(vendorSearch.toLowerCase())
            ).map((filteredType, index) => (
              <Box className={classes.DasboardFilterItem} key={index}>
                <Checkbox
                  disableRipple
                  checked={selectedTypes.includes(filteredType.value)}
                  onChange={() => handleTypeSelection(filteredType.value)}
                />
                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                  {filteredType.value}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Popover>
    </>
  );
}

export default Dashboard;

const Type = [
  {
    label: "Tab",
    value: "Tab",
  },
  {
    label: "Qr Codes",
    value: "Qr Codes",
  },
  {
    label: "Web URL",
    value: "Web URL",
  },
];

const MenuList = [
  { id: 1, popover: "Menu", Title: "Menu" },
  { id: 2, popover: "Category", Title: "Category" },
  { id: 3, popover: "Items", Title: "Items" },
  { id: 4, popover: "Modifiers", Title: "Modifiers" },
  { id: 5, popover: "PaymentMode", Title: "Payment Mode" },
];
