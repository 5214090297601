import { Fragment, useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "../screens/Insights/Insights";
import Catalog from "../screens/Catalog/Catalog";
import MenuDetails from "../screens/MenuDetails/MenuDetails";
import Manage from "../screens/Manage/Manage";
import Customers from "../screens/Customers/Customers";
import CustomerUserDetails from "../screens/CustomerUserDetails/CustomerUserDetails";
import PromoCode from "../screens/Promos/PromoCode";
import PromotionCreation from "../screens/PromotionCreation/PromotionCreation";
import PromotionUpdate from "../screens/PromotionCreation/PromotionUpdate";
import Advanced from "../screens/Advanced/Advanced";
import Behaviour from "../screens/Behaviour/Behaviour";
import Referrals from "../screens/Referrals/Referrals";
import Support from "../screens/Support/Support";
import Settings from "../screens/Settings/Settings";
import Display from "../screens/Display/Display";
import Theme from "../screens/Theme/Theme";
import Subscription from "../screens/Subscription/Subscription";
import Payment from "../screens/Subscription/Payment";
import Stripe from "../screens/Stripe";
import Success from "../screens/Stripe/success";
import Location from "../screens/Manage/Location";
import BillingInfoTable from "../screens/Settings/BillingInfoTable";
import LinkAccount from "../screens/LinkAccount/LinkAccount";
import { useNavigate } from "react-router-dom";
import ApiServices from "../helpers/ApiServices";
import { useDispatch } from "react-redux";
import { setSubsInfo } from "../store/reducers/VendorDetailsReducer";
import HelperModule from "../helpers/HelperModule";
import { useSelector } from "react-redux";
import { setLocationAdded, setPosConnect, setStoreLimit } from "../store/reducers/AuthReducer";
import { useAppDispatch } from "../Hooks";

const AppRoutes = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isSubsExpired, setSubsExpired] = useState(false);

  const subsInfo = useSelector((state) => state.vendorDetails.subsInfo);
  const { posConnection } = useSelector((state) => state.Auth);

  const userCode = localStorage.getItem("UserCode");

  const routes = [
    { path: "*", element: <Navigate to="/dashboard" />, allowExpired: false },
    { path: "/linkaccount", element: <LinkAccount />, allowExpired: false },
    { path: "/dashboard", element: <Dashboard />, allowExpired: false },
    { path: "/catalog", element: <Catalog />, allowExpired: false },
    // {
    //   path: "/menuDetails/:menu_name/:menu_code/",
    //   element: <MenuDetails />,
    //   allowExpired: false,
    // },
    {
      path: "/menuDetails/:menu_name/:menu_code/",
      element: <MenuDetails />,
      allowExpired: false,
    },
    { path: "/manage", element: <Manage />, allowExpired: false },
    { path: "/customers", element: <Customers />, allowExpired: false },
    {
      path: "/customeruserdetails",
      element: <CustomerUserDetails />,
      allowExpired: false,
    },
    { path: "/promos", element: <PromoCode />, allowExpired: false },
    {
      path: "/promotioncreation",
      element: <PromotionCreation />,
      allowExpired: false,
    },
    {
      path: "/promotionupdate",
      element: <PromotionUpdate />,
      allowExpired: false,
    },
    { path: "/advanced", element: <Advanced />, allowExpired: false },
    { path: "/behaviour", element: <Behaviour />, allowExpired: false },
    { path: "/referrals", element: <Referrals />, allowExpired: false },
    { path: "/support", element: <Support />, allowExpired: true },
    { path: "/settings", element: <Settings />, allowExpired: true },
    { path: "/display", element: <Display />, allowExpired: false },
    { path: "/theme", element: <Theme />, allowExpired: false },
    { path: "/subscription", element: <Subscription />, allowExpired: true },
    { path: "/stripe", element: <Stripe />, allowExpired: true },
    { path: "/success", element: <Success />, allowExpired: true },
    { path: "/payment", element: <Payment />, allowExpired: true },
    { path: "/location", element: <Location />, allowExpired: false },
    {
      path: "/BillingInfoTable",
      element: <BillingInfoTable />,
      allowExpired: true,
    },
  ];

  useEffect(() => {
    // if (posConnection === 0 || null) {
    //   navigate("/linkaccount");
    // }
    PosAccounStatus();
  }, [navigate]);

  // useEffect(()=>{
  //   if (posConnection === 1) {
  //     navigate("/dashboard");
  //   }else{
  //     navigate("/linkaccount");
  //   }
  // },[posConnection])

  const PosAccounStatus = async () => {
    if (userCode) {
      try {
        const response = await HelperModule().getMethod(
          ApiServices.base_URL + ApiServices.get_vendor + userCode
        );
        if (response.status === true) {
          // console.log(response?.data?.vendor_details);
          dispatch(setSubsInfo(response?.data?.vendor_details));
          dispatch(setPosConnect(response?.data?.vendor_details?.pos_account_status));
          dispatch(setLocationAdded(response?.data?.vendor_details?.pos_account?.location_added))
          dispatch(setStoreLimit(response?.data?.plan_details?.store_limit))
          // if (response?.data?.vendor_details?.pos_account_status === 0) {
          //   navigate("/linkaccount");
          // }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    //check subscription status
    if (subsInfo?.subscription_status === 0) {
      setSubsExpired(true);
    }
    if (!subsInfo) {
      PosAccounStatus();
    }
  }, [navigate, subsInfo]);

  return (
    <Fragment>
      <Routes>
        {routes.map((route, index) => {
          if (!route.allowExpired && isSubsExpired) {
            // Redirect to settings page if subscription has expired
            return (
              <Route
                key={index}
                path={route.path}
                element={<Navigate to="/settings" />}
              />
            );
          }
          return (
            <Route key={index} path={route.path} element={route.element} />
          );
        })}
      </Routes>
    </Fragment>
  );
};

export default AppRoutes;
