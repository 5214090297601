import React from 'react'
import '../Manage/ManageStyles.css'
import '../Customers/Customers.css'
import BackNavigation from '../../components/BackNavigation';
import CustomerUserDetailsTable from '../../components/CustomerUserDetailsTable';
import './CustomerUserDetails.css'

function CustomerUserDetails() {
    return (
        <div>
            <BackNavigation label="User Details" />
                <CustomerUserDetailsTable />
        </div>
    )
}

export default CustomerUserDetails