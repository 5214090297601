import { createSlice } from "@reduxjs/toolkit";

interface initialStateProps {
  LocationsList: any;
  locationSelectedId: String;
  filteredLocations: Array<String>;
}

const initialState: initialStateProps = {
  LocationsList: [],
  locationSelectedId: "",
  filteredLocations: [],
};

const locationsListDetailsReducer = createSlice({
  name: "locationDetails",
  initialState,
  reducers: {
    setLocationsList: (state, action) => {
      state.LocationsList = action.payload;
    },
    setLocationSelectedId: (state, action) => {
      state.locationSelectedId = action.payload;
    },
    setFilteredLocations: (state, action) => {
      state.filteredLocations = action.payload;
    },
  },
});

export const { setLocationsList, setLocationSelectedId, setFilteredLocations } =
  locationsListDetailsReducer.actions;
export default locationsListDetailsReducer.reducer;
