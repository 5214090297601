import { configureStore } from '@reduxjs/toolkit'
import AuthReducer from './reducers/AuthReducer'
import vendorDetailsReducer from './reducers/VendorDetailsReducer';
import menuListReducer from './reducers/MenuListReducer';
import catalogDetails from './reducers/CatalogDataReducer'
import modifierDetails from './reducers/ModifierDataReducer'
import promoCodeDetails from './reducers/PromoCodeReducer'
import subscriptionList from './reducers/SubscriptionReducer'
import locationDetails from './reducers/LocationsReducer'
import insightsDetails from './reducers/InsightsReducer'
import itemsListDetails from './reducers/ItemsListReducer'
import hotSellingListDetails from './reducers/HotSellingReducer'
import upSellingListDetails from './reducers/UpSellingReducer'
import crossSellingListDetails from './reducers/CrossSellingReducer'
import hotSellingDrpDetails from './reducers/HotSellingDrpReducer'
import manageData from './reducers/ManageReducer'

export const store = configureStore({
    reducer: {
        Auth: AuthReducer,
        vendorDetails: vendorDetailsReducer,
        menuListDetails: menuListReducer,
        catalogDetails: catalogDetails,
        modifierDetails: modifierDetails,
        promoCodeDetails: promoCodeDetails,
        subscriptionList: subscriptionList,
        locationDetails: locationDetails,
        insightsDetails: insightsDetails,
        itemsListDetails: itemsListDetails,
        hotSellingListDetails: hotSellingListDetails,
        upSellingListDetails: upSellingListDetails,
        crossSellingListDetails: crossSellingListDetails,
        hotSellingDrpDetails: hotSellingDrpDetails,
        manageData: manageData
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch