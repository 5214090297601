import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useSnackbar } from "notistack";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import { useAppDispatch } from "../../Hooks";
import BGIMAGE from "../../assets/images/background.jpg";
import LOGO from "../../assets/images/logo_main_img.png";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setPosConnect } from "../../store/reducers/AuthReducer";
import { Button, Grid, IconButton, InputAdornment, OutlinedInput, TextField, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import "./CreatePassword.css"

let firstName: any;
let lastName: any;
let email: any;
let phone: any;
let setPasswordToken: any;
function CreatePassword() {
  const navigate = useNavigate();
  const [password, setPassword]: any = useState("");
  const [confirmPassword, setConfirmPassword]: any = useState("");
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [auth_token, setAuthToken] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    sessionStorage.removeItem("timer");
  }, []);

  useEffect(() => {
    const Authorization: any = localStorage.getItem("Token");
    setAuthToken(Authorization);
    const set_password_token = localStorage.getItem("set_password_token");
    setPasswordToken = set_password_token;
    console.log("Authorization====>>>***", Authorization);
  }, []);

  useEffect(() => {
    const FirstName = localStorage.getItem("firstNameCreate");
    firstName = FirstName;
    const LastName = localStorage.getItem("lastNameCreate");
    lastName = LastName;
    const Email = localStorage.getItem("emailCreate");
    email = Email;
    const Phone = localStorage.getItem("phoneCreate");
    phone = Phone;
    // console.log(firstName, lastName, email, phone);
  }, []);

  const validatePassword = (passwordTest: any) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+?])[A-Za-z\d!@#$%^&*()_+?]{6,15}$/;
    return regex.test(password);
  };

  const RegisterSuccess = async () => {
    try {
      if (!auth_token) {
        console.error("Token is not available yet.");
        console.log(auth_token);
        return;
      }
      const token = auth_token;
      console.log("token--------@@@@@@@@@@@@@", token, validatePassword(password));
      if (!validatePassword(password)) {
        setPasswordError(
          "Password must include:\n• 6-15 characters\n• At least 1 uppercase letter\n• At least 1 special character\n• At least 1 number",
        );
        return;
      } else {
        setPasswordError(""); // Clear error if valid
      }
      if (password !== confirmPassword || password === "") {
        setConfirmPasswordError("Password didn't match.");
        return;
      } else {
        setConfirmPasswordError("");
      }

      const url = `https://api.miniosk.com/api/resetPassword?password=${password}&token=${setPasswordToken}`;

      const headers: any = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      console.log("headers", headers);
      console.log("api url", url);
      axios.post(url, headers).then((response: any) => {
        if (
          response.data.status === true &&
          password === confirmPassword &&
          password !== ""
        ) {
          dispatch(setPosConnect(response.data.user.pos_id));
          // localStorage.setItem("PosAccount", response.data.user.pos_id);
          console.log("Response:", response.data);
          navigate("/helpUsToGrow");
          localStorage.setItem("IsLoginCreate", response.data.user);
          console.log(
            "response.data.user.pos_account_exists",
            response.data.user.pos_id
          );
        } else {
          console.log("Error Create Password");
        }
      });
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  return (
    <>
      {/* <div className="container-login text-start">
        <div className="main-container-login">
          <h2 className="miniosk-login">Don't stop now</h2>
          <p className="miniosk-login-desc" style={{ width: "29.55vw" }}>
            Create Password
          </p>
          <div>
            <h3 className="email-miniosk">Enter Password</h3>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <input
                placeholder="Enter a password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                minLength={6}
                maxLength={15}
                className="miniosk-input"
              />
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    right: "5px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    display: "flex",
                  }}
                >
                  {showPassword ? (
                    <VisibilityOffIcon
                      className="mt-3 text-gray-400 cursor-pointer"
                      onClick={togglePasswordVisibility}
                    />
                  ) : (
                    <VisibilityIcon
                      className="mt-3 text-gray-400 cursor-pointer"
                      onClick={togglePasswordVisibility}
                    />
                  )}
                </div>
              </div>
            </div>
            {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}
          </div>
          <div>
            <h3 className="email-miniosk">Confirm Password</h3>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <input
                placeholder="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                minLength={6}
                maxLength={15}
                className="miniosk-input"
              />
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    right: "5px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    display: "flex",
                  }}
                >
                  {showPassword ? (
                    <VisibilityOffIcon
                      className="mt-3 text-gray-400 cursor-pointer"
                      onClick={toggleConfirmPasswordVisibility}
                    />
                  ) : (
                    <VisibilityIcon
                      className="mt-3 text-gray-400 cursor-pointer"
                      onClick={toggleConfirmPasswordVisibility}
                    />
                  )}
                </div>
              </div>
            </div>
            {confirmPasswordError && (
              <p style={{ color: "red" }}>{confirmPasswordError}</p>
            )}
          </div>
          <button
            className="continue-create-pass-button"
            onClick={RegisterSuccess}
          >
            Continue
          </button>
          <div className="kiosk_imgDiv">
            <img src={LOGO} alt="logo" style={{ width: "150px" }} />
          </div>
        </div>
        <div style={{ height: "100vh", overflow: "hidden", width: "60%" }}>
          <img
            src={BGIMAGE}
            alt="bg"
            style={{ maxWidth: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
      </div> */}
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={5}
          xl={5}
          className="grid-container-password"
        >
          <div className="container-hide"></div>
          <div className="container-create-password">
            <div className="header-text">
              <Typography
                variant="h4"
                sx={{
                  typography: {
                    xs: "h4",
                    sm: "h4",
                    md: "h4",
                    lg: "h4",
                    xl: "h4",
                  },
                  color: "#201C1C",
                  fontFamily: "Poppins",
                }}
              >
                Create a password
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ color: "#201C1CB8", fontFamily: "Poppins" }}
              >
                Strengthening your defense - Create a powerful password now
              </Typography>
            </div>
            <div className="form-container">
              <div>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#201C1C",
                    fontFamily: "Poppins",
                    textAlign: "left",
                  }}
                  mb={1}
                  mt={2}
                  className="email-header"
                >
                  Password
                </Typography>
                <OutlinedInput
                  error={passwordError ? true : false}
                  id="outlined-adornment-password"
                  className="textfield-create-pass"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter password"
                  sx={{
                    "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                      height: "2rem",
                      borderRadius: "8px",
                    },
                  }}
                  onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {passwordError && <Typography variant="body1" color="error">
                  <ul style={{ margin: 0, paddingLeft: 20 }}>
                    <li>6-15 characters</li>
                    <li>At least 1 uppercase letter</li>
                    <li>At least 1 special character</li>
                    <li>At least 1 number</li>
                  </ul>
                </Typography>}
              </div>
              <div>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#201C1C",
                    fontFamily: "Poppins",
                    textAlign: "left",
                  }}
                  mb={1}
                  mt={2}
                  className="email-header"
                >
                  Confirm Password
                </Typography>
                <OutlinedInput
                  id="outlined-adornment-password"
                  className="textfield-create-pass"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Enter password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  sx={{
                    "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                      height: "2rem",
                      borderRadius: "8px",
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {confirmPasswordError && <Typography variant="body2" color="error">
                  {confirmPasswordError}
                </Typography>}
              </div>
              <Button
                type="submit"
                variant="contained"
                onClick={RegisterSuccess}
                sx={{
                  marginTop: "1rem",
                  height: "4rem",
                  fontSize: "1.2rem",
                  backgroundColor: "#8B5CFF",
                  borderRadius: "8px",
                  ":hover": {
                    bgcolor: "#8B5CFF",
                  },
                }}
                className="continue-button-create-pass"
              >
                {loading ? "Loading..." : "Continue"}
              </Button>
            </div>
          </div>
          <div className="kiosk_image_logo">
            <img src={LOGO} alt="logo" style={{ width: "150px", display: "block", margin: "auto" }} />
          </div>
        </Grid>
        <Grid
          item
          xs={0}
          sm={6}
          md={6}
          lg={7}
          xl={7}
          className="container-image"
        >
          <img
            src={BGIMAGE}
            alt="bg"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default CreatePassword;
