import { createSlice } from '@reduxjs/toolkit';

interface initialStateProps {
    PromoCodeList: any,
    PromoIndex: any
}

const initialState: initialStateProps = {
    PromoCodeList: [],
    PromoIndex: [],
}

const PromoCodeListReducer = createSlice({
    name: 'promocodeDetails',
    initialState,
    reducers: {
        setPromoCodeList: (state, action) => {
            state.PromoCodeList = action.payload
        },
        setPromoIndex: (state, action) => {
            state.PromoIndex = action.payload
        },
    },
});

export const { setPromoCodeList, setPromoIndex } = PromoCodeListReducer.actions;
export default PromoCodeListReducer.reducer;