import { Fragment, useState, ChangeEvent, useEffect } from "react";
import {
  useTheme,
  Popover,
  MenuItem,
  Typography,
  Box,
  TextField,
  Checkbox,
  IconButton,
} from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import Filter from "../assets/images/Filter.png";
import { createStyles, makeStyles } from "@mui/styles";
import ApiServices from "../helpers/ApiServices";
import HelperModule from "../helpers/HelperModule";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../Hooks";
import {
  setCategoryId,
  setItemsId,
  setMenuListDetails,
  setModifiersId,
  setPaymentModeId
} from "../store/reducers/MenuListReducer";
import { setMenuId } from "../store/reducers/MenuListReducer";
import { ClearIcon } from "@mui/x-date-pickers/icons";

interface DashboardFilterProps {
  onSelectMenuCode: (
    menuCodes: string[],
   
  ) => void;
}

let UserCodeData: any;
let AuthTokenId: any;
let GlobalMenuCodeID:any
function DashboardFilter({ onSelectMenuCode }: DashboardFilterProps) {
  const classes = useStyles();
  const { palette }: any = useTheme();
  const [open, setOpen] = useState<null | HTMLElement>(null);
  const [selectedMenu, setSelectedMenu] = useState<readonly string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<readonly string[]>(
    []
  );
  const [globalMenuId, setGlobalMenuId] = useState<string[]>([]);
  const [selectedItem, setSelectedItem] = useState<readonly string[]>([]);
  const [selectedModifier, setSelectedModifier] = useState<readonly string[]>(
    []
  );
  const [selectedPaymentMode, setSelectedPaymentMode] = useState<readonly string[]>(
    []
  )
  const [searchQuery, setSearchQuery] = useState<string>("");

  const dispatch = useDispatch();
  // const [Menu, setMenuListDetails] = useState({});
  const [isPopover, setIsPopover]: any = useState({
    Menu: null,
    Category: null,
    Items: null,
    Modifiers: null,
    PaymentMode: null,
  });

  useEffect(() => {
    const UserCode = localStorage.getItem("UserCode");
    UserCodeData = UserCode;
  }, []);

  useEffect(() => {
    const TokenData: any = localStorage.getItem("Token");
    AuthTokenId = TokenData;
  }, []);

  const { Menu } = useAppSelector((state) => state.menuListDetails);
  const { CatalogList } = useAppSelector((state) => state.catalogDetails);

  const handleSearch = (event: any, popover: string) => {
    setSearchQuery(event.target.value);
  };

  const MenusListData = async () => {
    try {
      let apiEndpoint =
        ApiServices.base_URL +
        ApiServices.dashboard_filter +
        `?user_code=${UserCodeData}`;
      if (selectedMenu.length > 0) {
        const selectedMenuString:any = selectedMenu.join(",");
        console.log(selectedMenuString, "selectedMenuString");
        setGlobalMenuId(selectedMenuString);
        GlobalMenuCodeID = selectedMenuString
        const selectedCategoryString = selectedCategory.join(",");
        const selectedItemString = selectedItem.join(",");
        const selectedModifierString = selectedModifier.join(",");
        const selectedPaymentModeString = selectedPaymentMode.join(",");
        apiEndpoint += `&menu_codes=${GlobalMenuCodeID}&category_ids=${selectedCategoryString}&item_ids=${selectedItemString}&modifier_list_ids=${selectedModifierString}&payment_mode_ids=${selectedPaymentModeString}`;
      }
      const response = await HelperModule().getMethod(apiEndpoint);
      if (response.status === true) {
        dispatch(setMenuListDetails(response));
        console.log("End point Menu Code response", response);
      } else {
        console.log("Error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    MenusListData();
  }, [selectedMenu, selectedCategory, selectedItem]);

  // const response = await HelperModule().getMethod(ApiServices.base_URL + ApiServices.dashboard_filter + `?user_code=${UserCodeData}&menu_codes=${"SI05Y60mWK "}&category_ids=${293}`);

  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
    setIsPopover({
      ...isPopover,
      Menu: null,
      Category: null,
      Items: null,
      Modifiers: null,
      PaymentMode: null,
    });
  };

  const handleSelectAllClick = (
    event: ChangeEvent<HTMLInputElement>,
    popover: string
  ) => {
    let menuData: any[] = [];
    switch (popover) {
      case "Menu":
        menuData = Menu?.menus || [];
        console.log(event.target.checked, "event.target.checked");
        if (event.target.checked) {
          const updatedSelectedMenu =
            menuData.map((value: any) => value.menu_code) || [];
          setSelectedMenu(updatedSelectedMenu);
          handleMenuCodeSelection(updatedSelectedMenu);
        } else {
          setSelectedMenu([]);
          handleMenuCodeSelection([]);
        }
        break;
      case "Category":
        menuData = Menu?.categories || [];
        console.log(event.target.checked, "event.target.checked");
        if (event.target.checked) {
          const updatedSelectedMenu =
            menuData.map((value: any) => value?.id) || [];
          setSelectedCategory(updatedSelectedMenu);
          handleMenuCodeSelection(updatedSelectedMenu);
        } else {
          setSelectedCategory([]);
          handleMenuCodeSelection([]);
        }
        break;
      case "Items":
        menuData = Menu?.items || [];
        if (event.target.checked) {
          const updatedSelectedMenu =
            menuData.map((value: any) => value.id) || [];
          setSelectedItem(updatedSelectedMenu);
          handleMenuCodeSelection(updatedSelectedMenu);
        } else {
          setSelectedItem([]);
          handleMenuCodeSelection([]);
        }
        break;
      case "Modifiers":
        menuData = Menu?.modifiers || [];
        if (event.target.checked) {
          const updatedSelectedMenu =
            menuData.map((value: any) => value.id) || [];
          setSelectedModifier(updatedSelectedMenu);
          handleMenuCodeSelection(updatedSelectedMenu);
        } else {
          setSelectedModifier([]);
          handleMenuCodeSelection([]);
        }
        break;
      case "PaymentMode":
        menuData = Menu?.paymentmode || [];
        break;
      default:
        break;
    }
  };


  const handleMenuCodeSelection = (menuCodes: string[]) => {
    onSelectMenuCode(GlobalMenuCodeID);
  };

  const handleClick = (name: any, key: string) => {
    if (name) {
      if (key === "menu") {
        const selectedIndex = selectedMenu.indexOf(name);
        let newSelected: string[] = [];
        if (selectedIndex === -1) {
          newSelected = [...selectedMenu, name];
        } else {
          newSelected = selectedMenu.filter((item) => item !== name);
        }
        setSelectedMenu(newSelected);
        dispatch(setMenuId(newSelected));
        handleMenuCodeSelection(newSelected);
      } else if (key === "category") {
        const selectedIndex = selectedCategory.indexOf(name);
        let newSelected: string[] = [];
        if (selectedIndex === -1) {
          newSelected = [...selectedCategory, name];
        } else {
          newSelected = selectedCategory.filter((item) => item !== name);
        }
        setSelectedCategory(newSelected);
        dispatch(setCategoryId(newSelected));
        handleMenuCodeSelection(newSelected);
      } else if (key === "items") {
        const selectedIndex = selectedItem.indexOf(name);
        let newSelected: string[] = [];
        if (selectedIndex === -1) {
          newSelected = [...selectedItem, name];
        } else {
          newSelected = selectedItem.filter((item) => item !== name);
        }
        setSelectedItem(newSelected);
        dispatch(setItemsId(newSelected));
        handleMenuCodeSelection(newSelected);
      } else if (key === "modifier") {
        const selectedIndex = selectedModifier.indexOf(name);
        let newSelected: string[] = [];
        if (selectedIndex === -1) {
          newSelected = [...selectedModifier, name];
        } else {
          newSelected = selectedModifier.filter((item) => item !== name);
        }
        setSelectedModifier(newSelected);
        dispatch(setModifiersId(newSelected));
        handleMenuCodeSelection(newSelected);
      }
      else if(key === "paymentmode"){
        const selectedIndex = selectedPaymentMode.indexOf(name);
        let newSelected: string[] = [];
        if (selectedIndex === -1) {
          newSelected = [...selectedPaymentMode, name];
        } else {
          newSelected = selectedPaymentMode.filter((item) => item !== name);
        }
        setSelectedPaymentMode(newSelected);
        dispatch(setPaymentModeId(newSelected));
        handleMenuCodeSelection(newSelected);

      }
      else return;
    }
  };

  const isSelectedMenu = (id: any, popover: string) => {
    let menuData: any[] = [];
    let selectedData;
    switch (popover) {
      case "Menu":
        menuData = Menu?.menus || [];
        selectedData =
          selectedMenu.indexOf(id) !== -1 ||
          selectedMenu.length === menuData.length;
        break;
      case "Category":
        menuData = Menu?.categories || [];
        selectedData =
          selectedCategory.indexOf(id) !== -1 ||
          selectedCategory.length === menuData.length;
        break;
      case "Items":
        menuData = Menu?.items || [];
        selectedData =
          selectedItem.indexOf(id) !== -1 ||
          selectedItem.length === menuData.length;
        break;
      case "Modifiers":
        menuData = Menu?.modifiers || [];
        selectedData =
          selectedModifier.indexOf(id) !== -1 ||
          selectedModifier.length === menuData.length;
        break;
      case "PaymentMode":
        menuData = Menu?.paymentmode || [];
        selectedData =
          selectedPaymentMode.indexOf(id) !== -1 ||
          selectedPaymentMode.length === menuData.length;
        break;
      default:
        break;
    }

    return selectedData;
  };
  return (
    <Fragment>
      <button
        onClick={(event: any) => handleOpen(event)}
        className="filter-btn"
      >
        <img
          src={Filter}
          alt="Delete"
          style={{ height: "14px", width: "14px", marginRight: "10px" }}
        />
        Filter
      </button>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{ sx: { borderRadius: "8px", padding: "10px" } }}
      >
        {MenuList?.map((value, index) => (
          <Box
            key={index}
            onClick={(event) =>
              setIsPopover({
                ...isPopover,
                [value?.popover]: event?.currentTarget,
              })
            }
          >
            <MenuItem
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "50px",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: palette?.common?.menuItem,
                  fontWeight: 500,
                  lineHeight: "16px",
                }}
              >
                {value?.Title}
              </Typography>
              <KeyboardArrowDownRoundedIcon
                sx={{ width: "20px", height: "20px" }}
              />
            </MenuItem>
          </Box>
        ))}
      </Popover>

      <Popover
        open={Boolean(isPopover?.Menu)}
        anchorEl={isPopover?.Menu}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: { width: `${isPopover?.Menu?.clientWidth + 20}px` },
          className: classes.popoverContainer,
        }}
      >
        <Box className={classes.contentContainer}>
          <Box className={classes.headerContainer}>
            <Typography variant="subtitle1" className={classes.menuHeaderTitle}>
              Menu
            </Typography>
            <IconButton
              disableFocusRipple
              disableRipple
              onClick={() =>
                setIsPopover({
                  ...isPopover,
                  Menu: null,
                  Category: null,
                  Items: null,
                  Modifiers: null,
                  PaymentMode: null,
                })
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M15 12.5L10 7.5L5 12.5"
                  stroke="#201C1C"
                  stroke-opacity="0.72"
                  stroke-width="1.42281"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </IconButton>
          </Box>

          <Box className={classes.menuContainer}>
            <TextField
              type="text"
              placeholder="Search"
              onChange={(event) => handleSearch(event, "Menu")}
              value={searchQuery}
              InputProps={{
                style: { borderRadius: "6px", backgroundColor: "#FFFFFF" },
                sx: {
                  ".MuiOutlinedInput-notchedOutline": { borderRadius: "6px" },
                  input: {
                    borderRadius: "6px",
                    backgroundColor: "#FFFFFF",
                    padding: "8px 8px",
                  },
                },
                endAdornment: searchQuery && (
                  <IconButton onClick={() => setSearchQuery("")}>
                    <ClearIcon />
                  </IconButton>
                )
              }}
              size="medium"
              fullWidth
            />
            <Box className={classes.scrollContainer}>
              {Menu?.menus?.length > 0 ? (
                <Box className={classes.scrollSubContainer}>
                  <Box className={classes.containerMenu}>
                    <Checkbox
                      disableRipple
                      indeterminate={
                        selectedMenu?.length > 0 &&
                        selectedMenu?.length < Menu?.menus?.length
                      }
                      checked={
                        Menu?.menus?.length > 0 &&
                        selectedMenu?.length === Menu?.menus?.length
                      }
                      onChange={(event) => handleSelectAllClick(event, "Menu")}
                    />
                    <Typography
                      variant="subtitle1"
                      className={classes.menuTitle}
                    >
                      Select All
                    </Typography>
                  </Box>
                  {Menu?.menus
                    ?.filter((value: any) =>
                      value.menu_name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                    )
                    .map((value: any, index: any) => {
                      const isItemSelected = isSelectedMenu(
                        value.menu_code,
                        "Menu"
                      );
                      return (
                        <Box key={index} className={classes.containerMenu}>
                          <Checkbox
                            disableRipple
                            checked={isItemSelected}
                            onChange={() => {
                              handleClick(value.menu_code, "menu");
                            }}
                          />
                          <Typography
                            variant="subtitle1"
                            className={classes.menuTitle}
                          >
                            {value.menu_name}
                          </Typography>
                        </Box>
                      );
                    })}
                </Box>
              ) : (
                <Box sx={{ textAlign: "center", marginTop: "16px" }}>
                  <Typography variant="subtitle1">No Menus Found</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Popover>

      <Popover
        open={Boolean(isPopover?.Category)}
        anchorEl={isPopover?.Category}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: { width: `${isPopover?.Menu?.menus?.clientWidth + 20}px` },
          className: classes.popoverContainer,
        }}
      >
        <Box className={classes.contentContainer}>
          <Box className={classes.headerContainer}>
            <Typography variant="subtitle1" className={classes.menuHeaderTitle}>
              Category
            </Typography>
            <IconButton
              disableFocusRipple
              disableRipple
              onClick={() =>
                setIsPopover({
                  ...isPopover,
                  Menu: null,
                  Category: null,
                  Items: null,
                  Modifiers: null,
                  PaymentMode: null,
                })
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M15 12.5L10 7.5L5 12.5"
                  stroke="#201C1C"
                  stroke-opacity="0.72"
                  stroke-width="1.42281"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </IconButton>
          </Box>
          <Box className={classes.menuContainer}>
            <TextField
              type="text"
              placeholder="Search"
              onChange={(event) => handleSearch(event, "Category")}
              value={searchQuery}
              InputProps={{
                style: { borderRadius: "6px", backgroundColor: "#FFFFFF" },
                sx: {
                  ".MuiOutlinedInput-notchedOutline": { borderRadius: "6px" },
                  input: {
                    borderRadius: "6px",
                    backgroundColor: "#FFFFFF",
                    padding: "8px 8px",
                  },
                },
                endAdornment: searchQuery && (
                  <IconButton onClick={() => setSearchQuery("")}>
                    <ClearIcon />
                  </IconButton>
                )
              }}
              size="medium"
              fullWidth
            />
            <Box className={classes.scrollContainer}>
              {Menu?.categories?.length > 0 ? (
                <Box className={classes.scrollSubContainer}>
                  <Box className={classes.containerMenu}>
                    <Checkbox
                      disableRipple
                      indeterminate={
                        selectedMenu?.length > 0 &&
                        selectedMenu?.length < Menu?.categories?.length
                      }
                      checked={
                        Menu?.categories?.length > 0 &&
                        selectedCategory?.length === Menu?.categories?.length
                      }
                      onChange={(event) =>
                        handleSelectAllClick(event, "Category")
                      }
                    />
                    <Typography
                      variant="subtitle1"
                      className={classes.menuTitle}
                    >
                      Select All
                    </Typography>
                  </Box>

                  {Menu?.categories
                    ?.filter((value: any) =>
                      value.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                    )
                    .map((value: any, index: any) => {
                      const isItemSelected = isSelectedMenu(
                        value?.id,
                        "Category"
                      );
                      return (
                        <Box key={index} className={classes.containerMenu}>
                          <Checkbox
                            disableRipple
                            checked={isItemSelected}
                            onChange={() => {
                              handleClick(value?.id, "category");
                            }}
                          />
                          <Typography
                            variant="subtitle1"
                            className={classes.menuTitle}
                          >
                            {value?.name}
                          </Typography>
                        </Box>
                      );
                    })}
                </Box>
              ) : (
                <Box sx={{ textAlign: "center", marginTop: "16px" }}>
                  <Typography variant="subtitle1">
                    No Categories Found
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Popover>

      <Popover
        open={Boolean(isPopover?.Items)}
        anchorEl={isPopover?.Items}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: { width: `${isPopover?.Menu?.clientWidth + 20}px` },
          className: classes.popoverContainer,
        }}
      >
        <Box className={classes.contentContainer}>
          <Box className={classes.headerContainer}>
            <Typography variant="subtitle1" className={classes.menuHeaderTitle}>
              Items
            </Typography>
            <IconButton
              disableFocusRipple
              disableRipple
              onClick={() =>
                setIsPopover({
                  ...isPopover,
                  Menu: null,
                  Category: null,
                  Items: null,
                  Modifiers: null,
                  PaymentMode: null,
                })
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M15 12.5L10 7.5L5 12.5"
                  stroke="#201C1C"
                  stroke-opacity="0.72"
                  stroke-width="1.42281"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </IconButton>
          </Box>

          <Box className={classes.menuContainer}>
            <TextField
              type="Text"
              placeholder="Search"
              onChange={(event) => handleSearch(event, "Items")}
              value={searchQuery}
              InputProps={{
                style: { borderRadius: "6px", backgroundColor: "#FFFFFF" },
                sx: {
                  ".MuiOutlinedInput-notchedOutline": { borderRadius: "6px" },
                  input: {
                    borderRadius: "6px",
                    backgroundColor: "#FFFFFF",
                    padding: "8px 8px",
                  },
                },
                endAdornment: searchQuery && (
                  <IconButton onClick={() => setSearchQuery("")}>
                    <ClearIcon />
                  </IconButton>
                )
              }}
              size="medium"
              fullWidth
            />
            <Box className={classes.scrollContainer}>
              {Menu?.items?.length > 0 ? (
                <Box className={classes.scrollSubContainer}>
                  <Box className={classes.containerMenu}>
                    <Checkbox
                      disableRipple
                      indeterminate={
                        selectedMenu?.length > 0 &&
                        selectedMenu?.length < Menu?.items?.length
                      }
                      checked={
                        Menu?.items?.length > 0 &&
                        selectedItem?.length === Menu?.items?.length
                      }
                      onChange={(event) => handleSelectAllClick(event, "Items")}
                    />
                    <Typography
                      variant="subtitle1"
                      className={classes.menuTitle}
                    >
                      Select All
                    </Typography>
                  </Box>

                  {Menu?.items
                    ?.filter((value: any) =>
                      value.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                    )
                    .map((value: any, index: any) => {
                      const isItemSelected = isSelectedMenu(value?.id, "Items");
                      return (
                        <Box key={index} className={classes.containerMenu}>
                          <Checkbox
                            disableRipple
                            checked={isItemSelected}
                            onChange={() => {
                              handleClick(value?.id, "items");
                            }}
                          />
                          <Typography
                            variant="subtitle1"
                            className={classes.menuTitle}
                          >
                            {value?.name}
                          </Typography>
                        </Box>
                      );
                    })}
                </Box>
              ) : (
                <Box sx={{ textAlign: "center", marginTop: "16px" }}>
                  <Typography variant="subtitle1">No Items Found</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Popover>

      <Popover
        open={Boolean(isPopover?.Modifiers)}
        anchorEl={isPopover?.Modifiers}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: { width: `${isPopover?.Menu?.clientWidth + 20}px` },
          className: classes.popoverContainer,
        }}
      >
        <Box className={classes.contentContainer}>
          <Box className={classes.headerContainer}>
            <Typography variant="subtitle1" className={classes.menuHeaderTitle}>
              Modifiers
            </Typography>
            <IconButton
              disableFocusRipple
              disableRipple
              onClick={() =>
                setIsPopover({
                  ...isPopover,
                  Menu: null,
                  Category: null,
                  Items: null,
                  Modifiers: null,
                  PaymentMode: null,
                })
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M15 12.5L10 7.5L5 12.5"
                  stroke="#201C1C"
                  stroke-opacity="0.72"
                  stroke-width="1.42281"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </IconButton>
          </Box>

          <Box className={classes.menuContainer}>
            <TextField
              type="Text"
              placeholder="Search"
              onChange={(event) => handleSearch(event, "Modifiers")}
              value={searchQuery}
              InputProps={{
                style: { borderRadius: "6px", backgroundColor: "#FFFFFF" },
                sx: {
                  ".MuiOutlinedInput-notchedOutline": { borderRadius: "6px" },
                  input: {
                    borderRadius: "6px",
                    backgroundColor: "#FFFFFF",
                    padding: "8px 8px",
                  },
                },
                endAdornment: searchQuery && (
                  <IconButton onClick={() => setSearchQuery("")}>
                    <ClearIcon />
                  </IconButton>
                )
              }}
              size="medium"
              fullWidth
            />
            <Box className={classes.scrollContainer}>
              {Menu?.modifiers?.length > 0 ? (
                <Box className={classes.scrollSubContainer}>
                  <Box className={classes.containerMenu}>
                    <Checkbox
                      disableRipple
                      indeterminate={
                        selectedMenu?.length > 0 &&
                        selectedMenu?.length < Menu?.modifiers?.length
                      }
                      checked={
                        Menu?.modifiers?.length > 0 &&
                        selectedModifier?.length === Menu?.modifiers?.length
                      }
                      onChange={(event) =>
                        handleSelectAllClick(event, "Modifiers")
                      }
                    />
                    <Typography
                      variant="subtitle1"
                      className={classes.menuTitle}
                    >
                      Select All
                    </Typography>
                  </Box>
                  {Menu?.modifiers
                    ?.filter((value: any) =>
                      value.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                    )
                    .map((value: any, index: any) => {
                      const isItemSelected = isSelectedMenu(
                        value?.id,
                        "Modifiers"
                      );
                      return (
                        <Box key={index} className={classes.containerMenu}>
                          <Checkbox
                            disableRipple
                            checked={isItemSelected}
                            onChange={() => {
                              handleClick(value?.id, "modifier");
                            }}
                          />
                          <Typography
                            variant="subtitle1"
                            className={classes.menuTitle}
                          >
                            {value?.name}
                          </Typography>
                        </Box>
                      );
                    })}
                </Box>
              ) : (
                <Box sx={{ textAlign: "center", marginTop: "16px" }}>
                  <Typography variant="subtitle1">
                    No Modifiers Found
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Popover>

      <Popover
        open={Boolean(isPopover?.PaymentMode)}
        anchorEl={isPopover?.PaymentMode}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: { width: `${isPopover?.Menu?.clientWidth + 20}px` },
          className: classes.popoverContainer,
        }}
      >
        <Box className={classes.contentContainer}>
          <Box className={classes.headerContainer}>
            <Typography variant="subtitle1" className={classes.menuHeaderTitle}>
              Payment Mode
            </Typography>
            <IconButton
              disableFocusRipple
              disableRipple
              onClick={() =>
                setIsPopover({
                  ...isPopover,
                  Menu: null,
                  Category: null,
                  Items: null,
                  Modifiers: null,
                  PaymentMode: null,
                })
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M15 12.5L10 7.5L5 12.5"
                  stroke="#201C1C"
                  stroke-opacity="0.72"
                  stroke-width="1.42281"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </IconButton>
          </Box>

          <Box className={classes.menuContainer}>
            <TextField
              type="Text"
              placeholder="Search"
              onChange={(event) => handleSearch(event, "PaymentMode")}
              value={searchQuery}
              InputProps={{
                style: { borderRadius: "6px", backgroundColor: "#FFFFFF" },
                sx: {
                  ".MuiOutlinedInput-notchedOutline": { borderRadius: "6px" },
                  input: {
                    borderRadius: "6px",
                    backgroundColor: "#FFFFFF",
                    padding: "8px 8px",
                  },
                },
                endAdornment: searchQuery && (
                  <IconButton onClick={() => setSearchQuery("")}>
                    <ClearIcon />
                  </IconButton>
                )
              }}
              size="medium"
              fullWidth
            />
            <Box className={classes.scrollContainer}>
              {Menu?.paymentmode?.length > 0 ? (
                <Box className={classes.scrollSubContainer}>
                  <Box className={classes.containerMenu}>
                    <Checkbox
                      disableRipple
                      indeterminate={
                        selectedMenu?.length > 0 &&
                        selectedMenu?.length < Menu?.paymentmode?.length
                      }
                      checked={
                        Menu?.paymentmode?.length > 0 &&
                        selectedMenu?.length === Menu?.paymentmode?.length
                      }
                      onChange={(event) =>
                        handleSelectAllClick(event, "PaymentMode")
                      }
                    />
                    <Typography
                      variant="subtitle1"
                      className={classes.menuTitle}
                    >
                      Select All
                    </Typography>
                  </Box>
                  {Menu?.paymentmode
                    ?.filter((value: any) =>
                      value.toLowerCase().includes(searchQuery.toLowerCase())
                    )
                    .map((value: any, index: any) => {
                      const isItemSelected = isSelectedMenu(
                        value,
                        "PaymentMode"
                      );
                      return (
                        <Box key={index} className={classes.containerMenu}>
                          <Checkbox
                            disableRipple
                            checked={isItemSelected}
                            onChange={() => {
                              handleClick(value, "paymentmode");
                            }}
                          />
                          <Typography
                            variant="subtitle1"
                            className={classes.menuTitle}
                          >
                            {value}
                          </Typography>
                        </Box>
                      );
                    })}
                </Box>
              ) : (
                <Box sx={{ textAlign: "center", marginTop: "16px" }}>
                  <Typography variant="subtitle1">
                    No Payment Modes Found
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Popover>
    </Fragment>
  );
}

export default DashboardFilter;

const MenuList = [
  { id: 1, popover: "Menu", Title: "Menu" },
  { id: 2, popover: "Category", Title: "Category" },
  { id: 3, popover: "Items", Title: "Items" },
  { id: 4, popover: "Modifiers", Title: "Modifiers" },
  { id: 5, popover: "PaymentMode", Title: "Payment Mode" },
];

const useStyles = makeStyles(() =>
  createStyles({
    popoverContainer: {
      borderRadius: "8px",
      display: "inline-flex",
      padding: "10px",
      alignItems: "flex-start",
      gap: "10px",
      border: "1px solid rgba(32, 28, 28, 0.08)",
      background: "#FFF",
    },
    contentContainer: {
      display: "flex",
      padding: "0px 10px 10px 10px",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "6px",
      borderRadius: "8px",
      border: "1px solid rgba(32, 28, 28, 0.08)",
      width: "100%",
    },
    headerContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    },
    menuHeaderTitle: {
      color: "rgba(32, 28, 28, 0.72)",
      fontFamily: "Poppins",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "16px",
    },
    menuContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "8px",
    },
    scrollContainer: {
      width: "100%",
      height: "200px",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: "4px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundClip: "padding-box",
        backgroundColor: "#8B5CFF",
        borderRadius: 9999,
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundClip: "padding-box",
        backgroundColor: "#8b5cff66",
        borderRadius: 9999,
      },
    },
    menuTitle: {
      color: "black",
      leadingTrim: "both",
      textEdge: "cap",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "16px",
      textTransform: "capitalize",
    },
    scrollSubContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    containerMenu: {
      display: "flex",
      alignItems: "center",
      gap: "6px",
    },
  })
);
