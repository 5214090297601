import React, { Fragment, useEffect, useState } from "react";
import { useStyles } from "./UseStyles";
import { visuallyHidden } from "@mui/utils";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Pagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import Modal from "@mui/material/Modal";
import Cross from "../assets/images/Cross.png";
import Download from "../assets/images/Download.png";
import { useLocation } from "react-router-dom";
import HelperModule from "../helpers/HelperModule";
import ApiServices from "../helpers/ApiServices";
import CircularProgress from "@mui/material/CircularProgress";

function createData(
  device_id: any,
  device_name: any,
  menu_name: any,
  total_orders: any,
  status: any
) {
  return { device_id, device_name, menu_name, total_orders, status };
}

const rows: any = [
  createData("12344511", "05/17/2023 11:43 AM", "3", "Heading", "Delivered"),
  createData("12344511", "05/17/2023 11:43 AM", "3", "Heading", "Delivered"),
  createData("12344511", "05/17/2023 11:43 AM", "3", "Heading", "Delivered"),
  createData("12344511", "05/17/2023 11:43 AM", "3", "Heading", "Delivered"),
  createData("12344511", "05/17/2023 11:43 AM", "3", "Heading", "Delivered"),
  createData("12344511", "05/17/2023 11:43 AM", "3", "Heading", "Delivered"),
  createData("12344511", "05/17/2023 11:43 AM", "3", "Heading", "Delivered"),
  createData("12344511", "05/17/2023 11:43 AM", "3", "Heading", "Delivered"),
  createData("12344511", "05/17/2023 11:43 AM", "3", "Heading", "Delivered"),
  createData("12344511", "05/17/2023 11:43 AM", "3", "Heading", "Delivered"),
  createData("12344511", "05/17/2023 11:43 AM", "3", "Heading", "Delivered"),
];

type Order = "asc" | "desc";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array:any, comparator:any) {
  const stabilizedThis = array.map((el:any, index:any) => [el, index]);
  stabilizedThis.sort((a:any, b:any) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
  });
  return stabilizedThis.map((el:any) => el[0]);
}

function getComparator(order:any, orderBy:any) {
  return (a:any, b:any) => {
      if (a[orderBy] < b[orderBy]) {
          return order === 'asc' ? -1 : 1;
      }
      if (a[orderBy] > b[orderBy]) {
          return order === 'asc' ? 1 : -1;
      }
      return 0;
  };
}

const headCells: any = [
  {
    id: "order_id",
    disablePadding: true,
    label: "Order Id",
  },
  {
    id: "date",
    disablePadding: false,
    label: "date",
  },
  {
    id: "no_0f_items",
    disablePadding: false,
    label: "No. of Items",
  },
  {
    id: "amount",
    disablePadding: false,
    label: "Amount ($)",
  },
  {
    id: "status",
    disablePadding: false,
    label: "Status",
  },
];

function EnhancedTableHead(props: any) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              className={classes.TableHeadTitle}
              active={true} // orderBy === headCell.id
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              IconComponent={(event) => (
                <ArrowDropDownRoundedIcon
                  {...event}
                  sx={{ color: "rgba(32, 28, 28, 0.32)" }}
                />
              )}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const CustomerUserDetailsTable = () => {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<any>("date");
  const [webSettingsModal, setWebSettingsModal] = React.useState(false);
  const location = useLocation();
  const [ordersData, setOrdersData]: any = useState({});
  const [ordersDetails, setOrdersDetails]: any = useState({});
  const { userCodeDataDetails } = location.state;
  const [loading, setLoading] = React.useState(true);
  const [orderId, setOrderId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = ordersData?.data?.orders.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(ordersData?.data?.orders.length / recordsPerPage);

  const HandleWebSettings = (orderId: string) => () => {
    setWebSettingsModal(true);
    setOrderId(orderId);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const CustomerOrderDetailsData = async () => {
    try {
      const response = await HelperModule().getMethod(
        ApiServices.base_URL +
          ApiServices.Customer_order_details +
          userCodeDataDetails
      );
      if (response.status === true) {
        setOrdersData(response);
        setLoading(false);
        // dispatch(setPromoCodeList(response.promo_codes));
      }
    } catch (error) {
      setLoading(false);

      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const OrderDetails = async (orderId: string) => {
    try {
      const response = await HelperModule().getMethod(
        ApiServices.base_URL +
          ApiServices.Order_details +
          `?order_id=${orderId}`
      );
      if (response.status === true) {
        setOrdersDetails(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadInvoice = async () => {
    try {
      const response = await fetch(
        `https://api.miniosk.com/api/order/download-order-receipt/${orderId}`
      );
      if (!response.ok) {
        throw new Error(
          `Failed to download the invoice. Response status: ${response.status}`
        );
      }
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Invoice_${orderId}.pdf`);
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        link.parentNode?.removeChild(link);
      }, 100);
    } catch (error) {
      console.error("Error occurred while downloading the invoice:", error);
    }
  };

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  // const visibleRows = React.useMemo(
  //   () =>
  //     stableSort(
  //       ordersData?.data?.orders.length > 0
  //         ? ordersData?.data?.orders
  //         : currentRecords,
  //       getComparator(order, orderBy)
  //     ),
  //   [order, orderBy, currentRecords, ordersData?.data?.orders]
  // );

  useEffect(() => {
    CustomerOrderDetailsData();
  }, []);

  useEffect(() => {
    OrderDetails(orderId);
  }, [orderId]);

  return (
    <Fragment>
      {loading ? (
        <div className="Loader-container">
          <CircularProgress disableShrink   sx={{color: '#8B5CFF'}}/>
        </div>
      ) : (
        <>
          <div className="user-details-input">
            <h3 className="menu-heading">Name</h3>
            <input
              placeholder="Menu Name"
              value={ordersData?.data?.name}
              style={{
                width: "44.94vw",
                height: "7.20vh",
                borderRadius: "8px",
                borderWidth: "1px",
                borderColor: "#201C1C3D",
                padding: "10px 16px 10px 16px",
                outline: "none",
                fontWeight: "400",
                fontSize: "14px",
                fontFamily: "Poppins",
                marginTop: "8px",
              }}
            />
            <h3 className="menu-heading" style={{ paddingTop: "16px" }}>
              Mobile Number
            </h3>
            <input
              placeholder="Mobile Number"
              value={ordersData?.data?.phone}
              style={{
                width: "44.94vw",
                height: "7.20vh",
                borderRadius: "8px",
                borderWidth: "1px",
                borderColor: "#201C1C3D",
                padding: "10px 16px 10px 16px",
                outline: "none",
                fontWeight: "400",
                fontSize: "14px",
                fontFamily: "Poppins",
                marginTop: "8px",
              }}
            />
          </div>
          <div className="miniosk-table-container">
            <Box className={classes.TableMain}>
              <TableContainer>
                <Table sx={{ minWidth: 750 }} size={"medium"}>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {currentRecords.map((row: any, index: any) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell align="left" style={{}}>
                            <Typography
                              variant="body2"
                              className={classes.DashboarBodyCell}
                              sx={{
                                fontWeight: 400,
                                fontSize: "14px",
                                color: "#201C1C",
                                fontFamily: "Poppins",
                              }}
                            >
                              {row?.reference_id}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="body2"
                              className={classes.DashboarBodyCell}
                              sx={{
                                fontWeight: 400,
                                fontSize: "14px",
                                color: "#201C1C",
                                fontFamily: "Poppins",
                              }}
                            >
                              {new Date(row?.created_at)
                                .toLocaleString()
                                .replace(/,/g, " ")}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="body2"
                              className={classes.DashboarBodyCell}
                              sx={{
                                fontWeight: 400,
                                fontSize: "14px",
                                color: "#201C1C",
                                fontFamily: "Poppins",
                              }}
                            >
                              {row?.items_count}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="body2"
                              className={classes.DashboarBodyCell}
                              sx={{
                                fontWeight: 400,
                                fontSize: "14px",
                                color: "#201C1C",
                                fontFamily: "Poppins",
                              }}
                            >
                              {row?.amount}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography
                              variant="body2"
                              className={classes.DashboarBodyCell}
                              sx={{
                                fontWeight: 500,
                                fontSize: "14px",
                                fontFamily: "Poppins",
                                color: "#18BC46",
                              }}
                            >
                              {row?.state}
                            </Typography>
                            <div
                              onClick={HandleWebSettings(row.id)}
                              style={{ marginLeft: "18px" }}
                            >
                              <button
                                style={{
                                  width: "7.46vw",
                                  height: "5.56vh",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: "#8B5CFF",
                                  color: "#FFFFFF",
                                  borderWidth: 0,
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  fontFamily: "Poppins",
                                  borderRadius: "8px",
                                  marginLeft: "40px",
                                  transition: "background-color 0.3s",
                                }}
                                onMouseEnter={(e) => {
                                  (
                                    e.target as HTMLButtonElement
                                  ).style.backgroundColor = "#6d43e57D";
                                }}
                                onMouseLeave={(e) => {
                                  (
                                    e.target as HTMLButtonElement
                                  ).style.backgroundColor = "#8B5CFF";
                                }}
                              >
                                View Order
                              </button>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {ordersData?.data?.orders.length <= 0 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "50.84vw",
                          padding: "50px",
                        }}
                      >
                        <h1
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "24px",
                            textAlign: "center",
                          }}
                        >
                          No Orders Found
                        </h1>
                      </div>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <Pagination color="primary" count={10} /> */}

              {ordersData?.data?.orders.length > recordsPerPage && (
                <Pagination
                  count={nPages}
                  color="primary"
                  size="small"
                  page={currentPage}
                  onChange={handlePaginationChange}
                  className={classes.paginationPrevNextBtn}
                />
              )}
            </Box>
          </div>
        </>
      )}
      <Modal
        open={webSettingsModal}
        onClose={() => setWebSettingsModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            transition: "bottom 0.3s ease-in-out",
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: "24px",
            boxShadow: 24,
            padding: "16px",
            borderWidth: 0,
          }}
        >
          <style>
            {`::-webkit-scrollbar {width: 6px;} ::-webkit-scrollbar-track {   display: none; } ::-webkit-scrollbar-thumb {   background-color: transparent; }`}
          </style>
          <div className="import-title-container">
            <h3 className="import-title">Order Details</h3>
            <div
              style={{
                backgroundColor: "#F34A4A29",
                height: "27.06px",
                width: "27.06px",
                borderRadius: "27.91px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <img
                onClick={() => setWebSettingsModal(false)}
                src={Cross}
                alt="Cross"
                style={{ width: "24px", height: "24px", cursor: "pointer" }}
              />
            </div>
          </div>

          <div className="order-info">
            <h3 className="menu-heading" style={{ paddingTop: "10px" }}>
              Order Id
            </h3>

            <h3
              style={{
                margin: 0,
                fontSize: "14px",
                fontFamily: "Poppins",
                fontWeight: "600",
                color: "#201C1C",
              }}
            >
              {ordersDetails?.order_details?.reference_id}
            </h3>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "24px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingRight: "24px",
                }}
              >
                {ordersDetails?.order_details?.in_order.map(
                  (orderItem: any, index: number) => (
                    <div key={index} style={{ paddingTop: "24px" }}>
                      <h3
                        className="menu-heading"
                        style={{ color: "#201C1C70" }}
                      >
                        Ordered
                      </h3>
                      <div style={{ width: "21.88vw" }}>
                        <h3
                          style={{
                            margin: 0,
                            fontSize: "14px",
                            fontFamily: "Poppins",
                            fontWeight: "600",
                            color: "#201C1C",
                          }}
                        >
                          {orderItem?.item?.name} -$ {orderItem?.item_price}
                        </h3>
                      </div>
                    </div>
                  )
                )}
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                {ordersDetails?.order_details?.in_order.map(
                  (orderItem: any, index: number) => (
                    <div
                      key={index}
                      style={{ paddingTop: "24px", marginLeft: "32px" }}
                    >
                      <h3
                        className="menu-heading"
                        style={{ color: "#201C1C70" }}
                      >
                        Add ons
                      </h3>
                      <div style={{ width: "21.88vw" }}>
                        {orderItem.in_order_modifiers.length > 0 && (
                          <h3
                            style={{
                              margin: 0,
                              fontSize: "14px",
                              fontFamily: "Poppins",
                              fontWeight: "600",
                              color: "#201C1C",
                            }}
                          >
                            {orderItem.in_order_modifiers.map(
                              (modifier: any, index: number) => (
                                <span key={index}>
                                  {modifier?.modifier?.name || " No Addons"} - ${" "}
                                  {modifier?.modifier?.price_money?.amount /
                                    100}
                                  {index !==
                                    orderItem.in_order_modifiers.length - 1 &&
                                    ", "}
                                </span>
                              )
                            )}
                          </h3>
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "24px",
              }}
            >
              <div style={{ width: "21.88vw" }}>
                <h3 className="menu-heading" style={{ color: "#201C1C70" }}>
                  Discount Amount
                </h3>
                <h3
                  style={{
                    margin: 0,
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    color: "#201C1C",
                  }}
                >
                  $ {ordersDetails?.order_details?.discount_amount}
                </h3>
              </div>
              <div style={{ marginLeft: "56px" }}>
                <h3 className="menu-heading" style={{ color: "#201C1C70" }}>
                  Tips
                </h3>
                <h3
                  style={{
                    margin: 0,
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    color: "#201C1C",
                  }}
                >
                  $ {ordersDetails?.order_details?.tip}
                </h3>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "24px",
              }}
            >
              <div style={{ width: "21.88vw" }}>
                <h3 className="menu-heading" style={{ color: "#201C1C70" }}>
                  Tax
                </h3>
                <h3
                  style={{
                    margin: 0,
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    color: "#201C1C",
                  }}
                >
                  $ {ordersDetails?.order_details?.tax_amount}
                </h3>
              </div>
              <div style={{ marginLeft: "56px" }}>
                <h3 className="menu-heading" style={{ color: "#201C1C70" }}>
                  Total Amount
                </h3>
                <h3
                  style={{
                    margin: 0,
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    color: "#201C1C",
                  }}
                >
                  $ {ordersDetails?.order_details?.net_amount}
                </h3>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "24px",
              }}
            ></div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "24px",
              }}
            >
              <div style={{ width: "21.88vw" }}>
                <h3 className="menu-heading" style={{ color: "#201C1C70" }}>
                  Billing
                </h3>
                <h3
                  style={{
                    margin: 0,
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    color: "#201C1C",
                  }}
                >
                  {new Date(ordersDetails?.order_details?.created_at)
                    .toLocaleString()
                    .replace(/,/g, " ")}
                </h3>
              </div>
              <div style={{ marginLeft: "56px" }}>
                <h3 className="menu-heading" style={{ color: "#201C1C70" }}>
                  Payment Type
                </h3>
                <h3
                  style={{
                    margin: 0,
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    color: "#201C1C",
                  }}
                >
                  {ordersDetails?.order_details?.payment_mode}
                </h3>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "24px",
              }}
            >
              <div style={{ width: "21.88vw" }}>
                <h3 className="menu-heading" style={{ color: "#201C1C70" }}>
                  Payment Status
                </h3>
                <h3
                  style={{
                    margin: 0,
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    color: "#201C1C",
                  }}
                >
                  {ordersDetails?.order_details?.payment_status}
                </h3>
              </div>
              <div style={{ marginLeft: "56px" }}>
                <h3 className="menu-heading" style={{ color: "#201C1C70" }}>
                  Order Status
                </h3>
                <h3
                  style={{
                    margin: 0,
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    color: "#201C1C",
                  }}
                >
                  {ordersDetails?.order_status}
                </h3>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "24px",
            }}
          >
            <button
              onClick={downloadInvoice}
              style={{
                width: "179.86px",
                height: "36.34px",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#8B5CFF",
                color: "#FFFFFF",
                borderWidth: 0,
                fontWeight: "500",
                fontSize: "12px",
                fontFamily: "Poppins",
                borderRadius: "8px",
                transition: "background-color 0.3s",
              }}
              onMouseEnter={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor =
                  "#6d43e57D";
              }}
              onMouseLeave={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor =
                  "#8B5CFF";
              }}
            >
              <img
                src={Download}
                alt="Download"
                style={{ height: "12px", width: "8px", marginRight: "10px" }}
              />
              Download Invoice
            </button>
          </div>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default CustomerUserDetailsTable;
