import React, { Fragment, useState } from "react";
import {
    Box,
    TextField,
    Button,
    Typography,
    AppBar,
    Toolbar,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import { setIsLogin } from "../../../src/store/reducers/AuthReducer";
import { useParams } from 'react-router-dom';

import PasswordSuccessful from './PasswordSuccessful';

import MuiDialog from './MuiDialog';
import { setSuccessDialog } from "../../store/reducers/AuthReducer";
import ApiServices from "../../helpers/ApiServices";
import HelperModule from "../../helpers/HelperModule";
import { enqueueSnackbar } from "notistack";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

const CustomTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "white",
        },

        "& .MuiInput-underline:after": {
            borderBottomColor: "#FBFBFB",
        },

        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#FBFBFB",
            },

            "&:hover fieldset": {
                borderColor: "#FBFBFB",
            },

            "&.Mui-focused fieldset": {
                borderColor: "#FBFBFB",
            },
        },
    },
})(TextField);

const ConfirmPassword = () => {
    const Navigate = useNavigate();

    const dispatch = useAppDispatch();
    const { successDialog } = useAppSelector(state => state?.Auth)
    const { token } = useParams();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [value, setValue] = useState({
        password: "",
        confirmpassword: "",
    });

    const IsLogin: any = true;

    const onLogin = () => {
        console.log("first")
        dispatch(setSuccessDialog(true))
    };

    const [isDialogOpen, setDialogOpen] = useState(false);

    const validatePassword = (passwordTest: any) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+?])[A-Za-z\d!@#$%^&*()_+?]{6,15}$/;
        return regex.test(value?.password) && value?.password?.length <= 15;
    };

    const toggleDialog = async () => {
        const params = {
            "password": value.password,
            "token": token
        };

        try {
            if (!validatePassword(value.password) || value.password.length > 15) {
                enqueueSnackbar(
                    'Password must have 6-15 characters with at least one uppercase letter, one special character, and one number.',
                    { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }, style: { fontFamily: 'Poppins', fontWeight: '500' } }
                );
                return;
            }
            const response = await HelperModule().postMethod(ApiServices.base_URL + ApiServices.reset_password, params);
            if (response.status === true) {
                console.log(response);
                enqueueSnackbar(response.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' }, style: { fontFamily: 'Poppins', fontWeight: '500' } });
                setDialogOpen(!isDialogOpen);
                setTimeout(() => {
                    Navigate('/user');
                }, 500);
            } else {
                console.log("Error");
                return [];
            }
        } catch (error) {
            console.log(error);
            return [];
        }
    };


    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    }

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
    }


    return (
        <Fragment>
            {/* <Box
                sx={{ marginTop: "4px", marginLeft: "10px", display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "16px", width: "100%", pt: 1, pb: 1, }}
            > */}
            <Box
                sx={{
                    display: "flex", justifyContent: "center", alignItems: "center", gap: "12px", pr: 1, pl: 1,
                }}
            >
            </Box>
            {/* </Box> */}
            <div style={{ justifyContent: "center", alignItems: "center", display: "flex", paddingTop: "212px", }}>
                <Box
                    sx={{
                        display: "flex", justifyContent: "center", padding: "24px 16px", flexDirection: "column", alignItems: "center", gap: "24px", borderRadius: "32px", background: "#FFF", width: "30%", overflow: "hidden",
                    }}
                >
                    <Typography
                        sx={{
                            color: "#201C1C", fontFamily: "poppins", fontSize: "24px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal",
                        }}
                    >
                        Enter Password
                    </Typography>

                    <Box sx={{ backgroundColor: "transparent", width: "100%" }}>
                        <Typography
                            sx={{
                                color: "#201C1C", fontFamily: "poppins", fontSize: "14px", fontStyle: "normal", fontWeight: 400, lineHeight: "normal", pt: 0.5, pb: 0.5,
                            }}
                        >
                            Password
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                            <CustomTextField type={showPassword ? 'text' : 'password'} value={value?.password} onChange={(e) => setValue({ ...value, password: e?.target?.value })} placeholder="password" variant="outlined" fullWidth size="medium"
                                InputProps={{
                                    inputProps: {
                                        style: {
                                            borderRadius: "16px", backgroundColor: "#FBFBFB", padding: "14px",
                                        },
                                        minLength: 6,
                                        maxLength: 15,
                                    },
                                    style: { borderRadius: "16px", backgroundColor: "#FBFBFB" },
                                }}
                            />
                            <div style={{ position: 'absolute', right: '25px', alignItems: 'center' }}>
                                {showPassword ? (
                                    <VisibilityOffIcon className="mt-3 text-gray-400 cursor-pointer" onClick={togglePasswordVisibility} />
                                ) : (
                                    <VisibilityIcon className="mt-3 text-gray-400 cursor-pointer" onClick={togglePasswordVisibility} />
                                )}
                            </div>
                        </div>
                    </Box>

                    <Box sx={{ backgroundColor: "transparent", width: "100%" }}>
                        <Typography
                            sx={{
                                color: "#201C1C", fontFamily: "poppins", fontSize: "14px", fontStyle: "normal", fontWeight: 400, lineHeight: "normal", pt: 0.5, pb: 0.5,
                            }}
                        >
                            Confirm Password
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                            <CustomTextField type={showConfirmPassword ? 'text' : 'password'} value={value?.confirmpassword} onChange={(e) => setValue({ ...value, confirmpassword: e?.target?.value })} placeholder="confirm password" variant="outlined" fullWidth size="medium"
                                InputProps={{
                                    inputProps: {
                                        style: {
                                            borderRadius: "16px",
                                            backgroundColor: "#FBFBFB",
                                            padding: "14px",
                                        },
                                        minLength: 6,
                                        maxLength: 15,
                                    },
                                    style: { borderRadius: "16px", backgroundColor: "#FBFBFB" },
                                }}
                            />

                            <div style={{ position: 'absolute', right: '25px', alignItems: 'center' }}>
                                {showConfirmPassword ? (
                                    <VisibilityOffIcon className="mt-3 text-gray-400 cursor-pointer" onClick={toggleConfirmPasswordVisibility} />
                                ) : (
                                    <VisibilityIcon className="mt-3 text-gray-400 cursor-pointer" onClick={toggleConfirmPasswordVisibility} />
                                )}
                            </div>
                        </div>

                        {!value?.confirmpassword ? null : value.password !== value.confirmpassword && (
                            <p style={{ color: "#8B5CFF" }}>
                                * Password and Confirm Password Must be same
                            </p>
                        )}
                    </Box>

                    <Box
                        sx={{ paddingTop: 2, backgroundColor: "transparent", width: "100%" }}
                    >
                        <Button
                            // onClick={onLogin}
                            disabled={
                                !value?.password ||
                                !value?.confirmpassword ||
                                RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i).test(
                                    value?.password
                                ) ||
                                value.password !== value.confirmpassword
                            }
                            fullWidth
                            size="large"
                            style={{
                                color: "#FFFFFF", textAlign: "center", fontFamily: "Poppins", fontSize: "14px", backgroundColor: "#8B5CFF", cursor: "pointer", borderRadius: "8px",
                            }}
                            onClick={toggleDialog}
                        >
                            Submit
                        </Button>
                        <MuiDialog
                            open={successDialog}
                            onClose={() => dispatch(setSuccessDialog(!successDialog))}
                        >
                            <PasswordSuccessful />
                        </MuiDialog>
                    </Box>
                </Box>
            </div>
        </Fragment>
    );
};

export default ConfirmPassword;
