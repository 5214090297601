import { Box, CircularProgress, Modal } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";

import Cross from "../../../assets/images/Cross.png";
import Refresh from "../../../assets/images/refresh.png";
import SuccessModal from "../../../components/SuccessModal";
import ApiServices from "../../../helpers/ApiServices";
import HelperModule from "../../../helpers/HelperModule";
import { setCatalogList } from "../../../store/reducers/CatalogDataReducer";

import "../MenuDetails.css";

let UserCodeData: any;
let AuthTokenId: any;
function CategoryModal({
  editCategory,
  closeModal,
  category,
  setCategory,
  // MenusDataItems = null
}: any) {
  const [categoryNameModal, setcategoryNameModal]: any = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState("");
  const { menu_name, menu_code }: any = useParams();
  const [timings, setTimings]: any = useState({});
  const location = useLocation();
  const dispatch = useDispatch();
  const { location_id } = location.state;
  const { CatalogList } = useSelector((state: any) => state.catalogDetails);

  const WeekDaysData = [
    { weekday: "sunday" },
    { weekday: "monday" },
    { weekday: "tuesday" },
    { weekday: "wednesday" },
    { weekday: "thursday" },
    { weekday: "friday" },
    { weekday: "saturday" },
  ];

  const EditCategory = async () => {
    const formData = new FormData();
    formData.append("category_name", category?.name);
    formData.append("status", "1");
    formData.append("user_code", UserCodeData);
    formData.append("pos_id", category?.pos_id);
    formData.append("menu_code", menu_code);
    formData.append("location_id", location_id);
    if (typeof category?.image_url !== "string") {
      formData.append("photo", category?.image_url);
    }

    const scheduleKeys = Object.keys(timings);

    scheduleKeys.map((elem: any) => {
      formData.append(elem, JSON.stringify(timings[elem]));
    });

    if (category?.image_url as String) {
      // null
    } else {
      formData.append("image_path", category?.image_url);
    }
    try {
      setIsLoading(true);
      const response = await HelperModule().postFormDataMethod(
        ApiServices.base_URL + ApiServices.edit_category_name,
        formData
      );
      if (response.status === true) {
        closeModal();
        setTimeout(() => {
          setcategoryNameModal(true);
          setIsLoading(false);
        }, 100);
        MenusDataItems();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const MenusDataItems = async () => {
    const headers = {
      Authorization: `Bearer ${AuthTokenId}`,
    };
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
        ApiServices.category_list +
        `?menu_code=${menu_code}&user_code=${UserCodeData}`,
        headers
      );
      if (response.status === true) {
        dispatch(setCatalogList(response));
        const posIds = response.categoriesList?.map(
          (category: any) => category.pos_id
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTimeChange = (weekday: any, field: any, value: any) => {
    setTimings((prevTimings: any) => ({
      ...prevTimings,
      [weekday]: {
        ...prevTimings[weekday],
        [field]: dayjs(value).format("HH:mm:ss"),
      },
    }));
  };

  const handleCloseModal = () => {
    closeModal();
  };

  useEffect(() => {
    const TokenData: any = localStorage.getItem("Token");
    const UserCode = localStorage.getItem("UserCode");
    UserCodeData = UserCode;
    setToken(TokenData);
    AuthTokenId = TokenData;
  }, []);

  useEffect(() => {
    let schedules = category?.category_availability?.map((elem: any) => {
      return {
        [elem.day_of_week]: {
          start_time: elem.start_time,
          end_time: elem.end_time,
        },
      };
    });

    let obj: any = {};
    schedules?.map((elem: any) => {
      let keys = Object.keys(elem);

      let day = keys[0];

      obj[day] = elem[day];
    });

    setTimings({ ...obj });
  }, [category]);

  return (
    <>
      <Modal
        open={editCategory}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            //   bottom: editAllergen ? "0" : "-100%",
            transition: "bottom 0.3s ease-in-out",
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "47.73vw",
            height: "86.74vh",
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            padding: "16px",
            borderWidth: 0,
            overflowY: "auto",
          }}
        >
          <style>
            {`:: -webkit - scrollbar { width: 6px; } :: -webkit - scrollbar - track { display: none; } :: -webkit - scrollbar - thumb { background - color: transparent; } `}
          </style>
          <div className="import-title-container">
            <h3 className="import-title">Edit Category</h3>
            <div
              className="category-items-btn"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginRight: "20px",
              }}
            >
              {/* <button className="reimport-btn">
                <img
                  src={Refresh}
                  alt="refresh"
                  style={{ height: "16px", width: "16px", marginRight: "10px" }}
                />
                Reimport
              </button> */}

              <img
                onClick={handleCloseModal}
                src={Cross}
                alt="Cross"
                style={{
                  width: "30px",
                  height: "32px",
                  marginLeft: "20px",
                  backgroundColor: "#F34A4A29",
                  borderRadius: "33px",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
          <div className="menu-details">
            <h3 className="menu-title-header">General Details</h3>
            <h3 className="menu-heading">Category Name</h3>
            <input
              placeholder="Menu Name"
              value={category.name}
              onChange={(e: any) =>
                setCategory({ ...category, name: e.target.value })
              }
              maxLength={20}
              style={{
                marginTop: "8px",
                width: "44.94vw",
                height: "7.20vh",
                borderRadius: "16px",
                borderWidth: "1px",
                borderColor: "#201C1C3D",
                padding: "10px 16px 10px 16px",
                outline: "none",
                fontWeight: "400",
                fontSize: "14px",
                fontFamily: "Poppins",
              }}
            />
            <p className="input-sub-text">
              This text is the name of the category that will show up in your
              miniosks.
            </p>
            <h3 className="menu-title-header" style={{ marginTop: "16px" }}>
              Photo
            </h3>
            <p className="title-sub-text">
              This uploaded image will show up in the minioskfor this category,
              depending on any Styling options you select. For best performance,
              we recommend a maximum resolution of 1000 x 1000 pixels.
            </p>
          </div>
          <div
            className="photo-container"
            style={{
              marginTop: "14px",
              width: "152.44px",
              height: "152.44px",
              position: 'relative'
              // backgroundImage: `url(${selectedImage?.image_path})`,
            }}
          >
            {category?.image_url &&
              (typeof category?.image_url === "string" ? (
                // If itq's a URL
                <img
                  src={category.image_url}
                  alt="Uploaded"
                  style={{ height: "150px", width: "152.44px" }}
                />
              ) : (
                // If it's a file
                <img
                  src={URL.createObjectURL(category.image_url)}
                  alt="Uploaded"
                  style={{ height: "150px", width: "152.44px" }}
                />
              ))}
            {
              category?.image_url &&
              <Box onClick={() => setCategory({
                ...category,
                image_url: "",
              })} position={'absolute'} right={5} top={2}>
                <img src={Cross} alt="Cross" style={{ width: "15px", height: "15px", backgroundColor: "#F34A4A29", borderRadius: "33px", cursor: "pointer", }} />
              </Box>
            }
          </div>
          <Box display={'flex'} alignItems={'center'} columnGap={3} mt={2}>
            <div>
              <label
                htmlFor="imageInput"
                className="common-button-blue"
                style={{
                  paddingLeft: "60px",
                  paddingRight: "60px",
                  paddingTop: "16px",
                  paddingBottom: "16px",
                  cursor: "pointer",
                  whiteSpace: "nowrap"
                }}
              >
                Upload Photo
              </label>
              <input
                type="file"
                id="imageInput"
                onChange={(e) =>
                  setCategory({
                    ...category,
                    image_url: e.target.files?.[0],
                  })
                }
                style={{ display: "none" }}
              />
            </div>
            <div>
              <button onClick={() => setCategory({
                ...category,
                image_url: "",
              })} 
                disabled={!category?.image_url}
                style={{
                paddingLeft: "80px",
                paddingRight: "80px",
                paddingTop: "14px",
                paddingBottom: "14px",
                cursor: category?.image_url ? "pointer" : '',
                backgroundColor: "#F34A4A",
                opacity: category?.image_url ? 1 : 0.5,
                borderRadius: 8,
                color: "#fff"
              }}>
                Remove
              </button>
            </div>
          </Box>

          <h3 className="menu-title-header" style={{ paddingTop: "16px" }}>
            Time Controls
          </h3>
          <p className="title-sub-text">
            Use these settings to control when something is displayed in the
            menu. For example breakfast items can be limited to the morning
            hours, or certain drinks can be limited to happy hour.
          </p>
          {WeekDaysData?.length > 0 &&
            WeekDaysData?.map((item) => {
              return (
                <div key={item.weekday}>
                  <h3
                    style={{
                      paddingTop: "16px",
                      fontWeight: "500",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      margin: 0,
                      textTransform: "capitalize",
                    }}
                  >
                    {item?.weekday?.toLowerCase() || ""}
                  </h3>
                  <div
                    className="timeslots-input-container"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "6px",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["TimePicker"]}>
                        <TimePicker
                          label="Start Time"
                          sx={{}}
                          onChange={(val) =>
                            handleTimeChange(item.weekday, "start_time", val)
                          }
                          value={
                            timings[item?.weekday]?.start_time
                              ? dayjs(
                                timings[item?.weekday]?.start_time,
                                "HH:mm:ss"
                              )
                              : null
                          }
                          maxTime={
                            timings[item?.weekday]?.end_time
                              ? dayjs(
                                timings[item?.weekday]?.end_time,
                                "HH:mm:ss"
                              )
                              : null
                          }
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["TimePicker"]}>
                        <TimePicker
                          label="End Time"
                          onChange={(val) =>
                            handleTimeChange(item.weekday, "end_time", val)
                          }
                          value={
                            timings[item?.weekday]?.end_time
                              ? dayjs(
                                timings[item?.weekday]?.end_time,
                                "HH:mm:ss"
                              )
                              : null
                          }
                          minTime={
                            timings[item?.weekday]?.start_time
                              ? dayjs(
                                timings[item?.weekday]?.start_time,
                                "HH:mm:ss"
                              )
                              : null
                          }
                        />
                      </DemoContainer>
                    </LocalizationProvider>

                    {/* <input
                      placeholder="Start Time"
                      value={timings[item.weekday]?.start_time || ""}
                      style={{
                        marginTop: "8px",
                        width: "21.88vw",
                        height: "7.20vh",
                        borderRadius: "16px",
                        borderWidth: "1px",
                        borderColor: "#201C1C3D",
                        padding: "10px 16px 10px 16px",
                        outline: "none",
                        fontWeight: "400",
                        fontSize: "14px",
                        fontFamily: "Poppins",
                      }}
                      onChange={(e) =>
                        handleTimeChange(
                          item.weekday,
                          "start_time",
                          e.target.value
                        )
                      }
                    /> */}
                    {/* <input
                      placeholder="End Time"
                      value={timings[item.weekday]?.end_time || ""}
                      style={{
                        marginTop: "8px",
                        width: "21.88vw",
                        height: "7.20vh",
                        borderRadius: "16px",
                        borderWidth: "1px",
                        borderColor: "#201C1C3D",
                        padding: "10px 16px 10px 16px",
                        outline: "none",
                        fontWeight: "400",
                        fontSize: "14px",
                        fontFamily: "Poppins",
                        marginLeft: "16px",
                      }}
                      onChange={(e) =>
                        handleTimeChange(
                          item.weekday,
                          "end_time",
                          e.target.value
                        )
                      }
                    /> */}
                  </div>
                </div>
              );
            })}
          <div style={{ flexDirection: "row" }}>
            <button onClick={EditCategory} className="common-button-blue">
              {isLoading ? <CircularProgress color="inherit" size={25} /> : 'Apply'}
            </button>
            <button onClick={closeModal} className="common-button-cancel">
              Cancel
            </button>
          </div>
        </Box>
      </Modal>
      <SuccessModal
        open={categoryNameModal}
        onClose={() => setcategoryNameModal(false)}
        headerText="Successful!"
        bodyText="Category Updated successfully."
      />
    </>
  );
}

export default CategoryModal;
