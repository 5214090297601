import React, { useEffect, useState } from 'react'
import BackNavigation from '../../components/BackNavigation'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CREDITCARD from '../../assets/images/credit-card-02.png';
import APPLE from '../../assets/images/apple-pay.png';
import Modal from '@mui/material/Modal';
import SuccessModal from '../../components/SuccessModal';
import './Subscription.css'

function Payment() {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const handleSuccessModalClose = () => {
        setIsModalOpen(false);
    };

    return (

        <div>
            <BackNavigation label="Back" />
            <div style={{ display: 'flex', alignItems: 'space-between', marginLeft: '24px' }}>
                <Card sx={{ width: '268px', height: '210px', borderRadius: '8px', border: 'none', boxShadow: 'none' }}>
                    <CardContent>
                        <Typography sx={{ fontSize: '18px', width: '221px', fontFamily: 'poppins', fontWeight: '600', marginTop: '10px' }} color="#000000" gutterBottom>
                            Select Payment method
                        </Typography>

                        <Button startIcon={<img src={CREDITCARD} alt="Credit Card" width='24px' height='24px' style={{ marginRight: '8px' }} />} style={{ marginTop: '24px', width: '236px', height: '48px', borderRadius: "8px", backgroundColor: "#8B5CFF", color: '#FFFFFF', border: 0, fontFamily: 'Poppins', fontSize: '16px', fontWeight: '700', textTransform: 'capitalize' }} >Debit/Credit Card</Button>

                        <Button startIcon={<img src={APPLE} alt=" Apple Pay " width='24px' height='24px' style={{ marginRight: '8px', marginLeft: '12px' }} />}
                            style={{
                                marginTop: '18px',
                                width: '236px',
                                height: '48px',
                                borderRadius: "8px",
                                backgroundColor: "#8B5CFF38",
                                color: '#000000', border: 0,
                                fontFamily: 'Poppins',
                                fontSize: '16px',
                                fontWeight: '700',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                textTransform: 'capitalize'
                            }} >Apple Pay</Button>
                    </CardContent>
                </Card>

                <Card sx={{ width: '374px', height: '450px', borderRadius: '8px', border: 'none', boxShadow: 'none', marginLeft: '24px' }}>
                    <CardContent>
                        <div style={{ fontFamily: 'poppins', fontWeight: 700, fontSize: '14px', marginLeft: '16px' }}>
                            Total
                        </div>
                        <div style={{ fontFamily: 'poppins', fontWeight: 600, fontSize: '24px', marginLeft: '16px', marginTop: '12px' }}>
                            $456
                        </div>
                        <div style={{ fontFamily: 'poppins', fontWeight: 500, fontSize: '12px', marginLeft: '16px', marginTop: '24px' }}>
                            Name on card
                        </div>
                        <input className='input' style={{ width: '315px', height: '44px', borderRadius: '8px', border: '1px solid #201C1C3D', marginTop: '8px', marginLeft: '16px' }} placeholder="  Name">
                        </input>
                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '16px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '8px' }}>
                                <div style={{ fontFamily: 'poppins', fontWeight: 500, fontSize: '12px', marginTop: '24px' }}>
                                    Expiry Date
                                </div>
                                <input className='input' style={{ width: '154px', height: '44px', borderRadius: '8px', border: '1px solid #201C1C3D', marginTop: '8px' }} placeholder="  Month/Year">
                                </input>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', marginTop: '8px' }}>
                                <div style={{ fontFamily: 'poppins', fontWeight: 500, fontSize: '12px', marginTop: '24px' }}>
                                    CVV
                                </div>
                                <input className='input' style={{ width: '154px', height: '44px', borderRadius: '8px', border: '1px solid #201C1C3D', marginTop: '8px' }} placeholder="  CVV">
                                </input>
                            </div>
                        </div>
                        <div style={{ marginTop: '20px', marginLeft: '16px', display: 'flex', alignItems: 'center' }}>
                            <input type="checkbox" style={{ marginRight: '8px', width: '24px', height: '24px', borderRadius: '8px', border: '4px solid #000000' }} />
                            <div style={{ fontFamily: 'poppins', fontWeight: 500, fontSize: '14px', marginLeft: '12px'}}>
                                Save card details for later
                            </div>
                        </div>

                        <div style={{fontFamily: 'poppins', fontWeight: 400, fontSize: '14px', marginTop: '10px', marginLeft: '16px', color: '#8A8A8A'}}>
                            This transaction you make is totally secure. we don’t save your CVV number
                        </div>

                        <Button onClick={()=>setIsModalOpen(true)} style={{ marginTop: '22px', marginLeft: '16px', width: '320px', height: '48px', borderRadius: "8px", backgroundColor: "#8B5CFF", color: '#FFFFFF', border: 0, fontFamily: 'Poppins', fontSize: '14px', fontWeight: '700' }} >Continue</Button>
                    </CardContent>
                </Card>

            </div>
            <SuccessModal
                open={isModalOpen}
                onClose={handleSuccessModalClose}
                headerText="Successful!"
                bodyText="Payment has been done."
            />
            
        </div>
    )
}
export default Payment