import { useDispatch } from "react-redux";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import {
  setCatalogList,
  setItemsList,
} from "../../store/reducers/CatalogDataReducer";
import { useEffect, useState } from "react";

let AuthTokenId: any;
let UserCodeData: any;

const PromoCreationViewModel = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const UserCode = localStorage.getItem("UserCode");
    UserCodeData = UserCode;
  }, []);

  useEffect(() => {
    const TokenData: any = localStorage.getItem("Token");
    AuthTokenId = TokenData;
  }, []);

  const CategoryItemsList = async (MenuID: string) => {
    setLoading(true);
    const headers = {
      Authorization: `Bearer ${AuthTokenId}`,
    };
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
          ApiServices.promo_categories +
          `?user_code=${UserCodeData}&menu_code=${MenuID}`,
        headers
      );
      if (response.status === true) {
        setTimeout(() => {
          dispatch(setCatalogList(response?.categoriesList));
        }, 1000);
        setLoading(false);
      } else {
        console.log("Error");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const ItemsListData = async (MenuID: string, selectedCategoryId: string) => {
    const headers = {
      Authorization: `Bearer ${AuthTokenId}`,
    };
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
          ApiServices.items_list +
          `?user_code=${UserCodeData}&menu_code=${MenuID}&category_id=${selectedCategoryId}`,
        headers
      );
      if (response.status === true) {
        dispatch(setItemsList(response?.items));
        console.log(response, "Resp");
      } else {
        console.log("Error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return { CategoryItemsList, ItemsListData, loading };
};

export default PromoCreationViewModel;
