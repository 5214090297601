import React, { useEffect, useState } from "react";
import BGIMAGE from "../../assets/images/background.jpg";
import { useNavigate } from "react-router-dom";
import LOGO from "../../assets/images/logo_main_img.png";
import "./HelpUsToGrow.css";
import ApiServices from "../../helpers/ApiServices";
import { enqueueSnackbar } from "notistack";
import HelperModule from "../../helpers/HelperModule";
import { Button, Grid, Typography } from "@mui/material";
let userCodeData: any;
function HelpUsToGrow() {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    const UserCode = localStorage.getItem("UserCode");
    userCodeData = UserCode;
  }, []);

  const HelpToGrow = async () => {
    const Params = {
      user_code: userCodeData,
      referred_by_platform: selectedOption,
    };
    console.log("Params", Params);
    try {
      const response = await HelperModule().postMethod(
        ApiServices.base_URL + ApiServices.help_us,
        Params
      );
      console.log(response);
      if (response.status === true) {
        console.log(response);
        enqueueSnackbar(response.message, {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
        navigate("/trails");
      }
      // dispatch(setIsLogin(true))
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  return (
    <>
      {/* <div className="container-login text-start">
        <div className="main-container-login" style={{ width: "40%" }}>
          <h2 className="miniosk-login">Helps Us To Grow</h2>
          <p className="miniosk-login-desc">How Did You Hear About Us ?? </p>
          <div></div>
          <div className="radioButton_maindiv">
            <label className="labelForradio">
              <input
                type="radio"
                name="radio"
                value="Google"
                onChange={() => setSelectedOption("google")}
              />
              <span className="textForRadio-btn">Google</span>
            </label>
            <label className="labelForradio">
              <input
                type="radio"
                name="radio"
                value="Facebook"
                onChange={() => setSelectedOption("facebook")}
              />
              <span className="textForRadio-btn">Facebook</span>
            </label>
            <label className="labelForradio">
              <input
                type="radio"
                name="radio"
                value="Instagram"
                onChange={() => setSelectedOption("instagram")}
              />
              <span className="textForRadio-btn">Instagram</span>
            </label>
            <label className="labelForradio">
              <input
                type="radio"
                name="radio"
                value="by_friend"
                onChange={() => setSelectedOption("by_friend")}
              />
              <span className="textForRadio-btn">By Friend</span>
            </label>
            <label className="labelForradio">
              <input
                type="radio"
                name="radio"
                value="Ads"
                onChange={() => setSelectedOption("ads")}
              />
              <span className="textForRadio-btn">Ads</span>
            </label>
            <label className="labelForradio">
              <input
                type="radio"
                name="radio"
                value="Others"
                onChange={() => setSelectedOption("other")}
              />
              <span className="textForRadio-btn">Others</span>
            </label>
          </div>
          <button className="continue-btn" onClick={HelpToGrow}>
            Continue
          </button>
          <div className="kiosk_imgDiv">
            <img src={LOGO} alt="logo" style={{ width: "150px" }} />
          </div>
        </div>
        <div style={{ height: "100vh", overflow: "hidden", width: "60%" }}>
          <img
            src={BGIMAGE}
            alt="bg"
            style={{ maxWidth: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
      </div> */}
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={5}
          xl={5}
          className="grid-container-password"
        >
          <div className="container-hide"></div>
          <div>
            <div className="header-text">
              <Typography
                variant="h4"
                sx={{
                  typography: {
                    xs: "h4",
                    sm: "h4",
                    md: "h4",
                    lg: "h4",
                    xl: "h4",
                  },
                  color: "#201C1C",
                  fontFamily: "Poppins",
                }}
              >
                Helps us to grow
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ color: "#201C1CB8", fontFamily: "Poppins" }}
              >
                How did you hear about us?
              </Typography>
            </div>
            <div>
              <label className="labelForradio">
                <input
                  type="radio"
                  name="radio"
                  value="Google"
                  onChange={() => setSelectedOption("google")}
                />
                <span className="textForRadio-btn">Google</span>
              </label>
              <label className="labelForradio">
                <input
                  type="radio"
                  name="radio"
                  value="Facebook"
                  onChange={() => setSelectedOption("facebook")}
                />
                <span className="textForRadio-btn">Facebook</span>
              </label>
              <label className="labelForradio">
                <input
                  type="radio"
                  name="radio"
                  value="Instagram"
                  onChange={() => setSelectedOption("instagram")}
                />
                <span className="textForRadio-btn">Instagram</span>
              </label>
              <label className="labelForradio">
                <input
                  type="radio"
                  name="radio"
                  value="by_friend"
                  onChange={() => setSelectedOption("by_friend")}
                />
                <span className="textForRadio-btn">By Friend</span>
              </label>
              <label className="labelForradio">
                <input
                  type="radio"
                  name="radio"
                  value="Ads"
                  onChange={() => setSelectedOption("ads")}
                />
                <span className="textForRadio-btn">Ads</span>
              </label>
              <label className="labelForradio">
                <input
                  type="radio"
                  name="radio"
                  value="Others"
                  onChange={() => setSelectedOption("other")}
                />
                <span className="textForRadio-btn">Others</span>
              </label>
              <Button
                type="submit"
                variant="contained"
                onClick={HelpToGrow}
                sx={{
                  marginTop: "1rem",
                  height: "4rem",
                  fontSize: "1.2rem",
                  backgroundColor: "#8B5CFF",
                  borderRadius: "8px",
                  ":hover": {
                    bgcolor: "#8B5CFF",
                  },
                }}
                className="continue-button-grow"
              >
                Continue
              </Button>
            </div>
          </div>
          <div className="kiosk_image_logo">
            <img src={LOGO} alt="logo" style={{ width: "150px", display: "block", margin: "auto" }} />
          </div>
        </Grid>
        <Grid
          item
          xs={0}
          sm={6}
          md={6}
          lg={7}
          xl={7}
          className="container-image"
        >
          <img
            src={BGIMAGE}
            alt="bg"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default HelpUsToGrow;
