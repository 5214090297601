import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Account from "../../components/Account";
import "./Settings.css";
import Profile from "../../assets/images/Avatar-img.svg";
import Edit from "../../assets/images/EditProfile.png";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import { useDispatch, useSelector } from "react-redux";
import { setVendorDetails } from "../../store/reducers/VendorDetailsReducer";
import { Box, Checkbox, Chip, Grid, Modal, Popover, TextField, Typography } from "@mui/material";
import SuccessModal from "../../components/SuccessModal";
import { useSnackbar } from "notistack";
import { setIsLogin, setLocationAdded, setPosConnect, setPosConnectSelected, setStoreLimit } from "../../store/reducers/AuthReducer";
import ConfirmationModal from "../../components/ConfirmationModal";
import moment from "moment";
import SubsModal from "../../components/SubsModal";
import { useStyles } from "../../components/UseStyles";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import axios from "axios";

let AuthTokenId: any;
let PosAccountStatus: any;
let UserCodeData: any;
function Settings() {
  const { VendorList, subsInfo } = useSelector(
    (state: any) => state.vendorDetails
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [showModal1, setShowModal1] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [userID, setUserID]: any = useState(null);
  const [editDetailsModal, setEditDetailsModal]: any = useState(false);
  const [vendorUpdated, setVendorUpdated] = useState(false);
  const [profilePic, setProfilePic]: any = useState(null);
  const [locationsmodal, setLocationsModal] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const [locationsretrievied, setLocationsRetrieved] = useState(false);
  const [isSubsModalOpen, setIsSubsModalOpen] = useState(false);
  const [squareLocations, setSquareLocations]: any = useState([]);
  const [profileImage, setProfileImage]: any = useState(null);

  // console.log(profileImage,"ProfileImage ============",VendorList?.vendor_details?.profile_image);

  const [showBillingInfo, setShowBillingInfo] = useState(false);
  const [squarelink, setSquareLink]: any = useState(false);
  const [unlinkconfirmation, setUnlinkConfirmation] = useState(false);
  const [tokenData, setToken] = useState("");
  const [
    cancelSubscriptionConfirmModal,
    setcancelSubscriptionConfirmModal,
  ]: any = React.useState(false);
  const [successModalBodyText, setSuccessModalBodyText]: any = useState("");
  const [successModal, setSuccessModal] = React.useState(false);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [onLocation, setLocation] = useState<null | HTMLElement>(null);
  const [selectedLocationNames, setSelectedLocationNames] = useState<string[]>(
    []
  );

  const { posConnection, storeLimit, location_added } = useSelector((state: any) => state?.Auth);

  useEffect(() => {
    const UserCode = localStorage.getItem("UserCode");
    UserCodeData = UserCode;
    const TokenData: any = localStorage.getItem("Token");
    setToken(TokenData);
  }, []);

  const FetchPosAccountStatus = () => {
    // const PosAccount = localStorage.getItem("PosAccount");
    PosAccountStatus = posConnection;
    // console.log("posAccountStatus Details", PosAccountStatus);
  };

  useEffect(() => {
    FetchPosAccountStatus();
  }, []);

  const handleBillingInfoClick = () => {
    setShowBillingInfo((prev) => !prev);
    navigate("/BillingInfoTable");
  };

  useEffect(() => {
    const GetUserID = localStorage.getItem("UserID");
    if (GetUserID) {
      setUserID(GetUserID);
      console.log("UserID", GetUserID);
    }
  }, []);

  useEffect(() => {
    if (posConnection === 0) {
      navigate("/linkaccount");
    } else if (location_added === 0) {
      if (posConnection === 1) {
        Locations();
      }
    }
    // else{
    //   navigate("/dashboard");
    // }
  }, [location_added])

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfilePic(file);
        const url = URL.createObjectURL(file);
        setProfileImage(url);
        localStorage.setItem("profilePic", reader.result as string);
      };
      reader.readAsDataURL(file);
      reader.onload = async () => {
        setProfilePic(file);
        const url = URL.createObjectURL(file);
        setProfileImage(url);
        console.log("url of the image", file);
        localStorage.setItem("profilePic", reader.result as string);
        
        await uploadImage(file);
      };
    }
  };

  const uploadImage = async (fileInput: any) => {
    setUploading(true);
    try {
      const formData = new FormData();
      formData.append("profile_image", fileInput);
  
      const response = await HelperModule().postFormDataMethod(
        ApiServices.base_URL + ApiServices.update_vendor + userID,
        formData
      );
  
      if (response.status === false) {
        enqueueSnackbar(response, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
        setUploading(false);
        return;
      }
  
      // Update local state and vendor data after successful upload
      setVendorUpdated(true);
      await VendorData(); // Wait for vendor data to update
      setUploading(false);
    } catch (err) {
      console.log(err);
      setUploading(false);
    }
  };

  const openFileInput = () => {
    const fileInput = document.getElementById(
      "fileInput"
    ) as HTMLInputElement | null;
    if (fileInput) {
      fileInput.click();
    }
  };

  const VendorData = async () => {
    if (UserCodeData) {
      try {
        const response = await HelperModule().getMethod(
          ApiServices.base_URL + ApiServices.get_vendor + UserCodeData
        );
        if (response.status === true) {
          console.log(response?.data?.vendor_details,"response?.data?.vendor_details")
          dispatch(setVendorDetails(response.data));
          dispatch(setPosConnectSelected(false))
          dispatch(setPosConnect(response?.data?.vendor_details?.pos_account_status))
          dispatch(setLocationAdded(response?.data?.vendor_details?.pos_account?.location_added))
          let ids = response?.data?.locations?.map((eachItem:any) => { return eachItem.id })
        setSelectedLocations(ids)
        let names = response?.data?.locations?.map((eachItem: any) => { return eachItem.name })
        setSelectedLocationNames(names)
          dispatch(setStoreLimit(response?.data?.plan_details?.store_limit))
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    VendorData();
  }, [UserCodeData, dispatch]);

  const name = VendorList?.vendor_details?.name || "";
  const email = VendorList?.vendor_details?.email || "";
  const phone = VendorList?.vendor_details?.phone || "";

  const subscription_id = VendorList?.vendor_plan?.subscription_id;
  const user_id = VendorList?.vendor_plan?.user_id;

  const handleCancelSubscription = () => {
    setcancelSubscriptionConfirmModal(true);
  };

  const handleLocation = (event: any) => {
    setLocation(event.currentTarget);
  };

  const CancelSubscription = async () => {
    const headers = {
      Authorization: `Bearer ${AuthTokenId}`,
    };
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
        ApiServices.cancel_subscription +
        user_id +
        "/" +
        subscription_id,
        headers
      );
      if (response.status === true) {
        setcancelSubscriptionConfirmModal(false);
        setSuccessModalBodyText("Cancelled Subscription Successfully.");
        setSuccessModal(true);
      } else {
        console.log("Error Response", response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (locationId: string, locationName: string) => {
    if (selectedLocations.includes(locationId)) {
      const updatedLocations = selectedLocations.filter(
        (id) => id !== locationId
      );
      const updatedLocationNames = selectedLocationNames.filter(
        (name) => name !== locationName
      );
      setSelectedLocations(updatedLocations);
      setSelectedLocationNames(updatedLocationNames);
    } else {
      if (selectedLocations.length < storeLimit) {
        const updatedLocations = [...selectedLocations, locationId];
        const updatedLocationNames = [...selectedLocationNames, locationName];
        setSelectedLocations(updatedLocations);
        setSelectedLocationNames(updatedLocationNames);
      } else {
        enqueueSnackbar(`Maximum ${storeLimit} locations allowed.`, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
        console.log("Maximum two locations allowed.");
      }
    }
  };

  const VendorUpdate = async (e: any) => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    if (profilePic) {
      console.log("Profile Image Data URL:", profilePic);
      formData.append("profile_image", profilePic);
    }
    try {
      console.log(formData.get("profile_image"), "formData");

      const response = await HelperModule().postFormDataMethod(
        ApiServices.base_URL + ApiServices.update_vendor + userID,
        formData
      );
      if (response.status === true) {
        console.log("first===================>", profilePic);
        setEditDetailsModal(false);
        setTimeout(() => {
          setVendorUpdated(true);
        }, 500);
        console.log(response);
        VendorData();
      } else if (response.status === false) {
        enqueueSnackbar(response, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
        console.log(response);
      }
    } catch (error) {
      // enqueueSnackbar("Incorrect password", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }, style: { fontFamily: 'Poppins', fontWeight: '500' }, });
    }
  };

  const LinkedAccount = () => {
    setShowModal1(false);
    navigate("/catalog");
  };

  const handleButtonClick = () => {
    navigate("/Subscription", {
      state: { subscription: VendorList.vendor_plan?.status },
    });
  };

  const Locations = () => {
    if (!tokenData) {
      console.error("Token is not available yet.");
      return;
    }
    const apiEndpoint =
      ApiServices.base_URL + ApiServices.square_locations + userID;
    const token = tokenData;
    axios
      .get(apiEndpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response: any) => {
        setSquareLocations(response.data.locations);
        setLocationsModal(true)
        console.log("Square locations Data", response.data.locations);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  };

  const SquareLinked = async () => {
    console.log("Clicked");
    const Params = {
      user_code: userID,
      location_ids: selectedLocations,
    };
    console.log("Params", Params);
    try {
      const response = await HelperModule().postMethod(
        ApiServices.base_URL + ApiServices.vendor_locations,
        Params
      );
      if (response.status === true) {
        setLocationsModal(false);
        setTimeout(() => {
          setLocationsRetrieved(true);
        }, 500);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const handleDeleteLocation = (locationId: string) => {
    const updatedLocations = selectedLocations.filter(
      (id) => id !== locationId
    );
    setSelectedLocations(updatedLocations);
  };

  const UnlinkSquare = async () => {
    const params = {
      user_code: UserCodeData,
    };
    if (UserCodeData) {
      try {
        const response = await HelperModule().postMethod(
          ApiServices.base_URL + ApiServices.unlinl_square,
          params
        );
        if (response.status === true) {
          // console.log("first===================>", response);
          setSquareLink(response.is_active);
          // console.log("vendorDetails", response);
          // localStorage.clear();
          dispatch(setIsLogin(true));
          FetchPosAccountStatus();
          setTimeout(() => {
            navigate("/linkaccount");
          }, 2000);
        } else {
          console.log("Error");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const checkSubs = () => {
    if (subsInfo?.subscription_status === 0) {
      setIsSubsModalOpen(true);
      const element = document.getElementById("billing-section");
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }
  };

  useEffect(() => {
    checkSubs();
  }, [subsInfo]);

  return (
    <>
      <div className="mainContainer-settings">
        <h1 className="manage">Manage</h1>
        <p className="about">
          {" "}
          Manage your Miniosk account, subscription, and more
        </p>
        <div style={{ paddingTop: "16px" }}>
          <p className="info-txt">Info</p>
          <p className="about">
            General info about your account and subscription.
          </p>
        </div>
        <div className="maincontainer">
          <div className="profile-main-img-container">
            <input
              id="fileInput"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleImageChange}
              disabled={isUploading}
            />
            <div
              style={{
                backgroundImage: profileImage
                  ? `url(${profileImage})`
                  : VendorList?.vendor_details?.profile_image
                  ? `url(${VendorList?.vendor_details?.profile_image})`
                  : `url(${Profile})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                width: "146.82px",
                height: "146.82px",
                marginLeft: "20px",
                border: "4.53px solid #8B5CFF",
                borderRadius: "80px",
              }}
            >
              <div
                onClick={openFileInput}
                className="miniosk-image-container"
                style={{ top: 100, left: 100 }}
              >
                <img
                  alt="Edit"
                  src={Edit}
                  style={{
                    height: "20.12px",
                    width: "20.12px",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="box-about">
            <p className="subscriptions">Name</p>
            <p className="label-setting" style={{ paddingTop: "4px" }}>
              {VendorList?.vendor_details?.name}
            </p>
          </div>
          <div className="box-about">
            <p className="subscriptions">Email</p>
            <p className="label-setting" style={{ paddingTop: "4px" }}>
              {VendorList?.vendor_details?.email}
            </p>
          </div>
          <div className="box-about">
            <p className="subscriptions">Phone Number</p>
            <p className="label-setting" style={{ paddingTop: "4px" }}>
              {VendorList?.vendor_details?.phone}
            </p>
          </div>
          <div className="box-about">
            <p className="subscriptions">Subscription Status</p>
            <p
              className="label-setting"
              style={{
                paddingTop: "4px",
                color:
                  VendorList?.vendor_details?.subscription_status === 0
                    ? "#F34A4A"
                    : VendorList?.vendor_details?.subscription_status === 1
                      ? "#18BC46"
                      : "#000",
              }}
            >
              {VendorList?.vendor_details?.subscription_status === 0
                ? "Inactive"
                : VendorList?.vendor_details?.subscription_status === 1
                  ? "Active"
                  : "-"}
            </p>
          </div>
          <div className="box-about">
            <p className="subscriptions">
              Total Usage For Current Billing Period
            </p>
            <p className="label-setting" style={{ paddingTop: "4px" }}>
              {VendorList?.transactions_count || "0"} transactions
            </p>
          </div>
          <div className="box-about">
            <p className="subscriptions">Billing Frequency</p>
            <p className="label-setting" style={{ paddingTop: "4px" }}>
              {VendorList?.plan_details?.duration || "0"} Days
            </p>
          </div>
          {/* <div className='box-about'>
                    <p className='subscriptions'>Credit Card </p>
                    <p className='label-setting' style={{ paddingTop: '4px', paddingBottom: '8px' }}>XXXX-XXXX-XXXX-6456</p>
                </div> */}
          <div
            className="btn-container-settings"
            style={{
              paddingLeft: "12px",
              paddingTop: "16px",
              paddingBottom: "16px",
            }}
          >
            <button
              onClick={() => setEditDetailsModal(true)}
              className="edit-btn"
              style={{ transition: "background-color 0.3s", border: "none" }}
              onMouseEnter={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor =
                  "#6d43e57D";
              }}
              onMouseLeave={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor =
                  "#8B5CFF";
              }}
            >
              Edit{" "}
            </button>
            {/* <button className='changePassword' style={{ marginLeft: "16px" }}>Change Password </button> */}
          </div>
        </div>

        <div
          id="billing-section"
          className="text-start"
          style={{ paddingTop: "24px", paddingBottom: "24px" }}
        >
          <h1 className="typesofbilling">Billing Info</h1>
          <p className="about">
            Your billing info is securely stored on-file via Stripe for
            recurring charges
            <br /> depending on your plan.
          </p>
          <button
            style={{
              marginTop: "16px",
              transition: "background-color 0.3s",
              border: "none",
              width: "100px",
            }}
            className="billinginfo-btn"
            onClick={handleBillingInfoClick}
            onMouseEnter={(e) => {
              (e.target as HTMLButtonElement).style.backgroundColor =
                "#6d43e57D";
            }}
            onMouseLeave={(e) => {
              (e.target as HTMLButtonElement).style.backgroundColor = "#8B5CFF";
            }}
          >
            Billing Info{" "}
          </button>
          {showBillingInfo && (
            <div style={{ marginTop: "16px" }}>
              <table>
                <thead>
                  <tr>
                    <th>Transaction ID</th>
                    <th>Date</th>
                    <th>Subscription Name</th>
                    <th>Payment Type</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>12345678990</td>
                    <td>26 JAN 2023</td>
                    <td>HEADING</td>
                    <td> Card</td>
                    <td>$456</td>
                  </tr>
                  <tr>
                    <td>12345678990</td>
                    <td>26 JAN 2023</td>
                    <td>HEADING</td>
                    <td> Card</td>
                    <td>$456</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div className="text-start" style={{ paddingBottom: "24px" }}>
          <h1 className="typesofbilling">Subscription </h1>
          <div className="maincontainer">
            <div className="box-about">
              <p className="subscriptions">Membership </p>
              {subsInfo?.subscription_status === 1 ? (
                <>
                  <p
                    className="label-setting"
                    style={{ paddingTop: "4px", paddingBottom: "2px" }}
                  >
                    {VendorList?.plan_details?.name}
                  </p>
                  <p className="label-setting" style={{ paddingBottom: "2px" }}>
                    $ {VendorList?.plan_details?.amount}
                  </p>
                  <p
                    className="label-setting"
                    style={{
                      paddingBottom: "8px",
                      color: "#F34A4A",
                      fontWeight: "500",
                      fontSize: "12px",
                    }}
                  >
                    Next Billing Date: {VendorList?.vendor_plan?.end_date}
                  </p>
                </>
              ) : subsInfo?.subscription_status === 0 ? (
                <p
                  style={{
                    color: "#F34A4A",
                    fontSize: "14px",
                  }}
                >
                  Subscription expired
                </p>
              ) : (
                ""
              )}
            </div>
          </div>

          <p className="about" style={{ marginTop: "16px" }}>
            If you need to cancel your subscription, you can do so at any time
            using the button
            <br /> below. A confirmation prompt will appear, and when you
            successfully cancel you'll
            <br /> see a confirmation page.
          </p>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <button
              style={{
                marginTop: "16px",
                paddingLeft: "8px",
                paddingRight: "8px",
                width: "200px",
                border: "none",
              }}
              className="cancel-subscription"
              onClick={handleCancelSubscription}
              disabled={subsInfo?.subscription_status === 0}
            >
              Cancel Subscription{" "}
            </button>
            <button
              className="subscriptionplan-btn"
              style={{
                marginTop: "16px",
                transition: "background-color 0.3s",
                border: "none",
              }}
              onClick={handleButtonClick}
              onMouseEnter={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor =
                  "#6d43e57D";
              }}
              onMouseLeave={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor =
                  "#8B5CFF";
              }}
            >
              Change Subscription Plan{" "}
            </button>
          </div>
        </div>
        {PosAccountStatus === null ? (
          <>
            <Account
              title="Square Account"
              button={"Link My Square Account"}
              description="Miniosk integrates directly with your Square Account to import item libraries and process payments on your behalf. You can also use this button below to completely remove access to your Square account (for example, to switch to a different Square account)."
              onClick={() => setShowModal1(true)}
            />
          </>
        ) : (
          <div style={{ width: "42.45vw", paddingBottom: "24px" }}>
            <h1
              style={{
                margin: 0,
                fontFamily: "Poppins",
                fontSize: "24px",
                fontWeight: "600",
                color: "#201C1C",
              }}
            >
              Square Account
            </h1>
            <p
              style={{
                margin: 0,
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: "400",
                color: "#201C1C7A",
              }}
            >
              Miniosk integrates directly with your Square Account to import
              item libraries and process payments on your behalf. You can also
              use this button below to completely remove access to your Square
              account (for example, to switch to a different Square account).
            </p>
            <button
              onClick={() => setUnlinkConfirmation(true)}
              style={{
                marginTop: "16px",
                paddingLeft: "8px",
                paddingRight: "8px",
                width: "200px",
                border: "none",
              }}
              className="cancel-subscription"
            >
              UnLink My Square Account
            </button>
          </div>
        )}

        {/* <Account
                title="Toast Account"
                button={'Link My Toast Account'}
                description="Miniosk integrates directly with your Square Account to import item libraries and process payments on your behalf. You can also use this button below to completely remove access to your Square account (for example, to switch to a different Square account)."
                onClick={() => setShowModal1(true)}
            />

            <Account
                title="Clover Account"
                button={'Link My Clover Account'}
                description="Miniosk integrates directly with your Square Account to import item libraries and  process payments on your behalf. You can also use this button below to  completely remove access to your Square account (for example, to switch to a different Square account)."
                onClick={() => setShowModal1(true)}import { KeyboardArrowDownRoundedIcon } from '@mui/icons-material/KeyboardArrowDownRounded';

            /> */}

        <Modal open={showModal1} onClose={() => setShowModal1(false)}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "44.8vw",
              height: "32.0vh",
              bgcolor: "background.paper",
              borderRadius: "8px",
              boxShadow: 24,
              padding: "16px",
            }}
          >
            <div className="success-container">
              <h1 className="success-header"> Successfully Linked</h1>
              <p className="success-desc">
                Your Square account has been successfully linked{" "}
              </p>
              <button
                className="done-btn"
                style={{ marginTop: "16px" }}
                onClick={LinkedAccount}
              >
                Import Menu
              </button>
            </div>
          </Box>
        </Modal>

        <Modal open={successMsg} onClose={() => setSuccessMsg(false)}>
          <div
            className="mainBox-modal"
            style={{ width: "33%", height: "150px" }}
          >
            <div className="pt-4 text-center">
              <h1 className="subheading"> Successfull!</h1>
              <p className="successmsg">You Import a new menu successfully! </p>
              <Link to="/catalog">
                <button className="done-btn">Done </button>
              </Link>
            </div>
          </div>
        </Modal>

        <Modal
          open={editDetailsModal}
          onClose={() => setEditDetailsModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              bottom: "-100%",
              transition: "bottom 0.3s ease-in-out",
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "392px",
              height: "250px",
              bgcolor: "background.paper",
              borderRadius: "8px",
              boxShadow: 24,
              padding: "16px",
              borderWidth: 0,
            }}
          >
            <h3 className="menu-heading">Name</h3>
            <input
              placeholder="Name"
              onChange={(e) =>
                dispatch(
                  setVendorDetails({
                    ...VendorList,
                    vendor_details: {
                      ...VendorList.vendor_details,
                      name: e.target.value,
                    },
                  })
                )
              }
              value={name}
              className="miniosk-input"
              style={{
                marginTop: "8px",
                width: "360px",
                height: "44px",
                borderRadius: "8px",
                borderWidth: "1px",
                borderColor: "#201C1C3D",
                padding: "10px 16px 10px 16px",
                outline: "none",
                fontWeight: "400",
                fontSize: "14px",
                fontFamily: "Poppins",
                color: "#201C1C",
              }}
            />
            {/* <h3 className='menu-heading' style={{ paddingTop: "24px" }}>Email</h3>
                    <input placeholder='Email' onChange={(e) => dispatch(setVendorDetails({ ...VendorList, vendor_details: { ...VendorList.vendor_details, email: e.target.value } }))} value={email} className='miniosk-input' style={{ marginTop: "8px", width: "360px", height: "44px", borderRadius: "8px", borderWidth: "1px", borderColor: "#201C1C3D", padding: "10px 16px 10px 16px", outline: "none", fontWeight: "400", fontSize: "14px", fontFamily: "Poppins", color: "#201C1C" }} /> */}
            <h3 className="menu-heading" style={{ paddingTop: "24px" }}>
              Phone Mumber
            </h3>
            <input
              placeholder="Phone Number"
              readOnly={true}
              onChange={(e) =>
                dispatch(
                  setVendorDetails({
                    ...VendorList,
                    vendor_details: {
                      ...VendorList.vendor_details,
                      phone: e.target.value,
                    },
                  })
                )
              }
              value={phone}
              className="miniosk-input"
              style={{
                marginTop: "8px",
                width: "360px",
                height: "44px",
                borderRadius: "8px",
                borderWidth: "1px",
                borderColor: "#201C1C3D",
                padding: "10px 16px 10px 16px",
                outline: "none",
                fontWeight: "400",
                fontSize: "14px",
                fontFamily: "Poppins",
                color: "#201C1C",
              }}
            />
            <div style={{ display: "flex", flexDirection: "row" }}>
              <button onClick={VendorUpdate} className="common-button-blue">
                Save
              </button>
              <button
                onClick={() => setEditDetailsModal(false)}
                className="common-button-cancel"
              >
                Cancel
              </button>
            </div>
          </Box>
        </Modal>

        <Modal
          open={locationsretrievied}
          onClose={() => setLocationsRetrieved(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "38.8vw",
              bgcolor: "background.paper",
              borderRadius: "8px",
              boxShadow: 24,
              padding: "16px",
            }}
          >
            <div className="success-container">
              <h2 className="success-header">
                Locations Retrieved Successfully
              </h2>
              {/* <p className='success-desc'>Your square account has been successfully linked</p> */}
              <button
                onClick={() => setLocationsRetrieved(false)}
                className="done-btn"
                style={{ marginTop: "16px" }}
              >
                Done
              </button>
            </div>
          </Box>
        </Modal>

        <Modal
          open={locationsmodal}
          onClose={() => setLocationsModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "44.8vw",
              bgcolor: "background.paper",
              borderRadius: "8px",
              boxShadow: 24,
              padding: "16px",
            }}
          >
            <div className="success-container">
              <h2 className="success-header">Successfully Linked</h2>
              <p className="success-desc">
                Your square account has been successfully linked
              </p>
              <p className="success-desc">
                Choose two location has you selected free trail
              </p>
              <Grid
                onClick={(event: any) => {
                  handleLocation(event);
                }}
                item
                xs={12}
                sm={6}
                md={3.5}
                lg={3.5}
                xl={3.5}
              >
                <Box className={classes?.OverviewMenu}>
                  {selectedLocations.length > 0 ? (
                    <>
                      {selectedLocations.map((locationId, index) => (
                        <Chip
                          key={index}
                          label={selectedLocationNames[index]}
                          onDelete={() => handleDeleteLocation(locationId)}
                          sx={{
                            margin: "0px",
                            height: "25px",
                            padding: "0px",
                          }}
                        />
                      ))}
                    </>
                  ) : (
                    <>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: 500, lineHeight: "16px" }}
                      >
                        Select Location
                      </Typography>
                      <KeyboardArrowDownRoundedIcon
                        sx={{ width: "20px", height: "20px", color: "grey" }}
                      />
                    </>
                  )}
                </Box>
              </Grid>

              {/* <button onClick={ImportCatalog} className='done-btn' style={{ marginTop: '16px' }}> */}
              <button
                onClick={SquareLinked}
                className="done-btn"
                style={{ marginTop: "16px" }}
              >
                Done
              </button>
            </div>
          </Box>
        </Modal>

        <SuccessModal
          open={vendorUpdated}
          onClose={() => setVendorUpdated(false)}
          headerText="Successful!"
          bodyText="Profile Details Updated successfully."
        />

        <ConfirmationModal
          open={unlinkconfirmation}
          onConfirm={UnlinkSquare}
          onCancel={() => setUnlinkConfirmation(false)}
          headerText="Are you sure?"
          bodyText="You want to Unlink your Square Account"
        />

        <SuccessModal
          open={successModal}
          onClose={() => {
            setSuccessModal(false);
            VendorData();
          }}
          headerText="Successful!"
          bodyText={successModalBodyText}
        />
        <ConfirmationModal
          open={cancelSubscriptionConfirmModal}
          onConfirm={CancelSubscription}
          onCancel={() => setcancelSubscriptionConfirmModal(false)}
          headerText="Are you sure?"
          bodyText="You want to Cancel Subscription"
        />
      </div>
      <SubsModal
        open={isSubsModalOpen}
        onConfirm={() => setIsSubsModalOpen(false)}
        headerText="Subscription Expired"
        bodyText="Your subscription has expired. Please renew immediately."
      />
      <Popover
        open={Boolean(onLocation)}
        anchorEl={onLocation}
        onClose={() => setLocation(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        PaperProps={{
          sx: { width: `{onLocation?.clientWidth}px`, borderRadius: "8px" },
        }}
      >
        <Box sx={{ p: 0.8, width: "100%", height: "200px", overflow: "auto" }}>
          <TextField
            type="Text"
            placeholder="Search"
            InputProps={{
              style: { borderRadius: "6px", backgroundColor: "#FFFFFF" },
              sx: {
                ".MuiOutlinedInput-notchedOutline": { borderRadius: "6px" },
                input: {
                  borderRadius: "6px",
                  backgroundColor: "#FFFFFF",
                  padding: "8px 8px",
                },
              },
            }}
            size="medium"
            fullWidth
          />
          <Box className={classes.DasboardFilterMain} sx={{ pt: 0.5 }}>
            {squareLocations && Array.isArray(squareLocations) ? (
              squareLocations.map((location: any, index: any) => (
                <Box key={index} className={classes.DasboardFilterItem}>
                  <Checkbox
                    disableRipple
                    checked={selectedLocations.includes(location.id)}
                    onChange={() => handleClick(location.id, location.name)}
                  />
                  <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                    {location?.name}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                No locations found
              </Typography>
            )}
          </Box>
        </Box>
      </Popover>
    </>
  );
}

export default Settings;
