import { createSlice } from "@reduxjs/toolkit";

interface initialStateProps {
    HotSellingDrp: any;
}

const initialState: initialStateProps = {
    HotSellingDrp: []
}

const HotSellingDrpReducer = createSlice({
    name: 'HotSellingDrpDetails',
    initialState,
    reducers: {
        setHotSellingDrp: (state, action) => {
            state.HotSellingDrp = action.payload
        }
    }
});

export const { setHotSellingDrp } = HotSellingDrpReducer.actions;
export default HotSellingDrpReducer.reducer