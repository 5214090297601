import { createSlice } from "@reduxjs/toolkit";

interface initialStateProps {
    UpSellingList: any;
}

const initialState: initialStateProps = {
    UpSellingList: []
}

const UpSellingReducer = createSlice({
    name: 'UpSellingListDetails',
    initialState,
    reducers: {
        setUpSellingList: (state, action) => {
            state.UpSellingList = action.payload
        }
    }
});

export const { setUpSellingList } = UpSellingReducer.actions;
export default UpSellingReducer.reducer