import { useEffect, useState } from "react";
import BackNavigation from "../../components/BackNavigation";
import "./Location.css";
import ApiServices from "../../helpers/ApiServices";
import HelperModule from "../../helpers/HelperModule";
import { useAppSelector } from "../../Hooks";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

let UserCodeData: any;
function Location() {
  const navigate = useNavigate();

  const { locationSelectedId } = useAppSelector(
    (state) => state.locationDetails
  );

  const [locationData, setLocationData] = useState<any>({});

  const locationListData = async () => {
    try {
      const response = await HelperModule().getMethod(
        ApiServices.base_URL +
          ApiServices.location_list +
          "/" +
          locationSelectedId +
          "/" +
          UserCodeData
      );
      if (response.status === true) {
        setLocationData(response.result);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const locID = useSelector(
    (store: any) => store.locationDetails?.locationSelectedId
  );

  useEffect(() => {
    if (!locID) {
      navigate("/manage");
    }
    const UserCode = localStorage.getItem("UserCode");
    UserCodeData = UserCode;
    locationListData();
  }, []);

  return (
    <div>
      <BackNavigation label="Back" />
      <div className="container">
        <div className="heading">Basic Information</div>
        <div className="basic-info">
          <div className="location-name">Location Business Name</div>
          <input
            className="location-input"
            value={locationData?.business_name}
          />
          <div className="nick-name">Nick Name</div>
          <input className="location-input" value={locationData?.name} />
        </div>
        <div className="business">Business Bio</div>
        <div className="basic-info">
          <div className="location-name">Bio</div>
          <input
            className="location-input1"
            value={
              locationData?.business_bio === null
                ? "N/A"
                : locationData?.business_bio
            }
          />
        </div>
        <div className="business">Location Type</div>
        <div className="location">
          <div className="location-name">Location Type</div>
          <input
            className="location-input"
            value={locationData?.business_type}
          />
        </div>
        <div className="business">Business Address</div>
        <div className="basic-info1">
          <div className="location-name">Address Line 1</div>
          <input
            className="location-input"
            value={locationData?.address?.address_line_1}
          />
          <div className="nick-name">Address Line 2</div>
          <input
            className="location-input"
            value={locationData?.address?.administrative_district_level_1}
          />
          <div className="nick-name">City</div>
          <input
            className="location-input"
            value={locationData?.address?.locality}
          />
          <div className="nick-name">Zip Code</div>
          <input
            className="location-input"
            value={locationData?.address?.postal_code}
          />
          <div className="nick-name">Country</div>
          <input
            className="location-input"
            value={locationData?.address?.country}
          />
        </div>
      </div>
    </div>
  );
}

export default Location;
