import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Switch from "@mui/material/Switch";
// import Switch from "react-switch";
import Checkbox from "@mui/material/Checkbox";
import "./PromoCreationStyles.css";
import BackNavigation from "../../components/BackNavigation";
import Rightarrow from "../../assets/images/ArrowRightColored.png";
import DownArrow from "../../assets/images/dropdown-down.png";
import { InputAdornment, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import { setLocationsList } from "../../store/reducers/LocationsReducer";
import { useDispatch, useSelector } from "react-redux";
import PromoCreationViewModel from "./PromoCreationViewModel";
import { setItemsList } from "../../store/reducers/CatalogDataReducer";
import { enqueueSnackbar } from "notistack";
import { useLocation, useNavigate, useNavigation } from "react-router";
import { setPromoIndex } from "../../store/reducers/PromoCodeReducer";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import _ from "lodash";

let UserCodeData: any;
let LocationID: any;
let AuthTokenId: any;
function PromotionUpdate() {
  const { LocationsList } = useSelector((state: any) => state.locationDetails);
  const { PromoIndex } = useSelector((state: any) => state.promoCodeDetails);
  console.log("==============================>", PromoIndex);
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = React.useState("");
  const [selectedLocationOption, setSelectedLocationOption] =
    React.useState("Select a location");
  const [selectedMenuOption, setSelectedMenuOption] = React.useState(
    PromoIndex?.menu?.menu_code || "Select Menu"
  );
  const [selectedValue, setSelectedValue]: any = React.useState(
    PromoIndex?.discount_type || "free_item"
  );
  const selectedCategoryIdsFromPromoIndex = PromoIndex?.categories || [];
  const [openItems, setOpenItems]: any = React.useState([]);
  const [selectedItems, setSelectedItems]: any = React.useState([]);
  const [promoCode, setPromoCode] = useState("");
  // const [discountType, setDiscountType] = useState('');
  const [startDate, setStartDate] = React.useState<Dayjs | null>();
  const [startTime, setStartTime] = useState("");
  const [endDate, setEndDate] = React.useState<Dayjs | null>();
  const [endTime, setEndTime] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [isRequiredPurchaseVisible, setIsRequiredPurchaseVisible] =
    useState(false);
  const [menuCard, setMenuCard]: any = useState([]);
  const [minAmount, setMinAmount] = useState("");
  const [maxAmount, setMaxAmount] = useState("");
  const [totalUses, setTotalUses] = useState("");
  const [tokenData, setToken] = useState("");
  const [locationID, setLocationID]: any = useState("");
  const [MenuID, setMenuID]: any = useState("");
  const { CategoryItemsList } = PromoCreationViewModel();
  const { CatalogList, ItemsList } = useSelector(
    (state: any) => state?.catalogDetails
  );
  const [itemsListopen, setitemsListOpen]: any = useState(false);
  const { ItemsListData } = PromoCreationViewModel();
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<
    { posId: any; categoryId: any }[]
  >([]);
  const [selectedItemIds, setSelectedItemIds] = useState<number[]>([]);
  const [customer, setCustomer] = useState("Select Customer");
  const [selectedCategoryCodes, setSelectedCategoryCodes]: any = useState([]);
  const [categoryDropdownState, setCategoryDropdownState]: any = useState({});
  const Navigate = useNavigate();
  const location = useLocation();
  const discountType = PromoIndex?.discount_type;
  const [showPromo, setShowPromo] = React.useState(false);
  const [isSwitchOn, setIsSwitchOn] = useState({
    free: true,
    limit: false,
    promo: false,
  });
  const [durationSwitch, setDurationSwitch] = useState(false)
  const [limitSwitch, setLimitSwitch] = useState(false)

  const [selectedCategoryIdsfreeItem, setSelectedCategoryIdsfreeItem] =
    useState<{ posId: any; categoryId: any }[]>([]);
  const [selectedItemIdsfreeItem, setSelectedItemIdsfreeItem] = useState<
    number[]
  >([]);

  // redeclare

  const [selectedItemsfreeItem, setSelectedItemsfreeItem]: any = React.useState(
    []
  );
  const [select_all, setSelect_all] = React.useState(false);
  const [isSwitchEnabled, setIsSwitchEnabled] = React.useState(false);
  const [isSwitchEnabledUsage, setIsSwitchEnabledUsage] = React.useState(false);
  const [isSwitchEnabledDuration, setIsSwitchEnabledDuration] =
    React.useState(false);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [openItemsList, setOpenItemsList]: any = React.useState([]);

  const [itemsListopenfreeItem, setitemsListOpenfreeItem]: any =
    useState(false);

  const [selectedCategoryCodesfreeItem, setSelectedCategoryCodesfreeItem]: any =
    useState([]);

  const [categoryDropdownStatefreeItem, setCategoryDropdownStatefreeItem]: any =
    useState({});

  const [categoryDropdownStates, setCategoryDropdownStates] = useState<{
    [key: string]: boolean;
  }>({});
  const [categoryDropdownStatesfreeItem, setCategoryDropdownStatesfreeItem] =
    useState<{ [key: string]: boolean }>({});
  const [selectedCategorySwitch, setSelectedCategorySwitch] = useState<
    string | null
  >(null);
  const [selectedItemsSwitch, setSelectedItemsSwitch] = useState<string[]>([]);
  const [selectedItemSwitch, setSelectedItemSwitch] = useState<string | null>(
    null
  );

  const [paramStartDate, setparamStartDate] = React.useState<String | null>();
  const [paramEndDate, setparamEndDate] = React.useState<String | null>();

  useEffect(() => {
    if (discountType === "amount_off") {
      setSelectedValue("Amount_off");
    } else if (discountType === "percentage_off") {
      setSelectedValue("percentage_off");
    } else {
      setSelectedValue("free_item");
    }
  }, [discountType]);

  useEffect(() => {
    const TokenData: any = localStorage.getItem("Token");
    AuthTokenId = TokenData;
  }, []);

  useEffect(() => {
    const UserCode = localStorage.getItem("UserCode");
    UserCodeData = UserCode;
  }, []);

  useEffect(() => {
    const TokenData: any = localStorage.getItem("Token");
    setToken(TokenData);
  }, []);

  useEffect(() => {
    let categories = PromoIndex?.categories?.filter(
      (elem: any) => elem?.selected
    );

    let ids = categories?.map((elem: any) => elem?.id);

    console.log(PromoIndex?.categories, "categories");
    if (ids) {
      setSelectedCategoryIds([...selectedCategoryIds, ...ids]);
    }

    let totalSelectedItems: any = [];
    let data = categories?.map((elem: any) => {
      let items = elem.items_list?.filter((item: any) => {
        if (item.selected) {
          console.log(item, "ITEM");
          return item;
        }
      });

      items?.map((elem: any) => {
        totalSelectedItems.push(elem?.id);
      });
    });

    console.log(data, "totalSelectedItems");

    console.log(totalSelectedItems, "totalSelectedItems");

    setSelectedItemIds([...selectedItemIds, ...totalSelectedItems]);

    // discount selection

    let disCategories = PromoIndex?.categories?.filter(
      (elem: any) => elem?.discounted_selected
    );

    let disIds = disCategories?.map((elem: any) => elem?.id);

    console.log(disIds, disCategories, "discount");
    if (ids) {
      setSelectedCategoryIdsfreeItem([...disIds]);
      setIsSwitchOn({ ...isSwitchOn, free: true });
    }

    setCustomer(PromoIndex?.advanced_settings?.eligibility_type);

    let totalSelectedItemsDiscount: any = [];
    let DiscountData = disCategories?.map((elem: any) => {
      let items = elem.items_list?.filter((item: any) => {
        if (item.discounted_selected) {
          console.log(item, "ITEM");
          return item;
        }
      });

      items?.map((elem: any) => {
        totalSelectedItemsDiscount.push(elem?.id);
      });
    });

    // setSelectedItemIds([...selectedItemIds, ...totalSelectedItems])
    setSelectedItemIdsfreeItem([...totalSelectedItemsDiscount]);

    // const disItemstotal =  disCategories?.map((elem: any) => elem?.items_list)

    // console.log(disItemstotal, disCategories, "discount items");

    // const disItems = disItemstotal?.filter((elem: any) => { console.log(elem?.discounted_selected )     })

    // console.log(disItems,"discount items");

    // let disItemIdeas = disItems?.map((elem: any) => elem?.id)

    // console.log(disItemIdeas,"ids");

    // if (ids) {
    //     // setSelectedCategoryIdsfreeItem([...disIds])
    //     setSelectedItemIdsfreeItem([...disItemIdeas]);

    // }

    // setSelectedItemIds([...selectedItemIds,...data])

    console.log(categories, "Promo Index");

    // setSelectedCategoryIds([...selectedCategoryIds,categoryId])
  }, [PromoIndex]);

  const handlediscountValueChange = (e: any) => {
    setDiscountValue(e.target.value);
  };

  const handleStartDate = (e: any) => {
    const inputValue = e.target.value.replace(/[^\d-]+/g, "");
    setStartDate(inputValue);
    const isValid = /^[0-9-]+$/.test(inputValue);
    if (!isValid) {
      enqueueSnackbar("Please Enter Valid Start Date.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      console.log("Invalid phone number");
    }
  };

  // const handleStartDateChange = (date: any) => {
  //     console.log("DATE==>", date)
  //     setStartDate(date);
  //     // if (endDate && date && date > endDate) {
  //     //   setEndDate(null);
  //     // }
  //   };

  const handleStartDateChange = (date: any) => {
    console.log("DATE==>", dayjs(date).format(), date);
    let formatDate = dayjs(date).format();
    setparamStartDate(formatDate);
    setStartDate(date);
    // if (endDate && date && date > endDate) {
    //   setEndDate(null);
    // }
  };

  const handleEndDateChange = (date: any) => {
    // if (!startDate || !date || date >= startDate) {
    let formatDate = dayjs(date).format();
    setparamEndDate(formatDate);
    setEndDate(date);
    // } else {
    //   console.error("End date must be after or the same as start date.");
    // }
  };

  const handleEndDate = (e: any) => {
    const inputValue = e.target.value.replace(/[^\d-]+/g, "");
    setEndDate(inputValue);
    const isValid = /^[0-9-]+$/.test(inputValue);
    if (!isValid) {
      enqueueSnackbar("Please Enter Valid End Date.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      console.log("Invalid phone number");
    }
  };

  const handleStartTime = (e: any) => {
    const inputValue = e.target.value.replace(/[^\d:]+/g, "");
    setStartTime(inputValue);
    const isValid = /^[0-9:]+$/.test(inputValue);
    if (!isValid) {
      enqueueSnackbar("Please Enter Valid Start Time.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      console.log("Invalid phone number");
    }
  };

  const handleEndTime = (e: any) => {
    const inputValue = e.target.value.replace(/[^\d:]+/g, "");
    setEndTime(inputValue);
    const isValid = /^[0-9:]+$/.test(inputValue);
    if (!isValid) {
      enqueueSnackbar("Please Enter Valid End Time.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      console.log("Invalid phone number");
    }
  };

  const handleCheckboxChange = (itemName: string, itemId: number) => {
    const updatedSelectedItems = [...selectedItems];
    const updatedSelectedItemIds = [...selectedItemIds];
    // if (updatedSelectedItems.includes(itemName)) {
    //     updatedSelectedItems.splice(updatedSelectedItems.indexOf(itemName), 1);
    //     updatedSelectedItemIds.splice(updatedSelectedItemIds.indexOf(itemId), 1);
    // } else {
    //     updatedSelectedItems.push(itemName);
    //     updatedSelectedItemIds.push(itemId);
    // }
    // setSelectedItems(updatedSelectedItems);
    // setSelectedItemIds(updatedSelectedItemIds);

    // console.log(itemId,"Item Id");

    if (selectedItemIds?.includes(itemId)) {
      // console.log("Coming here",);

      setSelectedItemIds(selectedItemIds?.filter((elem) => elem != itemId));
    } else {
      console.log("idsa");

      setSelectedItemIds([...selectedItemIds, itemId]);
    }

    // setSelectedItemIds(updatedSelectedItemIds);
    setSelectedItems(updatedSelectedItems);
  };

  const handleRadioChange = (event: any) => {
    setSelectedValue(event.target.value);
    setIsRequiredPurchaseVisible(event.target.value === "free_item");
  };

  const handleChangeMenu = (e: any) => {
    const selectedMenuId = e.target.value;
    setSelectedMenuOption(selectedMenuId);
    setMenuID(selectedMenuId);
  };

  const handleCustomerChange = (e: any) => {
    setCustomer(e.target.value);
  };

  const handleChangeLocation = (e: any) => {
    const selectedLocationID = e.target.value;
    setSelectedLocationOption(selectedLocationID);
    setLocationID(selectedLocationID);
  };

  const handleItemSelect = async (pos_id: string, itemId: number) => {
    console.log(pos_id, "pos_id");
    // setSelectedCategoryIds((prevSelectedCategoryIds: any) =>
    //     prevSelectedCategoryIds.includes(pos_id) ? prevSelectedCategoryIds.filter((id: any) => id !== pos_id) : [...prevSelectedCategoryIds, pos_id]
    // );
    // setSelectedItemIds((prevSelectedItemIds) =>
    //     prevSelectedItemIds.includes(itemId) ? prevSelectedItemIds.filter((id) => id !== itemId) : [...prevSelectedItemIds, itemId]
    // );
    // await ItemsListData("MenuID", pos_id);
    setitemsListOpen([itemId]);
    setCategoryDropdownState((prev: any) => ({
      ...prev,
      [pos_id]: true,
    }));
  };

  const handleItemSelectClose = async () => {
    setItemsList([]);
    // setitemsListOpen(false)
    setitemsListOpen([]);

    setCategoryDropdownState({});
  };

  console.log(selectedCategoryIds, "categories");

  console.log(selectedItemIds, "items");

  const handleCategorySelect = (posId: any, categoryId: any) => {
    console.log("called");

    const updatedCategoryIds = [...selectedCategoryIds];
    const updatedCategoryCodes = [...selectedCategoryCodes];
    const existingIndex = updatedCategoryIds.findIndex(
      (item) => item.posId === posId
    );
    // if (existingIndex !== -1) {
    //     updatedCategoryIds.splice(existingIndex, 1);
    //     updatedCategoryCodes.splice(existingIndex, 1);
    // } else {
    //     updatedCategoryIds.push({ posId, categoryId });
    //     updatedCategoryCodes.push(categoryId);
    // }
    // setSelectedCategoryIds(updatedCategoryIds);
    // setSelectedCategoryCodes(updatedCategoryCodes);

    if (selectedCategoryIds?.includes(categoryId)) {
      setSelectedCategoryIds(
        selectedCategoryIds?.filter((elem) => elem != categoryId)
      );

      let ids = PromoIndex?.categories?.filter(
        (elem: any) => elem.id == categoryId
      );
      console.log(ids, "=====ids");

      let itemIds = ids[0]?.items_list?.map((elem: any) => {
        return elem?.id;
      });

      let items = selectedItemIds?.filter((elem) => !itemIds?.includes(elem));

      setSelectedItemIds([...items]);

      console.log(items, "arrays");
    } else {
      console.log("coming here");

      setSelectedCategoryIds([...selectedCategoryIds, categoryId]);

      let category = PromoIndex?.categories?.filter(
        (elem: any) => elem.id == categoryId
      );

      let data = category[0]?.items_list?.map((elem: any) => elem.id);

      console.log(data, "items");

      setSelectedItemIds([...selectedItemIds, ...data]);

      // console.log(...selectedCategoryIds,categoryId);

      // let ids = updatedCategoryIds?.map((elem:any)=>elem.id)

      // console.log(ids,"ids",selectedItemIds);

      // setSelectedItemIds([...selectedItemIds,...ids])
    }

    setSelectedCategoryCodes(updatedCategoryCodes);
  };

  const handleCategorySelectWithSwitch = (posId: string, categoryId: any) => {
    console.log("Selected PosId:", posId);
    console.log("Selected CategoryId:", categoryId);

    console.log(categoryId, "categoryId");
    if (selectedCategoryIdsfreeItem?.includes(categoryId)) {
      setSelectedCategoryIdsfreeItem([]);
      categoryId = "";
    } else {
      setSelectedCategoryIdsfreeItem([categoryId]);
    }
    console.log(ItemsList, "ItemList");

    let items = ItemsList?.filter(
      (elem: any, index: any) => elem?.f_category_id == categoryId
    );

    let ids = items?.filter((elem: any, index: any) => {
      if (index == 0 || index == 1) {
        return elem.id;
      }
    });

    setSelectedItemIdsfreeItem(ids?.map((elem: any) => elem.id));

    // handleItemSelectCloseWithSwitch()
  };

  const handleItemSelectCloseWithSwitch = async () => {
    setItemsList([]);
    setitemsListOpenfreeItem(false);
    setCategoryDropdownStatefreeItem({});
  };

  const handleItemSelectWithSwitch = async (pos_id: string, itemId: number) => {
    console.log("Selected Category ID:", pos_id);

    // Toggle selectedItemSwitch based on the current state
    setSelectedItemSwitch((prevSelectedItem) =>
      prevSelectedItem === pos_id ? null : pos_id
    );

    // Open the item list
    console.log("Setting itemsListOpenfreeItem to true");
    setitemsListOpenfreeItem(itemId);

    // setSelectedCategoryIdsfreeItem((prevSelectedCategoryIds: any) =>
    //     prevSelectedCategoryIds.includes(pos_id)
    //         ? prevSelectedCategoryIds.filter((id: any) => id !== pos_id)
    //         : [...prevSelectedCategoryIds, pos_id]
    // );

    // setSelectedItemIdsfreeItem((prevSelectedItemIds) =>
    //     prevSelectedItemIds.includes(itemId)
    //         ? prevSelectedItemIds.filter((id) => id !== itemId)
    //         : [...prevSelectedItemIds, itemId]
    // );

    await ItemsListData(MenuID, pos_id);

    setCategoryDropdownStatefreeItem((prev: any) => ({
      ...prev,
      [pos_id]: true,
    }));
  };

  const handleCheckboxChangeWithSwitch = (itemName: string, itemId: number) => {
    console.log("Selected Item Name:", itemName);
    console.log("Selected Item ID:", itemId);
    const updatedSelectedItems = [...selectedItems];
    const updatedSelectedItemIds = [...selectedItemIds];
    const isSelected = selectedItems.includes(itemName);

    // if (!isSelected) {
    //     setSelectedItemsfreeItem([itemName]);
    // } else {
    //     setSelectedItemsfreeItem((prevSelectedItems: any) =>
    //         prevSelectedItems.filter((selectedItem: any) => selectedItem !== itemName)
    //     );
    // }
    // if (updatedSelectedItems.includes(itemName)) {
    //     updatedSelectedItems.splice(updatedSelectedItems.indexOf(itemName), 1);
    //     updatedSelectedItemIds.splice(updatedSelectedItemIds.indexOf(itemId), 1);
    // } else {
    //     updatedSelectedItems.push(itemName);
    //     updatedSelectedItemIds.push(itemId);
    // }

    setSelectedItemsfreeItem(updatedSelectedItems);
    // setSelectedItemIdsfreeItem(updatedSelectedItemIds);

    if (selectedItemIdsfreeItem?.includes(itemId)) {
      setSelectedItemIdsfreeItem(
        selectedItemIdsfreeItem?.filter((elem) => elem != itemId)
      );
    } else {
      console.log(selectedItemIdsfreeItem?.length);

      if (selectedItemIdsfreeItem?.length >= 2) {
        selectedItemIdsfreeItem.shift();
        setSelectedItemIdsfreeItem([...selectedItemIdsfreeItem, itemId]);
      } else {
        setSelectedItemIdsfreeItem([...selectedItemIdsfreeItem, itemId]);
      }
    }
  };

  const Promo = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${AuthTokenId}`,
      };
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
          ApiServices.promoCode_index +
          "/" +
          location.state.id,
        headers
      );
      if (response.status === true) {
        console.log(response.data, "Data");

        dispatch(setPromoIndex(response.data));
        setPromoCode(response.data?.promo_code);
        setDiscountValue(response.data?.discount_amount);
        setMinAmount(response.data?.advanced_settings?.cart_min_amount);
        setMaxAmount(response.data?.advanced_settings?.cart_max_amount);
        setCustomer(response.data?.advanced_settings?.eligibility_type);
        setStartDate(dayjs(response.data?.advanced_settings?.start_date));
        // let start = response.data?.advanced_settings?.start_time
        // start = start.split(":")
        // start = start[0] + ":" + start[1]
        // setStartTime(start)
        // let end = response.data?.advanced_settings?.end_time
        // end = end.split(":")
        // end = end[0] + ":" + end[1]
        console.log(
          response.data?.advanced_settings?.end_date &&
            response.data?.advanced_settings?.start_date,
          "END"
        );
        if (
          response.data?.advanced_settings?.end_date &&
          response.data?.advanced_settings?.start_date
        ) {
          setDurationSwitch(true)
        }
        setEndDate(dayjs(response.data?.advanced_settings?.end_date));
        // setEndTime(end)
        setTotalUses(response.data?.advanced_settings?.type_of_uses);
        console.log(!_.isEmpty(response.data?.advanced_settings?.type_of_uses),"Limit")
        if (response.data?.advanced_settings?.type_of_uses > 0) {
            setLimitSwitch(true)
        }
        setSelect_all(response.data?.select_all)
        setSelectedLocationOption(response?.data?.location?.id);
        setLocationID(response.data?.location?.id);
        setSelectedMenuOption(response.data?.menu?.menu_code);
        setMenuID(response.data?.menu?.menu_code);
        setShowPromo(response.data?.show);
        console.log(response.data.promotionalcategories.category, "Promo ID");
      } else {
        console.log("Error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const Locations = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${tokenData}`,
      };
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
          ApiServices.vendor_locations +
          `?user_code=${UserCodeData}`,
        headers
      );
      if (response.status === true) {
        dispatch(setLocationsList(response.data));
      } else {
        console.log("Error");
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const MenusListData = async () => {
    try {
      const response = await HelperModule().getMethod(
        ApiServices.base_URL + ApiServices.menu_list + UserCodeData
      );
      if (response.status === true) {
        setMenuCard(response.menu);
      } else {
        console.log("Error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    Promo();
  }, []);

  useEffect(() => {
    if (UserCodeData) {
      MenusListData();
      Locations();
    }
  }, [UserCodeData]);

  useEffect(() => {}, [locationID]);

  useEffect(() => {
    setTimeout(() => {}, 1000);

    if (selectedMenuOption !== "Select Menu") {
      CategoryItemsList(selectedMenuOption);
    }
  }, [selectedMenuOption]);

  const toggleSwitchPromo = () => {
    setShowPromo(!showPromo);
  };

  function isCategorySelected(category: any, selectedCategoryIds: any[]) {
    return selectedCategoryIds.includes(category?.pos_id);
  }

  const UpdatePromoCode = async () => {
    const selectedItemsFromPromoIndex =
      PromoIndex?.categories?.flatMap((category: any) =>
        category.items_list.map((item: any) => item.name)
      ) || [];

    // console.log(startTime.split(":"),endTime);

    const Params: any = {
      user_code: UserCodeData,
      promo_code: promoCode,
      location_id: PromoIndex?.location.id || locationID,
      menu_id: MenuID,
      discount_type: selectedValue,
      discount_amount: discountValue,
      cart_min_amount: minAmount,
      cart_max_amount: maxAmount,
      type_of_uses: totalUses,
      eligibility_type: customer,
      start_date: paramStartDate,
      start_time: startTime,
      end_date: paramEndDate,
      end_time: endTime,
      category_ids: selectedCategoryIds, // Use an empty array if promotionalcategories or category is undefined
      item_ids: selectedItemIds,
      discounted_category_ids: selectedCategoryIdsfreeItem,
      discounted_item_ids: selectedItemIdsfreeItem,
      visible: showPromo,
    };
    console.log("Params Discount % Params", Params);
    if (promoCode === "") {
      enqueueSnackbar("Please enter promo code", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      return;
    } else if (!locationID) {
      enqueueSnackbar("Please select location", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      return;
    } else if (menuCard.length === 0) {
      enqueueSnackbar("Please add menu", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      return;
    } else if (MenuID === "") {
      enqueueSnackbar("Please select menu", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      return;
    } else if (discountValue === "") {
      enqueueSnackbar("Please enter discount amount", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      return;
    } else if (selectedCategoryIds.length === 0) {
      enqueueSnackbar("Please select category", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      return;
    } else if (selectedItemIds.length === 0) {
      enqueueSnackbar("Please select item", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      return;
    } else if (selectedValue === "") {
      enqueueSnackbar("Please select discount type", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      return;
    } else if (minAmount === "") {
      enqueueSnackbar("Please enter min amount", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      return;
    } else if (maxAmount === "") {
      enqueueSnackbar("Please enter max amount", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      return;
    } else if (customer === "Select Customer") {
      enqueueSnackbar("Please select customer", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      return;
    }
    // else if (totalUses === "") {
    //     enqueueSnackbar("Please enter total uses", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }, style: { fontFamily: 'Poppins', fontWeight: '500' }, });
    //     return
    // }
    // else if (startDate === "") {
    //     enqueueSnackbar("Please enter start date", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }, style: { fontFamily: 'Poppins', fontWeight: '500' }, });
    //     return
    // }
    // else if (startTime === "") {
    //     enqueueSnackbar("Please enter start time", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }, style: { fontFamily: 'Poppins', fontWeight: '500' }, });
    //     return
    // }
    // else if (endDate === "") {
    //     enqueueSnackbar("Please enter end date", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }, style: { fontFamily: 'Poppins', fontWeight: '500' }, });
    //     return
    // }
    // else if (endTime === "") {
    //     enqueueSnackbar("Please enter end time", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }, style: { fontFamily: 'Poppins', fontWeight: '500' }, });
    //     return
    // }
    else {
      try {
        const response = await HelperModule().postMethod(
          ApiServices.base_URL +
            ApiServices.update_promo_code +
            location.state.id,
          Params
        );
        console.log(response);
        if (response.status === true) {
          console.log("Successs response", response);
          enqueueSnackbar(response.message, {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            style: { fontFamily: "Poppins", fontWeight: "500" },
          });
          setSelectedCategoryIds([]);
          setSelectedItemIds([]);
          setSelectedCategoryIdsfreeItem([]);
          setSelectedItemIdsfreeItem([]);

          Navigate("/promos");
          window.location.reload();
        } else {
          console.log("failed response", response);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleDurationChange = () => {
    setIsSwitchOn({ ...isSwitchOn, promo: !isSwitchOn?.promo });
  };

  useEffect(() => {
    if (!durationSwitch) {
      setStartDate(null);
      setEndDate(null);
      setparamStartDate('')
      setparamEndDate('')
    }
  }, [durationSwitch]);

  useEffect(() => {
    if (!limitSwitch) {
        setTotalUses('')
    }
  }, [limitSwitch]);

  const handleLimitChange = () => {
    setIsSwitchOn({ ...isSwitchOn, limit: !isSwitchOn?.limit });
  };

  const handleMinAmountChange = (e: any) => {
    const inputValue = e.target.value;
    const regex = /^[0-9]*$/;
    if (regex.test(inputValue)) {
      setMinAmount(inputValue);
    }
  };

  const handleMaxAmountChange = (e: any) => {
    const inputValue = e.target.value;
    const regex = /^[0-9]*$/;
    if (regex.test(inputValue)) {
      setMaxAmount(inputValue);
    }
  };

  const handleSelectAllCategories = (isChecked: boolean) => {
    if (isChecked) {
      const allCategoryIds = PromoIndex?.categories?.map((category: any) => category.id) || [];
      const allItemIds = PromoIndex?.categories?.flatMap((category: any) => category.items_list.map((item: any) => item.id)) || [];
      setSelectedCategoryIds(allCategoryIds);
      setSelectedItemIds(allItemIds);
      setSelect_all(true)
    } else {
      setSelectedCategoryIds([]);
      setSelectedItemIds([]);
      setSelect_all(false)
    }
  };

  return (
    <div>
      <BackNavigation label="Back" />
      <h1 className="promo-creation-title">Update Promo Code</h1>
      <>
        <div className="promotion-creation-container">
          <h3 className="promotion-list-title">Name</h3>
          <p className="promotion-list-sub-title">
            This will appear on menu and customer receipts{" "}
          </p>
          <input
            type="text"
            placeholder="Promo Code"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
            style={{
              marginTop: "8px",
              width: "44.94vw",
              height: "7.20vh",
              borderRadius: "8px",
              borderWidth: "1px",
              borderColor: "#201C1C3D",
              padding: "10px 16px 10px 16px",
              outline: "none",
              fontWeight: "400",
              fontSize: "14px",
              fontFamily: "Poppins",
            }}
          />
          <div className="menu-details">
            <h3 className="promotion-list-title">Select a location</h3>
            <FormControl sx={{ mt: 1, width: "44.94vw" }}>
              <Select
                value={selectedLocationOption}
                onChange={handleChangeLocation}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="Select a location">
                  {PromoIndex?.location?.name}
                </MenuItem>
                {LocationsList?.map((item: any) => (
                  <MenuItem key={item?.id} value={item?.id}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="menu-details">
            <h3 className="promotion-list-title">Select Menu</h3>
            <FormControl sx={{ mt: 1, width: "44.94vw" }}>
              <Select
                className="input-container-promo-creation"
                value={selectedMenuOption}
                onChange={handleChangeMenu}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="Select Menu">
                  {PromoIndex?.menu?.menu_name}
                </MenuItem>
                {menuCard?.map((item: any) => (
                  <MenuItem key={item?.id} value={item?.menu_code}>
                    {item?.menu_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <h3 className="promotion-list-title" style={{ paddingTop: "20px" }}>
            Discount Type
          </h3>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={selectedValue}
              onChange={handleRadioChange}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="percentage_off"
                control={<Radio size="small" />}
                label="Percentage off %"
                sx={{
                  fontWeight: "400",
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  color:
                    selectedValue === "percentage_off"
                      ? "#201C1C"
                      : "#201C1CB8",
                }}
              />
              <FormControlLabel
                value="Amount_off"
                control={<Radio size="small" />}
                label="Amount off"
                sx={{
                  fontWeight: "400",
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  color:
                    selectedValue === "Amount_off" ? "#201C1C" : "#201C1CB8",
                }}
              />
              <FormControlLabel
                value="free_item"
                control={<Radio size="small" />}
                label="Free item"
                sx={{
                  fontWeight: "400",
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  color:
                    selectedValue === "free_item" ? "#201C1C" : "#201C1CB8",
                }}
              />
            </RadioGroup>
          </FormControl>
          {selectedValue === "percentage_off" ||
          selectedValue === "Amount_off" ? (
            <div>
              <p>
                {selectedValue === "percentage_off"
                  ? "Enter Percentage"
                  : "Enter Amount"}
              </p>
              <div style={{ position: "relative", width: "fit-content" }}>
                <input
                  type="text"
                  placeholder={
                    selectedValue === "percentage_off"
                      ? "Enter Percentage"
                      : "Enter Amount"
                  }
                  value={discountValue}
                  onChange={handlediscountValueChange}
                  style={{
                    marginTop: "8px",
                    width: "44.94vw",
                    height: "7.20vh",
                    borderRadius: "8px",
                    borderWidth: "1px",
                    borderColor: "#201C1C3D",
                    padding: "10px 36px 10px 16px",
                    outline: "none",
                    fontWeight: "400",
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    boxSizing: "border-box",
                  }}
                />
                <span
                  style={{
                    position: "absolute",
                    right: "8px",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                >
                  {selectedValue === "percentage_off" ? "%" : "$"}
                </span>
              </div>
            </div>
          ) : null}
          {selectedValue === "free_item" ? (
            <div className="switch-container">
              <div className="switch-position-container">
                <h3
                  className="promotion-list-title"
                  style={{ paddingTop: "20px" }}
                >
                  Required Purchase ( Buy one, Get one, or Combo Deals)
                </h3>
                <p className="promotion-list-sub-title">
                  Customers must buy at least one full price item to use this
                  promo
                </p>
              </div>

              <Switch
                onChange={() =>
                  setIsSwitchOn({ ...isSwitchOn, free: !isSwitchOn?.free })
                }
                checked={isSwitchOn?.free}
              />
            </div>
          ) : null}
          {/*  */}
          <h3 className="promo-item-list" style={{ marginTop: "20px" }}>
            Items List
          </h3>
          <p className="promotion-list-sub-title">
            Customers must buy at least one full price item to use this promo
          </p>
          <div className="promo-list-category">
            <FormControlLabel
              control={
                <Checkbox
                  checked={select_all}
                  onChange={(e) => handleSelectAllCategories(e.target.checked)}
                />
              }
              label="Select All"
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                fontFamily: "Poppins",
                color: "#201C1C",
                margin: 0,
              }}
            />
          </div>
          {PromoIndex?.categories?.map((category: any, index: any) => (
            <div key={category?.name}>
              <div className="promo-list-category-container">
                <div className="promo-list-category">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedCategoryIds?.includes(category?.id)}
                      />
                    }
                    label={
                      <>
                        {category?.name}
                        <p
                          style={{
                            fontSize: "12px",
                            fontWeight: "400",
                            fontFamily: "Poppins",
                            color: "#201C1CB8",
                            margin: 0,
                          }}
                        >
                          {category?.qty}
                        </p>
                      </>
                    }
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      fontFamily: "Poppins",
                      color: "#201C1C",
                      margin: 0,
                    }}
                    onClick={() =>
                      handleCategorySelect(category?.pos_id, category?.id)
                    }
                  />
                </div>
                {itemsListopen == category?.id ? (
                  <img
                    onClick={() => handleItemSelectClose()}
                    src={DownArrow}
                    alt="arrow"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <img
                    onClick={() =>
                      handleItemSelect(category?.pos_id, category?.id)
                    }
                    src={Rightarrow}
                    alt="arrow"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>
              {categoryDropdownState[category?.pos_id] && (
                <div className="multi-select-dropdown">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="multi-select-dropdown">
                      {category?.items_list?.map(
                        (item: any, itemIndex: any) => (
                          <div key={item?.name} className="checkbox-item">
                            <FormControlLabel
                              control={
                                <div
                                  style={{
                                    flexDirection: "row",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Checkbox
                                    checked={selectedItemIds?.includes(
                                      item?.id
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(item?.name, item?.id)
                                    }
                                    sx={{ marginLeft: "32.5px" }}
                                  />
                                  <div
                                    className="category-item-qty"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "500",
                                        fontFamily: "Poppins",
                                        color: "#201C1CB8",
                                        margin: 0,
                                      }}
                                    >
                                      {item?.name}
                                    </p>
                                    <p
                                      style={{
                                        fontSize: "10px",
                                        fontWeight: "500",
                                        fontFamily: "Poppins",
                                        color: "#8B5CFF",
                                        margin: 0,
                                      }}
                                    >
                                      {item?.item_variation &&
                                      item?.item_variation?.price_money
                                        ?.amount !== undefined
                                        ? `$${
                                            (item?.item_variation?.price_money
                                              ?.amount || "N/A") / 100
                                          }`
                                        : "N/A"}
                                    </p>
                                  </div>
                                </div>
                              }
                              label={""}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
          {(isSwitchOn?.free && selectedValue == "free_item") && <h3 className="promotion-list-title" style={{ paddingTop: "20px" }}>
            Discounted Items
          </h3>
          }

          {isSwitchOn?.free &&
            selectedValue == "free_item" &&
            PromoIndex?.categories?.map((category: any) => (
              <div key={category?.name}>
                <div className="promo-list-category-container">
                  <div className="promo-list-category">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedCategoryIdsfreeItem?.includes(
                            category?.id
                          )}
                        />
                      }
                      label={
                        <>
                          {category?.name}
                          <p
                            style={{
                              fontSize: "12px",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                              color: "#201C1CB8",
                              margin: 0,
                            }}
                          >
                            {category?.qty}
                          </p>
                        </>
                      }
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontFamily: "Poppins",
                        color: "#201C1C",
                        margin: 0,
                      }}
                      onClick={() =>
                        handleCategorySelectWithSwitch(
                          category?.pos_id,
                          category?.id
                        )
                      }
                      disabled={
                        selectedCategorySwitch !== null &&
                        selectedCategorySwitch !== category?.pos_id
                      }
                    />
                  </div>
                  {itemsListopenfreeItem == category?.id ? (
                    <img
                      onClick={() => handleItemSelectCloseWithSwitch()}
                      src={DownArrow}
                      alt="arrow"
                      style={{
                        height: "20px",
                        width: "20px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <img
                      onClick={() =>
                        handleItemSelectWithSwitch(
                          category?.pos_id,
                          category?.id
                        )
                      }
                      src={Rightarrow}
                      alt="arrow"
                      style={{
                        height: "20px",
                        width: "20px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </div>

                {itemsListopenfreeItem &&
                  categoryDropdownStatefreeItem[category?.pos_id] && (
                    <div className="multi-select-dropdown">
                      <div className="multi-select-dropdown">
                        {ItemsList?.map((item: any, index: any) => (
                          <div key={item?.name} className="checkbox-item">
                            <FormControlLabel
                              control={
                                <div
                                  style={{
                                    flexDirection: "row",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Checkbox
                                    checked={selectedItemIdsfreeItem?.includes(
                                      item.id
                                    )}
                                    onChange={() =>
                                      handleCheckboxChangeWithSwitch(
                                        item?.name,
                                        item?.id
                                      )
                                    }
                                    sx={{ marginLeft: "32.5px" }}
                                    disabled={
                                      selectedCategorySwitch !== null &&
                                      selectedCategorySwitch !==
                                        category?.pos_id
                                    }
                                  />
                                  <div
                                    className="category-item-qty"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "500",
                                        fontFamily: "Poppins",
                                        color: "#201C1CB8",
                                        margin: 0,
                                      }}
                                    >
                                      {item?.name}
                                    </p>
                                    <p
                                      style={{
                                        fontSize: "10px",
                                        fontWeight: "500",
                                        fontFamily: "Poppins",
                                        color: "#8B5CFF",
                                        margin: 0,
                                      }}
                                    >
                                      ${" "}
                                      {(item?.item_variations[0]?.price_money
                                        ?.amount || "N/A") / 100}
                                    </p>
                                  </div>
                                </div>
                              }
                              label={""}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                {/* {categoryDropdownStatefreeItem[category?.pos_id] && (
                                <div className="multi-select-dropdown">
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className="multi-select-dropdown">
                                            {category?.items_list?.map((item: any, itemIndex: any) => (
                                                <div key={item?.name} className="checkbox-item">
                                                    <FormControlLabel
                                                        control={
                                                            <div style={{ flexDirection: "row", display: 'flex', alignItems: "center" }}>
                                                                <Checkbox
                                                                    checked={selectedItemIds?.includes(item?.id)}
                                                                    onChange={() => handleCheckboxChange(item?.name, item?.id)}
                                                                    sx={{ marginLeft: "32.5px" }}
                                                                />
                                                                <div className="category-item-qty" style={{ display: 'flex', flexDirection: "column" }}>
                                                                    <p style={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Poppins', color: '#201C1CB8', margin: 0 }}>{item?.name}</p>
                                                                    <p style={{ fontSize: '10px', fontWeight: '500', fontFamily: 'Poppins', color: '#8B5CFF', margin: 0 }}>
                                                                        {item?.item_variation && item?.item_variation?.price_money?.amount !== undefined
                                                                            ? `$${(item?.item_variation?.price_money?.amount || "N/A") / 100}`
                                                                            : "N/A"
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        }
                                                        label={""}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )} */}
              </div>
            ))}

          <h3 className="promotion-list-title" style={{ paddingTop: "20px" }}>
            Advanced Settings
          </h3>
          <h3
            className="promotion-list-container-sub-title"
            style={{ paddingTop: "10px" }}
          >
            Cart Rules
          </h3>
          <div
            className="amount-promo-container"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor="outlined-adornment-amount">
              Min Amount
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              endAdornment={<InputAdornment position="end">$</InputAdornment>}
              value={minAmount}
              onChange={handleMinAmountChange}
              label="Min Amount"
            />
          </FormControl>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor="outlined-adornment-amount">
              Max Amount
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              endAdornment={<InputAdornment position="end">$</InputAdornment>}
              value={maxAmount}
              onChange={handleMaxAmountChange}
              label="Max Amount"
            />
          </FormControl>
            {/* <input
              placeholder="Min Quantity"
              value={minAmount}
              onChange={(e) => setMinAmount(e.target.value)}
              style={{
                marginTop: "8px",
                width: "21.88vw",
                height: "7.20vh",
                borderRadius: "16px",
                borderWidth: "1px",
                borderColor: "#201C1C3D",
                padding: "10px 16px 10px 16px",
                outline: "none",
                fontWeight: "400",
                fontSize: "12px",
                fontFamily: "Poppins",
              }}
            />
            <input
              placeholder="Max Quantity"
              value={maxAmount}
              onChange={(e) => setMaxAmount(e.target.value)}
              style={{
                marginTop: "8px",
                width: "21.88vw",
                height: "7.20vh",
                borderRadius: "16px",
                borderWidth: "1px",
                borderColor: "#201C1C3D",
                padding: "10px 16px 10px 16px",
                outline: "none",
                fontWeight: "400",
                fontSize: "12px",
                fontFamily: "Poppins",
                marginLeft: "16px",
                paddingLeft: "16px",
              }}
            /> */}
          </div>
          <h3 className="promotion-list-title" style={{ paddingTop: "20px" }}>
            Customers Rules
          </h3>
          <h3
            className="promotion-list-container-sub-title"
            style={{ paddingTop: "10px" }}
          >
            Audience ( App Only )
          </h3>
          <p className="promotion-list-sub-title">
            Choose who can use this promo
          </p>
          <div className="menu-details">
            <FormControl sx={{ width: "44.94vw" }}>
              <Select
                className="input-container-promo-creation"
                value={customer}
                onChange={handleCustomerChange}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="Select Customer">Select Customer</MenuItem>
                {CustomersData?.map((item: any) => (
                  <MenuItem key={item?.name} value={item?.name}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="switch-container">
            <div className="switch-position-container">
              <h3
                className="promotion-list-title"
                style={{ paddingTop: "10px" }}
              >
                Limit total uses
              </h3>
              <p className="promo-item-switch-btn-sub">
                Limit how many times this promo code can be used
              </p>
            </div>
            <Switch
              checked={limitSwitch}
            //   value={isSwitchOn?.limit}
              onChange={()=>setLimitSwitch(!limitSwitch)}
            />
          </div>
          {limitSwitch ? (
            <>
              <p
                className="promo-item-switch-btn-sub"
                style={{ paddingTop: "8px" }}
              >
                Total Uses
              </p>
              <input
                placeholder="Total Uses"
                value={totalUses}
                onChange={(e) => setTotalUses(e.target.value)}
                style={{
                  marginTop: "8px",
                  width: "44.94vw",
                  height: "7.20vh",
                  borderRadius: "8px",
                  borderWidth: "1px",
                  borderColor: "#201C1C3D",
                  padding: "10px 16px 10px 16px",
                  outline: "none",
                  fontWeight: "400",
                  fontSize: "14px",
                  fontFamily: "Poppins",
                }}
              />
            </>
          ) : (
            ""
          )}
          <div className="switch-container">
            <div className="switch-position-container">
              <h3
                className="promotion-list-title"
                style={{ paddingTop: "20px" }}
              >
                Duration
              </h3>
              <p className="promotion-list-sub-title">
                Run this promo for a limited time between specific dates(i.e
                Happy Sale)
              </p>
            </div>
            <Switch
              checked={durationSwitch}
              onChange={()=>setDurationSwitch(!durationSwitch)}
            />
          </div>
          {durationSwitch ? (
            <>
              <p
                className="promotion-list-container-sub-title"
                style={{ paddingTop: "10px" }}
              >
                Start
              </p>
              <div
                className="amount-promo-container"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Start Date"
                    value={startDate}
                    closeOnSelect={false}
                    onAccept={(newValue) => handleStartDateChange(newValue)}
                  />
                </LocalizationProvider>
                {/* <input type="text" placeholder='YYYY-MM-DD' value={startDate} onChange={handleStartDate} style={{ marginTop: "8px", width: "21.88vw", height: "7.20vh", borderRadius: "16px", borderWidth: "1px", borderColor: "#201C1C3D", padding: "10px 16px 10px 16px", outline: "none", fontWeight: "400", fontSize: "12px", fontFamily: "Poppins" }} />
                            <input type="text" placeholder='HH:MM' value={startTime} onChange={handleStartTime} style={{ marginTop: "8px", width: "21.88vw", height: "7.20vh", borderRadius: "16px", borderWidth: "1px", borderColor: "#201C1C3D", padding: "10px 16px 10px 16px", outline: "none", fontWeight: "400", fontSize: "12px", fontFamily: "Poppins", marginLeft: "16px", paddingLeft: '16px' }} /> */}
              </div>
              <p
                className="promotion-list-container-sub-title"
                style={{ paddingTop: "10px" }}
              >
                End
              </p>
              <div
                className="amount-promo-container"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="End Date"
                    minDateTime={dayjs(startDate)}
                    closeOnSelect={false}
                    value={endDate}
                    onAccept={(newValue) => handleEndDateChange(newValue)}
                  />
                </LocalizationProvider>
                {/* <input type="text" placeholder='YYYY-MM-DD' value={endDate} onChange={handleEndDate} style={{ marginTop: "8px", width: "21.88vw", height: "7.20vh", borderRadius: "16px", borderWidth: "1px", borderColor: "#201C1C3D", padding: "10px 16px 10px 16px", outline: "none", fontWeight: "400", fontSize: "12px", fontFamily: "Poppins" }} />
                            <input type="text" placeholder='HH:MM' value={endTime} onChange={handleEndTime} style={{ marginTop: "8px", width: "21.88vw", height: "7.20vh", borderRadius: "16px", borderWidth: "1px", borderColor: "#201C1C3D", padding: "10px 16px 10px 16px", outline: "none", fontWeight: "400", fontSize: "12px", fontFamily: "Poppins", marginLeft: "16px", paddingLeft: '16px' }} /> */}
              </div>
            </>
          ) : (
            ""
          )}
          <div className="switch-container">
            <div className="switch-position-container">
              <h3
                className="promotion-list-title"
                style={{ paddingTop: "20px" }}
              >
                {" "}
                Show{" "}
              </h3>
              <p className="promotion-list-sub-title">
                Display this promo code
              </p>
            </div>
            <Switch
              onChange={toggleSwitchPromo}
              checked={showPromo}
            //   value={showPromo}
            />
          </div>
          {/* <button onClick={() => UpdatePromoCode()} className="common-button-blue">Update</button>
                    </> */}
          <button
            onClick={() => UpdatePromoCode()}
            className="common-button-blue"
            style={{ transition: "background-color 0.3s" }}
            onMouseEnter={(e) => {
              (e.target as HTMLButtonElement).style.backgroundColor =
                "#6d43e57D";
            }}
            onMouseLeave={(e) => {
              (e.target as HTMLButtonElement).style.backgroundColor = "#8B5CFF";
            }}
          >
            Update
          </button>
          <button onClick={() => Navigate(-1)} className="common-button-cancel">
            Cancel
          </button>
        </div>
      </>
    </div>
  );
}

export default PromotionUpdate;

const CustomersData = [
  { name: "All customers" },
  { name: "First time customers" },
];
