import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Card } from '@mui/material';
import ApiServices from '../../helpers/ApiServices';
import HelperModule from '../../helpers/HelperModule';
import { useNavigate } from 'react-router-dom';

function CheckoutForm({ name, amount, subscriptionId }) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return; // Stripe.js has not yet loaded.
    }

    setIsLoading(true);

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setIsLoading(false);
      console.error('[createPaymentMethod error]', error);
      setMessage(error?.message);
    } else {
      console.log('[PaymentMethod]', paymentMethod);
      // You can send paymentMethod.id to your server to complete the payment process.
      subscribe(paymentMethod.id)
    }
  };

  const subscribe = async (payment_method_id) => {

    let params = {
      payment_method_id,
      vendor_id: localStorage.getItem('UserID'),
      subscription_id: subscriptionId,
    }

    const payment = await HelperModule().postMethod(ApiServices.base_URL + ApiServices.stripe_subscribe, params);
    if (payment.status) {
      confirm_payment(payment?.payment_intent_client_secret)
    }
  };

  const confirm_payment = async (payment_intent_client_secret) => {
    stripe.confirmCardPayment(payment_intent_client_secret).then(function (result) {
      if (result.error) {
        // Show error to your customer (e.g., insufficient funds, 3D Secure authentication failed)
        console.error(result.error.message);
      } else {
        if (result.paymentIntent.status === 'succeeded') {
          // Payment succeeded, handle the subscription activation
          console.log('Payment succeeded');
          navigate("/success");
        }
      }
    });
  }

  return (
    <Card sx={{ width: 1000, height: 600, display: "flex", marginTop: 15 }}>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: 400, height: 600, fontFamily: "poppins", padding: "60px 20px" }}>
        <span style={{ fontWeight: 700, fontSize: 30, textAlign: "start" }}>Banthia Events NY LLC</span>
        <span style={{ fontWeight: 500, fontSize: 20, textAlign: "start" }}>Product name: {name}</span>
        <span style={{ fontWeight: 500, fontSize: 20, textAlign: "start" }}>Amount: US${amount}</span>
        <img src={require("../../assets/images/Graphic-Illustration.png")} height={300} width={400} />
      </div>
      <form id="payment-form" onSubmit={handleSubmit} style={{ width: 600, height: 600, display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
        <div style={{ width: 500, height: 600 }}>
          <CardElement />
          <button disabled={isLoading || !stripe || !elements} id="submit">
            <span id="button-text">
              {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
            </span>
          </button>
          {message && <div id="payment-message">{message}</div>}
        </div>
      </form>
    </Card>

  );
}

export default CheckoutForm;
