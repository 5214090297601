import React from "react";
import LOGO from "../assets/images/logo_main_img.png";
import { Typography } from "@mui/material";

export default function DashMobile() {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "2rem",
        }}
      >
        <div style={{ margin: 5 }}>
          <img
            src={LOGO}
            alt="logo"
            style={{ width: "150px", display: "block", margin: "auto" }}
          />
        </div>
        <Typography
          variant="h4"
          sx={{
            typography: {
              xs: "h5",
              sm: "h4",
              md: "h4",
              lg: "h4",
              xl: "h4",
            },
            // margin: "auto",
            color: "#201C1C",
            textAlign: "center",
            fontFamily: "Poppins",
          }}
        >
          Please log in from a desktop to access the dashboard.
        </Typography>
      </div>
    </>
  );
}
