import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import Cross from "../../../assets/images/Cross.png";
import Delete from "../../../assets/images/delete.png";
import EditMenu from "../../../assets/images/editmenu.png";
import ConfirmationModal from "../../../components/ConfirmationModal";
import SuccessModal from "../../../components/SuccessModal";
import ApiServices from "../../../helpers/ApiServices";
import HelperModule from "../../../helpers/HelperModule";

let AuthTokenId: any;
let UserCodeData: any;
function Allergen() {
  const [controller, setController] = useState({
    isLoading: false
  })

  const [allergenData, setAllergenData]: any = useState([]);
  const [allergenIdData, setAllergenIdData]: any = useState("");
  const [successModalBodyText, setSuccessModalBodyText]: any = useState("");
  const [successModal, setSuccessModal] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [allergenObject, setAllergenObject]: any = useState({
    image_path: "",
    menu_code: "",
    name: "",
    user_code: "",
  });
  const [createAllergen, setCreateAllergen] = React.useState(false);
  const [deleteAllergenConfirmModal, setDeleteAllergenConfirmModal]: any =
    React.useState(false);

  const { menu_code }: any = useParams();

  useEffect(() => {
    const TokenData: any = localStorage.getItem("Token");
    const UserCode = localStorage.getItem("UserCode");
    UserCodeData = UserCode;
    AuthTokenId = TokenData;
    GetAllergenData();
  }, []);

  const GetAllergenData = async () => {
    const headers = {
      Authorization: `Bearer ${AuthTokenId}`,
    };
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL + ApiServices.get_allergen_data + UserCodeData + `?menu_code=${menu_code}`, headers);
      if (response.status === true) {
        setAllergenData(response.data);
      }

    } catch (error) {
      console.log(error);
    }
  };

  const EditAllergen = (value: any) => {
    setAllergenObject(value);
    setCreateAllergen(true);
  };

  const handleDeleteAllergen = (id: any) => {
    setAllergenIdData(id);
    setDeleteAllergenConfirmModal(true);
  };

  const createNewAllergen = async () => {
    const formData = new FormData();
    formData.append("user_code", UserCodeData);
    formData.append("name", allergenObject?.name);
    formData.append("menu_code", menu_code);
    if (allergenObject?.image_path) {
      formData.append("image_path", allergenObject?.image_path);
    }
    try {
      const response = await HelperModule().postFormDataMethod(
        ApiServices.base_URL + ApiServices.create_allergen,
        formData
      );
      if (response.status === true) {
        // setCreateAllergenConfirmModal(false)
        setCreateAllergen(false);
        setSuccessModal(true);
        setSuccessModalBodyText("Created Allergen successfully.");
        setAllergenObject({
          image_path: "",
          menu_code: "",
          name: "",
          user_code: "",
        });
      }
      else if (response.status === false) {
        enqueueSnackbar(response.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, style: { fontFamily: "Poppins", fontWeight: "500" }, });
      }
      else if (response.response.status === 400) {
        enqueueSnackbar(response.response.data.message, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
        setCreateAllergen(false);
        setAllergenObject({
          image_path: "",
          menu_code: "",
          name: "",
          user_code: "",
        });
      }
    } catch (error) {
      console.log("error catch", error);
    }
  };

  const handleEditAllergen = async () => {
    setController({
      ...controller,
      isLoading: true
    })
    const formData = new FormData();
    formData.append("user_code", UserCodeData);
    formData.append("name", allergenObject?.name);
    formData.append("menu_code", menu_code);
    formData.append("id", allergenObject?.id);
    if (allergenObject?.image_path as String) {
      formData.append("image_path", allergenObject.image_path);
      // null
      // console.log("first")
    } else {
      formData.append("image_path", allergenObject.image_path);
    }
    try {
      const response = await HelperModule().postFormDataMethod(
        ApiServices.base_URL + ApiServices.create_allergen,
        formData
      );
      setController({
        ...controller,
        isLoading: false
      })
      if (response.status === true) {
        // setEditAllergenConfirmModal(false)
        setCreateAllergen(false);
        setSuccessModalBodyText("Created Allergen successfully.");
        setSuccessModal(true);
        setAllergenObject({
          image_path: "",
          menu_code: "",
          name: "",
          user_code: "",
        });
      }
    } catch (error) {
      setController({
        ...controller,
        isLoading: false
      })
      console.log("error catch", error);
    }
  };

  const DeleteAllergen = async () => {
    try {
      const response = await HelperModule().deleteMethod(
        ApiServices.base_URL + ApiServices.delete_allergen + allergenIdData
      );
      if (response.status === true) {
        setDeleteAllergenConfirmModal(false);
        setSuccessModalBodyText("Deleted Allergen successfully.");
        setSuccessModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setAllergenObject({
      image_path: "",
      menu_code: "",
      name: "",
      user_code: "",
    });
    setCreateAllergen(false);
  };

  const handleSaveButton = () => {
    if (allergenObject?.id) {
      handleEditAllergen();
    } else {
      createNewAllergen();
    }
  };

  return (
    <>
      <div className="allergen">
        <h1 className="menu-id" style={{ marginTop: "20px" }}>
          Allergen
        </h1>
        <p className="desc" style={{ width: "20.2vw" }}>
          You can create update to five Allergens
        </p>
        <div className="allergen-card-container">
          <div className="allergen-card-sub-container">
            {allergenData?.length > 0 &&
              allergenData?.map((item: any) => (
                <div key={item.id} className="card-details">
                  <div className="allergen-row">
                    <div className="img-container-allergen">
                      <img
                        src={item?.image_path}
                        alt={item.name}
                        style={{ width: "24px", height: "24px" }}
                      />
                    </div>
                    <div
                      onClick={() => EditAllergen(item)}
                      className="edit-menu-container"
                    >
                      <img
                        src={EditMenu}
                        alt="edit"
                        style={{
                          width: "15.74px",
                          height: "15.74px",
                          backgroundColor: "#8B5CFF0A",
                        }}
                      />
                    </div>
                  </div>
                  <div className="allergen-details">
                    <div className="allergen-details-sub">
                      <h3 className="allregen-header">{item.name}</h3>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <p className="allergen-sub-header">Created At</p>
                        <p className="allergen-sub-header">
                          {item.created_at.split("T")[0]}
                        </p>
                      </div>
                    </div>
                    <div
                      onClick={() => handleDeleteAllergen(item.id)}
                      className="edit-menu-container"
                      style={{ backgroundColor: "#F34A4A21" }}
                    >
                      <img
                        src={Delete}
                        alt="delete"
                        style={{
                          width: "15.74px",
                          height: "15.74px",
                          backgroundColor: "#8B5CFF0A",
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <button
            onClick={() => setCreateAllergen(true)}
            className="create-new-btn"
            style={{ transition: "background-color 0.3s" }}
            onMouseEnter={(e) => {
              (e.target as HTMLButtonElement).style.backgroundColor =
                "#6d43e57D";
            }}
            onMouseLeave={(e) => {
              (e.target as HTMLButtonElement).style.backgroundColor = "#8B5CFF";
            }}
          >
            Create New
          </button>
        </div>
      </div>
      <Modal
        open={createAllergen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            width: "35.28vw",
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
          }}
        >
          <div className="import-title-container">
            <h3 className="import-title">
              {allergenObject.id ? "Edit Allergen" : "Add Allergen"}
            </h3>
            <img
              onClick={handleClose}
              src={Cross}
              alt="Cross"
              style={{ width: "24px", height: "24px" }}
            />
          </div>
          <div className="menu-details">
            <h3 className="menu-heading">Photo</h3>
            <p className="input-sub-text">
              This uploaded image will show up in the minioskfor this category,
              depending on any Styling options you select. For best performance,
              we recommend a maximum resolution of 1000 x 1000 pixels.
            </p>
          </div>
          <div
            className="photo-container"
            style={{ marginTop: "14px", width: "152.44px", height: "152.44px" }}
          >
            <div>
              {allergenObject?.image_path &&
                (typeof allergenObject?.image_path === "string" ? (
                  <img
                    src={allergenObject.image_path}
                    alt="Uploaded"
                    style={{ height: "150px", width: "152.44px" }}
                  />
                ) : (
                  <img
                    src={URL.createObjectURL(allergenObject.image_path)}
                    alt="Uploaded"
                    style={{ height: "150px", width: "152.44px" }}
                  />
                ))}
            </div>
          </div>
          <div style={{ marginTop: "32px", marginBottom: "14PX" }}>
            <label
              htmlFor="imageInput"
              className="common-button-blue"
              style={{
                paddingLeft: "80px",
                paddingRight: "80px",
                paddingTop: "16px",
                paddingBottom: "16px",
                transition: "background-color 0.3s",
                cursor: "pointer",
              }}
              onMouseEnter={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor =
                  "#6d43e57D";
              }}
              onMouseLeave={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor =
                  "#8B5CFF";
              }}
            >
              Upload Photo
            </label>
            <input
              type="file"
              id="imageInput"
              style={{ display: "none" }}
              onChange={(e) =>
                setAllergenObject({
                  ...allergenObject,
                  image_path: e.target.files?.[0],
                })
              }
            />
          </div>
          <div className="menu-details">
            <h3 className="menu-heading">Name</h3>
            <input
              placeholder="Allergen Name"
              style={{
                marginTop: "8px",
                width: "31.7vw",
                height: "7.20vh",
                borderRadius: "16px",
                borderWidth: "1px",
                borderColor: "#201C1C3D",
                padding: "10px 16px 10px 16px",
                outline: "none",
                fontWeight: "400",
                fontSize: "14px",
                fontFamily: "Poppins",
              }}
              value={allergenObject?.name}
              onChange={(e) =>
                setAllergenObject({ ...allergenObject, name: e.target.value })
              }
            />
            <div style={{ display: "flex", flexDirection: "row" }}>
              <button
                className="common-button-blue"
                onClick={handleSaveButton}
                style={{ transition: "background-color 0.3s", backgroundColor: controller.isLoading ? '#6d43e57D' : "#8B5CFF" }}
                // onMouseEnter={(e) => {
                //   (e.target as HTMLButtonElement).style.backgroundColor =
                //     "#6d43e57D";
                // }}
                // onMouseLeave={(e) => {
                //   (e.target as HTMLButtonElement).style.backgroundColor =
                //     "#8B5CFF";
                // }}
                disabled={controller.isLoading}
              >
                Save
              </button>
              <button className="common-button-cancel" onClick={handleClose}>
                Cancel
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      <SuccessModal
        open={successModal}
        onClose={() => {
          setSuccessModal(false);
          GetAllergenData();
        }}
        headerText="Successful!"
        bodyText={successModalBodyText}
      />
      <ConfirmationModal
        open={deleteAllergenConfirmModal}
        onConfirm={DeleteAllergen}
        onCancel={() => setDeleteAllergenConfirmModal(false)}
        headerText="Are you sure?"
        bodyText="You want to Delete Allergen"
      />
    </>
  );
}

export default Allergen;
