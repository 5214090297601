import React, { useEffect, useState } from 'react'
import INFO from '../../assets/images/info.png'
import TRASH from '../../assets/images/trash.png'
import Modal from '../../components/Modal';
import './Behaviour.css'
import HelperModule from '../../helpers/HelperModule';
import ApiServices from '../../helpers/ApiServices';
import { enqueueSnackbar } from 'notistack';
import { CircularProgress } from '@mui/material';

let UserCodeData: any;
function Behaviour() {
	const [showModal1, setShowModal1] = useState(false);
	const [listData, setListData]: any = useState([])
	const [instructions, setinstructions] = useState('')
	const [special_request_instructions, setspecial_request_instructions] = useState('')
	const [item_quantities_instructions, setitem_quantities_instructions] = useState('')
	const [max_quantity_per_cart, setmax_quantity_per_cart] = useState('')
	const [minimum_transaction_amount, setminimum_transaction_amount] = useState('')
	const [idle_screen_timeout, setidle_screen_timeout] = useState('')
	const [idle_screen_title, setidle_screen_title] = useState('')
	const [idle_screen_description, setidle_screen_description] = useState('')
	const [stock_threshold, setstock_threshold] = useState('')
	const [manually_sync_square_inventory, setmanually_sync_square_inventory] = useState('')
	const [order_number_threshold, setorder_number_threshold] = useState('')
	const [checkout_step_title_in_dining_option_prompt, setcheckout_step_title_in_dining_option_prompt] = useState('')
	const [checkout_step_description_in_dining_option_prompt, setcheckout_step_description_in_dining_option_prompt] = useState('')
	const [checkout_step_title_in_text_field, setcheckout_step_title_in_text_field] = useState('')
	const [checkout_step_description_in_text_field, setcheckout_step_description_in_text_field] = useState('')
	const [background_image, setbackground_image] = useState<File | null>(null);
	const [loading, setloading] = useState(false)
	useEffect(() => {
		const UserCode = localStorage.getItem("UserCode")
		UserCodeData = UserCode
	}, [])

	const BehaviourInitial = async () => {
		setloading(true)
		try {
			const response = await HelperModule().getMethod(ApiServices.base_URL + ApiServices.styling_behaviour_list + `?user_code=${UserCodeData}`);
			if (response.status === true) {
				setListData(response.stylingBehaviorList[0])
				setinstructions(response.stylingBehaviorList[0].instructions)
				setspecial_request_instructions(response.stylingBehaviorList[0].special_request_instructions)
				setitem_quantities_instructions(response.stylingBehaviorList[0].item_quantities_instructions)
				setmax_quantity_per_cart(response.stylingBehaviorList[0].max_quantity_per_cart)
				setminimum_transaction_amount(response.stylingBehaviorList[0].minimum_transaction_amount)
				setidle_screen_timeout(response.stylingBehaviorList[0].idle_screen_timeout)
				setidle_screen_title(response.stylingBehaviorList[0].idle_screen_title)
				setidle_screen_description(response.stylingBehaviorList[0].idle_screen_description)
				setstock_threshold(response.stylingBehaviorList[0].stock_threshold)
				setmanually_sync_square_inventory(response.stylingBehaviorList[0].manually_sync_square_inventory)
				setorder_number_threshold(response.stylingBehaviorList[0].order_number_threshold)
				setcheckout_step_title_in_dining_option_prompt(response.stylingBehaviorList[0].title_in_dining)
				setcheckout_step_description_in_dining_option_prompt(response.stylingBehaviorList[0].description_in_dining)
				setcheckout_step_title_in_text_field(response.stylingBehaviorList[0].title_in_text)
				setcheckout_step_description_in_text_field(response.stylingBehaviorList[0].description_in_text)
				setloading(false)
			} else {
				enqueueSnackbar('Data not found', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }, style: { fontFamily: 'Poppins', fontWeight: '500' }, });
				console.log(response)
			}
		}
		catch (error) {
			console.log(error)
		}
		finally {
			setloading(false)
		}
	};

	const handleCheckboxChange = (checkboxName: string) => {
		setListData((prevListData: { [key: string]: number }) => {
			const updatedListData = { ...prevListData };
			updatedListData[checkboxName] = updatedListData[checkboxName] === 1 ? 0 : 1;
			const checkboxGroups = {
				show_item_details: ['item_tapping_checkbox'],
				pay_in_cash: ['pay_in_cash_checkbox'],
				display_dining: ['side_bar_checkbox'],
				use_to_go: ['default_dining_checkbox'],
				ask_customer: ['special_requests_checkbox'],
				ask_customer_item: ['special_requests_checkbox'],
				checkOut_add: ['checkout_item_checkbox'],
				return_main: ['main_screen_checkbox'],
				show_idle: ['successful_transaction_checkbox'],
				show_idle_background: ['idle_screen_image_checkbox'],
				sticker: ['kiosk_sticker'],
				automatically_show_items: ['sold_out_checkbox'],
				automatically_orders: ['automatically_number_orders_checkbox'],
				customer_order: ['before_order_checkbox'],
				add_checkout: ['checkout_to_square'],
				required_input: ['required_input_checkbox'],
				before_customer: ['customer_order_checkbox'],
				use_customer: ['order_name_checkbox']
			};

			const currentGroup = Object.entries(checkboxGroups).find(group => group[1].includes(checkboxName));

			if (currentGroup) {
				const [, groupCheckboxes] = currentGroup;
				groupCheckboxes.forEach(groupCheckbox => {
					if (groupCheckbox !== checkboxName) {
						updatedListData[groupCheckbox] = 0;
					}
				});
			}

			return updatedListData;
		}, () => {
			BehaviourData();
		});
	};

	const handleMaxQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value;
		const regex = /^\d*\.?\d*$/;
		if (regex.test(newValue)) {
			setmax_quantity_per_cart(newValue);
		}
	};

	const handleMinQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value;
		const regex = /^\d*\.?\d*$/;
		if (regex.test(newValue)) {
			setminimum_transaction_amount(newValue);
		}
	};

	const BehaviourData = async () => {
		const formData: any = new FormData();

		formData.append('item_quantities_checkbox', listData?.item_quantities_checkbox);
		formData.append('pay_in_cash_checkbox', listData?.pay_in_cash_checkbox);
		formData.append('instructions', instructions);
		formData.append('side_bar_checkbox', listData?.side_bar_checkbox);
		formData.append('default_dining_checkbox', listData?.default_dining_checkbox);
		formData.append('special_requests_checkbox', listData?.special_requests_checkbox);
		formData.append('special_request_instructions', special_request_instructions);
		// formData.append('special_requests_checkbox', 1);
		// formData.append('special_request_instructions', 'special request instructions');
		formData.append('item_tapping_checkbox', listData?.item_tapping_checkbox);
		formData.append('item_quantities_instructions', item_quantities_instructions);
		formData.append('checkout_item_checkbox', listData?.checkout_item_checkbox);
		formData.append('main_screen_checkbox', listData?.main_screen_checkbox);
		formData.append('max_quantity_per_cart', max_quantity_per_cart);
		formData.append('minimum_transaction_amount', minimum_transaction_amount);
		formData.append('successful_transaction_checkbox', listData?.successful_transaction_checkbox);
		formData.append('idle_screen_timeout', idle_screen_timeout);
		formData.append('idle_screen_title', idle_screen_title);
		formData.append('idle_screen_description', idle_screen_description);
		formData.append('idle_screen_image_checkbox', listData?.idle_screen_image_checkbox);
		formData.append('kiosk_sticker', listData?.kiosk_sticker);
		formData.append('sold_out_checkbox', listData?.sold_out_checkbox);
		formData.append('stock_threshold', stock_threshold);
		formData.append('manually_sync_square_inventory', listData?.manually_sync_square_inventory);
		formData.append('automatically_number_orders_checkbox', listData?.automatically_number_orders_checkbox);
		formData.append('order_number_threshold', order_number_threshold);
		formData.append('new_checkout_step', JSON.stringify({ "step_name": "Custom Step", "description": "Add custom step details" }));
		formData.append('title_in_dining', 'Select Dining Option');
		formData.append('description_in_dining', 'Choose how you want to dine');
		formData.append('before_order_checkbox', listData?.before_order_checkbox);
		formData.append('checkout_to_square', listData?.checkout_to_square);
		formData.append('title_in_text', 'Enter Additional Information');
		formData.append('description_in_text', 'Provide any extra details');
		formData.append('required_input_checkbox', listData?.required_input_checkbox);
		formData.append('customer_order_checkbox', listData?.customer_order_checkbox);
		// formData.append('order_name_checkbox', listData?.order_name_checkbox);
		formData.append('order_name_checkbox', listData?.order_name_checkbox);
		formData.append('pos_order_checkbox', listData?.pos_order_checkbox);
		formData.append('user_code', UserCodeData);
		formData.append('background_image', listData?.background_image);

		if (background_image) {
			formData.append("background_image", background_image);
		}

		console.log("formData============================================>", formData);
		try {
			const response = await HelperModule().postFormDataMethod(ApiServices.base_URL + ApiServices.styling_behaviour, formData);
			console.log(response)
			if (response.status === true) {
				enqueueSnackbar(response.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' }, style: { fontFamily: 'Poppins', fontWeight: '500' }, });
				BehaviourInitial()
			} else if (response.status === false) {
				console.log(response)
				// enqueueSnackbar(response.errors.email[0], { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }, style: { fontFamily: 'Poppins', fontWeight: '500' }, });
			}
		}
		catch (error) {
			// enqueueSnackbar("Incorrect password", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }, style: { fontFamily: 'Poppins', fontWeight: '500' }, });
			console.log("error catch", error)
		}
	}


	useEffect(() => {
		BehaviourInitial()
	}, [])

	return (
		<div className='mainContainer-behaviour'>
			{loading ?
				<div className="Loader-container">
					<CircularProgress disableShrink  sx={{color: '#8B5CFF'}} />
				</div> :
				<>
					<h1 className='behaviour-content'>Behaviour</h1>
					<p className='settingToControl'>Settings to control how your minioskinteract with customers.</p>
					<div className='mainbox'>
						<p className="labelForBehaviour">
							<input type="checkbox"
								checked={listData?.item_tapping_checkbox === 1}
								onChange={() => handleCheckboxChange('item_tapping_checkbox')}
							/>
							<span style={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: '500', color: '#201C1C' }}>Show Item Details When Tapping On An Item</span>
						</p>
						<p className='about-item-behaviour'>Use this option to show an item's details when tapping on an item. If you don't have any images, descriptions, or item<br /> variations, it's much more user friendly to have the miniosk skip this view.</p>
					</div>

					<div className='cards'>
						<p className="labelForBehaviour">
							<input type="checkbox"
								checked={listData?.pay_in_cash_checkbox === 1}
								onChange={() => handleCheckboxChange('pay_in_cash_checkbox')}
							/>
							<span style={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: '500', color: '#201C1C' }}>Pay In Cash</span>
						</p>
						<p className='about-item-behaviour'>Enable this setting to make pay in cash available to users.</p>
						{/* <p className='instructions-behaviour'>Instructions</p>
				<div>
					<input value={instructions} onChange={(e: any) => setinstructions(e.target.value)} type='text' placeholder='Type Here' style={{ fontFamily: 'Poppins', borderRadius: "8px", border: "1px solid #201C1C3D", width: '95%', height: "7.13vh", marginTop: '8px', display: 'flex', alignItems: 'center', paddingLeft: '16px', fontSize: '14px' }} />
					<p className='aboutthebehaviourr'>Use this setting to customize the instructions for the customer to pay in cash.</p>
				</div> */}
					</div>
					<div className=' cards'>
						<div className=' bg-white auto mt-[20px] pt-[10px] pl-[15px] w-[62%] rounded-[8px]'>
							{/* <p className="labelForBehaviour">
						<input type="checkbox"
							checked={listData?.side_bar_checkbox === 1}
							onChange={() => handleCheckboxChange('side_bar_checkbox')}
						/>
						<span className='checkbox-content'>Display dining options in the checkout side bar</span>
					</p>
					<p className='aboutthebehaviourr'>Use this option to show a FOR HERE / TO GO switch in the checkout side bar. This will show up on receipts.</p> */}
							<p className="labelForBehaviour">
								<input type="checkbox"
									checked={listData?.default_dining_checkbox === 1}
									onChange={() => handleCheckboxChange('default_dining_checkbox')}
									style={{ marginTop: '16px' }} />
								<span className='checkbox-content' style={{ marginTop: '16px' }}>Use TO GO as the default dining option</span>
							</p>
							<p className='aboutthebehaviourr'>When enabled, this option will set the default dining option to TO GO. When disabled, Miniosk will default to FOR HERE.</p>
						</div>
					</div>
					<div className=' cards'>
						<p className="labelForBehaviour">
							<input type="checkbox"
								checked={listData?.special_requests_checkbox === 1}
								onChange={() => handleCheckboxChange('special_requests_checkbox')}
							/>
							<span className='checkbox-content'>Ask the customer for special requests</span>
						</p>
						<p className='aboutthebehaviourr pt-2'>Enable this setting to show the customer a text area to type in any special requests.</p>
						{/* <p className='instructions-behaviour'>Special Request Instructions</p>
				<div>
					<input value={special_request_instructions} onChange={(e: any) => setspecial_request_instructions(e.target.value)} type='text' placeholder='Type Here' style={{ fontFamily: 'Poppins', borderRadius: "8px", border: "1px solid #201C1C3D", width: '95%', height: "7.13vh", marginTop: '8px', display: 'flex', alignItems: 'center', paddingLeft: '16px', fontSize: '14px' }} />
					<p className='aboutthebehaviourr py-2'>Use this setting to customize the instructions for the customer to type in special requests.</p>
				</div> */}
					</div>
					{/* <div className=' cards'>
				<p className="labelForBehaviour">
					<input type="checkbox"
						checked={listData?.special_requests_checkbox === 1}
						onChange={() => handleCheckboxChange('special_requests_checkbox')}
					/>
					<span className='checkbox-content'>Ask the customer for item quantities</span>
				</p>
				<p className='aboutthebehaviourr pt-1'>Enable this setting to ask the customer for how many of an item they want.</p>
				<p className='instructions-behaviour'>Item Quantities Instructions</p>
				<div>
					<input value={item_quantities_instructions} onChange={(e: any) => setitem_quantities_instructions(e.target.value)} type='text' placeholder='Type Here' style={{ fontFamily: 'Poppins', borderRadius: "8px", border: "1px solid #201C1C3D", width: '95%', height: "7.13vh", marginTop: '8px', display: 'flex', alignItems: 'center', paddingLeft: '16px', fontSize: '14px' }} />
					<p className='aboutthebehaviourr py-2'>Use this setting to customize the instructions for the customer choose the item quantity amount.</p>
				</div>
			</div>
			<div className=' mainbox'>
				<p className="labelForBehaviour">
					<input type="checkbox"
						checked={listData?.checkout_item_checkbox === 1}
						onChange={() => handleCheckboxChange('checkout_item_checkbox')}
					/>
					<span className='checkbox-content'>Ask the customer if they want to checkout after adding an item</span>
				</p>
				<p className='aboutthebehaviourr py-2'>Use this option to show a FOR HERE / TO GO switch in the checkout side bar. This will show up on receipts.</p>


			</div> */}
					<div className=' mainbox'>
						<p className="labelForBehaviour">
							<input type="checkbox"
								checked={listData?.main_screen_checkbox === 1}
								onChange={() => handleCheckboxChange('main_screen_checkbox')}
							/>
							<span className='checkbox-content'>Return to main categories screen after adding an item</span>
						</p>
						<p className='aboutthebehaviourr py-2'>With this enabled, the miniosk navigate back to the main categories after the customer adds an item.</p>
					</div>

					<div className='cards'>
						<p className='instructions-behaviour'>Max Quantity Per Cart</p>
						<div>
							<input value={max_quantity_per_cart} onChange={handleMaxQuantity} type='text' placeholder='Type Here' style={{ fontFamily: 'Poppins', borderRadius: "8px", border: "1px solid #201C1C3D", width: '95%', height: "7.13vh", marginTop: '8px', display: 'flex', alignItems: 'center', paddingLeft: '16px', fontSize: '14px' }} />
							<p className='aboutthebehaviourr'>Use this setting to customize the instructions for the customer to pay in cash.</p>
						</div>
						<p className='instructions-behaviour'>Minimum Transaction Amount</p>
						<div>
							<input value={minimum_transaction_amount} onChange={handleMinQuantity} type='text' placeholder='Type Here' style={{ fontFamily: 'Poppins', borderRadius: "8px", border: "1px solid #201C1C3D", width: '95%', height: "7.13vh", marginTop: '8px', display: 'flex', alignItems: 'center', paddingLeft: '16px', fontSize: '14px' }} />
							<p className='aboutthebehaviourr'>Use this setting to customize the instructions for the customer to pay in cash.</p>
						</div>
					</div>
					<div className=' cards'>
						{/* <p className="labelForBehaviour">
					<input type="checkbox"
						checked={listData?.successful_transaction_checkbox === 1}
						onChange={() => handleCheckboxChange('successful_transaction_checkbox')}

					/>
					<span className='text-[12px] text-[#ACAAAA]' style={{ fontFamily: 'Poppins' }}>Always show the idle screen after successful transactions</span>
				</p>
				<p className='aboutthebehaviourr mt-1 mb-2'>Use this option to default to showing the idle screen after a customer checks out. When this option is off, the idle screen will only show after a certain amount of time, configured below.</p>

				<p className='idleScreen'>Idle Screen Timeout</p>
				<div>
					<input value={idle_screen_timeout} onChange={(e: any) => setidle_screen_timeout(e.target.value)} type='text' placeholder='Type Here' style={{ fontFamily: 'Poppins', borderRadius: "8px", border: "1px solid #201C1C3D", width: '95%', height: "7.13vh", marginTop: '8px', display: 'flex', alignItems: 'center', paddingLeft: '16px', fontSize: '14px' }} />
					<p className='aboutthebehaviourr mt-1'>This is the amount of time, in seconds, that should pass without any customer interaction. After this, the miniosk will display an idle screen. Set this value to 0 to disable the idle screen.</p>
				</div>

				<p className='idleScreen'>Idle Screen Title</p>
				<div>
					<input value={idle_screen_title} onChange={(e: any) => setidle_screen_title(e.target.value)} type='text' placeholder='Type Here' style={{ fontFamily: 'Poppins', borderRadius: "8px", border: "1px solid #201C1C3D", width: '95%', height: "7.13vh", marginTop: '8px', display: 'flex', alignItems: 'center', paddingLeft: '16px', fontSize: '14px' }} />
					<p className='aboutthebehaviourr mt-1'>This text will be displayed in the idle screen, in large bold text.</p>
				</div>
				<textarea value={idle_screen_description} onChange={(e: any) => setidle_screen_description(e.target.value)} className="textarea-behaviour" placeholder="Enter text..."></textarea>
				<p className='aboutthebehaviourr'>This text will be displayed in the idle screen, in large bold text.</p>
				<p className="labelForBehaviour" style={{ marginTop: '16px' }}>
					<input type="checkbox"
						checked={listData?.idle_screen_image_checkbox === 1}
						onChange={() => handleCheckboxChange('idle_screen_image_checkbox')}

					/>
					<span className='checkbox-content'>Show idle screen background image</span>
				</p>
				<p className='aboutthebehaviourr'>This image will be used as the background for the idle screen, if set to be displayed. You can use gifs for a fancy animated background!</p>
				<div className='upload-container' style={{ paddingTop: "10px" }}>
					<div style={{ width: "180px", height: "180px", overflow: "hidden", borderRadius: "8px" }}>
						{background_image ? (
							<img
								src={URL.createObjectURL(background_image)}
								alt="Uploaded"
								style={{ width: "100%", height: "100%" }}
							/>
						)
							:
							<img alt='cafe' src={listData?.background_image} style={{ width: "100%", height: "100%" }} />
						}
					</div>
					<div style={{ display: "flex", alignItems: "flex-start", gap: "8px", }}>
						<label htmlFor="imageInput" className='uploadbg-btn' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Upload Background Image </label>
						<input type="file" id="imageInput" onChange={(e) => setbackground_image(e.target.files?.[0] || null)} style={{ display: 'none', }} />
					</div>
				</div> */}
						<p className="labelForBehaviour" style={{ marginTop: '8px' }}>
							<input type="checkbox"
								checked={listData?.kiosk_sticker === 1}
								onChange={() => handleCheckboxChange('kiosk_sticker')}

							/>
							<span className='checkbox-content'>Display 'Powered by Miniosk' sticker</span>
						</p>
						<p className='aboutthebehaviourr pb-4'>Keep this on to show a small, non-intrusive 'Powered by Miniosk' text at the bottom of the idle screen. This is completely optional but helps us out a ton, thanks in advance! 🙏</p>
					</div>

					{/* <div className='cards'>
				<p className="labelForBehaviour">
					<input type="checkbox"
						checked={listData?.sold_out_checkbox === 1}
						onChange={() => handleCheckboxChange('sold_out_checkbox')}
					/>
					<span className='checkbox-content'>Automatically show items as sold out</span>
				</p>
				<p className='aboutthebehaviourr'>Enable this option to have your minioskautomatically sync with item availability and inventory in Square. When item variations are sold out, they'll be labeled as such and cannot be ordered. When item variations are back in stock, customers can immediately start ordering those items again. You can disable this individually for each item variation in the Menu Editor.</p>
				<p className='instructions-behaviour'>Stock Threshold</p>
				<div>
					<input value={stock_threshold} onChange={(e: any) => setstock_threshold(e.target.value)} type='text' placeholder='Type Here' style={{ fontFamily: 'Poppins', borderRadius: "8px", border: "1px solid #201C1C3D", width: '95%', height: "7.13vh", marginTop: '8px', display: 'flex', alignItems: 'center', paddingLeft: '16px', fontSize: '14px' }} />
					<p className='aboutthebehaviourr'>Use this field to set the remaining stock count that will trigger an item in the miniosk to be labeled as sold out. This is useful in preventing the overselling of any items.</p>
				</div>
				<div className='flex pt-4 gap-2' style={{ display: 'flex', flexDirection: 'row', marginTop: '16px' }}>
					<img alt='info' src={INFO} className='h-[18px]' style={{ height: '24px', width: '24px', }} />
					<p className='info-inst' style={{ paddingLeft: "8px" }}>The button below is available for manually syncing Square Inventory to Miniosk. Typically you don't need to do this, as Square will send Miniosk changes to inventory as they happen and Miniosk will often check for inventory updates in miniosk mode.</p>
				</div>
				<button className='manual-btn'>Manually Sync Square Inventory </button>
			</div> */}
					{/* <div className=' cards'>
				<p className="labelForBehaviour">
					<input type="checkbox"
						checked={listData?.automatically_number_orders_checkbox === 1}
						onChange={() => handleCheckboxChange('automatically_number_orders_checkbox')}

					/>
					<span className='checkbox-content'>Automatically Number Orders</span>
				</p>
				<p className='aboutthebehaviourr'>Enable this option to have your minioskautomatically number orders as they come in. When the customer reaches the checkout success screen, they'll see their order number.</p>
				<p className='instructions-behaviour'>Order Number Threshold</p>
				<div>
					<input value={order_number_threshold} onChange={(e: any) => setorder_number_threshold(e.target.value)} type='text' placeholder='Type Here' style={{ fontFamily: 'Poppins', borderRadius: "8px", border: "1px solid #201C1C3D", width: '95%', height: "7.13vh", marginTop: '8px', display: 'flex', alignItems: 'center', paddingLeft: '16px', fontSize: '14px' }} />
					<p className='aboutthebehaviourr pb-4'>Use this field to configure the number that Miniosk will reset the order number on. For example, you might want to reset<br /> the order number at 99, starting over at 1 again. You can set this to zero to never reset the order number, though note<br /> that order numbers always reset the next day.</p>
				</div>
			</div> */}
					{/* <h1 className='checkoutflow'>Checkout Flow</h1>
			<p className='customize'>Customize the exact steps your customers need to complete to finish their order. For example, you might want to ask the customer for an order name, a phone number, or whether they want to add their Square Loyalty account. Learn more about how to create new steps in a custom checkout flow</p>
			<button className='uploadbg-btn' onClick={() => setShowModal1(true)}>New Checkout Step</button> */}

					{/* <div className=' cards'>
				<div className=' bg-white h-auto mt-[15px] pt-[8px] px-[15px] w-[62%] rounded-[8px]'>
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
						<p className='steps'>Step 1
							<span style={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: '600', color: '#8B5CFF', marginLeft: "8px" }}>Dining Option Prompt</span> </p>
						<div className="edit-menu-container" style={{ backgroundColor: "#F34A4A21", marginRight: '16px', cursor: 'pointer' }}>
							<img src={TRASH} alt="delete" style={{ width: '15.74px', height: '15.74px', backgroundColor: "#8B5CFF0A", }} />
						</div>
					</div>

					<p className='aboutSteps'>A special type of prompt that asks the customer for their dining option selection<br />
						("For Here" or "To Go").</p>

					<p className='instructions-behaviour'>Checkout Step Title </p>
					<div>
						<input value={checkout_step_title_in_dining_option_prompt} onChange={(e: any) => setcheckout_step_title_in_dining_option_prompt(e.target.value)} type='text' placeholder='Dining Option' style={{ fontFamily: 'Poppins', borderRadius: "8px", border: "1px solid #201C1C3D", width: '95%', height: "7.13vh", marginTop: '8px', display: 'flex', alignItems: 'center', paddingLeft: '16px', fontSize: '14px' }} />
						<p className='aboutthebehaviourr mt-[5px]'>This is the main text that the customer sees. We recommend using a short name here (e.g. 'Order Name') as this will be passed on to printed receipts.</p>
					</div>
					<p className='instructions-behaviour'>Checkout Step Description </p>
					<textarea value={checkout_step_description_in_dining_option_prompt} onChange={(e: any) => setcheckout_step_description_in_dining_option_prompt(e.target.value)} className="textarea-behaviour" placeholder="Enter text..."></textarea>
					<p className='aboutthebehaviourr mt-[5px]'>This is some additional text that the customer sees. It's optional, but we recommend using this to more fully describe the checkout step and what the customer should do. (e.g. 'Fill in your name and we'll call you when your order is ready!')</p>
					<p className="labelForBehaviour" style={{ paddingTop: '16px' }}>
						<input type="checkbox"
							checked={listData?.before_order_checkbox === 1}
							onChange={() => handleCheckboxChange('before_order_checkbox')}
						/>
						<span className='checkbox-content'>Show before customer starts their order</span>
					</p>
					<p className='aboutthebehaviourr pt-2'>Enable this option to have the customer fill in an answer before browsing the menu.</p>
					<p className="labelForBehaviour" style={{ paddingTop: '16px' }}>
						<input type="checkbox"
							checked={listData?.checkout_to_square === 1}
							onChange={() => handleCheckboxChange('checkout_to_square')}
						/>
						<span className='checkbox-content'>Add checkout step to Square POS Order</span>
					</p>
					<p className='aboutthebehaviourr pt-2'>If enabled, Miniosk will add the customer's answer to the note section of any Square Orders.</p>
				</div>
			</div> */}

					<div className=' cards' style={{ marginBottom: '20px' }}>

						{/* <div className=' bg-white h-auto mt-[15px] pt-[8px] px-[15px] w-[62%] rounded-[8px]'>
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
						<p className='steps'>Step 1
							<span style={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: '600', color: '#8B5CFF', marginLeft: "8px" }}>Text Field</span> </p>
						<div className="edit-menu-container" style={{ backgroundColor: "#F34A4A21", marginRight: '16px', cursor: 'pointer' }}>
							<img src={TRASH} alt="delete" style={{ width: '15.74px', height: '15.74px', backgroundColor: "#8B5CFF0A", }} />
						</div>
					</div>
					<p className='aboutSteps'>A special type of prompt that asks the customer for their dining option selection<br />
						("For Here" or "To Go").</p>

					<p className='instructions-behaviour'>Checkout Step Title </p>
					<div>
						<input value={checkout_step_title_in_text_field} onChange={(e: any) => setcheckout_step_description_in_text_field(e.target.value)} type='text' placeholder='Dining Option' style={{ fontFamily: 'Poppins', borderRadius: "8px", border: "1px solid #201C1C3D", width: '95%', height: "7.13vh", marginTop: '8px', display: 'flex', alignItems: 'center', paddingLeft: '16px', fontSize: '14px' }} />
						<p className='aboutthebehaviourr mt-[5px]'>This is the main text that the customer sees. We recommend using a short name here (e.g. 'Order Name') as this will be passed on to printed receipts.</p>
					</div>
					<p className='instructions-behaviour'>Checkout Step Description </p>
					<textarea value={checkout_step_description_in_text_field} onChange={(e: any) => setcheckout_step_description_in_text_field(e.target.value)} className="textarea-behaviour" placeholder="Enter text..."></textarea>
					<p className='aboutthebehaviourr mt-[5px]'>This is some additional text that the customer sees. It's optional, but we recommend using this to more fully describe the checkout step and what the customer should do. (e.g. 'Fill in your name and we'll call you when your order is ready!')</p>
					<p className="labelForBehaviour" style={{ paddingTop: '16px' }}>
						<input type="checkbox"
							checked={listData?.required_input_checkbox === 1}
							onChange={() => handleCheckboxChange('required_input_checkbox')}
						/>
						<span className='checkbox-content'>Required input</span>
					</p>
					<p className='aboutthebehaviourr pt-2'>Enable this option to require the customer to fill in an answer before proceeding.</p>

					<p className="labelForBehaviour" style={{ paddingTop: '16px' }}>
						<input type="checkbox"
							checked={listData?.customer_order_checkbox === 1}
							onChange={() => handleCheckboxChange('customer_order_checkbox')}
						/>
						<span className='checkbox-content'>Show before customer starts their order</span>
					</p>
					<p className='aboutthebehaviourr pt-2'>Enable this option to have the customer fill in an answer before browsing the menu.</p>
					<p className="labelForBehaviour" style={{ paddingTop: '16px' }}>
						<input type="checkbox"
							checked={listData?.order_name_checkbox === 1}
							onChange={() => handleCheckboxChange('order_name_checkbox')}
						/>
						<span className='checkbox-content'>Use the customer's input as an order name</span>
					</p>
					<p className='aboutthebehaviourr pt-2'>This is used when passing customer input as the order name for printed receipts and kitchen display systems.</p>
				</div> */}
						<button onClick={BehaviourData} className="common-button-blue" style={{ transition: "background-color 0.3s" }} onMouseEnter={(e) => { (e.target as HTMLButtonElement).style.backgroundColor = "#6d43e57D"; }} onMouseLeave={(e) => { (e.target as HTMLButtonElement).style.backgroundColor = "#8B5CFF"; }}>Update</button>
						{/* <button className='common-button-cancel'>Cancel</button> */}
					</div>
					<Modal isOpen={showModal1} onClose={() => setShowModal1(false)}>
						<div className='absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 w-[40%] bg-white rounded-[15px] p-[10px] h-auto'>

							<h1 className='font-bold text-[15px]'>New Checkout Step</h1>
							<p className='font-bold text-[13px] pt-3'>First, Select A Checkout Step Type</p>
							<p className='aboutthebehaviourr my-3'>The Checkout Step Type Defines What type Of Input A Customer Can Type In (E.G Text Vs Phone Number). After You Select A Type We'll Fill in Some Information   </p>
							{/* <p type='text' placeholder='Text' className='w-[37vw] h-[12vh] mt-1 rounded-[12px] border border-[#dddddd] font-normal text-[12px] pl-[12px]'></p> */}
							<div className='h-[150px] overflow-y-auto scrollbar-none'>
								<div className='w-[37vw] h-[10vh] mt-1 rounded-[12px] border border-[#dddddd] font-normal text-[12px] pl-[12px] pt-2'>
									<p className='text-[12px]'>Text</p>
									<p className='aboutthebehaviourr my-3'>A Basic Text field to ask the customer to type an answer to a question</p>
								</div>
								<div className='w-[37vw] h-[10vh] mt-1 rounded-[12px] border border-[#dddddd] font-normal text-[12px] pl-[12px] pt-2'>
									<p className='text-[12px]'>Text</p>
									<p className='aboutthebehaviourr my-3'>A Basic Text field to ask the customer to type an answer to a question</p>
								</div>
								<div className='w-[37vw] h-[10vh] mt-1 rounded-[12px] border border-[#dddddd] font-normal text-[12px] pl-[12px] pt-2'>
									<p className='text-[12px]'>Text</p>
									<p className='aboutthebehaviourr my-3'>A Basic Text field to ask the customer to type an answer to a question</p>
								</div>
							</div>
							<div className='flex gap-[10vh]'>
								<button className='cursor-pointer text-white bg-[#D1BEFF] text-center text-[10px] h-[30px] w-[150px] rounded-[10px]  mb-[10px] font-normal mt-2'>Next </button>
								<button className='text-red-500 text-[11px]' onClick={() => setShowModal1(false)}>Cancel</button>
							</div>

						</div>

					</Modal>

				</>
			}
		</div>
	)
}

export default Behaviour