import React, { Fragment, useMemo, useEffect, useState, useRef } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigation,
} from "react-router-dom";
import "@fontsource/poppins";
import "@fontsource/poppins/400.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "rsuite/dist/rsuite-no-reset.min.css";
// import 'rsuite/dist/rsuite.min.css';
import SidebarNav from "./screens/Sidebar/Sidebar";
import { SnackbarProvider } from "notistack";
import AppRoutes from "./routes/AppRoutes";
import AuthRoutes from "./routes/AuthRoutes";
import { useAppDispatch, useAppSelector } from "./Hooks";
import { setIsLogin } from "./store/reducers/AuthReducer";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useResize } from "./utility/useDimensions";
import DashMobile from "./screens/dashMobile";

function App() {
  const dispatch = useAppDispatch();
  const { IsLogin } = useAppSelector((state) => state?.Auth);
  const [width, setWidth] = useState(window.innerWidth);

  console.log(width, "width, height");

  useEffect(() => {
    if (localStorage.getItem("IsLogin")) {
      dispatch(setIsLogin(true));
    } else {
      dispatch(setIsLogin(false));
    }
  }, [IsLogin]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    
    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  if (IsLogin === null) {
    return <Fragment></Fragment>;
  }

  return (
    <>
      <GoogleOAuthProvider clientId="554215699089-8m7u2cdp249f81telld1h27b9in0kvo6.apps.googleusercontent.com">
        <SnackbarProvider maxSnack={3}>
          <Router>
            {IsLogin ? (
              <div className="app-container">
                {width > 768 ? (
                  <>
                    <SidebarNav />
                    <div className="main-content">
                      <AppRoutes />
                    </div>
                  </>
                ) : <DashMobile/>}
              </div>
            ) : (
              <div className="app-container">
                <div className="main-content">
                  <AuthRoutes />
                </div>
              </div>
            )}
          </Router>
        </SnackbarProvider>
      </GoogleOAuthProvider>
    </>
  );
}

export default App;
