import { createSlice } from '@reduxjs/toolkit';

interface initialStateProps {
    InsightsList: any,
    salesTableList: any
}

const initialState: initialStateProps = {
    InsightsList: {},
    salesTableList: []
}

const insightsDetailsReducer = createSlice({
    name: 'insightsDetails',
    initialState,
    reducers: {
        setInsightsList: (state, action) => {
            state.InsightsList = action.payload
        },
        setSalesTableList: (state, action) => {
            state.salesTableList = action.payload
        }
    },
});

export const { setInsightsList, setSalesTableList } = insightsDetailsReducer.actions;
export default insightsDetailsReducer.reducer;