import { createSlice } from '@reduxjs/toolkit';

interface initialStateProps {
    CatalogList: any,
    ItemsList: any
    items_sub_list: any;

}

const initialState: initialStateProps = {
    CatalogList: [],
    ItemsList: [],
    items_sub_list: {},

}

const catalogDetailsReducer = createSlice({
    name: 'catalogDetails',
    initialState,
    reducers: {
        setCatalogList: (state, action) => {
            state.CatalogList = action.payload
        },
        setItemsList: (state, action) => {
            state.ItemsList = action.payload
        },
        setItemsSubList: (state, action) => {
            state.items_sub_list = action?.payload;
        },
    },
});

export const { setCatalogList, setItemsList, setItemsSubList } = catalogDetailsReducer.actions;
export default catalogDetailsReducer.reducer;