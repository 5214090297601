import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useLocation, Link } from "react-router-dom";
import "./CreateProfile.css";
import LOGO from "../../assets/images/logo_main_img.png";
import BGIMAGE from "../../assets/images/background.jpg";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import { useParams } from "react-router-dom";
import "react-international-phone/style.css";
import MuiPhone from "./MuiPhone";
import { setUserDetails } from "../../store/reducers/AuthReducer";
import { useDispatch, useSelector } from "react-redux";
import { Grid, TextField, Typography, Button } from "@mui/material";

function CreateProfile() {
  const [firstName, setFirstName]: any = useState("");
  const [lastName, setLastName]: any = useState("");
  const [email, setEmail]: any = useState("");
  const [referral, setReferral] = useState("");
  const { id, mail } = useParams();
  const [phoneNumber, setPhoneNumber]: any = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isCheckboxChecked, setIsCheckboxChecked]: any = useState(false);
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [checkboxError, setCheckboxError] = useState("");
  const [errorbol, setErrorbol] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();
  const { userDetails } = useSelector((state: any) => state.Auth);

  useEffect(() => {
    setFirstName(userDetails.first_name);
    setLastName(userDetails.last_name);
    setPhoneNumber(userDetails.phone);
    setEmail(userDetails.email);
    sessionStorage.removeItem("timer");
    if (id) {
      setReferral(id);
    }
    if (mail) {
      setEmail(mail);
    }
  }, []);

  const handleCheckboxChange = (e: any) => {
    setIsCheckboxChecked(e.target.checked);
    if (e.target.checked) setCheckboxError("");
  };

  const Register = (e: any) => {
    e.preventDefault();
    let valid = true;

    const validateField = (
      field: any,
      errorSetter: any,
      validationFunction: any,
      errorMessage: any
    ) => {
      if (!field) {
        errorSetter("This field is required");
        valid = false;
      } else if (validationFunction()) {
        errorSetter(errorMessage);
        valid = false;
      } else {
        errorSetter("");
      }
    };

    validateField(
      firstName,
      setFirstNameError,
      isValidFirstName,
      "First name should have at least 3 characters."
    );
    validateField(
      lastName,
      setLastNameError,
      isValidLastName,
      "Last name should have at least 3 characters."
    );
    validateField(
      email,
      setEmailError,
      () => !isValidEmail(email),
      "Email format invalid"
    );
    validateField(
      phoneNumber,
      setPhoneError,
      () => !isValidPhoneNumber(),
      "Phone number invalid"
    );

    if (!isCheckboxChecked) {
      setCheckboxError(
        "Please agree to the Terms of Service & Privacy Policy."
      );
      valid = false;
    } else {
      setCheckboxError("");
    }

    if (valid) {
      GetOtp(e);
    }
  };

  const isValidPhoneNumber = () => {
    const phoneNumberRegex = /^\+?([0-9]{1,3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return phoneNumberRegex.test(phoneNumber);
  };

  const isValidFirstName = () => {
    if (firstName.length <= 3) return true;
    return false;
  };

  const isValidLastName = () => {
    if (lastName.length <= 3) return true;
    return false;
  };

  const isValidEmail = (email: any) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  // const handlePhoneNumberChange = (e: any) => {
  //   const inputValue = e.target.value.replace(/[^\d]+/g, "");
  //   setPhoneNumber(inputValue);
  //   const isValid = /^[0-9]+$/.test(inputValue);
  //   if (!isValid) {
  //     enqueueSnackbar("Please Enter Numeric Characters.", {
  //       variant: "error",
  //       anchorOrigin: { vertical: "top", horizontal: "right" },
  //       style: { fontFamily: "Poppins", fontWeight: "500" },
  //     });
  //   }
  // };

  const GetOtp = async (e: any) => {
    e.preventDefault();
    const Params = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phoneNumber,
    };
    const dispatchDetails = {
      first_name: firstName,
      last_name: lastName,
      phone: phoneNumber,
      email: email,
      back: true,
    };
    try {
      const response = await HelperModule().postMethod(
        ApiServices.base_URL + ApiServices.create_user_otp,
        Params
      );
      if (response.status === true) {
        enqueueSnackbar(response.message, {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
        setTimeout(() => {
          navigate("/otp", {
            state: { firstName, lastName, email, phoneNumber },
          });
        }, 1000);
        dispatch(setUserDetails(dispatchDetails));
        // localStorage.setItem("firstNameCreate", firstName);
        // localStorage.setItem("lastNameCreate", lastName);
        // localStorage.setItem("emailCreate", email);
        // localStorage.setItem("phoneCreate", phoneNumber);
        localStorage.setItem("UserCode", response.user.user_code);
        localStorage.setItem("Token", response.auth_token);
        localStorage.setItem("set_password_token", response.set_password_token);
      }
      // else if (response.Status === 422) {
      //   enqueueSnackbar(response.message, {
      //     variant: "error",
      //     anchorOrigin: { vertical: "top", horizontal: "right" },
      //     style: { fontFamily: "Poppins", fontWeight: "500" },
      //   });
      // }
      else if (response.status === false) {
        enqueueSnackbar(response.message, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
      } else {
        enqueueSnackbar(response.message, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
      }
    } catch (error) {
      // enqueueSnackbar("Invalid Mobile Number", {
      //   variant: "error",
      //   anchorOrigin: { vertical: "top", horizontal: "right" },
      //   style: { fontFamily: "Poppins", fontWeight: "500" },
      // });
      console.log("error catch", error);
    }
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={5}
          xl={5}
          className="grid-container-password"
        >
          <div className="container-hide"></div>
          <div className="form-container-profile">
            <Typography
              variant="h4"
              sx={{
                typography: {
                  xs: "h5",
                  sm: "h4",
                  md: "h4",
                  lg: "h4",
                  xl: "h4",
                },
                color: "#201C1C",
                fontFamily: "Poppins",
              }}
            >
              Create a new business account
            </Typography>
            <div>
              <Typography
                variant="body1"
                sx={{
                  typography: {
                    xs: "body2",
                    sm: "body1",
                    md: "body1",
                    lg: "body1",
                    xl: "body1",
                  },
                  color: "#201C1C",
                  fontFamily: "Poppins",
                  textAlign: "left",
                }}
                mb={1}
                mt={2}
                className="email-header"
              >
                First name
              </Typography>
              <TextField
                error={firstNameError ? true : false}
                placeholder="Enter First Name"
                id="outlined-basic"
                variant="outlined"
                helperText={firstNameError ? firstNameError : ""}
                onChange={(e) => setFirstName(e.target.value)}
                className="email-textfield"
                sx={{
                  "& .MuiInputBase-root": {
                    height: "4rem",
                    borderRadius: "8px",
                  },
                }}
              />
            </div>
            <div>
              <Typography
                variant="body1"
                sx={{
                  typography: {
                    xs: "body2",
                    sm: "body1",
                    md: "body1",
                    lg: "body1",
                    xl: "body1",
                  },
                  color: "#201C1C",
                  fontFamily: "Poppins",
                  textAlign: "left",
                }}
                mb={1}
                mt={2}
                className="email-header"
              >
                Last name
              </Typography>
              <TextField
                error={lastNameError ? true : false}
                placeholder="Enter Last Name"
                id="outlined-basic"
                variant="outlined"
                helperText={lastNameError ? lastNameError : ""}
                onChange={(e) => setLastName(e.target.value)}
                className="email-textfield"
                sx={{
                  "& .MuiInputBase-root": {
                    height: "4rem",
                    borderRadius: "8px",
                  },
                }}
              />
            </div>
            <div>
              <Typography
                variant="body1"
                sx={{
                  typography: {
                    xs: "body2",
                    sm: "body1",
                    md: "body1",
                    lg: "body1",
                    xl: "body1",
                  },
                  color: "#201C1C",
                  fontFamily: "Poppins",
                  textAlign: "left",
                }}
                mb={1}
                mt={2}
                className="email-header"
              >
                Email
              </Typography>
              <TextField
                error={emailError ? true : false}
                placeholder="example@gmail.com"
                id="outlined-basic"
                variant="outlined"
                value={email}
                helperText={emailError ? emailError : ""}
                onChange={(e) => setEmail(e.target.value)}
                className="email-textfield"
                sx={{
                  "& .MuiInputBase-root": {
                    height: "4rem",
                    borderRadius: "8px",
                  },
                }}
              />
            </div>
            <div>
              <Typography
                variant="body1"
                sx={{
                  typography: {
                    xs: "body2",
                    sm: "body1",
                    md: "body1",
                    lg: "body1",
                    xl: "body1",
                  },
                  color: "#201C1C",
                  fontFamily: "Poppins",
                  textAlign: "left",
                }}
                mb={1}
                mt={2}
                className="email-header"
              >
                Phone number
              </Typography>
              <MuiPhone
                error={phoneError ? true : false}
                placeholder="Enter Phone Number"
                value={phoneNumber}
                onChange={setPhoneNumber}
                helperText={phoneError ? phoneError : ""}
                className="miniosk-input-phone"
                sx={{
                  ".css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input": {
                    borderRadius: "8px",
                    padding: "0",
                    height: "4rem",
                  },
                }}
              />
            </div>
            {referral && (
              <div>
                <Typography
                  variant="body1"
                  sx={{
                    typography: {
                      xs: "body2",
                      sm: "body1",
                      md: "body1",
                      lg: "body1",
                      xl: "body1",
                    },
                    color: "#201C1C",
                    fontFamily: "Poppins",
                    textAlign: "left",
                  }}
                  mb={1}
                  mt={2}
                  className="email-header"
                >
                  Referal Code
                </Typography>
                <TextField
                  error={errorbol}
                  placeholder="example@gmail.com"
                  id="outlined-basic"
                  variant="outlined"
                  helperText={errorbol ? errorMessage : ""}
                  onChange={(e) => setReferral(e.target.value)}
                  className="email-textfield"
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "4rem",
                      borderRadius: "8px",
                    },
                  }}
                />
              </div>
            )}
            <div className="checkboxDiv">
              <input
                type="checkbox"
                id="onlyOption"
                checked={isCheckboxChecked}
                onChange={handleCheckboxChange}
              />
              <div>
                <label htmlFor="onlyOption" className="radio-input">
                  I agree with the{" "}
                  <Link
                    style={{ textDecoration: "underline", color: "black" }}
                    to={"https://miniosk.com/terms-of-service.pdf"}
                  >
                    Terms of service
                  </Link>{" "}
                  &{" "}
                  <Link
                    style={{ textDecoration: "underline", color: "black" }}
                    to={"https://miniosk.com/privacy-policy.pdf"}
                  >
                    Privacy policy
                  </Link>
                  .
                </label>
              </div>
            </div>
            {checkboxError && <p className="error-text">{checkboxError}</p>}
            <Button
              type="submit"
              variant="contained"
              onClick={Register}
              sx={{
                marginTop: "1rem",
                height: "4rem",
                fontSize: "1.2rem",
                backgroundColor: "#8B5CFF",
                borderRadius: "8px",
                ":hover": {
                  bgcolor: "#8B5CFF",
                },
              }}
              className="continue-button-width"
            >
              Continue
            </Button>
          </div>
          <div className="kiosk_image_logo">
            <img src={LOGO} alt="logo" style={{ width: "150px", display: "block", margin: "auto" }} />
          </div>
        </Grid>
        <Grid
          item
          xs={0}
          sm={6}
          md={6}
          lg={7}
          xl={7}
          className="container-image"
        >
          <img
            src={BGIMAGE}
            alt="bg"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default CreateProfile;
