import "../Manage/ManageStyles.css";
import "./Customers.css";
import CustomersTable from "../../components/CustomersTable";

function Customers() {
  return (
    <div className="container">
      <h1 className="title-heading">Customers</h1>
      <div className="miniosk-table-container">
        <CustomersTable />
      </div>
    </div>
  );
}

export default Customers;
