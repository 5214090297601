import React from "react";
import BGIMAGE from "../../assets/images/background.jpg";
import { useNavigate } from "react-router-dom";
import LOGO from "../../assets/images/logo_main_img.png";
import { useAppDispatch } from "../../Hooks";
import { setIsLogin, setPosConnect } from "../../store/reducers/AuthReducer";
import { Button, Grid, Typography } from "@mui/material";
import "./Trails.css"

function Trails() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleContinueClick = async () => {
    const isAuthenticated = await localStorage.getItem("Token");
    dispatch(setIsLogin(true));
    console.log("isAuthenticated", isAuthenticated);
    window.location.href = "/linkaccount";
    if (isAuthenticated) {
      dispatch(setIsLogin(true));
      console.log("Authenticated");
      dispatch(setPosConnect(0));
      // await localStorage.setItem("PosAccount", "0")
      let login = await localStorage.setItem("IsLogin", "true");
      console.log(login);
      window.location.href = "/linkaccount";
      // window.location.href   = "/linkaccount"
    } else {
      dispatch(setIsLogin(true));
      console.log("UnAuthenticated");
    }
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={5}
          xl={5}
          alignContent="center"
          className="grid-container-item"
        >
          <div className="container-hide"></div>
          <div style={{  display:"flex", flexDirection:"column", justifyContent: "center", alignItems:"center" }}>
            <div className="header-text">
              <Typography
                variant="h4"
                sx={{
                  typography: {
                    xs: "h5",
                    sm: "h4",
                    md: "h4",
                    lg: "h4",
                    xl: "h4",
                  },
                  color: "#201C1C",
                  fontFamily: "Poppins",
                }}
              >
                Your 7 days free trail has been activated.
              </Typography>
            </div>
            <Button
              type="submit"
              variant="contained"
              onClick={handleContinueClick}
              sx={{
                marginTop: "2rem",
                height: "4rem",
                fontSize: "1.2rem",
                backgroundColor: "#8B5CFF",
                borderRadius: "8px",
                ":hover": {
                  bgcolor: "#8B5CFF",
                },
              }}
              className="continue-button-trails"
            >
              Continue
            </Button>
          </div>
          <div className="kiosk_image_logo">
            <img src={LOGO} alt="logo" style={{ width: "150px", display: "block", margin: "auto" }} />
          </div>
        </Grid>
        <Grid
          item
          xs={0}
          sm={6}
          md={6}
          lg={7}
          xl={7}
          className="container-image"
        >
          <img
            src={BGIMAGE}
            alt="bg"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Trails;
