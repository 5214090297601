import React, { Fragment, useEffect, useState } from "react";
import { useStyles } from "./UseStyles";
import { visuallyHidden } from "@mui/utils";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Pagination,
  TableRow,
  TableSortLabel,
  Typography,
  IconButton,
  LinearProgress,
  Button,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import Edit from "../assets/images/edit.png";
import HelperModule from "../helpers/HelperModule";
import ApiServices from "../helpers/ApiServices";
import { useDispatch, useSelector } from "react-redux";
import { setPromoCodeList } from "../store/reducers/PromoCodeReducer";
import CircularProgress from "@mui/material/CircularProgress";
import ConfirmationModal from "./ConfirmationModal";
import { useSnackbar } from "notistack";
import Search from "../assets/images/Search.png";
import Filter from "../assets/images/Filter.png";
import DropdoenFilter from "./Dropdown";
import Cross from "../assets/images/Cross.png";
import Switch from "@mui/material/Switch";
import FormControl from "@mui/material/FormControl";
import { MenuItem, Select } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ClearIcon } from "@mui/x-date-pickers/icons";
import dayjs from "dayjs";
import moment from "moment";
import _ from "lodash";
import DataTable from "./DataTable";

const headCells: any = [
  {
    id: "s_no",
    disablePadding: true,
    label: "S No",
    sortable: false,
  },
  {
    id: "promo_code",
    disablePadding: true,
    label: "Promo Name",
    sortable: false,
  },
  {
    id: "discount_type",
    disablePadding: false,
    label: "Type",
    sortable: true,
  },
  {
    id: "location.name",
    disablePadding: false,
    label: "Location",
    sortable: true,
  },
  {
    id: "status",
    disablePadding: false,
    label: "Status",
    sortable: true,
  },
];

type Order = "asc" | "desc";

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: "asc" | "desc",
  orderBy: Key
): (
  a: { [key in Key]: number | string | any },
  b: { [key in Key]: number | string | any }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function EnhancedTableHead(props: any) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort, controller } = props;
  const [isSwitchEnabled, setIsSwitchEnabled] = React.useState(false);
  const [isSwitchEnabledDuration, setIsSwitchEnabledDuration] =
    React.useState(false);

  const toggleSwitchDuration = () => {
    setIsSwitchEnabledDuration(!isSwitchEnabledDuration);
  };

  const toggleSwitch = () => {
    setIsSwitchEnabled(!isSwitchEnabled);
  };

  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      const isAsc = orderBy === property && order === "asc";
      onRequestSort(event, property, isAsc ? "desc" : "asc");
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              className={classes.TableHeadTitle}
              active={controller.field === headCell.id} // orderBy === headCell.id
              // direction={orderBy === headCell.id ? order : "asc"}
              direction={controller.order === "asc" ? "asc" : "desc"}
              disabled={!headCell.sortable}
              onClick={createSortHandler(headCell.id)}
              IconComponent={(event) => (
                <ArrowDropDownRoundedIcon
                  {...event}
                  sx={{ color: "rgba(32, 28, 28, 0.32)" }}
                />
              )}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

let UserCodeData: any;
const PromoCodeTable = () => {
  const { PromoCodeList } = useSelector((state: any) => state.promoCodeDetails);
  const { enqueueSnackbar } = useSnackbar();
  console.log("PromoCodeList===>", PromoCodeList);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<any>("promo_code");
  const [deletePromoCde, setDeletepromoCode] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredValues, setFilteredValues] = useState([]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [initLoading, setInitLoading] = useState(true);
  const [controller, setController] = useState({
    page: 1,
    rowsPerPage: 10,
    field: "",
    order: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const [currentRecords, setCurrentRecords] = useState([]);

  const nPages = Math.ceil(recordsCount / controller.rowsPerPage);

  // const nPages = Math.ceil(PromoCodeList.length / recordsPerPage)
  const [viewModal, setViewModal] = React.useState(false);
  const [promoId, setPromoId] = useState("");
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [isSwitchOn, setIsSwitchOn] = useState(true);
  const [from, setFrom] = useState(0);
  const [selectedDeleteId, setSelectedDeleteId] = useState(0);

  const handleViewButtonClick = (itemId: any) => {
    setSelectedRowId(itemId);
    setViewModal(true);
  };

  const navigate = useNavigate();
  const HandlePromoCodeUpdate = (id: any) => {
    navigate("/promotionupdate", { state: { id: id } });
  };

  useEffect(() => {
    const UserCode = localStorage.getItem("UserCode");
    UserCodeData = UserCode;
    PromocodeListData();
  }, []);

  const PromocodeListData = async () => {
    try {
      const response = await HelperModule().getMethod(
        ApiServices.base_URL +
          ApiServices.promocode_list +
          `?user_code=${UserCodeData} &search=${search} &page=${controller.page} &field=${controller.field} &order=${controller.order}`
      );
      if (response.status === true) {
        dispatch(setPromoCodeList(response?.promo_codes?.data));
        setFrom(response?.promo_codes?.from);
        setRecordsCount(response?.promo_codes?.total);
        const filteredData = response?.promo_codes?.data;
        console.log("Filtered Data:", filteredData);
        setFilteredValues(filteredData);
        setCurrentRecords(response?.promo_codes?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(PromoCodeList);
    setCurrentRecords(PromoCodeList);
  }, [PromoCodeList]);

  const DeletePromoCode = async (promocodeId: any) => {
    try {
      const response = await HelperModule().deleteMethod(
        ApiServices.base_URL + ApiServices.promoCode_delete + promocodeId
      );
      if (response.status === true) {
        setDeletepromoCode(false);
        console.log(response);
        window.location.reload();
        enqueueSnackbar(response.message, {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
      } else {
        console.log("Failed", response);
        setDeletepromoCode(false);
        enqueueSnackbar(response.message, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
      }
    } catch (error) {
      console.log("error catch", error);
    }
  };

  const handleDeleteButtonClick = () => {
    console.log("Delete=======================>", selectedDeleteId);
    DeletePromoCode(selectedDeleteId);
  };

  const DeletePromoModel = (id: any) => {
    console.log(id);
    setSelectedDeleteId(id);
    setDeletepromoCode(true);
    // PromocodeListData();
  };

  const handleSearch = (value: string) => {
    setSearch(value);
    console.log("Search Value:", value);

    const lowerCaseSearch = value.toLowerCase();
    let filteredList = PromoCodeList?.filter((promo: any) => {
      return (
        promo.promo_code.toLowerCase().includes(lowerCaseSearch) ||
        promo.discount_type.toLowerCase().includes(lowerCaseSearch)
        // Add more fields if needed
      );
    });
    setFilteredValues(filteredList);
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      setController({ ...controller, page: 1 });
    }, 1500);
    return () => clearTimeout(getData);
  }, [search]);

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
    setController({
      ...controller,
      page: value,
    });
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }
    PromocodeListData();
  }, [controller, search]);

  const visibleRows = React.useMemo(() => {
    const dataToDisplay =
      filteredValues.length > 0 ? filteredValues : PromoCodeList;
    const sortedData = stableSort(dataToDisplay, getComparator(order, orderBy));
    return sortedData;
  }, [order, orderBy, PromoCodeList, filteredValues]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    setController({
      ...controller,
      field: property,
      order:
        property !== controller.field
          ? "desc"
          : controller.order === "asc"
          ? "desc"
          : "asc",
      page: 1,
    });

    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const onActionClick = (row: any, buttonIndex: number) => {
    switch (buttonIndex) {
      case 0:
        handleViewButtonClick(row?.id); // Call the function for the first button
        break;
      case 1:
        DeletePromoModel(row?.id); // Call the function for the second button
        break;
      // Add more cases as needed for additional buttons
      default:
        console.log("Unknown button clicked", row);
    }
  };

  const actionButtons = [
    <button
      onClick={(e) => e.stopPropagation()}
      style={{
        width: "78.64px",
        height: "38.64px",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#201C1C14",
        color: "#201C1C",
        borderWidth: 0,
        fontWeight: "400",
        fontSize: "12px",
        fontFamily: "Poppins",
        borderRadius: "8px",
        marginLeft: "40px",
        transition: "background-color 0.3s",
      }}
      onMouseEnter={(e) => {
        (e.target as HTMLButtonElement).style.backgroundColor = "#6d43e57D";
      }}
      onMouseLeave={(e) => {
        (e.target as HTMLButtonElement).style.backgroundColor = "#201C1C14";
      }}
    >
      View
    </button>,
    <button
      onClick={(e) => e.stopPropagation()}
      style={{
        width: "90.9px",
        height: "38.64px",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#F34A4A29",
        color: "#F34A4A",
        borderWidth: 0,
        fontWeight: "400",
        fontSize: "12px",
        fontFamily: "Poppins",
        borderRadius: "8px",
        marginLeft: "12px",
        transition: "background-color 0.3s",
      }}
      onMouseEnter={(e) => {
        (e.target as HTMLButtonElement).style.backgroundColor = "#F34A4A7D";
      }}
      onMouseLeave={(e) => {
        (e.target as HTMLButtonElement).style.backgroundColor = "#F34A4A29";
      }}
    >
      Remove
    </button>,
  ];

  //   useEffect(() => {
  //     setController({
  //       ...controller,
  //       page: 1,
  //     });
  //   }, [filteredValues]);

  return (
    <Fragment>
      <DataTable
        order={order}
        orderBy={orderBy}
        nPages={nPages}
        currentRecords={currentRecords}
        visibleRows={visibleRows}
        controller={controller}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        setController={setController}
        setCurrentPage={setCurrentPage}
        headCells={headCells}
        loading={isLoading}
        actionElement={actionButtons}
        onActionClick={onActionClick}
      />

      <Modal open={viewModal} onClose={() => setViewModal(false)}>
        <>
          {PromoCodeList.filter((item: any) => item?.id === selectedRowId).map(
            (item: any, index: any) => {
              console.log(item, "items");
              console.log(item?.advanced_settings?.type_of_uses);
              console.log(item?.advanced_settings?.start_date);
              return (
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "44.8vw",
                    height: "80vh",
                    bgcolor: "background.paper",
                    borderRadius: "8px",
                    boxShadow: 24,
                    padding: "16px",
                    overflowY: "auto",
                    "-ms-overflow-style": "none",
                    scrollbarWidth: "none",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "start",
                    }}
                  >
                    <div>
                      <div style={{}}>
                        <h3
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: "500",
                            fontSize: "12px",
                            color: "#201C1C70",
                          }}
                        >
                          Name
                        </h3>
                      </div>
                      <h3
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "600",
                          fontSize: "14px",
                          color: "#201C1C",
                          marginTop: "8px",
                        }}
                      >
                        {item?.promo_code}
                      </h3>
                    </div>
                    <div
                      style={{
                        backgroundColor: "#F34A4A29",
                        height: "27.06px",
                        width: "27.06px",
                        borderRadius: "27.91px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        marginRight: "12px",
                      }}
                    >
                      <img
                        onClick={() => setViewModal(false)}
                        src={Cross}
                        alt="Cross"
                        style={{
                          width: "24px",
                          height: "24px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      fontSize: "12px",
                      color: "#201C1C70",
                      marginTop: "8px",
                    }}
                  >
                    Location
                  </div>
                  <h3
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      fontSize: "14px",
                      color: "#201C1C",
                      marginTop: "8px",
                    }}
                  >
                    {item?.location?.name}
                  </h3>
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      fontSize: "12px",
                      color: "#201C1C70",
                      marginTop: "24px",
                    }}
                  >
                    Menu
                  </div>
                  <h3
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      fontSize: "14px",
                      color: "#201C1C",
                      marginTop: "8px",
                    }}
                  >
                    {item?.menu?.menu_name}
                  </h3>
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      fontSize: "12px",
                      color: "#201C1C70",
                      marginTop: "24px",
                    }}
                  >
                    Discount Type
                  </div>
                  <h3
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      fontSize: "14px",
                      color: "#201C1C",
                      marginTop: "8px",
                    }}
                  >
                    {item?.discount_type}
                  </h3>
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      fontSize: "12px",
                      color: "#201C1C70",
                      marginTop: "24px",
                    }}
                  >
                    Item List
                  </div>
                  <>
                    <div>
                      {item?.promotionalcategories.map(
                        (categoryData: any, index: number) => (
                          <div key={categoryData?.category?.id}>
                            {/* Display Category */}
                            <h3
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: "600",
                                fontSize: "14px",
                                color: "#201C1C",
                                marginTop: "8px",
                              }}
                            >
                              {categoryData?.category?.name}
                            </h3>

                            {/* Display Items */}
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              {categoryData?.default.map((itemData: any) => (
                                <div
                                  key={itemData?.id}
                                  style={{
                                    width: "120px",
                                    height: "120px",
                                    marginBottom: "20px",
                                    marginRight: "20px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      backgroundColor: "#F8F8F8",
                                      width: "100%",
                                      height: "100%",
                                      padding: "8px",
                                    }}
                                  >
                                    <div
                                      className="menu-heading"
                                      style={{ color: "#201C1C70" }}
                                    >
                                      {itemData?.name}
                                    </div>
                                    <h3
                                      style={{
                                        margin: 0,
                                        fontSize: "14px",
                                        fontFamily: "Poppins",
                                        fontWeight: "600",
                                        color: "#201C1C",
                                      }}
                                    >
                                      $
                                      {
                                        itemData?.item_variation?.price_money
                                          ?.amount
                                      }
                                    </h3>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </>
                  <h3
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      fontSize: "20px",
                    }}
                  >
                    Advanced Settings
                  </h3>
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      fontSize: "12px",
                      color: "#201C1C70",
                      marginTop: "16px",
                    }}
                  >
                    Cart Rules
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "0px",
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        fontSize: "14px",
                      }}
                    >
                      ${item?.advanced_settings?.cart_min_amount}
                    </h3>
                    <h3
                      style={{
                        marginLeft: "20px",
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        fontSize: "14px",
                      }}
                    >
                      -
                    </h3>
                    <h3
                      style={{
                        marginLeft: "20px",
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        fontSize: "14px",
                      }}
                    >
                      ${item?.advanced_settings?.cart_max_amount}
                    </h3>
                  </div>
                  {/* <div className="switch-container">
                                    <div className="switch-position-container">
                                        <h3 className='promotion-list-title' style={{ paddingTop: "5px", fontFamily: 'Poppins', fontWeight: '500', fontSize: '12px', color: '#201C1C70' }}>One Per Cart</h3>
                                        <p className='promotion-list-sub-title'>Customers can only redeem this promo  once per order</p>
                                    </div>
                                    <Switch checked={isSwitchOn} />
                                </div> */}
                  <h3
                    className="promotion-list-title"
                    style={{
                      paddingTop: "20px",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    Customers rules
                  </h3>
                  <h3
                    className="promotion-list-container-sub-title"
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      fontSize: "12px",
                      color: "#201C1C70",
                      marginTop: "4px",
                    }}
                  >
                    Audience ( App Only )
                  </h3>
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      fontSize: "12px",
                      marginTop: "4px",
                    }}
                  >
                    All Customers
                  </p>
                  {/* <div className="switch-container">
                                    <div className="switch-position-container">
                                        <h3 className='promo-item-switch-btn' style={{ paddingTop: "10px", fontFamily: "Poppins", fontWeight: "600" }} >Single use ( App Only )</h3>
                                        <p className='promo-item-switch-btn-sub'>Customer can only redeem this promo code</p>
                                    </div>
                                    <Switch checked={isSwitchOn} />
                                </div> */}
                  <div className="switch-container">
                    <div className="switch-position-container">
                      <h3
                        className="promo-item-switch-btn"
                        style={{
                          paddingTop: "5px",
                          fontFamily: "Poppins",
                          fontWeight: "600",
                        }}
                      >
                        Limit total uses
                      </h3>
                      <p className="promo-item-switch-btn-sub">
                        Limit how many times this promo code can be used
                      </p>
                      <h3
                        className="promo-item-switch-btn"
                        style={{
                          paddingTop: "10px",
                          fontFamily: "Poppins",
                          fontWeight: "600",
                          fontSize: "14px",
                        }}
                      >
                        {item?.advanced_settings?.type_of_uses === null ||
                        item?.advanced_settings?.type_of_uses < 1
                          ? "UNLIMITED"
                          : item?.advanced_settings?.type_of_uses}
                      </h3>
                    </div>
                    {/* <Switch checked={isSwitchOn} /> */}
                  </div>
                  <div style={{ marginTop: "0px" }}>
                    <h3
                      className="promotion-list-title"
                      style={{
                        paddingTop: "20px",
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        fontSize: "12px",
                      }}
                    >
                      Duration
                    </h3>
                    <p className="promotion-list-sub-title">
                      Run this promo for a limited time between specific
                      dates(i.e Happy Sale)
                    </p>
                  </div>
                  {item?.advanced_settings?.start_date &&
                  item?.advanced_settings?.end_date ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "0px",
                      }}
                    >
                      <h3
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "600",
                          fontSize: "14px",
                        }}
                      >
                        {moment(item?.advanced_settings?.start_date).format(
                          "MMMM Do YYYY, h:mm a"
                        )}
                      </h3>
                      <h3
                        style={{
                          marginLeft: "20px",
                          fontFamily: "Poppins",
                          fontWeight: "600",
                          fontSize: "14px",
                        }}
                      >
                        -
                      </h3>
                      <h3
                        style={{
                          marginLeft: "20px",
                          fontFamily: "Poppins",
                          fontWeight: "600",
                          fontSize: "14px",
                        }}
                      >
                        {moment(item?.advanced_settings?.end_date).format(
                          "MMMM Do YYYY, h:mm a"
                        )}
                      </h3>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "0px",
                      }}
                    >
                      <h3
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "600",
                          fontSize: "14px",
                        }}
                      >
                        No Duration
                      </h3>
                    </div>
                  )}
                  <button
                    onClick={() => HandlePromoCodeUpdate(item.id)}
                    className="common-button-blue"
                    style={{
                      height: "40px",
                      width: "260px",
                      transition: "background-color 0.3s",
                    }}
                    onMouseEnter={(e) => {
                      (e.target as HTMLButtonElement).style.backgroundColor =
                        "#6d43e57D";
                    }}
                    onMouseLeave={(e) => {
                      (e.target as HTMLButtonElement).style.backgroundColor =
                        "#8B5CFF";
                    }}
                  >
                    Edit
                  </button>
                </Box>
              );
            }
          )}
        </>
      </Modal>
      <ConfirmationModal
        open={deletePromoCde}
        onConfirm={handleDeleteButtonClick}
        onCancel={() => setDeletepromoCode(false)}
        headerText="Are you sure?"
        bodyText="You want to Delete Promo Code"
      />
    </Fragment>
  );
};

export default PromoCodeTable;
