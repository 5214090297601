import { Box, Button, Grid, OutlinedInput, Typography } from '@mui/material';
import MuiDialog from './MuiDialog';
import { Fragment, useState } from 'react';
import { useAppSelector } from '../../Hooks';

const PasswordSent = () => {
    const { emailSelected } = useAppSelector((state) => state.Auth);
    return (
        <Fragment>
            <Grid container direction="column" alignItems="center" justifyContent="center" height="210px">
                <Typography variant="h4" style={{ color: "#8B5CFF" }}>
                    Reset Password
                </Typography>
                <br></br>
                <Typography variant="body1">
                    Reset Password link has been sent to <b style={{ color: "#8B5CFF" }}>{emailSelected}</b>
                </Typography>
            </Grid>
        </Fragment>

    )
}

export default PasswordSent