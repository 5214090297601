import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) =>
    createStyles({
        popoverContainer: {
            borderRadius: "8px",
            display: "inline-flex",
            padding: "10px",
            alignItems: "flex-start",
            gap: "10px",
            border: "1px solid rgba(32, 28, 28, 0.08)",
            background: "#FFF",
          },
          contentContainer: {
            display: "flex",
            padding: "0px 10px 10px 10px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "6px",
            borderRadius: "8px",
            border: "1px solid rgba(32, 28, 28, 0.08)",
            width: "100%",
          },
          headerContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          },
          menuHeaderTitle: {
            color: "rgba(32, 28, 28, 0.72)",
            fontFamily: "Poppins",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "16px",
          },
          menuContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "8px",
          },
          scrollContainer: {
            width: "100%",
            height: "200px",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundClip: "padding-box",
              backgroundColor: "#8B5CFF",
              borderRadius: 9999,
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundClip: "padding-box",
              backgroundColor: "#8b5cff66",
              borderRadius: 9999,
            },
          },
          menuTitle: {
            color: "black",
            leadingTrim: "both",
            textEdge: "cap",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "16px",
            textTransform: "capitalize",
          },
          scrollSubContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          },
          containerMenu: {
            display: "flex",
            alignItems: "center",
            gap: "6px",
          },
        ScreenMain: {
            display: "inline-flex",
            paddingBottom: "0px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "24px",
            width: '100%'
        },
        HeaderMain: {
            display: "flex", alignItems: "flex-start", gap: "8px",
        },
        OverviewMain: {
            // display: "flex",
            // flexDirection: "column",
            // alignItems: "flex-start",
            gap: "12px",
            width: '80%',
            height: 400
        },
        CardMain: {
            height: "156px",
            display: "flex",
            // padding: "20px 32px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "23px",
            backgroundColor: "#FFFFFF",
            borderRadius: '8px',
            border: '1px solid #201C1C0A'
            // border: '1px solid #000'
        },
        CardInner: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: 148,
            // gap: "16px",
            cursor: "pointer"
        },
        IconOuter: {
            display: "flex",
            padding: "14px",
            alignItems: "flex-start",
            gap: "10px",
            borderRadius: "45px",
            background: "#FBFBFB",
        },
        TextOuter: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
        },
        DialogMain: {
            display: "inline-flex",
            padding: "32px 16px",
            flexDirection: "column",
            alignItems: "center",
            gap: "24px",
            borderRadius: "24px",
            background: "#FFFFFF",
            width: "100%"
        },
        DialogScrollSection: {
            width: '100%',
            overflow: 'auto',
            padding: '15px',
            '&::-webkit-scrollbar': {
                width: 8,
            },
            "&::-webkit-scrollbar-track": {
                borderRadius: "50px",
                backgroundColor: theme?.palette?.orange?.light,
            },
            "&::-webkit-scrollbar-thumb": {
                border: '2px solid rgba(0, 0, 0, 0)',
                backgroundClip: 'padding-box',
                borderRadius: 9999,
                backgroundColor: theme?.palette?.orange?.light,
            },
            "&::-webkit-scrollbar-thumb:hover": {
                border: '2px solid rgba(0, 0, 0, 0)',
                backgroundClip: 'padding-box',
                borderRadius: 9999,
                backgroundColor: theme?.palette?.orange?.main,
                transition: 'ease-in 1s'
            },
        },
        CloseButtonMain: {
            display: "flex",
            alignItems: "center",
            gap: "16px",
        },
        CloseButton: {
            display: "flex",
            padding: "4px 3px",
            alignItems: "flex-start",
            gap: "10px",
            borderRadius: "33px",
            backgroundColor: theme?.palette?.error?.light,
            cursor: 'pointer'
        },
        SubscriptionMain: {
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            alignItems: "flex-start"
        },
        FormLable: {
            fontWeight: 500,
            lineHeight: 'normal'
        },
        SubcriptionFormMain: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "8px",
            width: '100%'
        },
        TableSectionMain: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "20px",
            width: "100%"
        },
        TableTitle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: "100%"
        },
        TableMain: {
            // display: "flex",
            padding: "16px",
            // flexDirection: "column",
            // alignItems: "flex-start",
            // gap: "32px",
            borderRadius: "16px",
            backgroundColor: "#FFFFFF",
            // width: "123`%"
        },
        summaryContent: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        TableHeadTitle: {
            color: "#201C1C52",
            fontFamily: "Poppins",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "16px",
            textTransform: "uppercase",
            width: "max-content",
            '&.Mui-active': {
                color: "#201C1C52",
                fontFamily: "Poppins",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "16px",
                textTransform: "uppercase",
            },
            '& .MuiTableSortLabel-icon': {
                color: `${"#201C1C52"} !important`,
                fontSize: "24px",
            },
            '&:hover': {
                color: "#201C1C52",
            }
        },
        DashboarBodyCell: {
            textTransform: 'uppercase',
            lineHeight: "16px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
        },
        OverviewMenu: {
            border: `1px solid ${"#201C1C52"}`,
            display: "flex",
            padding: "10px 16px",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            // gap: "8px",
            borderRadius: "8px",
            cursor: 'pointer',
            height: '40px',
        },
        DasboardFilterMain: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "10px",
        },
        DasboardFilterItem: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "6px",
        },
        UserBodyCell: {
            fontWeight: 400,
            lineHeight: "16px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
        },
        root: {
            width: "32.06vw",
            "& .MuiOutlinedInput-input": {
                color: "#201C1CB8"
            },
            "& .MuiInputLabel-root": {
                color: "#201C1CB8"
            },
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: "#201C1CB8"
            },
            "& .MuiInputBase-input-MuiOutlinedInput-input": {
                padding: '0px'
            },  
        },

        paginationLinkStyle: {
            // backgroundColor: "#b90000",
            borderRadius: "5px",
      
            // "&:hover": {
            //     backgroundColor: "#772e2e",
            // },
      
            "&:active": {
              backgroundColor: "#772e2e",
            },
          },
          paginationPrevNextBtn: {
            "& .css-tnzq9a-MuiSvgIcon-root-MuiPaginationItem-icon": {
              backgroundColor: "#8B5CFF",
              color: "white",
              width: "2em",
              height: "2em",
              borderRadius: "50%",
              margin: "0 1px"
            },
            "& .css-17wtj0y-MuiButtonBase-root-MuiPaginationItem-root.Mui-disabled": {
              backgroundColor: "#8B5CFF",
              color: "white",
              width: "1.4em",
              height: "1.4em",
              borderRadius: "50%",
            },
            "& .css-psyrsv-MuiButtonBase-root-MuiPaginationItem-root.Mui-disabled": {
              backgroundColor: "#8B5CFF",
              color: "white",
              borderRadius: "50%",
            },
            "& .css-1hxrwmy-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected": {
                backgroundColor: "#8B5CFF",
                color: "white",
            },
            "& .css-1oj2twp-MuiPagination-root": {
                margin: "10px 0"
            },
            "& .css-wjh20t-MuiPagination-ul": {
                margin: "10px 0"
            }
          },

        calendarRoot: {
            width: "auto",
            "& .MuiPickersCalendarHeader-label": {
                color: "#201C1C",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
                fontFamily: "Poppins",
            },
            // "& .MuiSvgIcon-root":{
            //     color: "#201C1C",
            // },
            "& .css-1q04gal-MuiDateCalendar-root": {
                color: '#bbdefb',
                borderRadius: 0,
                borderWidth: 0,
                borderColor: '#2196f3',
                border: '0px solid',
                backgroundColor: "#FFFFFF",
                padding: 0,
                overflow: "hidden",
                width: "auto",
                maxHeight: "285px",
            },
            '& .MuiDayCalendar-weekDayLabel': {
                color: "#000000",
                textAlign: "center",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
                fontFamily: "Poppins",
            },
            '& .MuiPickersDay-root': {
                color: "#000000",
                textAlign: "center",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                fontFamily: "Poppins",
            },
            '& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected': {
                color: "#FFFFFF",
                textAlign: "center",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
                backgroundColor: "#8B5CFF",
                width: "33px",
                height: "31px",
                fontFamily: "Poppins",
            },
            '& .MuiPickersDay-today': {
                color: "#000000",
                textAlign: "center",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
                border: `1px solid #8B5CFF`,
                width: "33px",
                height: "31px",
                fontFamily: "Poppins",
            },
            '& .MuiDayCalendar-weekContainer': {
                alignItems: "center",
                fontFamily: "Poppins",
            },
            '& .MuiPickersDay-dayOutsideMonth': {
                color: "#201c1c70",
                textAlign: "center",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                fontFamily: "Poppins",
            },
            '& .MuiYearCalendar-root': {
                flexWrap: "wrap",
                overflowY: "auto",
                height: "100%",
                padding: "0 0px",
                width: "auto",
                maxHeight: "280px",
                boxSizing: "border-box",
                position: "relative",
                fontFamily: "Poppins",
            },
            '& .MuiPickersYear-yearButton.Mui-selected': {
                color: "#FFFFFF",
                textAlign: "center",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
                backgroundColor: 'red', // palette?.primary?.main,
                fontFamily: "Poppins",
            },
            '& .MuiPickersYear-yearButton': {
                color: "#919EAB", /// 
                textAlign: "center",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                fontFamily: "Poppins",
                // backgroundColor: 'red' // palette?.primary?.main,
            },
            '& .MuiPickersYear-yearButton.Mui-disabled': {
                color: "#201c1c70", /// #919EAB
                textAlign: "center",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                fontFamily: "Poppins",
                // backgroundColor: 'red' // palette?.primary?.main,
            },
        }
    }),

);