import { createSlice } from "@reduxjs/toolkit";

interface initialStateProps {
    CrossSellingList: any;
}

const initialState: initialStateProps = {
    CrossSellingList: []
}

const CrossSellingReducer = createSlice({
    name: 'CrossSellingListDetails',
    initialState,
    reducers: {
        setCrossSellingList: (state, action) => {
            state.CrossSellingList = action.payload
        }
    }
});

export const { setCrossSellingList } = CrossSellingReducer.actions;
export default CrossSellingReducer.reducer