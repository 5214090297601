import { createSlice } from "@reduxjs/toolkit";

interface initialStateProps {
  VendorList: Array<any>;
  vendorInfo: any;
  vendorBillingInfo: any;
  subsInfo: String;
}

const initialState: initialStateProps = {
  VendorList: [],
  vendorInfo: {},
  vendorBillingInfo: [],
  subsInfo: "",
};

const vendorDetailsReducer = createSlice({
  name: "vendorDetails",
  initialState,
  reducers: {
    setVendorDetails: (state, action) => {
      state.VendorList = action.payload;
    },
    setVendorInfo: (state, action) => {
      state.vendorInfo = action.payload;
    },
    setVendorBillingInfo: (state, action) => {
      state.vendorBillingInfo = action.payload;
    },
    setSubsInfo: (state, action) => {
      state.subsInfo = action.payload;
    },
    clearVendorInfo: (state) => {
      state.subsInfo = "";
    },
  },
});

export const {
  setVendorDetails,
  setVendorInfo,
  setVendorBillingInfo,
  setSubsInfo,
  clearVendorInfo,
} = vendorDetailsReducer.actions;
export default vendorDetailsReducer.reducer;
