import { Box, Modal, Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
import Refresh from "../../../assets/images/refresh.png";
import Cross from "../../../assets/images/Cross.png";

function ModifierModal({
  editModifier,
  closeModal,
  modifier,
  setModifier,
}: any) {
  return (
    <Modal
      open={editModifier}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          //   bottom: editAllergen ? "0" : "-100%",
          transition: "bottom 0.3s ease-in-out",
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "47.73vw",
          // height: "86.74vh",
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          padding: "16px",
          borderWidth: 0,
          overflowY: "auto",
        }}
      >
        <style>
          {`:: -webkit - scrollbar { width: 6px; } :: -webkit - scrollbar - track { display: none; } :: -webkit - scrollbar - thumb { background- color: transparent;
        }`}
        </style>
        <div className="import-title-container">
          <h3 className="import-title">Edit Modifier</h3>
          <div className="category-items-btn" style={{ display: "flex" }}>
            <button className="reimport-btn">
              <img
                src={Refresh}
                alt="refresh"
                style={{ height: "16px", width: "16px", marginRight: "10px" }}
              />
              Reimport
            </button>
            {/* <button className="delete-btn-item">Delete</button> */}
            <img
              onClick={closeModal}
              src={Cross}
              alt="Cross"
              style={{
                width: "30px",
                height: "32px",
                backgroundColor: "#F34A4A29",
                borderRadius: "33px",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        <div className="menu-details">
          <h3 className="menu-title-header">General Details</h3>
          <h3 className="menu-heading">Name</h3>
          <input
            placeholder="Menu Name"
            value={modifier?.name}
            onChange={(e: any) =>
              setModifier({ ...modifier, name: e.target.value })
            }
            style={{
              marginTop: "8px",
              width: "44.94vw",
              height: "7.20vh",
              borderRadius: "16px",
              borderWidth: "1px",
              borderColor: "#201C1C3D",
              padding: "10px 16px 10px 16px",
              outline: "none",
              fontWeight: "400",
              fontSize: "14px",
              fontFamily: "Poppins",
            }}
          />
          <p className="input-sub-text">
            This text is the name of the category that will show up in your
            miniosks.
          </p>
          <FormControlLabel
            control={
              <Checkbox
                value={modifier?.status === 0 ? false : true}
                onChange={(e: any) =>
                  setModifier({
                    ...modifier,
                    status: e.target.checked === true ? 1 : 0,
                  })
                }
              />
            }
            label="Show this modifier in miniosks"
            color="#201C1C"
            className="check-box"
            style={{
              fontWeight: "500",
              fontSize: "12px",
              fontFamily: "Poppins",
              height: "20px",
              marginTop: "16px",
            }}
          />
          <p className="input-sub-text">
            Select this checkbox to show this modifer in your miniosks. If
            disabled, the modifer and all of its children will be hidden.
          </p>
          <h3 className="menu-title-header">Photo</h3>
          <p className="title-sub-text">
            This uploaded image will show up in the minioskfor this category,
            depending on any Styling options you select. For best performance,
            we recommend a maximum resolution of 1000 x 1000 pixels.
          </p>
          <div
            className="photo-container"
            style={{
              marginTop: "14px",
              width: "152.44px",
              height: "152.44px",
              // backgroundImage: `url(${selectedImage?.image_path})`,
            }}
          >
            {modifier?.image_url &&
              (typeof modifier?.image_url === "string" ? (
                // If it's a URL
                <img
                  src={modifier.image_url}
                  alt="Uploaded"
                  style={{ height: "150px", width: "152.44px" }}
                />
              ) : (
                // If it's a file
                <img
                  src={URL.createObjectURL(modifier.image_url)}
                  alt="Uploaded"
                  style={{ height: "150px", width: "152.44px" }}
                />
              ))}
          </div>
        </div>
        <div style={{ marginTop: "32px", marginBottom: "14PX" }}>
          <label
            htmlFor="imageInput"
            className="common-button-blue"
            style={{
              paddingLeft: "32px",
              paddingRight: "32px",
              paddingTop: "16px",
              paddingBottom: "16px",
            }}
          >
            Upload Photo
          </label>
          <input
            type="file"
            id="imageInput"
            onChange={(e) =>
              setModifier({
                ...modifier,
                image_url: e.target.files?.[0],
              })
          }
            style={{ display: "none" }}
          />
        </div>
        {/* <button className="common-button-blue">Upload Photo</button> */}
        <h3 className="menu-title-header" style={{ marginTop: "16px" }}>
          Pricing Details
        </h3>
        <h3 className="menu-heading">Price</h3>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            paddingTop: "8px",
          }}
        >
          <input
            placeholder="Menu Name"
            value={
              typeof modifier?.price_money === "string"
                ? JSON.parse(modifier?.price_money).amount / 100
                : modifier?.price_money?.amount / 100
            }
            onChange={(e: any) =>
              setModifier({ ...modifier, name: e.target.value })
            }
            style={{
              width: "44.94vw",
              height: "7.20vh",
              borderRadius: "8px",
              borderWidth: "1px",
              borderColor: "#201C1C3D",
              padding: "10px 16px 10px 16px",
              outline: "none",
              fontWeight: "400",
              fontSize: "14px",
              fontFamily: "Poppins",
            }}
          />
          <div style={{ position: "absolute", right: "10px" }}>
            <h3
              style={{
                margin: 0,
                fontWeight: "500",
                fontSize: "14px",
                color: "#201C1C",
                fontFamily: "Poppins",
              }}
            >
              $
            </h3>
          </div>
        </div>
        {/* <FormControlLabel
          control={<Checkbox />}
          label="Custom Price"
          color="#201C1C"
          className="check-box"
          style={{
            fontWeight: "500",
            fontSize: "12px",
            fontFamily: "Poppins",
            height: "20px",
            marginTop: "16px",
          }}
        />
        <p className="input-sub-text">
          Most items are set to a fixed price. You can enable this setting to
          allow customers to type in a custom price.
        </p>
        <FormControlLabel
          control={<Checkbox />}
          label="Pre-selected this Modifier"
          color="#201C1C"
          className="check-box"
          style={{
            fontWeight: "500",
            fontSize: "12px",
            fontFamily: "Poppins",
            height: "20px",
            marginTop: "16px",
          }}
        />
        <p className="input-sub-text">
          When enabled, this modifier will be initially selected by default.
        </p> */}

        <div style={{ flexDirection: "row" }}>
          <button className="common-button-blue">Apply</button>
          <button onClick={closeModal} className="common-button-cancel">
            Cancel
          </button>
        </div>
      </Box>
    </Modal>
  );
}

export default ModifierModal;
