import { createSlice } from '@reduxjs/toolkit';

interface initialStateProps {
    ItemsListDataDetails: any,
    ItemTagEditName : any

}

const initialState: initialStateProps = {
    ItemsListDataDetails: [],
    ItemTagEditName: '',

}

const itemsListDetailsReducer = createSlice({
    name: 'itemsListDetails',
    initialState,
    reducers: {
        setItemsList: (state, action) => {
            state.ItemsListDataDetails = action.payload
        },

        setItemTagEditName: (state, action) => {
            state.ItemTagEditName = action.payload
        }
      
    },
});

export const { setItemsList, setItemTagEditName } = itemsListDetailsReducer.actions;
export default itemsListDetailsReducer.reducer;