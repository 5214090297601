import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

function ConfirmationModal({ open, onConfirm, headerText, bodyText, onCancel }: any) {
    const handleClose = () => {
        onConfirm();
    };
    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '44.8vw', bgcolor: 'rgba(255, 255, 255, 0.9)', borderRadius: '8px', boxShadow: 1, padding: '16px' }}>
                <div className='success-container'>
                    <h2 className='success-header'>{headerText}</h2>
                    <p className='success-desc'>{bodyText}</p>
                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                        <button onClick={onConfirm} style={{ width: "16.69vw", height: "5.89vh", backgroundColor: "#8B5CFF", borderRadius: 8, border: 0, fontSize: "14px", fontWeight: "500", fontFamily: 'Poppins', color: "#FFFFFF", marginTop: "24px" }} >Yes
                        </button>
                        <button onClick={onCancel} style={{ width: "16.69vw", height: "5.89vh", backgroundColor: "#FFFFFF", borderRadius: 8, border: 0, fontSize: "14px", fontWeight: "500", fontFamily: 'Poppins', color: "#F34A4A", marginTop: "24px", marginLeft: "16px" }}>No
                        </button>
                    </div>
                </div>
            </Box>
        </Modal>
    );
}

export default ConfirmationModal;

