import React, { Fragment, useEffect, useState } from "react";
import { useStyles } from "./UseStyles";
import { visuallyHidden } from "@mui/utils";
import {
  Box,
  Table,
  TableBody,
  useTheme,
  TableCell,
  TableContainer,
  TableHead,
  Pagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import ApiServices from "../helpers/ApiServices";
import HelperModule from "../helpers/HelperModule";
import DashboardFilter from "./DashboardFilter";
import DownloadSummary from "./DownloadSummary";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { setSalesTableList } from "../store/reducers/InsightsReducer";
import Modal from "@mui/material/Modal";
import Download from "../assets/images/Download.png";
import Cross from "../assets/images/Cross.png";
import DataTable from "./DataTable";

let UserCodeData: any;
function createData(
  date: any,
  order_id: any,
  items: any,
  subtotal: any,
  taxes: any,
  discount: any,
  tips: any,
  total: any,
  payment_mode: any
) {
  return {
    date,
    order_id,
    items,
    subtotal,
    taxes,
    discount,
    tips,
    total,
    payment_mode,
  };
}

const characters = "Heading";
function generateString(length: any) {
  let result = " ";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
function generateNumber(length: any) {
  let result = " ";
  const charactersLength = "0123456789".length;
  for (let i = 0; i < length; i++) {
    result += "0123456789".charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);

  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis?.map((el) => el[0]);
}

const headCells: any = [
  {
    id: "created_at",
    disablePadding: true,
    label: "Date",
  },
  {
    id: "reference_id",
    disablePadding: false,
    label: "order ID",
  },
  {
    id: "item_count",
    disablePadding: false,
    label: "items",
  },
  {
    id: "net_amount",
    disablePadding: false,
    label: "Subtotal ($)",
  },
  {
    id: "tax_amount",
    disablePadding: false,
    label: "Taxes ($)",
  },
  {
    id: "discount_amount",
    disablePadding: false,
    label: "Discount (%)",
  },
  {
    id: "tip",
    disablePadding: false,
    label: "Tips ($)",
  },
  {
    id: "total",
    disablePadding: false,
    label: "Total ($)",
  },
  {
    id: "payment_mode",
    disablePadding: false,
    label: "Payment Mode",
  },
];

function EnhancedTableHead(props: any) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells?.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              className={classes.TableHeadTitle}
              active={true} // orderBy === headCell.id
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              IconComponent={(event) => (
                <ArrowDropDownRoundedIcon
                  {...event}
                  sx={{ color: "#201C1C52" }}
                />
              )}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface SalesTableProps {}

const SalesTable = () => {
  const classes = useStyles();
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const { salesTableList } = useSelector((state: any) => state.insightsDetails);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<any>("date");
  const [SalesTableData, setSalesTableData]: any = useState([]);
  const [selectedMenuCode, setSelectedMenuCode] = useState<string | null>(null);
  const [selectedRow, setSelectedRow]: any = useState(null);

  const [initLoading, setInitLoading] = useState(true);

  const [recordsCount, setRecordsCount] = useState(0);

  const [controller, setController] = useState({
    page: 1,
    rowsPerPage: 10,
    field: "",
    order: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = salesTableList.data;

  const nPages = Math.ceil(recordsCount / controller.rowsPerPage);
  const [search, setSearch] = useState("");
  const [filteredValues, setFilteredValues] = useState([]);

  const [loading, setLoading] = React.useState(true);
  const [isOrderLoading, setOrderLoading] = useState(false);
  const [webSettingsModal, setWebSettingsModal] = React.useState(false);
  const [ordersDetails, setOrdersDetails]: any = useState({});
  const [orderId, setOrderId] = useState("");
  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    const UserCode = localStorage.getItem("UserCode");
    UserCodeData = UserCode;
  }, []);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const OrderDetails = async (orderId: string) => {
    try {
      setOrderLoading(true);
      const response = await HelperModule().getMethod(
        ApiServices.base_URL +
          ApiServices.Order_details +
          `?order_id=${orderId}`
      );
      setOrderLoading(false);
      if (response.status === true) {
        setOrdersDetails(response);
      } else {
        console.log("OrderDetails Error");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    OrderDetails(orderId);
  }, [orderId]);

  const downloadInvoice = async () => {
    try {
      const response = await fetch(
        `https://api.miniosk.com/api/order/download-order-receipt/${orderId}`
      );
      if (!response.ok) {
        throw new Error(
          `Failed to download the invoice. Response status: ${response.status}`
        );
      }
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Invoice_${orderId}.pdf`);
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        link.parentNode?.removeChild(link);
      }, 100);
    } catch (error) {
      console.error("Error occurred while downloading the invoice:", error);
    }
  };

  const HandleWebSettings = (orderId: string) => () => {
    setWebSettingsModal(true);
    setOrderId(orderId);
  };

  const InsightsSalesTable = async (selectedMenuCode: any, page: any) => {
    setLoading(true);
    try {
      const menuCodes = selectedMenuCode || "";
      const response = await HelperModule().getMethod(
        ApiServices.base_URL +
          ApiServices.insights_sales_table +
          `?user_code=${UserCodeData}&menu_codes=${menuCodes}&page=${controller.page}&search=${search}&field=${controller.field}&order=${controller.order}`
      );
      if (response.status === true) {
        dispatch(setSalesTableList(response.result));
        setSalesTableData(response.result?.data);
        setRecordsCount(response?.result?.total);
        setLoading(false);
      } else {
        console.log("Error Response", response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMenuCodeSelectionWrapper = (selectedMenuCode: string[]) => {
    InsightsSalesTable(selectedMenuCode, 1);
  };

  useEffect(() => {
    if (selectedMenuCode === null || selectedMenuCode === "") {
      InsightsSalesTable(selectedMenuCode, 1);
      setLoading(false);
    }
    if (selectedMenuCode !== null) {
      InsightsSalesTable(selectedMenuCode, 1);
      setLoading(false);
    }
  }, [selectedMenuCode]);

  useEffect(() => {
    const getData = setTimeout(() => {
      if (initLoading) {
        setInitLoading(false);
        return;
      }
      setController({ ...controller, page: 1 });
    }, 1000);
    return () => clearTimeout(getData);
  }, [search]);

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
    setController({
      ...controller,
      page: value,
    });
  };

  useEffect(() => {
    if (loading) {
      return;
    }
    InsightsSalesTable(selectedMenuCode, controller.page);
  }, [controller]);


  const visibleRows = React.useMemo(() => {
    const dataToDisplay =
      filteredValues.length > 0 ? filteredValues : salesTableList?.data;
    const sortedData = stableSort(dataToDisplay, getComparator(order, orderBy));
    // return sortedData.slice(indexOfFirstRecord, indexOfLastRecord); // Limiting to 10 records
    return salesTableList?.data;
  }, [order, orderBy, salesTableList?.data, filteredValues]);

  

  return (
    <>
      <>
        <div className="sub-container" style={{ backgroundColor: "#FBFBFB" }}>
          <h2 className="sales-header">Sales</h2>
          <div className="sales-btn-container">
            <div className="btn-pad">
              <DashboardFilter
                onSelectMenuCode={handleMenuCodeSelectionWrapper}
              />
            </div>
            <DownloadSummary />
          </div>
        </div>
      </>
      {/* <DataTable
        order={order}
        orderBy={orderBy}
        nPages={nPages}
        currentRecords={currentRecords}
        visibleRows={visibleRows}
        controller={controller}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        setController={setController}
        setCurrentPage={setCurrentPage}
        headCells={headCells}
        loading={loading}
      /> */}
      {loading ? (
        <div className="Loader-container">
          <CircularProgress disableShrink  sx={{color: '#8B5CFF'}} />
        </div>
      ) : (
        <Fragment>
          <Box className={classes.TableMain}>
            <TableContainer>
              <Table sx={{ minWidth: 750 }} size={"medium"}>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={visibleRows?.length}
                />

                <TableBody>
                  {visibleRows?.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        <Typography variant="body2">No Data Found</Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    currentRecords?.map((row: any, index: any) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell align="left">
                            <Typography
                              variant="body2"
                              className={classes.DashboarBodyCell}
                              sx={{
                                fontWeight: 400,
                                fontSize: "12px",
                                fontFamily: "Poppins",
                                width: "142px",
                              }}
                            >
                              {new Date(row?.created_at)
                                .toLocaleString()
                                .replace(/,/g, " ")}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              onClick={HandleWebSettings(row.id)}
                              variant="body2"
                              className={classes.DashboarBodyCell}
                              sx={{
                                color: palette?.primary?.main,
                                fontWeight: 400,
                                fontSize: "12px",
                                fontFamily: "Poppins",
                              }}
                            >
                              {/* {row?.taxes?.map((tax:any) => tax.catalog_object_id).join(', ') || "N/A"} */}
                              {row?.reference_id || "N/A"}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="body2"
                              className={classes.DashboarBodyCell}
                              sx={{
                                fontWeight: 400,
                                fontSize: "12px",
                                fontFamily: "Poppins",
                              }}
                            >
                              {row?.item_count}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="body2"
                              className={classes.DashboarBodyCell}
                              sx={{
                                fontWeight: 400,
                                fontSize: "12px",
                                fontFamily: "Poppins",
                              }}
                            >
                              {row?.net_amount}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="body2"
                              className={classes.DashboarBodyCell}
                              sx={{
                                fontWeight: 400,
                                fontSize: "12px",
                                fontFamily: "Poppins",
                              }}
                            >
                              {row?.tax_amount}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="body2"
                              className={classes.DashboarBodyCell}
                              sx={{
                                fontWeight: 400,
                                fontSize: "12px",
                                fontFamily: "Poppins",
                              }}
                            >
                              {row?.discount_amount}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="body2"
                              className={classes.DashboarBodyCell}
                              sx={{
                                fontWeight: 400,
                                fontSize: "12px",
                                fontFamily: "Poppins",
                              }}
                            >
                              {row?.tip}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="h6"
                              className={classes.DashboarBodyCell}
                              sx={{
                                fontWeight: 600,
                                fontSize: "14px",
                                fontFamily: "Poppins",
                              }}
                            >
                              {row?.total}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="h6"
                              className={classes.DashboarBodyCell}
                              sx={{
                                fontWeight: 600,
                                fontSize: "14px",
                                fontFamily: "Poppins",
                              }}
                            >
                              {row?.payment_mode}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {nPages > 1 && (
              <Pagination
                count={nPages}
                color="primary"
                size="small"
                onChange={handlePaginationChange}
                page={controller.page}
                className={classes.paginationPrevNextBtn}
              />
            )}
          </Box>

          <Modal
            open={webSettingsModal}
            onClose={() => {
              setWebSettingsModal(false);
              setOrdersDetails({});
              setOrderId("");
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                transition: "bottom 0.3s ease-in-out",
                position: "absolute" as "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                borderRadius: "24px",
                boxShadow: 24,
                padding: "16px",
                borderWidth: 0,
                minWidth: "50vw",
                minHeight: "50vh",
              }}
            >
              <style>
                {`::-webkit-scrollbar {width: 6px;} ::-webkit-scrollbar-track {   display: none; } ::-webkit-scrollbar-thumb {   background-color: transparent; }`}
              </style>
              <div className="import-title-container">
                <h3 className="import-title">Order Details</h3>
                <div
                  style={{
                    backgroundColor: "#F34A4A29",
                    height: "27.06px",
                    width: "27.06px",
                    borderRadius: "27.91px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img
                    onClick={() => setWebSettingsModal(false)}
                    src={Cross}
                    alt="Cross"
                    style={{ width: "24px", height: "24px", cursor: "pointer" }}
                  />
                </div>
              </div>

              {isOrderLoading ? (
                <Box sx={{ textAlign: "center", mt: 5 }}>
                  <CircularProgress size={30}  sx={{color: '#8B5CFF'}} />
                </Box>
              ) : ordersDetails?.order_details?.reference_id ? (
                <>
                  <Box sx={{ maxHeight: "60vh", overflowY: "auto" }}>
                    <h3 className="menu-heading" style={{ paddingTop: "10px" }}>
                      Order Id
                    </h3>

                    <h3
                      style={{
                        margin: 0,
                        fontSize: "14px",
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        color: "#201C1C",
                      }}
                    >
                      {ordersDetails?.order_details?.reference_id?.toUpperCase() ||
                        "-"}
                    </h3>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "24px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "24px",
                        }}
                      >
                        {ordersDetails?.order_details?.in_order.map(
                          (orderItem: any, index: number) => (
                            <div key={index} style={{ paddingTop: "24px" }}>
                              <h3
                                className="menu-heading"
                                style={{ color: "#201C1C70" }}
                              >
                                Ordered
                              </h3>
                              <div style={{ width: "21.88vw" }}>
                                <h3
                                  style={{
                                    margin: 0,
                                    fontSize: "14px",
                                    fontFamily: "Poppins",
                                    fontWeight: "600",
                                    color: "#201C1C",
                                  }}
                                >
                                  {orderItem?.item?.name} -${" "}
                                  {orderItem?.item_price}
                                </h3>
                              </div>
                            </div>
                          )
                        )}
                      </div>

                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {ordersDetails?.order_details?.in_order.map(
                          (orderItem: any, index: number) => (
                            <div
                              key={index}
                              style={{ paddingTop: "24px", marginLeft: "32px" }}
                            >
                              <h3
                                className="menu-heading"
                                style={{ color: "#201C1C70" }}
                              >
                                Add ons
                              </h3>
                              <div style={{ width: "21.88vw" }}>
                                {orderItem.in_order_modifiers.length > 0 && (
                                  <h3
                                    style={{
                                      margin: 0,
                                      fontSize: "14px",
                                      fontFamily: "Poppins",
                                      fontWeight: "600",
                                      color: "#201C1C",
                                    }}
                                  >
                                    {orderItem.in_order_modifiers.map(
                                      (modifier: any, index: number) => (
                                        <span key={index}>
                                          {modifier?.modifier?.name} - ${" "}
                                          {modifier?.modifier?.price_money
                                            ?.amount / 100}
                                          {index !==
                                            orderItem.in_order_modifiers
                                              .length -
                                              1 && ", "}
                                        </span>
                                      )
                                    )}
                                  </h3>
                                )}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "24px",
                      }}
                    >
                      <div style={{ width: "21.88vw" }}>
                        <h3
                          className="menu-heading"
                          style={{ color: "#201C1C70" }}
                        >
                          Discount Amount
                        </h3>
                        <h3
                          style={{
                            margin: 0,
                            fontSize: "14px",
                            fontFamily: "Poppins",
                            fontWeight: "600",
                            color: "#201C1C",
                          }}
                        >
                          $ {ordersDetails?.order_details?.discount_amount}
                        </h3>
                      </div>
                      <div style={{ marginLeft: "56px" }}>
                        <h3
                          className="menu-heading"
                          style={{ color: "#201C1C70" }}
                        >
                          Tips
                        </h3>
                        <h3
                          style={{
                            margin: 0,
                            fontSize: "14px",
                            fontFamily: "Poppins",
                            fontWeight: "600",
                            color: "#201C1C",
                          }}
                        >
                          $ {ordersDetails?.order_details?.tip}
                        </h3>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "24px",
                      }}
                    >
                      <div style={{ width: "21.88vw" }}>
                        <h3
                          className="menu-heading"
                          style={{ color: "#201C1C70" }}
                        >
                          Tax
                        </h3>
                        <h3
                          style={{
                            margin: 0,
                            fontSize: "14px",
                            fontFamily: "Poppins",
                            fontWeight: "600",
                            color: "#201C1C",
                          }}
                        >
                          $ {ordersDetails?.order_details?.tax_amount}
                        </h3>
                      </div>
                      <div style={{ marginLeft: "56px" }}>
                        <h3
                          className="menu-heading"
                          style={{ color: "#201C1C70" }}
                        >
                          Total Amount
                        </h3>
                        <h3
                          style={{
                            margin: 0,
                            fontSize: "14px",
                            fontFamily: "Poppins",
                            fontWeight: "600",
                            color: "#201C1C",
                          }}
                        >
                          $ {ordersDetails?.order_details?.net_amount}
                        </h3>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "24px",
                      }}
                    ></div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "24px",
                      }}
                    >
                      <div style={{ width: "21.88vw" }}>
                        <h3
                          className="menu-heading"
                          style={{ color: "#201C1C70" }}
                        >
                          Billing
                        </h3>
                        <h3
                          style={{
                            margin: 0,
                            fontSize: "14px",
                            fontFamily: "Poppins",
                            fontWeight: "600",
                            color: "#201C1C",
                          }}
                        >
                          {new Date(ordersDetails?.order_details?.created_at)
                            .toLocaleString()
                            .replace(/,/g, " ")}
                        </h3>
                      </div>
                      <div style={{ marginLeft: "56px" }}>
                        <h3
                          className="menu-heading"
                          style={{ color: "#201C1C70" }}
                        >
                          Payment Type
                        </h3>
                        <h3
                          style={{
                            margin: 0,
                            fontSize: "14px",
                            fontFamily: "Poppins",
                            fontWeight: "600",
                            color: "#201C1C",
                          }}
                        >
                          {ordersDetails?.order_details?.payment_mode}
                        </h3>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "24px",
                      }}
                    >
                      <div style={{ width: "21.88vw" }}>
                        <h3
                          className="menu-heading"
                          style={{ color: "#201C1C70" }}
                        >
                          Payment Status
                        </h3>
                        <h3
                          style={{
                            margin: 0,
                            fontSize: "14px",
                            fontFamily: "Poppins",
                            fontWeight: "600",
                            color: "#201C1C",
                          }}
                        >
                          {ordersDetails?.order_details?.payment_status}
                        </h3>
                      </div>
                      <div style={{ marginLeft: "56px" }}>
                        <h3
                          className="menu-heading"
                          style={{ color: "#201C1C70" }}
                        >
                          Order Status
                        </h3>
                        <h3
                          style={{
                            margin: 0,
                            fontSize: "14px",
                            fontFamily: "Poppins",
                            fontWeight: "600",
                            color: "#201C1C",
                          }}
                        >
                          {ordersDetails?.order_status}
                        </h3>
                      </div>
                    </div>
                  </Box>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "24px",
                    }}
                  >
                    <button
                      onClick={downloadInvoice}
                      style={{
                        width: "179.86px",
                        height: "36.34px",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#8B5CFF",
                        color: "#FFFFFF",
                        borderWidth: 0,
                        fontWeight: "500",
                        fontSize: "12px",
                        fontFamily: "Poppins",
                        borderRadius: "8px",
                        transition: "background-color 0.3s",
                      }}
                      onMouseEnter={(e) => {
                        (e.target as HTMLButtonElement).style.backgroundColor =
                          "#6d43e57D";
                      }}
                      onMouseLeave={(e) => {
                        (e.target as HTMLButtonElement).style.backgroundColor =
                          "#8B5CFF";
                      }}
                    >
                      <img
                        src={Download}
                        alt="Download"
                        style={{
                          height: "12px",
                          width: "8px",
                          marginRight: "10px",
                        }}
                      />
                      Download Invoice
                    </button>
                  </div>
                </>
              ) : (
                <Typography sx={{ textAlign: "center" }}>
                  No records found.
                </Typography>
              )}
            </Box>
          </Modal>
        </Fragment>
      )}
    </>
  );
};

export default SalesTable;
