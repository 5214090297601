import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

function SuccessModal({ open, onClose, headerText, bodyText }:any) {
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '44.8vw', bgcolor: 'background.paper', borderRadius: '8px', boxShadow: 24, padding: '16px' }}>
        <div className='success-container'>
          <h2 className='success-header'>{headerText}</h2>
          <p className='success-desc'>{bodyText}</p>
          <button onClick={onClose} className='done-btn' style={{marginTop: '16px'}}>
            Done
          </button>
        </div>
      </Box>
    </Modal>
  );
}

export default SuccessModal;
