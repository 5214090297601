import { CircularProgress, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useSnackbar } from 'notistack';
import React, { Fragment, useEffect, useState } from 'react'
import { ChromePicker, ColorChangeHandler } from 'react-color';

import ApiServices from '../../helpers/ApiServices';
import HelperModule from '../../helpers/HelperModule';

import './ThemeStyle.css';

let UserCodeData: any
const Theme = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [listData, setListData]: any = useState([]);
    const [background_style, setBackground_style]: any = useState('')
    const [category_style, setCategory_style]: any = useState('')
    const [category_look, setCategory_look]: any = useState('')
    const [category_card_size, setCategory_card_size]: any = useState('')
    const [category_text, setCategory_text]: any = useState('')
    const [category_font, setCategory_font]: any = useState('')
    const [item_price_display, setItem_price_display]: any = useState('')
    const [item_text_style, setItem_text_style]: any = useState('')
    const [item_text_position, setItem_text_position]: any = useState('')
    const [item_card_size, setItem_card_size]: any = useState('')
    const [item_text, setItem_text]: any = useState('')
    const [item_name_font, setItem_name_font]: any = useState('')
    const [item_price_font, setItem_price_font]: any = useState('')
    const [item_variation_style, setItem_variation_style]: any = useState('')
    const [item_variation_size, setItem_variation_size]: any = useState('')
    const [modifier_style, setModifier_style]: any = useState('')
    const [modifier_size, setModifier_size]: any = useState('')
    const [primary_color_hex_code, setPrimary_color_hex_code]: any = useState(null)
    const [secondary_color_hex_code, setSecondary_color_hex_code]: any = useState(null)
    const [user_code, setUserCode]: any = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const UserCode = localStorage.getItem("UserCode")
        UserCodeData = UserCode
    }, [])


    const handleColorChange: ColorChangeHandler = (color: any, event: any) => {
        setPrimary_color_hex_code(color.hex);
    };

    const handleSecondaryColorChange: ColorChangeHandler = (color: any, event: any) => {
        setSecondary_color_hex_code(color.hex);
    };

    const ThemeDataInitial = async () => {
        setLoading(true)
        try {
            const response = await HelperModule().getMethod(ApiServices.base_URL + ApiServices.styling_theme_list + `?user_code=${UserCodeData}`);
            if (response.status === true) {
                setListData(response.stylingThemeList[0])
                setBackground_style(response.stylingThemeList[0].background_style)
                setCategory_style(response.stylingThemeList[0].category_style)
                setCategory_look(response.stylingThemeList[0].category_look)
                setCategory_card_size(response.stylingThemeList[0].category_card_size)
                setCategory_text(response.stylingThemeList[0].category_text)
                setCategory_font(response.stylingThemeList[0].category_font)
                // setEnable_card_shadows(response.stylingThemeList[0].enable_card_shadows)
                setItem_price_display(response.stylingThemeList[0].item_price_display)
                setItem_text_style(response.stylingThemeList[0].item_text_style)
                setItem_text_position(response.stylingThemeList[0].item_text_position)
                setItem_card_size(response.stylingThemeList[0].item_card_size)
                setItem_text(response.stylingThemeList[0].item_text)
                setItem_name_font(response.stylingThemeList[0].item_name_font)
                setItem_price_font(response.stylingThemeList[0].item_price_font)
                // setEnable_item_shadows(response.stylingThemeList[0].enable_item_shadows)
                setItem_variation_style(response.stylingThemeList[0].item_variation_style)
                setItem_variation_size(response.stylingThemeList[0].item_variation_size)
                setModifier_style(response.stylingThemeList[0].modifier_style)
                setModifier_size(response.stylingThemeList[0].modifier_size)
                // setShow_price_breakdown(response.stylingThemeList[0].show_price_breakdown)
                setPrimary_color_hex_code(response?.stylingThemeList[0]?.primary_color_hex_code)
                setSecondary_color_hex_code(response?.stylingThemeList[0]?.secondary_color_hex_code)
                setUserCode(response.stylingThemeList[0].user_code)
                setLoading(false)
            } else {
                enqueueSnackbar('Data not found', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }, style: { fontFamily: 'Poppins', fontWeight: '500' }, });
            }
        }
        catch (error) {
            console.log(error)
        }
        finally {
            setLoading(false)
        }
    };

    const ThemeData = async () => {
        const Params = {
            background_style: background_style,
            category_style: category_style,
            category_look: category_look,
            category_card_size: category_card_size,
            category_text: category_text,
            category_font: category_font,
            enable_card_shadows: listData?.enable_card_shadows,
            item_price_display: item_price_display,
            item_text_style: item_text_style,
            item_text_position: item_text_position,
            item_card_size: item_card_size,
            item_text: item_text,
            item_name_font: item_name_font,
            item_price_font: item_price_font,
            enable_item_shadows: listData?.enable_item_shadows,
            item_variation_style: item_variation_style,
            item_variation_size: item_variation_size,
            modifier_style: modifier_style,
            modifier_size: modifier_size,
            show_price_breakdown: listData?.show_price_breakdown,
            primary_color_hex_code: primary_color_hex_code,
            secondary_color_hex_code: secondary_color_hex_code,
            user_code: UserCodeData
        }
        try {
            const response = await HelperModule().postMethod(ApiServices.base_URL + ApiServices.styling_theme, Params);
            if (response.status === true) {
                enqueueSnackbar(response.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' }, style: { fontFamily: 'Poppins', fontWeight: '500' }, });
                ThemeDataInitial()
            } else if (response.status === false) {
                console.log(response)
            }
        }
        catch (error) {
            console.log("error catch", error)
        }
    }

    useEffect(() => {
        ThemeDataInitial()
    }, [])

    return (
        <Fragment>
            <div className='mainContainer'>
                {loading ?
                    <div className="Loader-container">
                        <CircularProgress disableShrink  sx={{color: '#8B5CFF'}}/>
                    </div>
                    :
                    <>
                        <h3 className='mian-content'>Theme</h3>
                        <p className='mian-sub-content'>Configure your miniosk's look and feel to match your brand school</p>
                        <div className='color-pick'>
                            <div>
                                <h1 className='display-content'>Primary Color</h1>
                                <p className='mian-sub-content'>This is the primary color used for the apps Background and various parts of the GUI</p>
                                <div style={{}}>
                                    <div className="color-picker-card" style={{ width: '80%' }} >
                                        <ChromePicker color={primary_color_hex_code || ''} onChange={handleColorChange} disableAlpha={true} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h2 className='display-content'>Secondary Color</h2>
                                <p className='mian-sub-content'>This is the Secondary color used for the apps Background and various parts of the GUI</p>
                                <div style={{}}>
                                    <div className="color-picker-card" style={{ width: '80%' }}>
                                        <ChromePicker color={secondary_color_hex_code || ''} onChange={handleSecondaryColorChange} disableAlpha={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div style={{ marginTop: '30px' }}>
                            <div>
                                <h1 className='advance-content'>Advanced</h1>
                                <p className='advance-sub-content'>You can use the settings in the section below to fully customize each part of the menu.</p>
                            </div>
                           
                        </div> */}
                        <div style={{ marginTop: '30px' }}>
                            <div className='cards'>

                                <FormControl style={{ marginTop: '16px', backgroundColor: 'white', width: '62%', height: 'auto' }}>
                                    <FormLabel id="demo-controlled-radio-buttons-group" style={{ color: "black", fontSize: "20px", fontWeight: "700", }}>Categories</FormLabel>
                                    <p className='cards-sub-content' style={{ lineHeight: "15px", marginTop: "20px", fontSize: "12px", margin: 0 }}>Use these options to change how categories look in the miniosk.</p>
                                    
                                </FormControl>
                                <p className='cards-title'>Category Look</p>
                                <FormControl style={{ backgroundColor: 'white', width: '62%', height: 'auto' }}>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={category_look}
                                        onChange={(event) => setCategory_look(event.target.value)}
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 14, fontFamily: 'Poppins' } }}
                                    >
                                        <FormControlLabel value="only_text" control={<Radio />} label={<Typography variant="body2" fontFamily={'Poppins'} fontSize={"14px"}>Only Text</Typography>} />
                                        <FormControlLabel value="only_image" control={<Radio />} label={<Typography variant="body2" fontFamily={'Poppins'} fontSize={"14px"}>Only Image</Typography>} />
                                        <FormControlLabel value="both" control={<Radio />} label={<Typography variant="body2" fontFamily={'Poppins'} fontSize={"14px"}>Both</Typography>} />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                        <button onClick={ThemeData} className="common-button-blue" style={{ transition: "background-color 0.3s" }} onMouseEnter={(e) => { (e.target as HTMLButtonElement).style.backgroundColor = "#6d43e57D"; }} onMouseLeave={(e) => { (e.target as HTMLButtonElement).style.backgroundColor = "#8B5CFF"; }}>Update</button>
                    </>}
            </div>
        </Fragment>
    )
}

export default Theme