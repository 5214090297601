import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  AppBar,
  Toolbar,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../Hooks";
import {
  setIsLogin,
  seteMailSelected,
} from "../../../src/store/reducers/AuthReducer";
import PasswordSent from "./PasswordSent";
import MuiDialog from "./MuiDialog";
import ApiServices from "../../helpers/ApiServices";
import HelperModule from "../../helpers/HelperModule";
import CircularProgress from "@mui/material/CircularProgress";

const CustomTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },

    "& .MuiInput-underline:after": {
      borderBottomColor: "#FBFBFB",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#FBFBFB",
      },

      "&:hover fieldset": {
        borderColor: "#FBFBFB",
      },

      "&.Mui-focused fieldset": {
        borderColor: "#FBFBFB",
      },
    },
  },
})(TextField);

const ForgetPassword = () => {
  const Navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [value, setValue]: any = useState();
  const IsLogin: any = true;
  const [isDialogOpen, setDialogOpen] = useState(false);
  // const toggleDialog = () => {
  //     setDialogOpen(!isDialogOpen);
  // }
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const Email = localStorage.getItem("email");
    setValue(Email);
  }, []);

  const toggleDialog = async () => {
    setLoading(true);
    const params = {
      email: value,
    };
    try {
      const response = await HelperModule().postMethod(
        ApiServices.base_URL + ApiServices.forget_password,
        params
      );
      if (response.status === true) {
        setDialogOpen(!isDialogOpen);
        dispatch(seteMailSelected(value));
      } else {
        console.log("Error");
        return [];
      }
    } catch (error) {
      console.log(error);
      return [];
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          height: "100vh",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "24px 16px",
            flexDirection: "column",
            alignItems: "center",
            gap: "24px",
            borderRadius: "32px",
            background: "#FFF",
            width: { xs: "70%", sm: "60%", md: "50%", lg: "40%", xl: "30%" }, // Decreased width even more
            overflow: "hidden",
          }}
        >
          <Typography
            sx={{
              color: "#201C1C",
              fontFamily: "poppins",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
            }}
          >
            Enter Email ID To Reset Password
          </Typography>

          <Box sx={{ backgroundColor: "transparent", width: "100%" }}>
            <Typography
              sx={{
                color: "#201C1C",
                fontFamily: "poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                pt: 0.5,
                pb: 0.5,
              }}
            >
              Email
            </Typography>

            <CustomTextField
              aria-readonly="true"
              type="email"
              value={value}
              placeholder="example@gmail.com"
              variant="outlined"
              fullWidth
              size="medium"
              InputProps={{
                inputProps: {
                  style: { borderRadius: "16px", backgroundColor: "#FBFBFB" },
                },

                style: { borderRadius: "16px", backgroundColor: "#FBFBFB" },
              }}
            />

            {!value
              ? null
              : !RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i).test(
                  value
                ) && <p style={{ color: "red" }}>* Enter Valid Email</p>}
          </Box>
          <Box
            sx={{
              paddingTop: 2,
              backgroundColor: "transparent",
              width: "100%",
            }}
          >
            <Button
              fullWidth
              size="large"
              onClick={toggleDialog}
              type="submit"
              style={{
                color: "#FFFFFF",
                textAlign: "center",
                fontSize: "14px",
                backgroundColor: "#8B5CFF",
                cursor: "pointer",
                borderRadius: "8px",
              }}
            >
              {loading ? "Loading..." : "Next"}
            </Button>
            <MuiDialog open={isDialogOpen} onClose={toggleDialog}>
              <PasswordSent />
            </MuiDialog>
            <Button
              onClick={() => Navigate("/user")}
              fullWidth
              size="large"
              style={{
                color: "#8B5CFF",
                textAlign: "center",
                fontSize: "14px",
                cursor: "pointer",
              }}
            >
              <b>Login</b>
            </Button>
          </Box>
        </Box>
      </div>
    </Fragment>
  );
};

export default ForgetPassword;
