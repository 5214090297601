import { createSlice } from "@reduxjs/toolkit";

interface initialStateProps {
    HotSellingList: any;
    HotSellingStatus: any;
}

const initialState: initialStateProps = {
    HotSellingList: [],
    HotSellingStatus: null,
}

const HotSellingReducer = createSlice({
    name: 'HotSellingListDetails',
    initialState,
    reducers: {
        setHotSellingList: (state, action) => {
            state.HotSellingList = action.payload
        },
        setHotSellingStatus: (state, action) => {
            state.HotSellingStatus = action.payload
        }
    }
});

export const { setHotSellingList, setHotSellingStatus } = HotSellingReducer.actions;
export default HotSellingReducer.reducer