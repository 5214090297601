import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import "./PromoCreationStyles.css";
import BackNavigation from "../../components/BackNavigation";
import Rightarrow from "../../assets/images/ArrowRightColored.png";
import DownArrow from "../../assets/images/dropdown-down.png";
import {
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import { setLocationsList } from "../../store/reducers/LocationsReducer";
import { useDispatch, useSelector } from "react-redux";
import PromoCreationViewModel from "./PromoCreationViewModel";
import {
  setCatalogList,
  setItemsList,
} from "../../store/reducers/CatalogDataReducer";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CircularProgress from "@mui/material/CircularProgress";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";

let UserCodeData: any;
let AuthTokenId: any;
function PromotionCreation() {
  const { LocationsList } = useSelector((state: any) => state.locationDetails);
  const dispatch = useDispatch();
  const [selectedLocationOption, setSelectedLocationOption] =
    React.useState("Select a location");
  const [selectedMenuOption, setSelectedMenuOption] =
    React.useState("Select Menu");
  const [selectedValue, setSelectedValue]: any =
    React.useState("percentage_off");
  const [selectedItems, setSelectedItems]: any = React.useState([]);
  const [selectedItemsfreeItem, setSelectedItemsfreeItem]: any = React.useState(
    []
  );
  const [isSwitchEnabledUsage, setIsSwitchEnabledUsage] = React.useState(false);
  const [isSwitchEnabledDuration, setIsSwitchEnabledDuration] =
    React.useState(false);
  const [showPromo, setShowPromo] = React.useState(false);
  const [promoCode, setPromoCode] = useState("");
  // const [startDate, setStartDate] = useState<Date | null>(null);
  const [startTime, setStartTime] = useState("");
  // const [endDate, setEndDate] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [isRequiredPurchaseVisible, setIsRequiredPurchaseVisible] =
    useState(false);
  const [menuCard, setMenuCard]: any = useState([]);
  const [minAmount, setMinAmount] = useState("");
  const [maxAmount, setMaxAmount] = useState("");
  const [totalUses, setTotalUses] = useState("");
  const [tokenData, setToken] = useState("");
  const [locationID, setLocationID]: any = useState("");
  const [MenuID, setMenuID]: any = useState("");
  const { CategoryItemsList, loading } = PromoCreationViewModel();
  const { CatalogList, ItemsList } = useSelector(
    (state: any) => state?.catalogDetails
  );
  const [itemsListopen, setitemsListOpen]: any = useState(false);
  const [itemsListopenfreeItem, setitemsListOpenfreeItem]: any =
    useState(false);
  const { ItemsListData } = PromoCreationViewModel();
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<
    { posId: any; categoryId: any }[]
  >([]);
  let [selectedCategoryIdsfreeItem, setSelectedCategoryIdsfreeItem] = useState<
    { posId: any; categoryId: any }[]
  >([]);
  const [selectedItemIds, setSelectedItemIds] = useState<number[]>([]);
  const [selectedItemIdsfreeItem, setSelectedItemIdsfreeItem] = useState<
    number[]
  >([]);
  const [customer, setCustomer] = useState("Select Customer");
  const [selectedCategoryCodes, setSelectedCategoryCodes]: any = useState([]);
  const [selectedCategoryCodesfreeItem, setSelectedCategoryCodesfreeItem]: any =
    useState([]);
  const [categoryDropdownState, setCategoryDropdownState]: any = useState({});
  const [categoryDropdownStatefreeItem, setCategoryDropdownStatefreeItem]: any =
    useState({});
  const [categoryDropdownStates, setCategoryDropdownStates] = useState<{
    [key: string]: boolean;
  }>({});
  const [selectedCategorySwitch, setSelectedCategorySwitch] = useState<
    string | null
  >(null);
  const [selectedItemSwitch, setSelectedItemSwitch] = useState<string | null>(
    null
  );
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [dropdownId, setDropdownId] = useState<any>("");
  const [dropdownIdWithSwitch, setDropdownIdWithSwitch] = useState<any>("");
  const [startDate, setStartDate] = React.useState<Dayjs | null>();
  const [endDate, setEndDate] = React.useState<Dayjs | null>();
  const [select_all, setSelect_all] = React.useState(false);

  const [paramStartDate, setparamStartDate] = React.useState<String | null>();
  const [paramEndDate, setparamEndDate] = React.useState<String | null>();

  const Navigate = useNavigate();

  useEffect(() => {
    const TokenData: any = localStorage.getItem("Token");
    AuthTokenId = TokenData;
    dispatch(setCatalogList([]));
    Locations();
  }, []);

  useEffect(() => {
    const UserCode = localStorage.getItem("UserCode");
    UserCodeData = UserCode;
  }, []);

  useEffect(() => {
    const TokenData: any = localStorage.getItem("Token");
    setToken(TokenData);
  }, []);

  const handlediscountValueChange = (e: any) => {
    const value = e.target.value;
    const regex = /^[0-9]*$/;
    if (!regex.test(value)) {
      enqueueSnackbar("Only numbers are allowed", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      return;
    }
    if (selectedValue === "percentage_off" && value > 100) {
      enqueueSnackbar("Percentage cannot be more than 100", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      return;
    }
    setDiscountValue(value);
  };

  const toggleSwitchUsage = () => {
    setIsSwitchEnabledUsage(!isSwitchEnabledUsage);
  };

  const toggleSwitchDuration = () => {
    setIsSwitchEnabledDuration(!isSwitchEnabledDuration);
  };

  const toggleSwitchPromo = () => {
    setShowPromo(!showPromo);
  };

  const handleStartTime = (e: any) => {
    const inputValue = e.target.value.replace(/[^\d:]+/g, "");
    setStartTime(inputValue);
    const isValid = /^[0-9:]+$/.test(inputValue);
    if (!isValid) {
      enqueueSnackbar("Please Enter Valid Start Time.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      console.log("Invalid phone number");
    }
  };

  const handleEndTime = (e: any) => {
    const inputValue = e.target.value.replace(/[^\d:]+/g, "");
    setEndTime(inputValue);
    const isValid = /^[0-9:]+$/.test(inputValue);
    if (!isValid) {
      enqueueSnackbar("Please Enter Valid End Time.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      console.log("Invalid phone number");
    }
  };
  const handleStartDateChange = (date: any) => {
    console.log("DATE==>", dayjs(date).format(), date);
    let formatDate = dayjs(date).format();
    setparamStartDate(formatDate);
    setStartDate(date);
    // if (endDate && date && date > endDate) {
    //   setEndDate(null);
    // }
  };

  const handleEndDateChange = (date: any) => {
    // if (!startDate || !date || date >= startDate) {
    let formatDate = dayjs(date).format();
    setparamEndDate(formatDate);
    setEndDate(date);
    // } else {
    //   console.error("End date must be after or the same as start date.");
    // }
  };

  const handleCheckboxChange = (
    itemName: string,
    itemId: number,
    item: any
  ) => {
    const updatedSelectedItems = [...selectedItems];
    if (selectedItemIds?.includes(itemId)) {
      setSelectedItemIds(selectedItemIds?.filter((elem) => elem != itemId));
    } else {
      setSelectedItemIds([...selectedItemIds, itemId]);
    }
    setSelectedItems(updatedSelectedItems);
  };

  const handleCheckboxChangeWithSwitch = (itemName: string, itemId: number) => {
    console.log("Selected Item Name:", itemName);
    console.log("Selected Item ID:", itemId);
    const updatedSelectedItems = [...selectedItems];
    setSelectedItemsfreeItem(updatedSelectedItems);
    if (selectedItemIdsfreeItem?.includes(itemId)) {
      setSelectedItemIdsfreeItem(
        selectedItemIdsfreeItem?.filter((elem) => elem != itemId)
      );
    } else {
      console.log(selectedItemIdsfreeItem?.length);

      if (selectedItemIdsfreeItem?.length >= 2) {
        selectedItemIdsfreeItem.shift();
        setSelectedItemIdsfreeItem([...selectedItemIdsfreeItem, itemId]);
      } else {
        setSelectedItemIdsfreeItem([...selectedItemIdsfreeItem, itemId]);
      }
    }
  };

  const handleRadioChange = (event: any) => {
    setSelectedValue(event.target.value);
    setDiscountValue("");
    setIsRequiredPurchaseVisible(event.target.value === "free_item");
  };

  const handleChangeMenu = (e: any) => {
    const selectedMenuId = e.target.value;
    setSelectedMenuOption(selectedMenuId);
    setMenuID(selectedMenuId);
  };

  const handleCustomerChange = (e: any) => {
    setCustomer(e.target.value);
  };

  const handleChangeLocation = (e: any) => {
    const selectedLocationID = e.target.value;
    setSelectedLocationOption(selectedLocationID);
    setLocationID(selectedLocationID);
    setSelectedMenuOption("Select Menu");
  };

  const handleItemSelect = async (pos_id: string, itemId: number) => {
    console.log("Selected Category ID:", pos_id);
    setitemsListOpen(itemId);
    setSelectedCategoryCodes((prevSelectedCategoryIds: any) =>
      prevSelectedCategoryIds.includes(pos_id)
        ? prevSelectedCategoryIds.filter((id: any) => id !== pos_id)
        : [...prevSelectedCategoryIds, pos_id]
    );
    setSelectedItemIds((prevSelectedItemIds) =>
      prevSelectedItemIds.includes(itemId)
        ? prevSelectedItemIds.filter((id) => id !== itemId)
        : [...prevSelectedItemIds, itemId]
    );
    await ItemsListData(MenuID, pos_id);
    setCategoryDropdownState((prev: any) => ({
      ...prev,
      [pos_id]: true,
    }));
    setDropdownId(itemId);
  };

  useEffect(() => {
    if (selectedCategoryIds?.includes(dropdownId)) {
      let ids = ItemsList?.filter(
        (elem: any) => elem.f_category_id === dropdownId
      );
      let itemIds = ids?.map((elem: any) => elem?.id);
      console.log(itemIds);
      setSelectedItemIds([...itemIds]);
    }
  }, [ItemsList, dropdownId]);

  // useEffect(() => {
  //   if (selectedCategoryIdsfreeItem?.includes(dropdownIdWithSwitch)) {
  //     let ids = ItemsList?.filter(
  //       (elem: any) => elem.f_category_id === dropdownIdWithSwitch
  //     );
  //     let itemIds = ids?.map((elem: any) => elem?.id);
  //     console.log(itemIds);
  //     setSelectedItemIdsfreeItem([...itemIds]);
  //   }
  // }, [ItemsList, dropdownIdWithSwitch]);

  const handleItemSelectClose = async () => {
    setItemsList([]);
    setitemsListOpen(false);
    setCategoryDropdownState({});
  };

  const handleCategorySelect = (posId: any, categoryId: any) => {
    console.log("Selected PosId:", posId);
    console.log("Selected CategoryId:", categoryId);
    // alert("CALLED");
    setCategoryDropdownStates((prevStates: any) => ({
      ...prevStates,
      [posId]: !prevStates[posId],
    }));
    if (selectedCategoryIds?.includes(categoryId)) {
      setSelectedCategoryIds(
        selectedCategoryIds?.filter((elem) => elem !== categoryId)
      );
      // Clear selectedItemIds when deselecting a category
      setSelectedItemIds([]);
    } else {
      setSelectedCategoryIds([...selectedCategoryIds, categoryId]);
      // Add all item IDs associated with the selected category
      let ids = ItemsList?.filter(
        (elem: any) => elem.f_category_id === categoryId
      );
      let itemIds = ids?.map((elem: any) => elem?.id);
      setSelectedItemIds([...itemIds]);
    }
  };

  const handleCategorySelectWithSwitch = (posId: string, categoryId: any) => {
    if (selectedCategoryIdsfreeItem?.includes(categoryId)) {
      setSelectedCategoryIdsfreeItem([]);
      setSelectedItemIdsfreeItem([]);
      categoryId = "";
    } else {
      setSelectedCategoryIdsfreeItem([categoryId]);
      let items = ItemsList?.filter(
        (elem: any, index: any) => elem?.f_category_id === categoryId
      );
      let ids = items?.filter((elem: any, index: any) => {
        if (index === 0 || index === 1) {
          return elem.id;
        }
      });
      console.log(ids, "items");
      setSelectedItemIdsfreeItem(ids?.map((elem: any) => elem.id));
    }
  };

  const handleItemSelectWithSwitch = async (pos_id: string, itemId: number) => {
    console.log("Selected Category ID:", pos_id);
    setSelectedItemSwitch((prevSelectedItem) =>
      prevSelectedItem === pos_id ? null : pos_id
    );
    console.log("Setting itemsListOpenfreeItem to true");
    setitemsListOpenfreeItem(itemId);
    setSelectedCategoryCodesfreeItem((prevSelectedCategoryIds: any) =>
      prevSelectedCategoryIds.includes(pos_id)
        ? prevSelectedCategoryIds.filter((id: any) => id !== pos_id)
        : [...prevSelectedCategoryIds, pos_id]
    );
    setSelectedItemIdsfreeItem((prevSelectedItemIds) =>
      prevSelectedItemIds.includes(itemId)
        ? prevSelectedItemIds.filter((id) => id !== itemId)
        : [...prevSelectedItemIds, itemId]
    );
    await ItemsListData(MenuID, pos_id);
    setCategoryDropdownStatefreeItem((prev: any) => ({
      ...prev,
      [pos_id]: true,
    }));
    setDropdownIdWithSwitch(itemId);
  };

  const handleItemSelectCloseWithSwitch = async () => {
    setItemsList([]);
    setitemsListOpenfreeItem(false);
    setCategoryDropdownStatefreeItem({});
  };

  const Locations = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${tokenData}`,
      };
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
          ApiServices.vendor_locations +
          `?user_code=${UserCodeData}`,
        headers
      );
      if (response.status === true) {
        dispatch(setLocationsList(response.data));
      } else {
        console.log("Error");
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const MenusListData = async () => {
    try {
      const response = await HelperModule().getMethod(
        ApiServices.base_URL +
          ApiServices.menu_list +
          UserCodeData +
          `?f_location_id=${locationID}`
      );
      if (response.status === true) {
        setMenuCard(response.menu);
      } else {
        console.log("Error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (UserCodeData && locationID) {
      MenusListData();
      Locations();
    }
  }, [UserCodeData, locationID]);

  useEffect(() => {
    Locations();
  }, []);

  useEffect(() => {}, [locationID]);

  useEffect(() => {
    if (MenuID) {
      CategoryItemsList(MenuID);
    }
  }, [MenuID]);

  const CreatePromoCode = async () => {
    const discountTypeOptions = ["percentage_off", "Amount_off", "free_item"];

    if (!discountTypeOptions.includes(selectedValue)) {
      console.log("Invalid discount type selected:", selectedValue);
      return;
    }
    let discountType: string;
    if (selectedValue === "percentage_off") {
      discountType = "percentage_off";
    } else if (selectedValue === "Amount_off") {
      discountType = "amount_off";
    } else if (selectedValue === "free_item") {
      discountType = "free_item";
    } else {
      console.log("Unhandled discount type:", selectedValue);
      return;
    }
    selectedCategoryIdsfreeItem = [selectedCategoryIdsfreeItem[0]];
    const Params: any = {
      user_code: UserCodeData,
      promo_code: promoCode,
      location_id: locationID,
      menu_id: MenuID,
      discount_type: discountType,
      discount_amount: "10",
      cart_min_amount: minAmount,
      cart_max_amount: maxAmount,
      type_of_uses: totalUses,
      eligibility_type: customer,
      start_date: paramStartDate,
      start_time: startTime,
      end_date: paramEndDate,
      end_time: endTime,
      category_ids: selectedCategoryIds,
      item_ids: [95, 96],
      discounted_category_ids:
        selectedCategoryIdsfreeItem === null ? [] : selectedCategoryIdsfreeItem,
      discounted_item_ids: selectedItemIdsfreeItem,
      visible: showPromo,
      select_all: select_all,
    };
    if (promoCode === "") {
      enqueueSnackbar("Please enter promo code", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      return;
    } else if (locationID === "") {
      enqueueSnackbar("Please select location", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      return;
    } else if (menuCard.length === 0) {
      enqueueSnackbar("Please add menu", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      return;
    } else if (MenuID === "") {
      enqueueSnackbar("Please select menu", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      return;
    } else if (selectedCategoryIds.length === 0) {
      enqueueSnackbar("Please select category", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      return;
      // } else if (selectedItemIds.length === 0) {
      //   enqueueSnackbar("Please select item", {
      //     variant: "error",
      //     anchorOrigin: { vertical: "top", horizontal: "right" },
      //     style: { fontFamily: "Poppins", fontWeight: "500" },
      //   });
      //   return;
    } else if (selectedValue === "") {
      enqueueSnackbar("Please select discount type", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      return;
    } else if (
      discountType === "free_item" &&
      selectedCategoryIdsfreeItem.length === 0
    ) {
      enqueueSnackbar("Please select category", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
    } else if (minAmount === "") {
      enqueueSnackbar("Please enter min amount", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      return;
    } else if (maxAmount === "") {
      enqueueSnackbar("Please enter max amount", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      return;
    } else if (customer === "Select Customer") {
      enqueueSnackbar("Please select customer", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      return;
    } else if (
      selectedValue === "free_item" &&
      selectedItemIdsfreeItem.length <= 0
    ) {
      enqueueSnackbar("Please select discounted items", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      return;
    } else if (minAmount === maxAmount) {
      enqueueSnackbar("Minimum amount and maximum amount cannot be same", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      return;

      // } else if (totalUses === "") {
      //   enqueueSnackbar("Please enter total uses", {
      //     variant: "error",
      //     anchorOrigin: { vertical: "top", horizontal: "right" },
      //     style: { fontFamily: "Poppins", fontWeight: "500" },
      //   });
      //   return;
      // } else if (paramStartDate === null) {
      //   enqueueSnackbar("Please enter start date", {
      //     variant: "error",
      //     anchorOrigin: { vertical: "top", horizontal: "right" },
      //     style: { fontFamily: "Poppins", fontWeight: "500" },
      //   });
      //   return;
      // } else if (startTime === "") {
      //   enqueueSnackbar("Please enter start time", {
      //     variant: "error",
      //     anchorOrigin: { vertical: "top", horizontal: "right" },
      //     style: { fontFamily: "Poppins", fontWeight: "500" },
      //   });
      //   return;
      // } else if (paramEndDate === null) {
      //   enqueueSnackbar("Please enter end date", {
      //     variant: "error",
      //     anchorOrigin: { vertical: "top", horizontal: "right" },
      //     style: { fontFamily: "Poppins", fontWeight: "500" },
      //   });
      //   return;
      // } else if (endTime === "") {
      //   enqueueSnackbar("Please enter end time", {
      //     variant: "error",
      //     anchorOrigin: { vertical: "top", horizontal: "right" },
      //     style: { fontFamily: "Poppins", fontWeight: "500" },
      //   });
      //   return;
    } else {
      try {
        const response = await HelperModule().postMethod(
          ApiServices.base_URL + ApiServices.creating_promo_code,
          Params
        );
        console.log(response);
        if (response.status === true) {
          console.log("Successs response", response);
          enqueueSnackbar(response.message, {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            style: { fontFamily: "Poppins", fontWeight: "500" },
          });
          Navigate("/promos");
        } else if (response.status === false) {
          enqueueSnackbar(response.message, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            style: { fontFamily: "Poppins", fontWeight: "500" },
          });
        } else {
          console.log("failed response", response);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handlePromoCodeChange = (e: any) => {
    const inputValue = e.target.value;
    const regex = /^[a-zA-Z0-9]*$/;
    if (regex.test(inputValue)) {
      setPromoCode(inputValue);
    }
  };

  const handleMinAmountChange = (e: any) => {
    const inputValue = e.target.value;
    const regex = /^[0-9]*$/;
    if (regex.test(inputValue)) {
      setMinAmount(inputValue);
    }
  };

  const handleMaxAmountChange = (e: any) => {
    const inputValue = e.target.value;
    const regex = /^[0-9]*$/;
    if (regex.test(inputValue)) {
      setMaxAmount(inputValue);
    }
  };

  const handleLimitChanges = (e: any) => {
    const inputValue = e.target.value;
    const regex = /^[0-9]*$/;
    if (regex.test(inputValue)) {
      setTotalUses(inputValue);
    }
  };

  const handleSelectAllClick = async () => {
    if (selectedCategoryIds?.length === CatalogList.length) {
      setSelectedCategoryIds([]);
      setSelectedItemIds([]);
      setSelect_all(false);
    } else {
      const ids = await CatalogList.map((elem: any) => {
        return elem?.id;
      });
      setSelectedCategoryIds(ids);
      // let items = ItemsList?.filter((elem: any, index: any) =>{return (elem?.f_category_id)});
      let itemIds = ItemsList?.map((elem: any) => elem?.id);
      // alert(JSON.stringify(itemIds))
      setSelectedItemIds(itemIds);
      setSelect_all(true);
    }
  };

  const handleSelectAllClickWithSwitch = async () => {
    if (selectedCategoryIdsfreeItem?.length === CatalogList.length) {
      setSelectedCategoryIdsfreeItem([]);
      setSelectedItemIdsfreeItem([]);
    } else {
      const ids = await CatalogList.map((elem: any) => {
        return elem?.id;
      });
      setSelectedCategoryIdsfreeItem(ids);
      // let items = ItemsList?.filter((elem: any, index: any) =>{return (elem?.f_category_id)});
      let itemIds = ItemsList?.map((elem: any) => elem?.id);
      setSelectedItemIdsfreeItem(itemIds);
    }
  };

  return (
    <div>
      <BackNavigation label="Back" />
      <h1 className="promo-creation-title">Create a Promotion</h1>
      <div className="promotion-creation-container">
        <h3 className="promotion-list-title">Name</h3>
        <p className="promotion-list-sub-title">
          {" "}
          This will appear on menu and customer receipts{" "}
        </p>
        <input
          type="text"
          placeholder="Promo Code"
          value={promoCode}
          onChange={handlePromoCodeChange}
          style={{
            marginTop: "8px",
            width: "44.94vw",
            height: "6.20vh",
            borderRadius: "8px",
            borderWidth: "1px",
            borderColor: "#201C1C3D",
            padding: "10px 16px 10px 16px",
            outline: "none",
            fontWeight: "400",
            fontSize: "14px",
            fontFamily: "Poppins",
          }}
        />
        <div className="menu-details">
          <h3 className="promotion-list-title">Select a location</h3>
          <FormControl sx={{ mt: 1, width: "44.94vw" }}>
            <Select
              value={selectedLocationOption}
              onChange={handleChangeLocation}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="Select a location">Select a location</MenuItem>
              {LocationsList?.map((item: any) => (
                <MenuItem key={item?.id} value={item?.id}>
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="menu-details">
          <h3 className="promotion-list-title">Select Menu</h3>
          <FormControl sx={{ mt: 1, width: "44.94vw" }}>
            <Select
              className="input-container-promo-creation"
              value={selectedMenuOption}
              onChange={handleChangeMenu}
              inputProps={{ "aria-label": "Without label" }}
              disabled={!locationID}
            >
              <MenuItem value="Select Menu">Select Menu</MenuItem>
              {menuCard?.length > 0 ? (
                menuCard.map((item: any) => (
                  <MenuItem key={item?.id} value={item?.menu_code}>
                    {item?.menu_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No menu items found in this location</MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
        <h3 className="promotion-list-title" style={{ paddingTop: "20px" }}>
          Discount Type
        </h3>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={selectedValue}
            onChange={handleRadioChange}
            name="radio-buttons-group"
          >
            <FormControlLabel
              value="percentage_off"
              control={<Radio size="small" />}
              label="Percentage off %"
              sx={{
                fontWeight: "400",
                fontSize: "14px",
                fontFamily: "Poppins",
                color:
                  selectedValue === "percentage_off" ? "#201C1C" : "#201C1CB8",
              }}
            />
            <FormControlLabel
              value="Amount_off"
              control={<Radio size="small" />}
              label="Amount off"
              sx={{
                fontWeight: "400",
                fontSize: "14px",
                fontFamily: "Poppins",
                color: selectedValue === "Amount_off" ? "#201C1C" : "#201C1CB8",
              }}
            />
            <FormControlLabel
              value="free_item"
              control={<Radio size="small" />}
              label="Free item"
              sx={{
                fontWeight: "400",
                fontSize: "14px",
                fontFamily: "Poppins",
                color: selectedValue === "free_item" ? "#201C1C" : "#201C1CB8",
              }}
            />
          </RadioGroup>
        </FormControl>
        {selectedValue === "percentage_off" ||
        selectedValue === "Amount_off" ? (
          <div>
            <p>
              {selectedValue === "percentage_off"
                ? "Enter Percentage"
                : "Enter Amount"}
            </p>
            <div style={{ position: "relative", width: "fit-content" }}>
              <input
                type="text"
                placeholder={
                  selectedValue === "percentage_off"
                    ? "Enter Percentage"
                    : "Enter Amount"
                }
                value={discountValue}
                onChange={handlediscountValueChange}
                style={{
                  marginTop: "8px",
                  width: "44.94vw",
                  height: "7.20vh",
                  borderRadius: "8px",
                  borderWidth: "1px",
                  borderColor: "#201C1C3D",
                  padding: "10px 36px 10px 16px",
                  outline: "none",
                  fontWeight: "400",
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  boxSizing: "border-box",
                }}
              />
              <span
                style={{
                  position: "absolute",
                  right: "8px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                {selectedValue === "percentage_off" ? "%" : "$"}
              </span>
            </div>
          </div>
        ) : null}
        {selectedValue === "free_item" ? (
          <div className="switch-container">
            <div className="switch-position-container">
              <h3
                className="promotion-list-title"
                style={{ paddingTop: "20px" }}
              >
                Required Purchase ( Buy one, Get one, or Combo Deals)
              </h3>
              <p className="promotion-list-sub-title">
                Customers must buy at least one full price item to use this
                promo
              </p>
            </div>
            <Switch
              checked={isSwitchOn}
              onChange={() => setIsSwitchOn(!isSwitchOn)}
            />
          </div>
        ) : null}
        {CatalogList.length > 0 && (
          <>
            <h3 className="promo-item-list" style={{ marginTop: "20px" }}>
              Items List
            </h3>
            <p className="promotion-list-sub-title">
              Customers must buy at least one full price item to use this promo
            </p>
          </>
        )}
        {CatalogList.length > 0 && (
          <div className="promo-list-category-container">
            <div className="promo-list-category">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{}}
                    checked={select_all}
                    onChange={handleSelectAllClick}
                  />
                }
                label={"Select all"}
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  color: "#201C1C",
                  margin: 0,
                }}
                // onClick={() =>
                //   handleCategorySelect(category?.pos_id, category?.id)
                // }
              />
            </div>
          </div>
        )}
        {loading ? (
          <div className="Loader-container">
            <CircularProgress disableShrink sx={{ color: "#8B5CFF" }} />
          </div>
        ) : (
          CatalogList.length > 0 &&
          CatalogList.map((category: any, index: any) => {
            return (
              <>
                <div key={category?.name}>
                  <div className="promo-list-category-container">
                    <div className="promo-list-category">
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{}}
                            checked={selectedCategoryIds?.includes(
                              category?.id
                            )}
                          />
                        }
                        label={
                          <>
                            {category?.name}
                            <p
                              style={{
                                fontSize: "12px",
                                fontWeight: "400",
                                fontFamily: "Poppins",
                                color: "#201C1CB8",
                                margin: 0,
                              }}
                            >
                              {" "}
                              {category?.qty}
                            </p>
                          </>
                        }
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          fontFamily: "Poppins",
                          color: "#201C1C",
                          margin: 0,
                        }}
                        onClick={() =>
                          handleCategorySelect(category?.pos_id, category?.id)
                        }
                      />
                    </div>
                    {itemsListopen == category?.id ? (
                      <img
                        onClick={() => handleItemSelectClose()}
                        src={DownArrow}
                        alt="arrow"
                        style={{
                          height: "20px",
                          width: "20px",
                          marginRight: "10px",
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <img
                        onClick={() =>
                          handleItemSelect(category?.pos_id, category?.id)
                        }
                        src={Rightarrow}
                        alt="arrow"
                        style={{
                          height: "20px",
                          width: "20px",
                          marginRight: "10px",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                  {itemsListopen == category?.id && (
                    <div className="multi-select-dropdown">
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div className="multi-select-dropdown">
                          {ItemsList?.map((item: any, itemIndex: any) => {
                            console.log(item);
                            return (
                              <div key={item?.name} className="checkbox-item">
                                <FormControlLabel
                                  control={
                                    <div
                                      style={{
                                        flexDirection: "row",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Checkbox
                                        checked={selectedItemIds?.includes(
                                          item?.id
                                        )}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            item?.name,
                                            item?.id,
                                            item
                                          )
                                        }
                                        sx={{ marginLeft: "32.5px" }}
                                      />
                                      <div
                                        className="category-item-qty"
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "500",
                                            fontFamily: "Poppins",
                                            color: "#201C1CB8",
                                            margin: 0,
                                          }}
                                        >
                                          {" "}
                                          {item?.name}{" "}
                                        </p>
                                        <p
                                          style={{
                                            fontSize: "10px",
                                            fontWeight: "500",
                                            fontFamily: "Poppins",
                                            color: "#8B5CFF",
                                            margin: 0,
                                          }}
                                        >
                                          {" "}
                                          ${" "}
                                          {(item?.item_variations[0]
                                            ?.price_money?.amount || "N/A") /
                                            100}{" "}
                                        </p>
                                      </div>
                                    </div>
                                  }
                                  label={""}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            );
          })
        )}

        <h3 className="promotion-list-title" style={{ paddingTop: "20px" }}>
          {isSwitchOn ? "Discounted Items" : null}
        </h3>
        {isSwitchOn && (
          <>
            {/* {CatalogList.length > 0 && (
              <div className="promo-list-category-container">
                <div className="promo-list-category">
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{}}
                        checked={
                          selectedCategoryIdsfreeItem.length === CatalogList.length
                        }
                        onChange={handleSelectAllClickWithSwitch}
                      />
                    }
                    label={"Select all"}
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      fontFamily: "Poppins",
                      color: "#201C1C",
                      margin: 0,
                    }}
                    // onClick={() =>
                    //   handleCategorySelect(category?.pos_id, category?.id)
                    // }
                  />
                </div>
              </div>
            )} */}
            {CatalogList?.map((category: any) => (
              <div key={category?.name}>
                <div className="promo-list-category-container">
                  <div className="promo-list-category">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedCategoryIdsfreeItem?.includes(
                            category?.id
                          )}
                          onChange={() =>
                            handleCategorySelectWithSwitch(
                              category?.pos_id,
                              category?.id
                            )
                          }
                        />
                      }
                      label={
                        <>
                          {category?.name}
                          <p
                            style={{
                              fontSize: "12px",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                              color: "#201C1CB8",
                              margin: 0,
                            }}
                          >
                            {" "}
                            {category?.qty}{" "}
                          </p>
                        </>
                      }
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontFamily: "Poppins",
                        color: "#201C1C",
                        margin: 0,
                      }}
                      onChange={() =>
                        handleCategorySelectWithSwitch(
                          category?.pos_id,
                          category?.id
                        )
                      }
                      disabled={
                        selectedCategorySwitch !== null &&
                        selectedCategorySwitch !== category?.pos_id
                      }
                    />
                  </div>
                  {itemsListopenfreeItem == category?.id ? (
                    <img
                      onClick={() => handleItemSelectCloseWithSwitch()}
                      src={DownArrow}
                      alt="arrow"
                      style={{
                        height: "20px",
                        width: "20px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <img
                      onClick={() =>
                        handleItemSelectWithSwitch(
                          category?.pos_id,
                          category?.id
                        )
                      }
                      src={Rightarrow}
                      alt="arrow"
                      style={{
                        height: "20px",
                        width: "20px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </div>
                {itemsListopenfreeItem == category?.id && (
                  <div className="multi-select-dropdown">
                    <div className="multi-select-dropdown">
                      {ItemsList?.map((item: any, index: any) => (
                        <div key={item?.name} className="checkbox-item">
                          <FormControlLabel
                            control={
                              <div
                                style={{
                                  flexDirection: "row",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Checkbox
                                  checked={selectedItemIdsfreeItem?.includes(
                                    item.id
                                  )}
                                  onChange={() =>
                                    handleCheckboxChangeWithSwitch(
                                      item?.name,
                                      item?.id
                                    )
                                  }
                                  sx={{ marginLeft: "32.5px" }}
                                  disabled={
                                    selectedCategorySwitch !== null &&
                                    selectedCategorySwitch !== category?.pos_id
                                  }
                                />
                                <div
                                  className="category-item-qty"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "500",
                                      fontFamily: "Poppins",
                                      color: "#201C1CB8",
                                      margin: 0,
                                    }}
                                  >
                                    {item?.name}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "10px",
                                      fontWeight: "500",
                                      fontFamily: "Poppins",
                                      color: "#8B5CFF",
                                      margin: 0,
                                    }}
                                  >
                                    {" "}
                                    ${" "}
                                    {(item?.item_variations[0]?.price_money
                                      ?.amount || "N/A") / 100}
                                  </p>
                                </div>
                              </div>
                            }
                            label={""}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </>
        )}
        {/* <h3 className="promotion-list-title" style={{ paddingTop: "20px" }}>
          {" "}
          Advanced Settings{" "}
        </h3> */}
        <h3
          className="promotion-list-container-sub-title"
          style={{ paddingTop: "10px" }}
        >
          {" "}
          Cart Rules{" "}
        </h3>

        <div
          className="amount-promo-container"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor="outlined-adornment-amount">
              Min Amount
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              endAdornment={<InputAdornment position="end">$</InputAdornment>}
              value={minAmount}
              onChange={handleMinAmountChange}
              label="Min Amount"
            />
          </FormControl>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor="outlined-adornment-amount">
              Max Amount
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              endAdornment={<InputAdornment position="end">$</InputAdornment>}
              value={maxAmount}
              onChange={handleMaxAmountChange}
              label="Max Amount"
            />
          </FormControl>
          {/* <input
            placeholder="Min Amount"
            value={minAmount}
            onChange={handleMinAmountChange}
            style={{
              marginTop: "8px",
              width: "21.88vw",
              height: "7.20vh",
              borderRadius: "16px",
              borderWidth: "1px",
              borderColor: "#201C1C3D",
              padding: "10px 16px 10px 16px",
              outline: "none",
              fontWeight: "400",
              fontSize: "12px",
              fontFamily: "Poppins",
            }}
          /> */}
          {/* <input
            placeholder="Max Amount"
            value={maxAmount}
            onChange={handleMaxAmountChange}
            style={{
              marginTop: "8px",
              width: "21.88vw",
              height: "7.20vh",
              borderRadius: "16px",
              borderWidth: "1px",
              borderColor: "#201C1C3D",
              padding: "10px 16px 10px 16px",
              outline: "none",
              fontWeight: "400",
              fontSize: "12px",
              fontFamily: "Poppins",
              marginLeft: "16px",
              paddingLeft: "16px",
            }}
          /> */}
        </div>
        <h3 className="promotion-list-title" style={{ paddingTop: "20px" }}>
          Customers Rules
        </h3>
        <h3
          className="promotion-list-container-sub-title"
          style={{ paddingTop: "10px" }}
        >
          Audience ( App Only )
        </h3>
        <p className="promotion-list-sub-title">
          Choose who can use this promo
        </p>
        <div className="menu-details">
          <FormControl sx={{ width: "44.94vw" }}>
            <Select
              className="input-container-promo-creation"
              value={customer}
              onChange={handleCustomerChange}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="Select Customer">Select Customer</MenuItem>
              {CustomersData?.map((item: any) => (
                <MenuItem key={item?.name} value={item?.name}>
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="switch-container">
          <div className="switch-position-container">
            <h3
              className="promotion-list-title"
              // className="promo-item-switch-btn"
              style={{ paddingTop: "10px" }}
            >
              Limit total uses
            </h3>
            <p className="promo-item-switch-btn-sub">
              Limit how many times this promo code can be used
            </p>
          </div>
          <Switch onChange={toggleSwitchUsage} checked={isSwitchEnabledUsage} />
        </div>
        {isSwitchEnabledUsage && (
          <>
            <p
              className="promo-item-switch-btn-sub"
              style={{ paddingTop: "8px" }}
            >
              Total Uses
            </p>
            <input
              placeholder="Total Uses"
              value={totalUses}
              onChange={handleLimitChanges}
              style={{
                marginTop: "8px",
                width: "44.94vw",
                height: "7.20vh",
                borderRadius: "8px",
                borderWidth: "1px",
                borderColor: "#201C1C3D",
                padding: "10px 16px 10px 16px",
                outline: "none",
                fontWeight: "400",
                fontSize: "14px",
                fontFamily: "Poppins",
              }}
            />
          </>
        )}
        <div className="switch-container">
          <div className="switch-position-container">
            <h3 className="promotion-list-title" style={{ paddingTop: "20px" }}>
              {" "}
              Duration{" "}
            </h3>
            <p className="promotion-list-sub-title">
              Run this promo for a limited time between specific dates(i.e Happy
              Sale)
            </p>
          </div>
          <Switch
            onChange={toggleSwitchDuration}
            checked={isSwitchEnabledDuration}
          />
        </div>
        {isSwitchEnabledDuration && (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p
                  className="promotion-list-container-sub-title"
                  style={{ paddingTop: "10px" }}
                >
                  Start
                </p>
                <div
                  className="amount-promo-container"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "8px",
                    width: "22vw",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="Start Date"
                      minDateTime={dayjs(new Date())}
                      value={startDate}
                      closeOnSelect={false}
                      onAccept={(newValue) => handleStartDateChange(newValue)}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div>
                <p
                  className="promotion-list-container-sub-title"
                  style={{ paddingTop: "10px" }}
                >
                  End
                </p>
                <div
                  className="amount-promo-container"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "8px",
                    width: "22vw",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="End Date"
                      minDateTime={dayjs(startDate)}
                      closeOnSelect={false}
                      value={endDate}
                      onAccept={(newValue) => handleEndDateChange(newValue)}
                    />
                  </LocalizationProvider>
                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ width: "21.88vw", height: "7.20vh" }}
                  label="End Date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  minDate={startDate!}
                />
              </LocalizationProvider>
              <input
                type="text"
                placeholder="HH:MM"
                value={endTime}
                onChange={handleEndTime}
                style={{
                  width: "21.88vw",
                  height: "7.20vh",
                  borderRadius: "8px",
                  borderWidth: "1px",
                  borderColor: "#201C1C3D",
                  padding: "10px",
                  outline: "none",
                  fontWeight: "400",
                  fontSize: "12px",
                  fontFamily: "Poppins",
                  marginLeft: "16px",
                  paddingLeft: "16px",
                }}
              /> */}
                </div>
              </div>
            </div>
          </>
        )}
        <div className="switch-container">
          <div className="switch-position-container">
            <h3 className="promotion-list-title" style={{ paddingTop: "20px" }}>
              {" "}
              Show on kiosk{" "}
            </h3>
            <p className="promotion-list-sub-title">Display this promo code</p>
          </div>
          <Switch onChange={toggleSwitchPromo} checked={showPromo} />
        </div>
        <button
          onClick={CreatePromoCode}
          className="common-button-blue"
          style={{ transition: "background-color 0.3s" }}
          onMouseEnter={(e) => {
            (e.target as HTMLButtonElement).style.backgroundColor = "#6d43e57D";
          }}
          onMouseLeave={(e) => {
            (e.target as HTMLButtonElement).style.backgroundColor = "#8B5CFF";
          }}
        >
          {" "}
          Create{" "}
        </button>
        <button onClick={() => Navigate(-1)} className="common-button-cancel">
          Cancel
        </button>
      </div>
    </div>
  );
}

export default PromotionCreation;

const CustomersData = [
  { name: "All customers" },
  { name: "First time customers" },
];
