import React, { useEffect, useState } from "react";
import "./Advanced.css";
import { enqueueSnackbar } from "notistack";
import ApiServices from "../../helpers/ApiServices";
import HelperModule from "../../helpers/HelperModule";

let UserCodeData: any;
function Advanced() {
  const [listData, setListData]: any = useState([]);
  const [serviceFeemax, setServiceFeeMax]: any = useState(0);
  const [serviceFlatRate, setServiceFlatRate]: any = useState(0);
  const [servicePercentage, setServicePercentage]: any = useState(0);
  const [serviceFeeText, setServiceFeeText]: any = useState("");
  const [sendOrder, setSendOrder]: any = useState("");
  const [newOrder, setNewOrder]: any = useState("");
  const [passwordProtectionInst, setPasswordProtectionInst]: any = useState("");
  const [passwordProtection, setPasswordProtection]: any = useState("");

  const handleService = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const regex = /^\d*\.?\d*$/;
    if (regex.test(newValue)) {
      setServiceFeeMax(newValue);
    }
  };

  const handleFlatRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const regex = /^\d*\.?\d*$/;
    if (regex.test(newValue)) {
      setServiceFlatRate(newValue);
    }
  };

  const handlePercentageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const regex = /^\d*\.?\d*$/;
    if (regex.test(newValue)) {
      setServicePercentage(newValue);
    }
  };

  useEffect(() => {
    const UserCode = localStorage.getItem("UserCode");
    UserCodeData = UserCode;
  }, []);

  const AdvanceDataInitial = async () => {
    try {
      const response = await HelperModule().getMethod(
        ApiServices.base_URL +
          ApiServices.styling_advanced_list +
          `?user_code=${UserCodeData}`
      );
      if (response.status === true) {
        setListData(response.stylingAdvancedList[0]);
        setServiceFeeMax(response.stylingAdvancedList[0].service_fee_maximum);
        setServiceFlatRate(
          response.stylingAdvancedList[0].service_fee_flat_rate
        );
        setServicePercentage(
          response.stylingAdvancedList[0].service_fee_percentage
        );
        setServiceFeeText(response.stylingAdvancedList[0].service_fee_text);
        setSendOrder(response.stylingAdvancedList[0].send_order);
        setNewOrder(response.stylingAdvancedList[0].new_order);
        setPasswordProtectionInst(
          response.stylingAdvancedList[0].password_protect_instructions
        );
        setPasswordProtection(
          response.stylingAdvancedList[0].password_protection
        );
        console.log(listData);
      } else {
        enqueueSnackbar("Data not found", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckboxChange = (checkboxName: string) => {
    setListData(
      (prevListData: { [key: string]: number }) => {
        const updatedListData = { ...prevListData };
        updatedListData[checkboxName] =
          updatedListData[checkboxName] === 1 ? 0 : 1;
        const checkboxGroups = {
          add_service: ["add_a_service_fee_to_each_transaction_checkbox"],
          apply_taxes: ["apply_taxes_to_service_fee_checkbox"],
          show_service: ["show_service_fee_at_the_bottom_of_the_cart_checkbox"],
          show_service_btn: [
            "show_service_fee_as_a_line_item_in_the_cart_checkbox",
          ],
        };

        const currentGroup = Object.entries(checkboxGroups).find((group) =>
          group[1].includes(checkboxName)
        );
        if (currentGroup) {
          const [, groupCheckboxes] = currentGroup;
          groupCheckboxes.forEach((groupCheckbox) => {
            if (groupCheckbox !== checkboxName) {
              updatedListData[groupCheckbox] = 0;
            }
          });
        }
        return updatedListData;
      },
      () => {
        AdvanceData();
      }
    );
  };

  const AdvanceData = async () => {
    const Params = {
      language: listData.language,
      time_zone: listData.time_zone,
      add_a_service_fee_to_each_transaction_checkbox:
        listData.add_a_service_fee_to_each_transaction_checkbox,
      service_fee_maximum: serviceFeemax,
      service_fee_flat_rate: serviceFlatRate,
      service_fee_percentage: servicePercentage,
      apply_taxes_to_service_fee_checkbox:
        listData.apply_taxes_to_service_fee_checkbox,
      show_service_fee_at_the_bottom_of_the_cart_checkbox:
        listData.show_service_fee_at_the_bottom_of_the_cart_checkbox,
      show_service_fee_as_a_line_item_in_the_cart_checkbox:
        listData.show_service_fee_as_a_line_item_in_the_cart_checkbox,
      service_fee_text: serviceFeeText,
      send_order: sendOrder,
      new_order: newOrder,
      password_protect_instructions: passwordProtectionInst,
      password_protection: passwordProtection,
      user_code: UserCodeData,
    };
    console.log("Params", Params);
    try {
      const response = await HelperModule().postMethod(
        ApiServices.base_URL + ApiServices.styling_advanced,
        Params
      );
      if (response.status === true) {
        console.log(response);
        setListData(response.updated_columns);
        enqueueSnackbar(response.message, {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
        window.location.reload();
      } else if (response.status === false) {
        console.log(response);
        // enqueueSnackbar(response.errors.email[0], { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }, style: { fontFamily: 'Poppins', fontWeight: '500' }, });
      }
    } catch (error) {
      // enqueueSnackbar("Incorrect password", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }, style: { fontFamily: 'Poppins', fontWeight: '500' }, });
      console.log("error catch", error);
    }
  };

  useEffect(() => {
    AdvanceDataInitial();
  }, []);

  return (
    <div className="pt-[20px] pl-[15px]">
      <h1
        style={{
          fontFamily: "Poppins",
          fontSize: "28px",
          fontWeight: "600",
          paddingTop: "24px",
          margin: 0,
        }}
      >
        Advanced Settings
      </h1>
      <p
        style={{
          fontFamily: "Poppins",
          fontWeight: "400",
          fontSize: "14px",
          margin: 0,
          color: "#201C1C7A",
        }}
      >
        This section is dedicated to special use case settings and advanced
        usage of Miniosk.
      </p>
      <div className="cards">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            className="timeslots-input-container"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "8px",
                paddingBottom: "8px",
              }}
            >
              <h3 className="advanced-list-title">Language</h3>
              <div className="dropdown">
                <select
                  style={{
                    width: "21.88vw",
                    color: "#201C1C",
                    marginTop: "8px",
                    height: "7.20vh",
                    borderRadius: "8px",
                    borderWidth: "1px",
                    borderColor: "#201C1C3D",
                    padding: "10px 16px 10px 16px",
                    outline: "none",
                    fontWeight: "400",
                    fontSize: "14px",
                    fontFamily: "Poppins",
                  }}
                >
                  <option className="option" value="EN">
                    EN
                  </option>
                </select>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "8px",
                paddingBottom: "8px",
              }}
            >
              <h3
                className="advanced-list-title"
                style={{ marginLeft: "16px" }}
              >
                Time Zone
              </h3>
              <div className="dropdown">
                <select
                  style={{
                    color: "#201C1C",
                    marginTop: "8px",
                    width: "21.88vw",
                    height: "7.20vh",
                    borderRadius: "8px",
                    borderWidth: "1px",
                    borderColor: "#201C1C3D",
                    padding: "10px 16px 10px 16px",
                    outline: "none",
                    fontWeight: "400",
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    marginLeft: "16px",
                  }}
                >
                  <option className="option" value="GMT">
                    GMT
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h1
        style={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: "600",
          paddingTop: "24px",
          margin: 0,
        }}
      >
        Advanced Theme
      </h1>
      <p
        style={{
          fontFamily: "Poppins",
          fontWeight: "400",
          fontSize: "14px",
          margin: 0,
          color: "#201C1C7A",
        }}
      >
        This section is dedicated to special use case settings and advanced
        usage of Miniosk.
      </p>
      <div className="cards">
        <div>
          <label className="labelForBehaviour">
            <input
              type="checkbox"
              checked={
                listData?.add_a_service_fee_to_each_transaction_checkbox === 1
              }
              onChange={() =>
                handleCheckboxChange(
                  "add_a_service_fee_to_each_transaction_checkbox"
                )
              }
            />
            <span
              style={{
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "14px",
                margin: 0,
                color: "#201C1C",
              }}
            >
              Add a service fee to each transaction
            </span>
          </label>
          <p
            style={{
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "12px",
              margin: 0,
              paddingTop: "8px",
              color: "#201C1C7A",
            }}
          >
            Service Fee Maximum
          </p>
          {/* <div>
						<input
							value={serviceFeemax}
							onChange={handleService}
							type='text' maxLength={4} placeholder='00' style={{ fontFamily: 'Poppins', borderRadius: "8px", border: "1px solid #201C1C3D", width: '95%', height: "7.13vh", marginTop: '8px', display: 'flex', alignItems: 'center', paddingLeft: '16px', fontSize: '14px' }} />
						<p style={{ fontFamily: 'Poppins', fontWeight: '400', fontSize: "10px", margin: 0, paddingTop: '8px', color: "#201C1C7A" }} >This is the percentage of the subtotal that the service fee charge will cap out to. This may vary by your local jurisdiction, but typically we've seen a maximum of 4% of the subtotal.</p>
					</div> */}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
              paddingTop: "8px",
            }}
          >
            <input
              placeholder="Maximum Service Fee Percentage"
              value={serviceFeemax}
              onChange={handleService}
              style={{
                width: "97%",
                height: "7.20vh",
                borderRadius: "8px",
                borderWidth: "1px",
                borderColor: "#201C1C3D",
                padding: "10px 16px 10px 16px",
                outline: "none",
                fontWeight: "400",
                fontSize: "14px",
                fontFamily: "Poppins",
              }}
            />
            <div style={{ position: "absolute", right: "40px" }}>
              <h3
                style={{
                  margin: 0,
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#201C1C",
                  fontFamily: "Poppins",
                }}
              >
                %
              </h3>
            </div>
          </div>
          <p
            style={{
              width: "95%",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "10px",
              margin: 0,
              paddingTop: "8px",
              color: "#201C1C7A",
            }}
          >
            This is the percentage of the subtotal that the service fee charge
            will cap out to. This may vary by your local jurisdiction, but
            typically we've seen a maximum of 4% of the subtotal.
          </p>

          <p
            style={{
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "12px",
              margin: 0,
              paddingTop: "8px",
              color: "#201C1C7A",
            }}
          >
            Service Fee Flat Rate
          </p>
          {/* <div>
						<input
							value={serviceFlatRate}
							onChange={handleFlatRateChange}
							type='text' placeholder='00' style={{ fontFamily: 'Poppins', borderRadius: "8px", border: "1px solid #201C1C3D", width: '95%', height: "7.13vh", marginTop: '8px', display: 'flex', alignItems: 'center', paddingLeft: '16px', fontSize: '14px' }} />
					</div> */}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
              paddingTop: "8px",
            }}
          >
            <input
              placeholder="Flat Rate Service Fee"
              value={serviceFlatRate}
              onChange={handleFlatRateChange}
              style={{
                width: "97%",
                height: "7.20vh",
                borderRadius: "8px",
                borderWidth: "1px",
                borderColor: "#201C1C3D",
                padding: "10px 16px 10px 16px",
                outline: "none",
                fontWeight: "400",
                fontSize: "14px",
                fontFamily: "Poppins",
              }}
            />
            <div style={{ position: "absolute", right: "40px" }}>
              <h3
                style={{
                  margin: 0,
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#201C1C",
                  fontFamily: "Poppins",
                }}
              >
                $
              </h3>
            </div>
          </div>
          <p
            style={{
              width: "95%",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "10px",
              margin: 0,
              paddingTop: "8px",
              color: "#201C1C7A",
            }}
          >
            This is a flat rate that will be added to your customer's order.
          </p>

          <p
            style={{
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "12px",
              margin: 0,
              paddingTop: "8px",
              color: "#201C1C7A",
            }}
          >
            Service Fee Percentage
          </p>
          {/* <div>
						<input
							value={servicePercentage}
							onChange={handlePercentageChange}
							type='text' placeholder='00' style={{ fontFamily: 'Poppins', borderRadius: "8px", border: "1px solid #201C1C3D", width: '95%', height: "7.13vh", marginTop: '8px', display: 'flex', alignItems: 'center', paddingLeft: '16px', fontSize: '14px' }} />
					</div> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
              paddingTop: "8px",
            }}
          >
            <input
              placeholder="Percentage Service Fee"
              value={servicePercentage}
              onChange={handlePercentageChange}
              style={{
                width: "97%",
                height: "7.20vh",
                borderRadius: "8px",
                borderWidth: "1px",
                borderColor: "#201C1C3D",
                padding: "10px 16px 10px 16px",
                outline: "none",
                fontWeight: "400",
                fontSize: "14px",
                fontFamily: "Poppins",
              }}
            />
            <div style={{ position: "absolute", right: "40px" }}>
              <h3
                style={{
                  margin: 0,
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#201C1C",
                  fontFamily: "Poppins",
                }}
              >
                %
              </h3>
            </div>
          </div>
          <p
            style={{
              width: "95%",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "10px",
              margin: 0,
              paddingTop: "8px",
              color: "#201C1C7A",
            }}
          >
            This is a percentage amount of the customer's order that will be
            added to the total.
          </p>
        </div>
      </div>
      {/* <div className='cards'>
				<div>
					<label className="labelForBehaviour" >
						<input type="checkbox"

							checked={listData.apply_taxes_to_service_fee_checkbox === 1}
							onChange={() => handleCheckboxChange('apply_taxes_to_service_fee_checkbox')} />
						<span style={{ fontFamily: 'Poppins', fontWeight: '400', fontSize: "14px", margin: 0, color: "#201C1C" }} >Apply taxes to service fee</span>
					</label>
				</div>
			</div> */}
      {/* <p style={{ fontFamily: 'Poppins', fontWeight: '400', fontSize: "10px", margin: 0, paddingTop: '8px', color: "#201C1C7A" }} className='text-[12px] my-4'>How should service fees be displayed?</p>
					<label className="labelForBehaviour" style={{ marginTop: '16px' }}>
						<input type="checkbox"

							checked={listData.show_service_fee_at_the_bottom_of_the_cart_checkbox === 1}
							onChange={() => handleCheckboxChange('show_service_fee_at_the_bottom_of_the_cart_checkbox')}
						/>
						<span style={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: '400' }}>Show service fee at the bottom of the cart next to taxes (does not apply to the v3 Beta version)</span>
					</label>
					<label className="labelForBehaviour" style={{ marginTop: '16px' }}>
						<input type="checkbox"
							checked={listData.show_service_fee_as_a_line_item_in_the_cart_checkbox === 1}
							onChange={() => handleCheckboxChange('show_service_fee_as_a_line_item_in_the_cart_checkbox')}
						/>
						<span style={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: '400' }}>Show service fee at the bottom of the cart next to taxes (does not apply to the v3 Beta version)</span>
					</label>
					<p style={{ fontFamily: 'Poppins', fontWeight: '500', fontSize: "12px", margin: 0, paddingTop: '8px', color: "#201C1C7A" }} className='text-[12px] text-[#858585] pt-[5px]'>Service Fee Text</p>
					<div>
						<input
							value={serviceFeeText}
							onChange={(e: any) => setServiceFeeText(e.target.value)}
							type='text' placeholder='Service Fee' style={{ fontFamily: 'Poppins', borderRadius: "8px", border: "1px solid #201C1C3D", width: '95%', height: "7.13vh", marginTop: '8px', display: 'flex', alignItems: 'center', paddingLeft: '16px', fontSize: '14px' }} />
						<p style={{ fontFamily: 'Poppins', fontWeight: '400', fontSize: "10px", margin: 0, paddingTop: '8px', color: "#201C1C7A" }} className='text-[10px] text-[#b5b4b4] pt-1'>This is the text that will be displayed in the cart.</p>
					</div>
				</div>
			</div>
			<div className='cards'>
				<div>
					<p style={{ fontFamily: 'Poppins', fontWeight: '400', fontSize: "14px", margin: 0, color: "#201C1C", paddingTop: "16px" }} >Open Tab - Order Button Text</p>
					<div>
						<input
							value={sendOrder}
							onChange={(e: any) => setSendOrder(e.target.value)}
							type='text' placeholder='Send Order' style={{ fontFamily: 'Poppins', borderRadius: "8px", border: "1px solid #201C1C3D", width: '95%', height: "7.13vh", marginTop: '8px', display: 'flex', alignItems: 'center', paddingLeft: '16px', fontSize: '14px' }} />
						<p style={{ fontFamily: 'Poppins', fontWeight: '400', fontSize: "10px", margin: 0, paddingTop: '8px', color: "#201C1C7A" }}>Use this field to change the text for the button customers will use to send orders in when Open Tab is enabled.</p>
					</div>
					<p style={{ fontFamily: 'Poppins', fontWeight: '400', fontSize: "14px", margin: 0, color: "#201C1C", paddingTop: '16px' }} >Open Tab - New Order Text</p>
					<div>
						<input
							value={newOrder}
							onChange={(e: any) => setNewOrder(e.target.value)}
							type='text' placeholder='New Order' style={{ fontFamily: 'Poppins', borderRadius: "8px", border: "1px solid #201C1C3D", width: '95%', height: "7.13vh", marginTop: '8px', display: 'flex', alignItems: 'center', paddingLeft: '16px', fontSize: '14px' }} />
						<p style={{ fontFamily: 'Poppins', fontWeight: '400', fontSize: "10px", margin: 0, paddingTop: '8px', color: "#201C1C7A" }}>Use this field to change the text that shows up next to new items in an order when Open Tab is enabled.</p>
					</div>
				</div>
			</div> */}

      <div className="cards" style={{ marginBottom: "20px" }}>
        {/* <div>
					<div>
						<input
							value={passwordProtectionInst}
							onChange={(e: any) => setPasswordProtectionInst(e.target.value)}
							type='text' placeholder='Password Protection Instructions' style={{ fontFamily: 'Poppins', borderRadius: "8px", border: "1px solid #201C1C3D", width: '95%', height: "7.13vh", marginTop: '8px', display: 'flex', alignItems: 'center', paddingLeft: '16px', fontSize: '14px' }} />
						<p style={{ fontFamily: 'Poppins', fontWeight: '400', fontSize: "10px", margin: 0, paddingTop: '8px', color: "#201C1C7A" }}>Use this field to change the text that shows up when an item is password protected.</p>
					</div>
					<p style={{ fontFamily: 'Poppins', fontWeight: '400', fontSize: "14px", margin: 0, color: "#201C1C", paddingTop: "16px" }} >Open Tab - New Order Text</p>
					<div>
						<textarea
							value={passwordProtection}
							onChange={(e: any) => setPasswordProtection(e.target.value)}
							className="textarea-behaviour" placeholder="Password Protection"></textarea>
						<p style={{ fontFamily: 'Poppins', fontWeight: '400', fontSize: "10px", margin: 0, paddingTop: '8px', color: "#201C1C7A" }}>Use this text area to define passwords for categories and items. For multiple passwords, separate passwords with spaces or new lines.</p>
					</div>
				</div> */}
        <button
          onClick={AdvanceData}
          className="common-button-blue"
          style={{ transition: "background-color 0.3s" }}
          onMouseEnter={(e) => {
            (e.target as HTMLButtonElement).style.backgroundColor = "#6d43e57D";
          }}
          onMouseLeave={(e) => {
            (e.target as HTMLButtonElement).style.backgroundColor = "#8B5CFF";
          }}
        >
          Update
        </button>
        {/* <button className='common-button-cancel'>Cancel</button> */}
      </div>
    </div>
  );
}

export default Advanced;
