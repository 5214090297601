import { createSlice } from '@reduxjs/toolkit';

interface initialStateProps {
    IsLogin: null,
    successDialog: boolean
    emailSelected: string
    referalCode: string
    userDetails: any,
    posConnection: number,
    location_added: number,
    storeLimit: number,
    posConnectSelected: boolean,
    user: any
}

const initialState: initialStateProps = {
    IsLogin: null,
    successDialog:false,
    emailSelected: '',
    referalCode: '',
    userDetails: {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        back: false
    },
    posConnection: 0,
    location_added: 0,
    storeLimit: 0,
    posConnectSelected: false,
    user: {}
}

const AuthReducer = createSlice({
    name: 'AuthReducer',
    initialState,
    reducers: {
        setIsLogin: (state, action) => {
            state.IsLogin = action?.payload
        },
        setSuccessDialog:(state, action) => {
            state.successDialog = action?.payload
        },
        seteMailSelected:(state, action) => {
            state.emailSelected = action?.payload
        },
        setReferalCode:(state, action) => {
            state.referalCode = action?.payload
        },
        setUserDetails: (state, action) => {
            state.userDetails = action?.payload
        },
        setPosConnect: (state, action) => {
            state.posConnection = action?.payload
        },
        setPosConnectSelected: (state, action) => {
            state.posConnectSelected = action?.payload
        },
        setUserObject: (state, action) => {
            state.user = action?.payload
        },
        setLocationAdded: (state, action) => {
            state.location_added = action?.payload
        },
        setStoreLimit: (state, action) => {
            state.storeLimit = action?.payload
        }
    },

});

export const {
    setIsLogin,
    setSuccessDialog,
    seteMailSelected,
    setReferalCode,
    setUserDetails,
    setPosConnect,
    setPosConnectSelected,
    setUserObject,
    setLocationAdded,
    setStoreLimit
} = AuthReducer.actions;
export default AuthReducer.reducer;