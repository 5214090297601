import { createSlice } from '@reduxjs/toolkit';

interface initialStateProps {
    MenuList: any,
    WebList: any,
    Status:any
    
}

const initialState: initialStateProps = {
    MenuList: [],
    WebList: {},
    Status:""

}

const menuListDetailsReducer = createSlice({
    name: 'manageData',
    initialState,
    reducers: {
        setMenusList: (state, action) => {
            state.MenuList = action.payload
        },
        setWebList: (state, action) => {
            state.WebList = action.payload
        },
        setStatus: (state, action) => {
            state.Status = action.payload
        }
    },
});

export const { setMenusList, setWebList, setStatus } = menuListDetailsReducer.actions;
export default menuListDetailsReducer.reducer;