import { createSlice } from "@reduxjs/toolkit";

interface initialStateProps {
    SubscriptionList: any;
}

const initialState: initialStateProps = {
    SubscriptionList: []
}

const SubscriptionReducer = createSlice({
    name: 'subscriptionList',
    initialState,
    reducers: {
        setSubscriptionList: (state, action) => {
            state.SubscriptionList = action.payload
        }
    }
});

export const { setSubscriptionList } = SubscriptionReducer.actions;
export default SubscriptionReducer.reducer