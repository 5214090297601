import Card from '@mui/material/Card';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Success = () => {
    const navigate = useNavigate();
    useEffect(()=>{
        setTimeout(()=>{
            redirect();
        },10000);
    },[])

    const redirect = () => {
        navigate("/settings")
    }

    return (
        <div style={{ display:"flex", justifyContent:"center" }}>
            <Card sx={{ width: 800, height:400, marginTop:"15%", display:"flex",flexDirection:"column", alignItems:"center",justifyContent:"center" }}>
                <img src={require("../../assets/images/check.png")} height={200} width={200}/>
                <span style={{ fontSize:30 }}>Payment Successfull!!</span>
            </Card>
        </div>
    )
}

export default Success