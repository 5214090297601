import React, { ReactNode } from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

function Modal({ isOpen, onClose, children }: ModalProps) {
  if (!isOpen) return null;
  const close = () => {
    onClose(); // Call the onClose callback to close the modal
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-70 z-50">
      <div className="modal">
        <div className="modal-content">
          {children}
        </div>
        <button onClick={close} className="modal-close">Close</button>
      </div>
    </div>
  );
}

export default Modal;
