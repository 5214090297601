import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";

import { useLocation } from "react-router-dom";

// Replace with your Stripe public key
const stripePromise = loadStripe(
  "pk_test_51OFsNpSCXlRpzTWPdKWHmDm0WKMBJjCdmNiO3mBM6HYAiVCl50IfuV9yOgvlIu3t5BXa4wjI5Bia1xbdynANXRz500bZQwZH04"
);

function Stripe() {
  const location = useLocation();
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        name={location.state.name}
        amount={location.state.amount}
        subscriptionId={location.state.subscription_id}
      />
    </Elements>
  );
}

export default Stripe;
