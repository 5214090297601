import {
  Box,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  TableSortLabel,
  Typography,
} from "@mui/material";
import React from "react";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { useStyles } from "./UseStyles";
import { Palette } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { visuallyHidden } from "@mui/utils";
import moment from "moment"; // Import moment

function EnhancedTableHead(props: any) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort, headCells, actionElement } = props;

  console.log(orderBy, "orderBy");
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells?.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            align={"left"} // Ensure header alignment is left
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              className={classes.TableHeadTitle}
              active={orderBy === headCell.id} // orderBy === headCell.id
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              IconComponent={(event) => (
                <ArrowDropDownRoundedIcon
                  {...event}
                  sx={{ color: "#201C1C52" }}
                />
              )}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {actionElement && ( // Render action header if actionElement exists
          <TableCell align="center">
            <TableSortLabel className={classes.TableHeadTitle}>
              Actions
            </TableSortLabel>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}
function DataTable({
  order,
  orderBy,
  nPages,
  currentRecords,
  visibleRows,
  controller,
  setOrder,
  setOrderBy,
  setController,
  setCurrentPage,
  headCells,
  loading,
  actionElement, // New prop for action element
  onActionClick, // New prop for action click handler
}: any) {
  // console.log(currentRecords, visibleRows, "currentRecords")

  const classes = useStyles();
  const { palette }: any = useTheme();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setController({
      ...controller,
      order: isAsc ? "desc" : "asc",
      field: property,
    });
  };

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
    setController({
      ...controller,
      page: value,
    });
  };

  return (
    <Box className={classes.TableMain}>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} size={"medium"}>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
            actionElement={actionElement}
            rowCount={visibleRows?.length}
          />

          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <LinearProgress />
                </TableCell>
              </TableRow>
            ) : visibleRows?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  <Typography variant="body2">No Data Found</Typography>
                </TableCell>
              </TableRow>
            ) : (
              currentRecords?.map((row: any, index: any) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    sx={{ cursor: "pointer" }}
                  >
                    {headCells.map((headCell: any) => (
                      <TableCell
                        align="left" // Ensure body alignment is left
                        key={headCell.id}
                      >
                        <Typography
                          variant="body2"
                          className={classes.DashboarBodyCell}
                          sx={{
                            fontWeight: 400,
                            fontSize: "12px",
                            fontFamily: "Poppins",
                            width: "142px", // Set a fixed width for all cells
                            padding: "8px", // Add padding for better spacing
                            color:
                              headCell.id === "status"
                                ? row[headCell.id] === 1
                                  ? "green"
                                  : "red" // Set color based on status
                                : "inherit", // Default color for other cells
                            overflow: "hidden", // Set overflow to hidden
                            textOverflow: "ellipsis", // Add ellipsis for overflow
                            whiteSpace: "nowrap", // Prevent wrapping
                          }}
                        >
                          {headCell.id === "s_no" // Check for s_no
                            ? index + 1 // Display index + 1 for s_no
                            : headCell.id === "status"
                            ? row[headCell.id] === 1
                              ? "ACTIVE"
                              : "INACTIVE" // Display status text
                            : headCell.id === "created_at" // Check if the cell is for a date
                            ? moment(row[headCell.id]).format("MMM Do YYYY") // Format date
                            : Array.isArray(row[headCell.id]) // Check if row is a type array
                            ? row[headCell.id].join(", ") // Combine the strings with ,
                            : row[headCell.id] || "N/A"}
                        </Typography>
                      </TableCell>
                    ))}
                    {actionElement && ( // Check if actionElement is provided
                      <TableCell align="left">
                        <Box sx={{ display: "flex", gap: 1 }}>
                          {" "}
                          {/* Added Box for styling */}
                          {Array.isArray(actionElement)
                            ? actionElement.map((button, index) =>
                                React.cloneElement(button, {
                                  row,
                                  onClick: () => onActionClick(row, index), // Pass row and index for each button
                                })
                              )
                            : React.cloneElement(actionElement, {
                                row,
                                onClick: () => onActionClick(row, 0), // Pass row for single button
                              })}
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {nPages > 1 && (
        <Pagination
          count={nPages}
          color="primary"
          size="small"
          onChange={handlePaginationChange}
          page={controller.page}
          className={classes.paginationPrevNextBtn}
        />
      )}
    </Box>
  );
}

export default DataTable;
