import React, { Fragment, useEffect, useState } from "react";
import { useStyles } from "./UseStyles";
import { visuallyHidden } from "@mui/utils";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Pagination,
  TableRow,
  TableSortLabel,
  Typography,
  IconButton,
  LinearProgress,
} from "@mui/material";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { useNavigate } from "react-router-dom";
import HelperModule from "../helpers/HelperModule";
import ApiServices from "../helpers/ApiServices";
import LocationFilter from "./Dropdown";
import Search from "../assets/images/Search.png";
import { ClearIcon } from "@mui/x-date-pickers/icons";
import { useSelector } from "react-redux";
import DropdoenFilter from "./Dropdown";
import DataTable from "./DataTable";

function createData(
  device_id: any,
  device_name: any,
  menu_name: any,
  location: any,
  total_orders: any,
  status: any
) {
  return { device_id, device_name, menu_name, location, total_orders, status };
}

type Order = "asc" | "desc";

const headCells: any = [
  {
    id: "user_code",
    disablePadding: true,
    label: "User Id",
    sortable: false,
  },
  {
    id: "name",
    disablePadding: false,
    label: "User Name",
    sortable: true,
  },
  {
    id: "phone",
    disablePadding: false,
    label: "Mobile Number",
    sortable: false,
  },
  {
    id: "last_visit_date",
    disablePadding: false,
    label: "Last Visit Date",
    sortable: true,
  },
  {
    id: "last_visit_count",
    disablePadding: false,
    label: "No. of Visits",
    sortable: true,
  },
  {
    id: "orders_count",
    disablePadding: false,
    label: "No. of Orders",
    sortable: true,
  },
];

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string | any },
  b: { [key in Key]: number | string | any }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function EnhancedTableHead(props: any) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort, controller } = props;

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              className={classes.TableHeadTitle}
              active={controller.field === headCell.id} // orderBy === headCell.id
              // direction={orderBy === headCell.id ? order : "asc"}
              direction={controller.order === "asc" ? "asc" : "desc"}
              disabled={!headCell.sortable}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={!headCell.sortable}
              IconComponent={(event) => (
                <ArrowDropDownRoundedIcon
                  {...event}
                  sx={{ color: "rgba(32, 28, 28, 0.32)" }}
                />
              )}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

let UserCodeData: any = localStorage.getItem("UserCode");

const CustomersTable = () => {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<any>("user_code");
  const [customerListData, setCustomerListdata]: any = useState([]);
  const [initLoading, setInitLoading] = useState(true);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);

  const [isLoading, setLoading] = useState(false);
  const [recordsCount, setRecordsCount] = useState(0);

  const [controller, setController] = useState({
    page: 1,
    rowsPerPage: 10,
    field: "",
    order: "",
  });

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  //   const currentRecords = customerListData.slice(
  //     indexOfFirstRecord,
  //     indexOfLastRecord
  //   );
  const currentRecords = customerListData;

  const nPages = Math.ceil(recordsCount / controller.rowsPerPage);
  const [search, setSearch] = useState("");
  const [filteredValues, setFilteredValues] = useState([]);

  const { filteredLocations, LocationsList } = useSelector(
    (store: any) => store.locationDetails
  );

  const HandleWebSettings = (row: any) => {
    // Use the userCode parameter as needed for navigation
    navigate("/customeruserdetails", {
      state: { userCodeDataDetails: row?.user_code },
    });
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    setController({
      ...controller,
      field: property,
      order:
        property !== controller.field
          ? "desc"
          : controller.order === "asc"
          ? "desc"
          : "asc",
      page: 1,
    });

    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const CustomerListData = async () => {
    try {
      setLoading(true);

      let locationQuery = [];
      locationQuery = LocationsList.filter((item: any) =>
        filteredLocations.includes(item?.location_id)
      );
      locationQuery = locationQuery.map((item: any) => item?.id);

      const response = await HelperModule().getMethod(
        ApiServices.base_URL +
          ApiServices.customer_list +
          `?user_code=${UserCodeData}&location_ids=${locationQuery}&page=${controller.page}&search=${search}&field=${controller.field}&order=${controller.order}`
      );

      setLoading(false);

      if (response.status === true) {
        setCustomerListdata(response?.all_customers?.data);
        setFilteredValues(response?.all_customers?.data);
        setRecordsCount(response?.all_customers?.total);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleLocationSelect = async (selectedLocations: string[]) => {
    try {
      setLoading(true);
      const locationQuery = LocationsList.filter((item: any) =>
        selectedLocations.includes(item?.location_id)
      ).map((item: any) => item?.location_id);
      const response = await HelperModule().getMethod(
        ApiServices.base_URL +
          ApiServices.customer_list +
          `?user_code=${UserCodeData}&location_ids=${
            locationQuery || ""
          }&page=${controller.page}&search=${search}&field=${
            controller.field
          }&order=${controller.order}`
      );
      setLoading(false);
      if (response.status === true) {
        setCustomerListdata(response?.all_customers?.data);
        setFilteredValues(response?.all_customers?.data);
        setRecordsCount(response?.all_customers?.total);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    handleLocationSelect(selectedLocations);
  }, [selectedLocations]);

  const handleSearch = (value: string) => {
    setSearch(value);
    // const lowerCaseSearch = value.toLowerCase();

    // // Filter the data based on the search value
    // const filteredList = customerListData.filter(
    //   (customer: any) =>
    //     (customer.user_code &&
    //       customer.user_code.toLowerCase().includes(lowerCaseSearch)) ||
    //     (customer.name &&
    //       customer.name.toLowerCase().includes(lowerCaseSearch)) ||
    //     (customer.phone && customer.phone.includes(value)) ||
    //     (customer.orders_count &&
    //       customer.orders_count.toString().includes(value))
    // );
    // setFilteredValues(filteredList);
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      if (initLoading) {
        setInitLoading(false);
        return;
      }
      setController({ ...controller, page: 1 });
    }, 1000);
    return () => clearTimeout(getData);
  }, [search]);

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
    setController({
      ...controller,
      page: value,
    });
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }
    CustomerListData();
  }, [controller]);

  const visibleRows = React.useMemo(() => {
    const dataToDisplay =
      filteredValues.length > 0 ? filteredValues : customerListData;
    const sortedData = stableSort(dataToDisplay, getComparator(order, orderBy));
    // return sortedData.slice(indexOfFirstRecord, indexOfLastRecord); // Limiting to 10 records
    return customerListData;
  }, [order, orderBy, customerListData, filteredValues]);

  useEffect(() => {
    setController({
      ...controller,
      page: 1,
    });
  }, [filteredLocations]);

  const actionButton = (
    <button
      style={{
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#8B5CFF",
        color: "#FFFFFF",
        borderWidth: 0,
        fontWeight: "500",
        fontSize: "10px",
        fontFamily: "Poppins",
        borderRadius: "8px",
        // marginLeft: "20px",
        padding: "10px",
        transition: "background-color 0.3s",
      }}
      onClick={(e) => e.stopPropagation()}
      onMouseEnter={(e) => {
        (e.target as HTMLButtonElement).style.backgroundColor = "#6d43e57D";
      }}
      onMouseLeave={(e) => {
        (e.target as HTMLButtonElement).style.backgroundColor = "#8B5CFF";
      }}
    >
     View Details
    </button>
  );

  return (
    <>
      <div className="manage-tabs-container" style={{ marginBottom: "16px" }}>
        <div
          className="manage-btn-container"
          style={{ position: "relative", display: "inline-block" }}
        >
          <img
            src={Search}
            height={15}
            alt="Search Icon"
            className="search-icon"
          />
          <input
            placeholder="Search..."
            className="promo-code-input-container"
            style={{ color: "black", outline: "none" }}
            value={search}
            onChange={(event) => handleSearch(event.target.value)}
          />
          {search && (
            <IconButton
              style={{
                position: "absolute",
                right: "8px",
                top: "50%",
                transform: "translateY(-50%)",
              }}
              onClick={() => setSearch("")}
            >
              <ClearIcon onClick={() => setFilteredValues(customerListData)} />
            </IconButton>
          )}
        </div>

        <div className="manage-btn-sub">
          <DropdoenFilter onLocationSelect={handleLocationSelect} />
        </div>
      </div>

      <Fragment>
        <DataTable
          order={order}
          orderBy={orderBy}
          nPages={nPages}
          currentRecords={currentRecords}
          visibleRows={visibleRows}
          controller={controller}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          setController={setController}
          setCurrentPage={setCurrentPage}
          headCells={headCells}
          loading={isLoading}
          actionElement={actionButton}
          onActionClick={HandleWebSettings}
        />
        {/* <Box className={classes.TableMain}>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} size={"medium"}>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={customerListData.length}
                controller={controller}
              />
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                ) : filteredValues?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <Typography variant="body2">No Data Found</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  visibleRows?.map((row: any, index: any) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell align="left" style={{ width: "155px" }}>
                          <Typography
                            variant="body2"
                            className={classes.DashboarBodyCell}
                            sx={{
                              fontWeight: 400,
                              fontSize: "14px",
                              color: "#201C1C",
                              fontFamily: "Poppins",
                            }}
                          >
                            {row?.user_code}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" style={{ width: "140px" }}>
                          <Typography
                            variant="body2"
                            className={classes.DashboarBodyCell}
                            sx={{
                              fontWeight: 400,
                              fontSize: "14px",
                              color: "#201C1C",
                              fontFamily: "Poppins",
                              width: "140px",
                            }}
                          >
                            {row?.name}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" style={{ width: "134px" }}>
                          <Typography
                            variant="body2"
                            className={classes.DashboarBodyCell}
                            sx={{
                              fontWeight: 400,
                              fontSize: "14px",
                              color: "#201C1C",
                              fontFamily: "Poppins",
                            }}
                          >
                            {row?.phone}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="body2"
                            className={classes.DashboarBodyCell}
                            sx={{
                              fontWeight: 400,
                              fontSize: "14px",
                              color: "#201C1C",
                              fontFamily: "Poppins",
                            }}
                          >
                            {row?.last_visit_date || "N/A"}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" style={{ width: "128px" }}>
                          <Typography
                            variant="body2"
                            className={classes.DashboarBodyCell}
                            sx={{
                              fontWeight: 400,
                              fontSize: "14px",
                              color: "#201C1C",
                              fontFamily: "Poppins",
                            }}
                          >
                            {row?.last_visit_count}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Typography
                            variant="body2"
                            className={classes.DashboarBodyCell}
                            sx={{
                              fontWeight: 500,
                              fontSize: "14px",
                              fontFamily: "Poppins",
                              color: "#18BC46",
                              width: "80px",
                            }}
                          >
                            {row?.orders_count}
                          </Typography>
                          <div
                            key={row.id}
                            onClick={() => HandleWebSettings(row.user_code)}
                            style={{ marginLeft: "50px" }}
                          >
                            <button
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#8B5CFF",
                                color: "#FFFFFF",
                                borderWidth: 0,
                                fontWeight: "500",
                                fontSize: "14px",
                                fontFamily: "Poppins",
                                borderRadius: "8px",
                                marginLeft: "20px",
                                padding: "10px",
                                transition: "background-color 0.3s",
                              }}
                              onMouseEnter={(e) => {
                                (
                                  e.target as HTMLButtonElement
                                ).style.backgroundColor = "#6d43e57D";
                              }}
                              onMouseLeave={(e) => {
                                (
                                  e.target as HTMLButtonElement
                                ).style.backgroundColor = "#8B5CFF";
                              }}
                            >
                              View Details
                            </button>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {nPages > 1 && (
            <Pagination
              count={nPages}
              color="primary"
              size="small"
              onChange={handlePaginationChange}
              page={controller.page}
              className={classes.paginationPrevNextBtn}
            />
          )}
        </Box> */}
      </Fragment>
    </>
  );
};
export default CustomersTable;
