import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Button } from "@mui/material";

function SubsModal({ open, onConfirm, headerText, bodyText }: any) {
  const handleClose = () => {
    onConfirm();
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "44.8vw",
          bgcolor: "rgba(255, 255, 255, 0.9)",
          borderRadius: "8px",
          boxShadow: 24,
          padding: "16px",
          minHeight: "200px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="success-container">
          <div>
            <ErrorOutlineIcon sx={{ fontSize: 70, color: "#F34A4A" }} />
          </div>
          <h2 className="success-header">{headerText}</h2>
          <p className="success-desc">{bodyText}</p>
          <button onClick={handleClose}  style={{ marginTop: '16px' , backgroundColor: "#8b5cff", fontFamily: "Poppins", fontWeight: "500", color: "white", padding: '5px', borderRadius: '8px', width: '100px', justifyContent: "center", alignItems: "center",display: "flex", border: 'none'}}>Close</button>
        </div>
      </Box>
    </Modal>
  );
}

export default SubsModal;
