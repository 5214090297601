import React from 'react'
import './BackNavigation.css'
import BaackNavigationIcon from '../assets/images/BackNavigation.png'
import { useNavigate } from 'react-router-dom';


function BackNavigation({ label }: any) {
    const navigate = useNavigate();


    const handleGoBack = () => {
        navigate(-1)
        console.log("Pressed")
    };

    return (
        <div className='container'>
            <div onClick={handleGoBack} className="navigation-container" style={{ cursor: 'pointer' }}>
                <img src={BaackNavigationIcon} alt='back' style={{ width: '6px', height: '12px', margin: 0, padding: 0 }} />
                <h3 className='back'>{label}</h3>
            </div>
        </div>
    )
}

export default BackNavigation