import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useSnackbar } from "notistack";
import "./Password.css";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import { useAppDispatch } from "../../Hooks";
import {
  setIsLogin,
  setPosConnect,
  setUserObject,
} from "../../store/reducers/AuthReducer";
import BGIMAGE from "../../assets/images/background.jpg";
import LOGO from "../../assets/images/logo_main_img.png";
import { Grid, TextField, Typography, Button, OutlinedInput, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function Password() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { email } = location.state;
  const [password, setPassword]: any = useState("");
  const [passwordError, setPasswordError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const Navigate = useNavigate();

  const HandleLogin = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (password === "") {
      // enqueueSnackbar("Please enter your password", {
      //   variant: "error",
      //   anchorOrigin: { vertical: "top", horizontal: "right" },
      //   style: { fontFamily: "Poppins", fontWeight: "500" },
      // });
      setErrorMessage("Please enter your password")
      setPasswordError(true)
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      return;
    }
    const Params = {
      email: email,
      password: password,
    };

    try {
      const response = await HelperModule().postMethod(
        ApiServices.base_URL + ApiServices.login,
        Params
      );
      console.log("USER===>", response?.user);
      if (response.status === true) {
        localStorage.setItem("referralCode", response.user?.referral_code);
        localStorage.setItem("IsLogin", response);
        localStorage.setItem("UserName", response.user?.name);
        localStorage.setItem("Email", response.user?.email);
        localStorage.setItem("UserCode", response.user?.user_code);
        dispatch(setPosConnect(response.user?.pos_account_status));
        dispatch(setUserObject(response?.user));
        console.log(response?.user, "PASSWORD");
        // localStorage.setItem("PosAccount", response.user?.pos_account_status);
        localStorage.setItem(
          "Subscription",
          response.user?.subscription_status
        );
        localStorage.setItem("UserID", response.user?.id);
        localStorage.setItem("Token", response.token);
        dispatch(setIsLogin(true));
      } else if (response.status === false) {
        // enqueueSnackbar(response.message, {
        //   variant: "error",
        //   anchorOrigin: { vertical: "top", horizontal: "right" },
        //   style: { fontFamily: "Poppins", fontWeight: "500" },
        // });
      setErrorMessage(response.message)
      setPasswordError(true)
      } else {
        // enqueueSnackbar(response.message, {
        //   variant: "error",
        //   anchorOrigin: { vertical: "top", horizontal: "right" },
        //   style: { fontFamily: "Poppins", fontWeight: "500" },
        // });
        setErrorMessage(response.message)
        setPasswordError(true)
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={5}
          xl={5}
          className="grid-container-password"
        >
          <div className="container-hide"></div>
          <div>
            <div className="header-text">
              <Typography
                variant="h4"
                sx={{
                  typography: {
                    xs: "h4",
                    sm: "h4",
                    md: "h4",
                    lg: "h4",
                    xl: "h4",
                  },
                  color: "#201C1C",
                  fontFamily: "Poppins",
                }}
              >
                Welcome back to your business account
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ color: "#201C1CB8", fontFamily: "Poppins" }}
              >
                Enter your password to log in as{" "}
                <span style={{ color: "#8B5CFF" }}>{email}</span>
              </Typography>
            </div>
            <div className="form-container">
              <div>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#201C1C",
                    fontFamily: "Poppins",
                    textAlign: "left",
                  }}
                  mb={1}
                  mt={2}
                  className="email-header"
                >
                  Password
                </Typography>
                {/* <TextField
                  error={passwordError}
                  placeholder="Enter password"
                  id="outlined-basic"
                  variant="outlined"
                  helperText={passwordError ? errorMessage : ""}
                  onChange={(e) => setPassword(e.target.value)}
                  className="email-textfield"
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "4rem",
                      borderRadius: "8px",
                    },
                  }}
                /> */}
                <OutlinedInput
                  error={passwordError ? true : false}
                  id="outlined-adornment-password"
                  className="email-textfield"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter password"
                  sx={{
                    "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                      height: "2rem",
                      borderRadius: "8px",
                    },
                  }}
                  onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={togglePasswordVisibility}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {passwordError && <Typography variant="body2" color="error">
                  {errorMessage}
                </Typography>}
              </div>
              <p
                className="forgotpassword"
                onClick={() => Navigate("/forgetpassword")}
              >
                Forgot your password?
              </p>
              <Button
                type="submit"
                variant="contained"
                onClick={HandleLogin}
                sx={{
                  marginTop: "1rem",
                  height: "4rem",
                  fontSize: "1.2rem",
                  backgroundColor: "#8B5CFF",
                  borderRadius: "8px",
                  ":hover": {
                    bgcolor: "#8B5CFF",
                  },
                }}
                className="continue-button-width"
              >
                {loading ? "Loading..." : "Login"}
              </Button>
            </div>
          </div>
          <div className="kiosk_image_logo">
            <img src={LOGO} alt="logo" style={{ width: "150px", display: "block", margin: "auto" }} />
          </div>
        </Grid>
        <Grid
          item
          xs={0}
          sm={6}
          md={6}
          lg={7}
          xl={7}
          className="container-image"
        >
          <img
            src={BGIMAGE}
            alt="bg"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Password;
