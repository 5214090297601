import {
  Box,
  Checkbox,
  CircularProgress,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

import AddCircle from "../../assets/images/add-circle.svg";
import Test_Image from "../../assets/images/Test.png";
import ApiServices from "../../helpers/ApiServices";
import HelperModule from "../../helpers/HelperModule";

import "./DisplayStyle.css";
import _ from "lodash";

let UserCodeData: any;
let AuthTokenId: any;
const defaultImage = require("../../assets/images/180_180.png");
interface TimeSlot {
  startTime: string;
  endTime: string;
}
function Display() {
  const [listData, setListData]: any = useState([]);
  const [showKioskName, setShowKioskName] = useState("");
  const [exitpassword, setExitPassword] = useState("");
  const [printingScreenTitle, setprintingScreenTitle] = useState("");
  const [printingScreenTitleDesc, setprintingScreenDesc] = useState("");
  const [approvedPayment, setapprovedPayment] = useState("");
  const [disapprovedPayment, setdisapprovedPayment] = useState("");
  const [thankyouTitle, setThankyouTitle] = useState("");
  const [tipTitle, setTipTitle] = useState("");
  const [thankyouDesc, setThankyouDesc] = useState("");
  const [checkoutsuccessTitle, setCheckoutSuccessTitle] = useState("");
  const [checkoutsuccessDesc, setCheckoutSuccessDesc] = useState("");
  const [promoCodeText, setPromoCodeText] = useState("");
  const [promoCodeprompt, setPromoCodePrompt] = useState("");
  const [customMessage, setCustomMessage] = useState("");
  const [kioskImage, setKioskImage]: any = useState<File | null>(null);
  const [kioskVideo, setKioskVideo]: any = useState<File | null>(null);
  const [imageDisplay, setImageDisplay] = useState(false);
  const [kioskVideoType, setKioskVideoType] = useState<String>("");
  const [starting_screen_file_type, setStarting_screen_file_type] = useState("")
  const [loading, setLoading] = useState(false);
  const [business_hoursModal, setBusinessHoursModal] = useState(false);
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([
    { startTime: "", endTime: "" },
  ]);

  const [enableMob, setEnableMob] = useState(0);
  const daysOfWeek = [
    { name: "Monday", value: "MON" },
    { name: "Tuesday", value: "TUE" },
    { name: "Wednesday", value: "WED" },
    { name: "Thursday", value: "THU" },
    { name: "Friday", value: "FRI" },
    { name: "Saturday", value: "SAT" },
    { name: "Sunday", value: "SUN" },
  ];

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      let days = daysOfWeek?.map((elem: any) => elem?.name);
      setSelectedDays(days);
      setSelectAll(true);
    } else {
      setSelectedDays([]);
      setSelectAll(false);
    }
  };

  const handleDaySelect = (event: SelectChangeEvent<typeof selectedDays>) => {
    const {
      target: { value },
    } = event;
    setSelectedDays(value as string[]);
    setSelectAll(value.length === daysOfWeek.length);
  };

  const handleAddTimeSlot = () => {
    setTimeSlots([...timeSlots, { startTime: "", endTime: "" }]);
  };

  const handleTimeSlotChange = (
    index: number,
    field: keyof TimeSlot,
    value: any
  ) => {
    let updatedTimeSlots = [...timeSlots];
    let val = updatedTimeSlots[index];
    val[field] = dayjs(value).format("HH:mm:ss");
    updatedTimeSlots.splice(index, 1, val);
    setTimeSlots([...updatedTimeSlots]);
  };

  useEffect(() => {
    const UserCode = localStorage.getItem("UserCode");
    UserCodeData = UserCode;
  }, []);

  useEffect(() => {
    const TokenData: any = localStorage.getItem("Token");
    AuthTokenId = TokenData;
  }, []);

  const handleImageUpload = (e: any) => {
    const selectedImage = e.target.files?.[0];
    if (selectedImage) {
      console.log("Selected Image:", selectedImage);
      setImageDisplay(true)
      setKioskImage(selectedImage);
    } else {
      console.log("No Image selected");
    }
  };

  const handleImageRemove = () => {
    setKioskImage("");
    setImageDisplay(false);
  };

  const handleRemoveVideo = () => {
    setKioskVideo("");
  };

  const handleVideoUpload = (e: any) => {
    const selectedVideo = e.target.files?.[0];
    if (selectedVideo) {
      console.log("Selected Video:", selectedVideo);
      setKioskVideo(selectedVideo);
      setKioskVideoType(selectedVideo.type);
    } else {
      console.log("No video selected");
    }
  };

  const DisplayDataIniitial = async () => {
    setLoading(true);
    const headers = {
      Authorization: `Bearer ${AuthTokenId}`,
    };
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
          ApiServices.styling_display_list +
          `?user_code=${UserCodeData}`,
        headers
      );
      if (response.status === true) {
        // setKioskImage(response.stylingDisplayList[0].show_kiosk_name);
        console.log("STYLE===>", response.stylingDisplayList[0])
        setStarting_screen_file_type(response.stylingDisplayList[0].starting_screen_file_type)
        setListData(response.stylingDisplayList[0]);
        setShowKioskName(response.stylingDisplayList[0].name);
        setExitPassword(response.stylingDisplayList[0].exit_password);
        setprintingScreenTitle(
          response.stylingDisplayList[0].printing_screen_title
        );
        setprintingScreenDesc(
          response.stylingDisplayList[0].printing_screen_description
        );
        setapprovedPayment(
          response.stylingDisplayList[0].approved_payment_screen_test
        );
        setdisapprovedPayment(
          response.stylingDisplayList[0].disapproved_payment_screen_test
        );
        setThankyouTitle(response.stylingDisplayList[0].thank_you_screen_title);
        setEnableMob(response?.stylingDisplayList[0]?.text_me_receipt);
        setTipTitle(response.stylingDisplayList[0].tip_description);
        setThankyouDesc(
          response.stylingDisplayList[0].thank_you_screen_description
        );
        setCheckoutSuccessTitle(
          response.stylingDisplayList[0].checkout_success_title
        );
        setCheckoutSuccessDesc(
          response.stylingDisplayList[0].checkout_success_description
        );
        setPromoCodeText(response.stylingDisplayList[0].promo_code_button_text);
        setPromoCodePrompt(response.stylingDisplayList[0].promo_code_prompt);
        setCustomMessage(response.stylingDisplayList[0].custom_message);
        setKioskVideo(
          response.stylingDisplayList[0]
            .display_image_or_video_for_starting_screen
        );
        setImageDisplay(
          response.stylingDisplayList[0].show_kiosk_name ? true : false
        );
        console.log(
          response.stylingDisplayList[0].powered_by_miniosk,
          "powered_by_miniosk"
        );
        setLoading(false);
      } else {
        enqueueSnackbar("Data not found", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (checkboxName: string) => {
    setListData(
      (prevListData: { [key: string]: number }) => {
        const updatedListData = { ...prevListData };
        updatedListData[checkboxName] =
          updatedListData[checkboxName] === 1 ? 0 : 1;
        const checkboxGroups = {
          name_checkbox: ["name_checkbox"],
          show_kiosk_name_checkbox: ["show_kiosk_name_checkbox"],
          display_images_checkbox: ["display_images_checkbox"],
          display_image_or_video_for_starting_screen_checkbox: [
            "display_image_or_video_for_starting_screen_checkbox",
          ],
          custom_message: ["custom_message_checkbox"],
          apply_promocode: [
            "display_a_button_in_the_cart_to_apply_promocodes_checkbox",
          ],
          powered_by_miniosk: ["powered_by_miniosk"],

          // display_image_check: ['display_images_checkbox']
        };

        const currentGroup = Object.entries(checkboxGroups).find((group) =>
          group[1].includes(checkboxName)
        );

        if (currentGroup) {
          const [, groupCheckboxes] = currentGroup;
          groupCheckboxes.forEach((groupCheckbox) => {
            if (groupCheckbox !== checkboxName) {
              updatedListData[groupCheckbox] = 0;
            }
          });
        }

        return updatedListData;
      },
      () => {
        DisplayData();
      }
    );
  };

  function validateFormData(listData: any, otherData: any): string | null {
    const fieldsToValidate = [
      { key: "name_checkbox", label: "Name checkbox" },
      { key: "show_kiosk_name_checkbox", label: "Show kiosk name checkbox" },
      { key: "display_image_or_video_for_starting_screen_checkbox", label: "Display image or video checkbox" },
      { key: "custom_message_checkbox", label: "Custom message checkbox" },
      { key: "display_a_button_in_the_cart_to_apply_promocodes_checkbox", label: "Promo code button checkbox" },
      { key: "display_images_checkbox", label: "Display images checkbox" },
      { key: "exitpassword", label: "Exit password", isOtherData: true },
      { key: "printingScreenTitle", label: "Printing screen title", isOtherData: true },
      { key: "printingScreenTitleDesc", label: "Printing screen description", isOtherData: true },
      { key: "approvedPayment", label: "Approved payment screen status", isOtherData: true },
      { key: "disapprovedPayment", label: "Disapproved payment screen status", isOtherData: true },
      { key: "thankyouTitle", label: "Thank you screen title", isOtherData: true },
      { key: "thankyouDesc", label: "Thank you screen description", isOtherData: true },
      { key: "checkoutsuccessTitle", label: "Checkout success title", isOtherData: true },
      { key: "checkoutsuccessDesc", label: "Checkout success description", isOtherData: true },
      { key: "showKioskName", label: "Kiosk name", isOtherData: true },
      { key: "customMessage", label: "Custom message", isOtherData: true },
      { key: "promoCodeText", label: "Promo code button text", isOtherData: true },
      { key: "promoCodeprompt", label: "Promo code prompt", isOtherData: true },
      { key: "tipTitle", label: "Tip description", isOtherData: true },
      { key: "enableMob", label: "Text me receipt option", isOtherData: true },
      { key: "powered_by_miniosk", label: "Powered by Miniosk checkbox" },
    ];

    for (const field of fieldsToValidate) {
      const value = field.isOtherData ? otherData[field.key] : listData?.[field.key];
      if (value === undefined || value === null || value === "") {
        return `${field.label} is required.`;
      }
    }

    return null;
  }

  const DisplayData = async () => {
    const otherData = {
      exitpassword,
      printingScreenTitle,
      printingScreenTitleDesc,
      approvedPayment,
      disapprovedPayment,
      thankyouTitle,
      thankyouDesc,
      checkoutsuccessTitle,
      checkoutsuccessDesc,
      showKioskName,
      customMessage,
      promoCodeText,
      promoCodeprompt,
      tipTitle,
      enableMob,
    };

    const validationError = validateFormData(listData, otherData);
    if (validationError) {
      enqueueSnackbar(validationError, {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      return;
    }

    const formData: any = new FormData();
    formData.append("name_checkbox", listData?.name_checkbox);
    formData.append(
      "show_kiosk_name_checkbox",
      listData?.show_kiosk_name_checkbox
    );
    formData.append(
      "display_image_or_video_for_starting_screen_checkbox",
      listData?.display_image_or_video_for_starting_screen_checkbox
    );
    formData.append(
      "custom_message_checkbox",
      listData?.custom_message_checkbox
    );
    formData.append(
      "display_a_button_in_the_cart_to_apply_promocodes_checkbox",
      listData?.display_a_button_in_the_cart_to_apply_promocodes_checkbox
    );
    formData.append(
      "display_images_checkbox",
      listData?.display_images_checkbox
    );
    formData.append("exit_password", exitpassword);
    formData.append(
      "new_business_hours",
      JSON.stringify([
        { day: "Monday", start_time: "08:00 AM", end_time: "05:00 PM" },
      ])
    );
    formData.append("printing_screen_title", printingScreenTitle);
    formData.append("printing_screen_description", printingScreenTitleDesc);
    formData.append("approved_payment_screen_status", approvedPayment);
    formData.append(
      "approved_payment_screen_test",
      "Your payment has been successfully processed."
    );
    formData.append("disapproved_payment_screen_test", disapprovedPayment);
    formData.append("thank_you_screen_title", thankyouTitle);
    formData.append("thank_you_screen_description", thankyouDesc);
    formData.append("checkout_success_title", checkoutsuccessTitle);
    formData.append("checkout_success_description", checkoutsuccessDesc);
    formData.append("name", showKioskName);
    formData.append("custom_message", customMessage);
    formData.append("promo_code_button_text", promoCodeText);
    formData.append("promo_code_prompt", promoCodeprompt);
    formData.append("user_code", UserCodeData);
    formData.append("tip_description", tipTitle);
    formData.append("text_me_receipt", enableMob);

    formData.append("powered_by_miniosk", listData?.powered_by_miniosk);
    if (kioskImage || kioskImage === "") {
      formData.append("show_kiosk_name", kioskImage);
    }
    if (kioskVideo || kioskVideo === "") {
      formData.append("display_image_or_video_for_starting_screen", kioskVideo);
    }
    console.log("formData09090", formData.get("show_kiosk_name"));
    try {
      const response = await HelperModule().postFormDataMethod(
        ApiServices.base_URL + ApiServices.styling_display,
        formData
      );
      console.log(response);
      if (response.status === true) {
        console.log(response);
        enqueueSnackbar(response.message, {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
        DisplayDataIniitial();
      } else if (response.status === false) {
        console.log(response);
        // enqueueSnackbar(response.errors.email[0], { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }, style: { fontFamily: 'Poppins', fontWeight: '500' }, });
      }
    } catch (error) {
      // enqueueSnackbar("Incorrect password", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }, style: { fontFamily: 'Poppins', fontWeight: '500' }, });
      console.log("error catch", error);
    }
  };

  useEffect(() => {
    DisplayDataIniitial();
  }, []);

  console.log(
    _.isEmpty(kioskImage) && imageDisplay,
    kioskImage,
    "<===Image"
  );

  return (
    <div className="mainContainer">
      {loading ? (
        <div className="Loader-container">
          <CircularProgress disableShrink sx={{ color: "#8B5CFF" }} />
        </div>
      ) : (
        <>
          <h1 className="display-mian-content">Display</h1>
          <p className="display-mian-sub-content">
            Configure how you'd like your miniosk to display.
          </p>
          <h1 className="display-mian-content" style={{ paddingTop: "20px" }}>
            General
          </h1>
          <p className="display-mian-sub-content">
            Basic settings for your miniosk.
          </p>
          <div className="cards">
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={listData?.name_checkbox === 1}
                onChange={() => handleCheckboxChange("name_checkbox")}
              />
              <span className="text-[12px]" style={{ fontFamily: "Poppins" }}>
                Show miniosk name
              </span>
            </label>
            <div>
              <input
                value={showKioskName}
                onChange={(e: any) => setShowKioskName(e.target.value)}
                type="text"
                placeholder="Name"
                style={{
                  fontFamily: "Poppins",
                  borderRadius: "8px",
                  border: "1px solid #201C1C3D",
                  width: "95%",
                  height: "7.13vh",
                  marginTop: "8px",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "16px",
                  fontSize: "14px",
                  outline: "none",
                }}
              />
              <p className="input-sub-lable">
                This is the name that will be displayed in the app.
              </p>
            </div>
          </div>
          <div className="cards">
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={listData?.show_kiosk_name_checkbox === 1}
                onChange={() =>
                  handleCheckboxChange("show_kiosk_name_checkbox")
                }
              />
              <span className="text-[12px]" style={{ fontFamily: "Poppins" }}>
                Show miniosk image
              </span>
            </label>
            <p className="about-item">
              This is the name that will be displayed in the app.
            </p>
            <div className="upload-container" style={{ paddingTop: "10px" }}>
              <div
                style={{
                  width: "180px",
                  height: "180px",
                  overflow: "hidden",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {(_.isEmpty(kioskImage) && imageDisplay) ? (
                  <img
                    src={
                      kioskImage
                        ? URL.createObjectURL(kioskImage)
                        : listData?.show_kiosk_name
                    }
                    alt="Miniosk"
                    style={{ width: "100%", height: "100%" }}
                  />
                ) : (
                  <label htmlFor="imageInput" style={{ cursor: "pointer" }}>
                    <svg
                      fill="#201c1c7a"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 50 50"
                      width="80px"
                      height="80px"
                    >
                      <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 24 13 L 24 24 L 13 24 L 13 26 L 24 26 L 24 37 L 26 37 L 26 26 L 37 26 L 37 24 L 26 24 L 26 13 L 24 13 z" />
                    </svg>
                  </label>
                )}
                {/* {kioskImage ? (
                  <img
                    src={
                      !_.isEmpty(kioskImage) && typeof kioskImage === "string"
                        ? kioskImage
                        : typeof kioskImage !== "string"
                        ? URL.createObjectURL(kioskImage)
                        : defaultImage
                    }
                    alt="Miniosk"
                    style={{ width: "100%", height: "100%" }}
                  />
                ) : (
                  <label htmlFor="videoInput" style={{ cursor: "pointer" }}>
                    <svg
                      fill="#201c1c7a"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 50 50"
                      width="80px"
                      height="80px"
                    >
                      <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 24 13 L 24 24 L 13 24 L 13 26 L 24 26 L 24 37 L 26 37 L 26 26 L 37 26 L 37 24 L 26 24 L 26 13 L 24 13 z" />
                    </svg>
                  </label>
                )} */}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "8px",
                }}
              >
                <label
                  htmlFor="imageInput"
                  className="upload-button"
                  style={{ width: "150px", cursor: "pointer" }}
                >
                  Upload
                </label>
                <input
                  type="file"
                  accept="image/*"
                  id="imageInput"
                  onChange={handleImageUpload}
                  style={{ display: "none" }}
                />
                <button onClick={handleImageRemove} className="remove-button">
                  Remove
                </button>
              </div>
            </div>
          </div>

          <div className="cards">
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={listData?.custom_message_checkbox === 1}
                onChange={() => handleCheckboxChange("custom_message_checkbox")}
              />
              <span className="text-[12px]" style={{ fontFamily: "Poppins" }}>
                Start screen Display
              </span>
            </label>
            <div>
              <p className="labelForDisplay">Custom message</p>
              <input
                value={customMessage}
                onChange={(e: any) => setCustomMessage(e.target.value)}
                type="text"
                placeholder="Type here"
                className="w-[45vw] h-[5vh] mt-1 rounded-[12px] border border-[#dddddd] font-normal text-[12px] pl-[12px]"
                style={{
                  fontFamily: "Poppins",
                  borderRadius: "8px",
                  border: "1px solid #201C1C3D",
                  width: "95%",
                  height: "7.13vh",
                  marginTop: "8px",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "16px",
                  fontSize: "14px",
                  outline: "none",
                }}
              />
              <p className="input-sub-lable">
                Use this field to change the message or default will be "tap to
                order"
              </p>
            </div>
          </div>

          <div className="cards">
            <div>
              <p className="card-title">Exit Password</p>
              <input
                type="text"
                placeholder="Exit Password"
                value={exitpassword}
                onChange={(e: any) => setExitPassword(e.target.value)}
                className="w-[45vw] h-[5vh] mt-1 rounded-[12px] border border-[#dddddd] font-normal text-[12px] pl-[12px]"
                style={{
                  fontFamily: "Poppins",
                  borderRadius: "8px",
                  border: "1px solid #201C1C3D",
                  width: "95%",
                  height: "7.13vh",
                  marginTop: "8px",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "16px",
                  fontSize: "14px",
                  outline: "none",
                }}
              />
              <p className="input-sub-lable">
                The default is 'PASSWORD'. When miniosk mode is active, to exit:
                tap and hold down on the miniosk name, logo, or CART label for a
                few seconds to prompt for the exit password OR double tap on the
                miniosk name, logo, or CART label to prompt for the exit
                password.
              </p>
            </div>
          </div>
          {/* <div className="cards">
            <div>
              <p className="card-title">Business Hours</p>
              <p className="input-sub-lable">
                Set up business hours to prevent customers from ordering on your
                minioskat certain times of the day. You can set up as many time
                blocks in the day as needed. You can also leave this blank if
                you'd like the miniosk to be available at all hours of the day.
              </p>
              <button
                onClick={() => setBusinessHoursModal(true)}
                className="business-button"
                style={{ transition: "background-color 0.3s" }}
                onMouseEnter={(e) => {
                  (e.target as HTMLButtonElement).style.backgroundColor =
                    "#6d43e57D";
                }}
                onMouseLeave={(e) => {
                  (e.target as HTMLButtonElement).style.backgroundColor =
                    "#8B5CFF";
                }}
              >
                New Business Hours
              </button>
            </div>
          </div> */}
          <div style={{ marginTop: "10px" }}>
            <h1 className="advance-content">Advanced Display</h1>
            <p className="display-advance-sub-content">
              Settings used to control your certain visual aspects. If you're
              looking for more styling options, check out the Styling section.
            </p>
          </div>
          <div className="cards">
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={
                  listData?.display_image_or_video_for_starting_screen_checkbox ===
                  1
                }
                onChange={() =>
                  handleCheckboxChange(
                    "display_image_or_video_for_starting_screen_checkbox"
                  )
                }
              />
              <span className="text-[12px]" style={{ fontFamily: "Poppins" }}>
                Display images or Video for starting screen
              </span>
            </label>
            <div className="upload-container" style={{ paddingTop: "10px" }}>
              <div
                style={{
                  width: "180px",
                  height: "180px",
                  overflow: "hidden",
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
                }}
              >
                {/* {kioskVideo ? (
                                    <img
                                        src={kioskVideo ? URL.createObjectURL(kioskVideo) : listData?.display_image_or_video_for_starting_screen}
                                        alt="Uploaded"
                                        style={{ width: "100%", height: "100%" }}
                                    />
                                )
                                    :
                                    <img alt='Miniosk' src={listData?.display_image_or_video_for_starting_screen ? listData?.display_image_or_video_for_starting_screen : defaultImage} style={{ width: "100%", height: "100%" }} />
                                } */}
                {kioskVideo && starting_screen_file_type === "image" &&
                  (typeof kioskVideo === "string" ||
                    kioskVideoType.includes("image")) && (
                    // If it's an image file
                    <img
                      alt="Miniosk"
                      src={
                        !_.isEmpty(kioskVideo) && typeof kioskVideo === "string"
                          ? kioskVideo
                          : typeof kioskVideo !== "string"
                          ? URL.createObjectURL(kioskVideo)
                          : defaultImage
                        // (!_.isEmpty(kioskVideo) && typeof kioskVideo !== "string")
                        //   ? URL.createObjectURL(kioskVideo)
                        //   : videoDisplay ? listData?.display_image_or_video_for_starting_screen :
                        //   defaultImage
                      }
                      style={{ width: "100%", height: "100%" }}
                    />
                  )}
                {
                  kioskVideo && starting_screen_file_type === "video" &&
                    (typeof kioskVideo === "string" ||
                      kioskVideoType.includes("video")) && (
                      // If it's a video file
                      <video
                        controls
                        style={{ width: "100%", height: "100%" }}
                        autoPlay
                        muted
                      >
                        <source
                          src={
                            !_.isEmpty(kioskVideo) &&
                            typeof kioskVideo === "string"
                              ? kioskVideo
                              : typeof kioskVideo !== "string"
                              ? URL.createObjectURL(kioskVideo)
                              : defaultImage
                            // (!_.isEmpty(kioskVideo) && typeof kioskVideo !== "string")
                            //   ? URL.createObjectURL(kioskVideo)
                            //   : videoDisplay ? listData?.display_image_or_video_for_starting_screen : defaultImage
                          }
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    )

                  // : (
                  //   <img
                  //     alt="Miniosk Default"
                  //     src={AddCircle}
                  //     style={{ width: "100%", height: "100%" }}
                  //   />
                  //   // <label htmlFor="videoInput" style={{ cursor: "pointer" }}>
                  //   //   <svg
                  //   //     fill="#201c1c7a"
                  //   //     xmlns="http://www.w3.org/2000/svg"
                  //   //     viewBox="0 0 50 50"
                  //   //     width="80px"
                  //   //     height="80px"
                  //   //   >
                  //   //     <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 24 13 L 24 24 L 13 24 L 13 26 L 24 26 L 24 37 L 26 37 L 26 26 L 37 26 L 37 24 L 26 24 L 26 13 L 24 13 z" />
                  //   //   </svg>
                  //   // </label>
                  // )
                }
                {!kioskVideo && (
                  // <img
                  //   src={defaultImage}
                  //   alt="Miniosk"
                  //   style={{ width: "100%", height: "100%" }}
                  // />
                  <label htmlFor="videoInput" style={{ cursor: "pointer" }}>
                    <svg fill="#201c1c7a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="80px" height="80px"><path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 24 13 L 24 24 L 13 24 L 13 26 L 24 26 L 24 37 L 26 37 L 26 26 L 37 26 L 37 24 L 26 24 L 26 13 L 24 13 z" /></svg>
                  </label>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "8px",
                }}
              >
                <label
                  htmlFor="videoInput"
                  className="upload-button"
                  style={{ width: "150px", cursor: "pointer" }}
                >
                  Upload
                </label>
                <input
                  type="file"
                  accept="image/*,video/*"
                  id="videoInput"
                  onChange={handleVideoUpload}
                  style={{ display: "none" }}
                />
                <button onClick={handleRemoveVideo} className="remove-button">
                  Remove
                </button>
              </div>
            </div>
          </div>

          <div className="cards">
            <div>
              {/* <p className="labelForDisplay">Customer Phone Number to send text receipt</p>
              <input
                value={tipTitle}
                onChange={(e: any) => setTipTitle(e.target.value)}
                type="text"
                placeholder="Type here"
                className="w-[45vw] h-[5vh] mt-1 rounded-[12px] border border-[#dddddd] font-normal text-[12px] pl-[12px]"
                style={{
                  fontFamily: "Poppins",
                  borderRadius: "8px",
                  border: "1px solid #201C1C3D",
                  width: "95%",
                  height: "7.13vh",
                  marginTop: "8px",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "16px",
                  fontSize: "14px",
                  outline: "none",
                }}
              />
              <p className="input-sub-lable">
                Use this to control the message that shows up in the last.
                default will be "Add a tip!"
              </p> */}
              <p className="labelForDisplay">
                Customer Phone Number This uploaded image will showto send text
                receipt
              </p>
              <Box display={"flex"} columnGap={2}>
                <label className="checkbox-label" style={{ marginTop: 0 }}>
                  <input
                    type="checkbox"
                    checked={enableMob ? true : false}
                    onChange={(e) => setEnableMob(e.target.checked ? 1 : 0)}
                  />
                  <span
                    className="text-[12px]"
                    style={{ fontFamily: "Poppins" }}
                  >
                    Enable
                  </span>
                </label>
              </Box>
              <p className="input-sub-lable">
                Use this to enable or disable phone number on receipt.
              </p>
            </div>
          </div>

          <div className="cards">
            <div>
              <p className="labelForDisplay">Printing Screen Title</p>
              <input
                value={printingScreenTitle}
                onChange={(e: any) => setprintingScreenTitle(e.target.value)}
                type="text"
                placeholder="Type here"
                className="w-[45vw] h-[5vh] mt-1 rounded-[12px] border border-[#dddddd] font-normal text-[12px] pl-[12px]"
                style={{
                  fontFamily: "Poppins",
                  borderRadius: "8px",
                  border: "1px solid #201C1C3D",
                  width: "95%",
                  height: "7.13vh",
                  marginTop: "8px",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "16px",
                  fontSize: "14px",
                  outline: "none",
                }}
              />
              <p className="input-sub-lable">
                Use this to control the message that shows up while orders are
                being sent to any receipt printers.
              </p>
            </div>
            <div>
              <p className="labelForDisplay">Printing Screen Description</p>
              <input
                value={printingScreenTitleDesc}
                onChange={(e: any) => setprintingScreenDesc(e.target.value)}
                type="text"
                placeholder="Type here"
                className="w-[45vw] h-[5vh] mt-1 rounded-[12px] border border-[#dddddd] font-normal text-[12px] pl-[12px]"
                style={{
                  fontFamily: "Poppins",
                  borderRadius: "8px",
                  border: "1px solid #201C1C3D",
                  width: "95%",
                  height: "7.13vh",
                  marginTop: "8px",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "16px",
                  fontSize: "14px",
                  outline: "none",
                }}
              />
              <p className="input-sub-lable">
                Use this to control the secondary message that shows up while
                orders are being sent to any receipt printers.
              </p>
            </div>
          </div>
          <div className="cards">
            <div>
              <p className="labelForDisplay">Approved payment Screen Text</p>
              <input
                value={approvedPayment}
                onChange={(e: any) => setapprovedPayment(e.target.value)}
                type="text"
                placeholder="Type here"
                className="w-[45vw] h-[5vh] mt-1 rounded-[12px] border border-[#dddddd] font-normal text-[12px] pl-[12px]"
                style={{
                  fontFamily: "Poppins",
                  borderRadius: "8px",
                  border: "1px solid #201C1C3D",
                  width: "95%",
                  height: "7.13vh",
                  marginTop: "8px",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "16px",
                  fontSize: "14px",
                  outline: "none",
                }}
              />
              <p className="input-sub-lable">
                Use this to control the message that shows up after orders
                payment get confirmed.
              </p>
            </div>
            <div>
              <p className="labelForDisplay">disapproved payment Screen Text</p>
              <input
                value={disapprovedPayment}
                onChange={(e: any) => setdisapprovedPayment(e.target.value)}
                type="text"
                placeholder="Type here"
                className="w-[45vw] h-[5vh] mt-1 rounded-[12px] border border-[#dddddd] font-normal text-[12px] pl-[12px]"
                style={{
                  fontFamily: "Poppins",
                  borderRadius: "8px",
                  border: "1px solid #201C1C3D",
                  width: "95%",
                  height: "7.13vh",
                  marginTop: "8px",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "16px",
                  fontSize: "14px",
                  outline: "none",
                }}
              />
              <p className="input-sub-lable">
                Use this to control the message that shows up after orders
                payment not get confirmed.
              </p>
            </div>
          </div>

          <div className="cards">
            <div>
              <p className="labelForDisplay">Tip Screen Title</p>
              <input
                value={tipTitle}
                onChange={(e: any) => setTipTitle(e.target.value)}
                type="text"
                placeholder="Type here"
                className="w-[45vw] h-[5vh] mt-1 rounded-[12px] border border-[#dddddd] font-normal text-[12px] pl-[12px]"
                style={{
                  fontFamily: "Poppins",
                  borderRadius: "8px",
                  border: "1px solid #201C1C3D",
                  width: "95%",
                  height: "7.13vh",
                  marginTop: "8px",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "16px",
                  fontSize: "14px",
                  outline: "none",
                }}
              />
              <p className="input-sub-lable">
                Use this to control the message that shows up in the last.
                default will be "Add a tip!"
              </p>
            </div>
          </div>

          <div className="cards">
            <div>
              <p className="labelForDisplay">Thank you screen title</p>
              <input
                value={thankyouTitle}
                onChange={(e: any) => setThankyouTitle(e.target.value)}
                type="text"
                placeholder="Type here"
                className="w-[45vw] h-[5vh] mt-1 rounded-[12px] border border-[#dddddd] font-normal text-[12px] pl-[12px]"
                style={{
                  fontFamily: "Poppins",
                  borderRadius: "8px",
                  border: "1px solid #201C1C3D",
                  width: "95%",
                  height: "7.13vh",
                  marginTop: "8px",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "16px",
                  fontSize: "14px",
                  outline: "none",
                }}
              />
              <p className="input-sub-lable">
                Use this to control the message that shows up in the last.
                default will be "You're all set!"
              </p>
            </div>
            <div>
              <p className="labelForDisplay">Thank you screen description</p>
              <input
                value={thankyouDesc}
                onChange={(e: any) => setThankyouDesc(e.target.value)}
                type="text"
                placeholder="Type here"
                className="w-[45vw] h-[5vh] mt-1 rounded-[12px] border border-[#dddddd] font-normal text-[12px] pl-[12px]"
                style={{
                  fontFamily: "Poppins",
                  borderRadius: "8px",
                  border: "1px solid #201C1C3D",
                  width: "95%",
                  height: "7.13vh",
                  marginTop: "8px",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "16px",
                  fontSize: "14px",
                  outline: "none",
                }}
              />
              <p className="input-sub-lable">
                Use this to control the message that shows up in the last.
                default will be "Have a nice day!"
              </p>
            </div>
          </div>
          <div className="cards">
            <div>
              <p className="labelForDisplay">Checkout Success Title</p>
              <input
                value={checkoutsuccessTitle}
                onChange={(e: any) => setCheckoutSuccessTitle(e.target.value)}
                type="text"
                placeholder="Type here"
                className="w-[45vw] h-[5vh] mt-1 rounded-[12px] border border-[#dddddd] font-normal text-[12px] pl-[12px]"
                style={{
                  fontFamily: "Poppins",
                  borderRadius: "8px",
                  border: "1px solid #201C1C3D",
                  width: "95%",
                  height: "7.13vh",
                  marginTop: "8px",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "16px",
                  fontSize: "14px",
                  outline: "none",
                }}
              />
              <p className="input-sub-lable">
                Use this to control the message that shows up when a customer
                finishes a transaction.
              </p>
            </div>
            <div>
              <p className="labelForDisplay">Checkout Success Description</p>
              <input
                value={checkoutsuccessDesc}
                onChange={(e: any) => setCheckoutSuccessDesc(e.target.value)}
                type="text"
                placeholder="Type here"
                className="w-[45vw] h-[5vh] mt-1 rounded-[12px] border border-[#dddddd] font-normal text-[12px] pl-[12px]"
                style={{
                  fontFamily: "Poppins",
                  borderRadius: "8px",
                  border: "1px solid #201C1C3D",
                  width: "95%",
                  height: "7.13vh",
                  marginTop: "8px",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "16px",
                  fontSize: "14px",
                  outline: "none",
                }}
              />
              <p className="input-sub-lable">
                Use this to control the secondary message that shows up when a
                customer finishes a transaction.
              </p>
            </div>
          </div>
          {/* <div className="cards">
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={
                  listData?.display_a_button_in_the_cart_to_apply_promocodes_checkbox ===
                  1
                }
                onChange={() =>
                  handleCheckboxChange(
                    "display_a_button_in_the_cart_to_apply_promocodes_checkbox"
                  )
                }
              />
              <span className="text-[12px]" style={{ fontFamily: "Poppins" }}>
                Display a button in the cart to apply promo codes
              </span>
            </label>
            <div>
              <p className="labelForDisplay">Promo Code Button Text</p>
              <input
                value={promoCodeText}
                onChange={(e: any) => setPromoCodeText(e.target.value)}
                type="text"
                placeholder="Type here"
                className="w-[45vw] h-[5vh] mt-1 rounded-[12px] border border-[#dddddd] font-normal text-[12px] pl-[12px]"
                style={{
                  fontFamily: "Poppins",
                  borderRadius: "8px",
                  border: "1px solid #201C1C3D",
                  width: "95%",
                  height: "7.13vh",
                  marginTop: "8px",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "16px",
                  fontSize: "14px",
                  outline: "none",
                }}
              />
              <p className="input-sub-lable">
                Use this field to change the message that shows up in the button
                for applying promo codes.
              </p>
            </div>
            <div>
              <p className="labelForDisplay">Promo Code Prompt</p>
              <input
                value={promoCodeprompt}
                onChange={(e: any) => setPromoCodePrompt(e.target.value)}
                type="text"
                placeholder="Type here"
                className="w-[45vw] h-[5vh] mt-1 rounded-[12px] border border-[#dddddd] font-normal text-[12px] pl-[12px]"
                style={{
                  fontFamily: "Poppins",
                  borderRadius: "8px",
                  border: "1px solid #201C1C3D",
                  width: "95%",
                  height: "7.13vh",
                  marginTop: "8px",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "16px",
                  fontSize: "14px",
                  outline: "none",
                }}
              />
              <p className="input-sub-lable">
                Use this field to change the message that shows up when asking a
                customer for promo codes. The promo code prompt is automatically
                shown if there's at least one promo code for the active menu.
              </p>
            </div>
          </div> */}

          <div className="cards">
            <p className="labelForBehaviour" style={{ marginTop: "8px" }}>
              <input
                type="checkbox"
                checked={listData?.powered_by_miniosk === 1}
                onChange={() => handleCheckboxChange("powered_by_miniosk")}
              />
              <span className="checkbox-content">
                Display 'Powered by Miniosk' sticker
              </span>
            </p>
            <p className="aboutthebehaviourr pb-4">
              Keep this on to show a small, non-intrusive 'Powered by Miniosk'
              text at the bottom of the idle screen. This is completely optional
              but helps us out a ton, thanks in advance! 🙏
            </p>
          </div>
          <button
            onClick={DisplayData}
            className="common-button-blue"
            style={{ transition: "background-color 0.3s" }}
            onMouseEnter={(e) => {
              (e.target as HTMLButtonElement).style.backgroundColor =
                "#6d43e57D";
            }}
            onMouseLeave={(e) => {
              (e.target as HTMLButtonElement).style.backgroundColor = "#8B5CFF";
            }}
          >
            Update
          </button>
        </>
      )}

      <Modal
        open={business_hoursModal}
        onClose={() => setBusinessHoursModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            transition: "bottom 0.3s ease-in-out",
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "47.73vw",
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            padding: "16px",
            borderWidth: 0,
            overflowY: "auto",
          }}
        >
          <style>{`:: -webkit - scrollbar { width: 6px; } :: -webkit - scrollbar - track { display: none; } :: -webkit - scrollbar - thumb { background - color: transparent; } `}</style>
          <div className="import-title-container">
            <h3 className="import-title">New Business Hours</h3>
          </div>
          <h3 className="menu-heading" style={{ marginTop: "16px" }}>
            Select Days
          </h3>
          <InputLabel id="days-label">Select Days</InputLabel>
          <Select
            labelId="days-label"
            id="days-select"
            multiple
            value={selectedDays}
            onChange={handleDaySelect}
            renderValue={(selected) => (selected as string[]).join(", ")}
            style={{
              marginTop: "8px",
              width: "44.94vw",
              height: "7.20vh",
              borderRadius: "16px",
              borderWidth: "1px",
              borderColor: "#201C1C3D",
              padding: "10px 16px 10px 16px",
              outline: "none",
              fontWeight: "400",
              fontSize: "14px",
              fontFamily: "Poppins",
            }}
          >
            <MenuItem key="select-all">
              <Checkbox checked={selectAll} onChange={handleSelectAll} />
              <ListItemText primary="Select All" />
            </MenuItem>
            {daysOfWeek.map((day) => {
              return (
                <MenuItem key={day.name} value={day.name}>
                  <Checkbox checked={selectedDays?.includes(day.name)} />
                  <ListItemText primary={day.name} />
                </MenuItem>
              );
            })}
          </Select>

          <h3 className="menu-heading" style={{ marginTop: "16px" }}>
            Select Time Slots
          </h3>

          <div
            className="timeslots-input-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              // alignItems: "center",
            }}
          >
            {timeSlots?.map((slot, index) => (
              <div
                key={index}
                className="timeslot-input-row"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "8px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    gap: "8px",
                    width: "100%",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["TimePicker"]}>
                      <TimePicker
                        label="Start Time"
                        onChange={(val) =>
                          handleTimeSlotChange(index, "startTime", val)
                        }
                        value={
                          slot?.startTime
                            ? dayjs(slot?.startTime, "HH:mm:ss")
                            : null
                        }
                        maxTime={
                          slot?.endTime
                            ? dayjs(slot?.endTime, "HH:mm:ss")
                            : null
                        }
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["TimePicker"]}>
                      <TimePicker
                        label="End Time"
                        onChange={(val) =>
                          handleTimeSlotChange(index, "endTime", val)
                        }
                        value={
                          slot?.endTime
                            ? dayjs(slot?.endTime, "HH:mm:ss")
                            : null
                        }
                        minTime={
                          slot?.startTime
                            ? dayjs(slot?.startTime, "HH:mm:ss")
                            : null
                        }
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
            ))}
          </div>

          <div style={{ flexDirection: "row" }}>
            <button className="common-button-blue">
              {loading ? (
                <CircularProgress color="inherit" size={25} />
              ) : (
                "Create"
              )}
            </button>
            <button
              onClick={() => setBusinessHoursModal(false)}
              className="common-button-cancel"
            >
              Cancel
            </button>
          </div>
        </Box>
      </Modal>
      {/* <button className='common-button-cancel'>Cancel</button> */}
    </div>
  );
}

export default Display;
