const ApiServices = {
    base_URL: "https://api.miniosk.com/api/",
    // base_URL: "https://dev.miniosk.com/api/",

    login: "vendor/vendorLogin",
    register: "vendor/vendorRegistration",
    validate_email: 'vendor/validateEmail/',
    link_pos: "possystem/linkPosAccount",
    locations: 'square/locationsList/',
    get_vendor: 'vendor/getVendor/',
    update_vendor: 'vendor/updateVendor/',
    create_menu: 'menu/createMenu',
    menu_list: 'menu/menuList/',
    catalog_list: 'menu/getMenu/',
    verify_refferal: 'verify-referral',
    items_list: 'menu/items',
    category_list: 'menu/categories',
    item_variations: 'menu/item-variations',
    modifiers_list: 'menu/modifiers',
    creating_promo_code: 'promocodes/promotional-codes',
    updating_promo_code: 'promocodes/promotional-codes/',
    apply_promo_code: 'promocodes/apply-promo-code',
    promocode_list: 'promocodes/promoCodesList',
    promoCode_delete: 'promocodes/deletePromoCode/',
    get_otp: 'send-otp',
    verify_otp: 'verify-otp',
    customer_list: 'customers/list',
    Customer_order_details: 'customers/show/',
    Order_details: 'order/order-details',
    Invoice_download: 'order/download-order-receipt',
    manage_menus: 'manage/menus',
    manage_web_settings: 'manage/web-settings',
    manage_kiosk: 'manage/kiosks',
    styling_theme: 'styling/styling-theme',
    styling_theme_list: 'styling/styling-theme-list',
    styling_advanced: 'styling/styling-advanced',
    styling_advanced_list: 'styling/styling-advanced-list',
    edit_category_name: 'menu/categories',
    edit_item_name: 'menu/items',
    styling_display_list: 'styling/styling-display-list',
    styling_display: 'styling/styling-display',
    styling_behaviour: 'styling/styling-behavior',
    styling_behaviour_list: 'styling/styling-behavior-list',
    update_kiosk: 'manage/update-kiosk-settings',
    update_web_settings: 'manage/update-kiosk-settings',
    subscription_list: 'subscriptions/subscriptionsList',
    vendor_locations: 'vendorLocations',
    help_us: 'users/referred-by-platform',
    get_allergen_data: 'users/allergen/',
    create_allergen: 'users/allergen',
    delete_allergen: 'users/allergen/',
    create_user_otp: 'create-user',
    verify_user_otp: 'verify-otp-email',
    resend_otp: 'resend-otp',
    get_hot_selling_data: 'users/hot-selling/',
    insights_data: 'vendor/getInsights',
    insights_sales_table: 'vendor/getSales',
    update_Miniosk: 'manage/update-web-settings/',
    tax: 'menu/taxes',
    get_up_selling_data: 'users/up-selling',
    get_cross_selling_data: 'users/cross-selling',
    create_payment_intent: 'stripe/create-payment-intent',
    stripe_subscribe: 'stripe/subscribe',
    download_qr: 'downloadQrCode',
    hot_selling_edit_drop_down: 'menu/item/dropdown',
    add_hot_selling_items: 'users/hot-selling',
    add_up_selling_items: 'users/up-selling',
    add_cross_selling_items: 'users/cross-selling',
    status_change: 'users/update-status',
    location_list: 'square/getLocationById',
    forget_password: 'forgotPassword',
    reset_password: 'resetPassword',
    promo_categories: 'menu/categories',
    square_locations: 'square/locationsList/',
    dashboard_filter: 'dashboard/filter',
    unlinl_square: 'link-square-account',
    changeStatus: 'menu/updateStatus',
    promoCode_index: 'promocodes/promocode',
    List_web_settings: 'manage/web-settings/',
    Send_Excel: 'vendor/download-csv',
    export_sales: 'salesReport',
    update_promo_code: 'promocodes/promotional-codes/',
    delete_menu: 'menu/deleteMenu/',
    cancel_subscription: 'vendor/cancelSubscription/',
    billing_info: 'stripe/vendorBillingInfo/',
    order_list:'order/list',
    past_billing_invoice: 'stripe/past-billing-invoice/',
    download_order_invoice: 'stripe/download-invoice/',
    download_billing_info: 'download-order-receipt/',
    rearrage_categorires: 'menu/rearrangeMenu',
    tag_edit: "menu/items/",
    hot_selling_status : 'menu/show/',
    vendor_billing: 'stripe/vendorBillingInfo/'
};

export default ApiServices;
