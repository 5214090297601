import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import STAR from '../../assets/images/star.png';
import Grid from '@mui/material/Grid';
import { useLocation, useNavigate } from 'react-router-dom';
import { setSubscriptionList } from '../../store/reducers/SubscriptionReducer';
import { useDispatch, useSelector } from 'react-redux';
import BackNavigation from '../../components/BackNavigation'
import ApiServices from '../../helpers/ApiServices';
import HelperModule from '../../helpers/HelperModule';
import styled from 'styled-components';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

let subscriptionPlan: any
let UserCodeData: any
function Subscription() {
    const navigate = useNavigate();
    const { SubscriptionList } = useSelector((state: any) => state.subscriptionList)
    const UserID = localStorage.getItem("UserID")
    const dispatch = useDispatch();

    useEffect(() => {
        const UserCode = localStorage.getItem("UserCode")
        UserCodeData = UserCode
    }, [])

    const handleButtonClick = async (item: any) => {
        // navigate('/stripe', {
        //     state: {
        //         name: item?.name,
        //         amount: item?.amount,
        //         subscription_id: item?.plan_code,
        //         type: "user_subscription"
        //     }
        // })
        try{
            const response = await axios.post(`https://api.miniosk.com/api/stripe/payment-link?vendor_id=${UserID}&type=subscription&subscription_id=${item?.plan_code}`)
            console.log(response)
            if(response?.data?.data?.payment_link){
                window.location.replace(response?.data?.data?.payment_link);
            }else{
                enqueueSnackbar("Something went wrong, Please try after sometime.", {
                    variant: "error",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    style: { fontFamily: "Poppins", fontWeight: "500" },
                  });
            }
            
        }catch(error:any){
            console.log(error)
        }
    }

    const HandleSubscriptionList = async () => {
        try {
            const response = await HelperModule().getMethod(ApiServices.base_URL + ApiServices.subscription_list + `?user_code=${UserCodeData}`);
            if (response.status === true) {
                dispatch(setSubscriptionList(response.subscriptions.data));
                console.log(response)
            }
        }
        catch (error) {
            console.log(error)
        }
    };
    useEffect(() => {
        HandleSubscriptionList()
    }, [])

    return (
        <div>
            <BackNavigation label="Subscription Plan" />
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} columns={{ xs: 12, sm: 12, md: 12 }} >
                    {SubscriptionList.map((item: any, index: any) => (
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} >
                            <CardContainer>
                                <Card sx={{ width: '100%', borderRadius: '8px', border: item.message === "Current Plan" ? '#18BC46 solid 2px' : item.message === "Plan Expired" ? 'red solid 2px' : 'none', boxShadow: 'none', padding: "40px 20px" , height: '100%'}}>
                                    <div style={{}}>
                                        <div style={{ justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
                                            <Typography sx={{ fontSize: '20px', fontFamily: 'poppins', fontWeight: 600, whiteSpace: 'nowrap', width: '95%' , height: '25px', overflow:"hidden"}} color="#303030" gutterBottom>
                                                {item?.name}
                                            </Typography>
                                            <p style={{ fontSize: '12px', fontFamily: 'poppins', fontWeight: 400, marginTop: "13px", width: '95%',height: '25px' }} color="#303030">
                                                {item?.description}
                                            </p>
                                        </div>
                                        <div style={{ margin: "18px 0px", justifyContent: "space-between", display: "flex", flexDirection: "column", marginTop: "28px" }}>
                                            <div style={{ display: 'flex', alignItems: 'baseline', width: '95%' }}>
                                                <div style={{ fontFamily: 'poppins', fontWeight: 500, fontSize: '20px',height: '25px' }}>$ {item?.amount}</div>
                                                <div style={{ fontFamily: 'poppins', fontWeight: 400, fontSize: '10px', color: '#737272',height: '25px' }}>/{item?.duration} days</div>
                                            </div>
                                            {/* <div style={{ fontFamily: 'poppins', fontWeight: 400, fontSize: '10px', color: '#737272', height: "7px" }}>
                                            $996 Billed Annually
                                        </div> */}
                                        </div>
                                        <div style={{ marginBottom: 18 }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <img src={STAR} alt="star" style={{ height: '16px' }} />
                                                <div style={{ fontFamily: 'poppins', width: '80%', fontWeight: 400, fontSize: '10px', color: '#0D0E0E', marginLeft: '12px',  }}>Subscription Type : {item?.plan_type}</div>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', margin: "21px 0px" }}>
                                                <img src={STAR} alt="star" style={{ height: '16px' }} />
                                                <div style={{ fontFamily: 'poppins', width: '80%', fontWeight: 400, fontSize: '10px', color: '#0D0E0E', marginLeft: '12px',}}>Fixed {item?.order_limit} Order limit , $ {item?.exceed_order_limit_amount} Charges after exceeding order limit</div>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <img src={STAR} alt="star" style={{ height: '16px' }} />
                                                <div style={{ fontFamily: 'poppins', width: '80%', fontWeight: 400, fontSize: '10px', color: '#0D0E0E', marginLeft: '12px', }}>Fixed {item?.store_limit} Location limit , $ {item?.exceed_store_limit} Charges after exceeding Location limite</div>
                                            </div>
                                        </div>
                                        {item?.user_subscription?.status !== 1 ?
                                            <Button style={{ width: '100%', height: '36px', marginTop: "18px", borderRadius: "8px", backgroundColor: "#8B5CFF", color: '#FFFFFF', border: 0, fontFamily: 'Poppins' }} onClick={() => handleButtonClick(item)}>Select</Button>
                                            :
                                            null
                                        }
                                        {item?.message === "Current Plan" ?
                                        <Button style={{ width: '100%', height: '36px', marginTop: "18px", borderRadius: "8px", backgroundColor: "#FFFFFF", color: '#18BC46', border: 0, fontFamily: 'Poppins', fontWeight: '600', fontSize: '14px' }}>{item?.message}</Button>
                                        : 
                                        <Button style={{ width: '100%', height: '36px', marginTop: "18px", borderRadius: "8px", backgroundColor: "#FFFFFF", color: 'red', border: 0, fontFamily: 'Poppins', fontWeight: '600', fontSize: '14px' }}>{item?.message}</Button>
                                         }
                                        </div>
                                </Card>
                            </CardContainer>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </div >
    )
}

export default Subscription