import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import './PromoCodeStyles.css'

import PromoCodeTable from '../../components/PromoCodeTable';
import Plus from '../../assets/images/plus-colored.png'

function PromoCode() {
    const navigate = useNavigate();

    const HandlePromoCodeCreation = () => {
        navigate('/promotioncreation');
    }

    return (
        <div className='container'>
            <h1 className='title-heading'>Promo Codes</h1>
            <div onClick={HandlePromoCodeCreation} className="add-promo-container">
                <div className="add-promo-image-container">
                    <img src={Plus} alt="add" style={{ height: "24px", width: "24px", cursor: "pointer" }} />
                </div>
                <h3 className='promo-code-header' style={{ paddingTop: "24px" }}>Add New</h3>
                <h3 className='promo-code-header'>Promo Code</h3>
            </div>

            <h3 className='promo-code-header'>Promo Codes</h3>
            <div className="promo-table-container">

                <PromoCodeTable />
            </div>
        </div>
    )
}
export default PromoCode