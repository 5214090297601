import React, { useEffect, useState } from "react";
import "./ManageStyles.css";
import LocationFilter from "../../components/Dropdown";
import KioskTable from "../../components/KioskTable";
import MenusTable from "../../components/MenusTable";
import WebSettingsTable from "../../components/WebSettingsTable";
import { setFilteredLocations } from "../../store/reducers/LocationsReducer";
import { useDispatch } from "react-redux";

function Manage() {
  const dispatch = useDispatch();
  const [activeButton, setActiveButton] = React.useState("Kiosk");
  const [isFilterDisabled, setFilterDisabled] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);

  const handleButtonClick = (buttonName: any) => {
    setActiveButton(buttonName);
  };

  useEffect(() => {
    dispatch(setFilteredLocations([]));
  }, [activeButton]);

  return (
    <div className="container">
      <h1 className="title-heading">Miniosk Manage</h1>
      <div className="manage-tabs-container">
        <div className="manage-btn-container" style={{ width: "350px", display: "flex", flexDirection: "row" }}  >
          <button onClick={() => handleButtonClick("Kiosk")} className={activeButton === "Kiosk" ? "active-button" : "inactive-button"} style={{ marginRight: "30px", marginLeft: "10px" }}>Kiosk</button>
          <button onClick={() => handleButtonClick("QR Code")} className={activeButton === "QR Code" ? "active-button" : "inactive-button"} style={{ marginRight: "30px" }}>QR Code</button>
          <button onClick={() => handleButtonClick("Web url")} className={activeButton === "Web url" ? "active-button" : "inactive-button"}>Web url</button>
        </div>
        <div className="manage-btn-sub">
          <LocationFilter
            onLocationSelect={setSelectedLocations}
          // isFilterDisabled={isFilterDisabled}
          />
        </div>
      </div>
      {activeButton === "Web url" && (
        <h1 style={{ margin: 0, fontWeight: "600", fontSize: "20px", fontFamily: "Poppins", paddingTop: "11.5px", }}>Web url</h1>
      )}
      <div className="miniosk-table-container">
        {activeButton === "Kiosk" && (
          <KioskTable setFilterDisabled={setFilterDisabled} selectedLocations={selectedLocations} />
        )}
        {activeButton === "QR Code" && (
          <MenusTable setFilterDisabled={setFilterDisabled} selectedLocations={selectedLocations} />
        )}
        {activeButton === "Web url" && (
          <WebSettingsTable setFilterDisabled={setFilterDisabled} selectedLocations={selectedLocations} />
        )}
      </div>
    </div>
  );
}

export default Manage;
