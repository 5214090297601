import { Fragment, useState, useEffect } from "react";
import Download from "../assets/images/Download.png";
import { useTheme, Popover, MenuItem, Typography, Box, CircularProgress } from "@mui/material";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import ApiServices from "../helpers/ApiServices";
import HelperModule from "../helpers/HelperModule";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { setSalesTableList } from "../store/reducers/InsightsReducer";

let UserCodeData: any;
const DownloadSummary = () => {
  const { palette }: any = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { salesTableList } = useSelector((state: any) => state.insightsDetails);
  
  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  useEffect(() => {
    const UserCode = localStorage.getItem("UserCode");
    UserCodeData = UserCode;
  }, []);

  const handleClose = () => {
    setOpen(null);
  };

  const InsightsSalesTable = async (selectedMenuCode: any, page: any) => {
    try {
      const menuCodes = selectedMenuCode || "";
      const response = await HelperModule().getMethod(
        ApiServices.base_URL +
        ApiServices.insights_sales_table +
        `?user_code=${UserCodeData}&menu_codes=${menuCodes}&page=${page}`
      );
      if (response.status === true) {
        dispatch(setSalesTableList(response.result.data));
      } else {
        console.log("Error Response", response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    InsightsSalesTable("", 1);
  }, []);

  const ExportToExcel = () => {
    const ExcelData = [
      [
        "Date",
        "order ID",
        "Items",
        "Subtotal",
        "Taxes",
        "Discount %",
        "Tips",
        "Total",
        "Payment Mode",
      ],
      ...salesTableList.map((row: any) => [
        new Date(row.created_at).toLocaleString().replace(/,/g, " "),
        row.order_id,
        row.item_count,
        row.subtotal,
        row.taxes && row.taxes.length > 0
          ? row.taxes[0]?.catalog_object_id || "N/A"
          : "N/A",
        row.discount_amount,
        row.tip,
        row.total,
        row.payment_mode,
      ]),
    ];
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(ExcelData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const array = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([array], { type: "application/octet-stream" });
    saveAs(blob, "SalesReport.xlsx");
  };

  const SendEmail = async () => {
    setLoading(true)
    try {
      const response = await HelperModule().getMethod(
        ApiServices.base_URL +
        ApiServices.export_sales +
        `?user_code=${UserCodeData}&type=email`
      );
      if (response.status === true) {
        setLoading(false)
        enqueueSnackbar("Sales report sent to Email successfully.", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
      } else {
        setLoading(false)
        enqueueSnackbar("Error while sent error.", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setLoading(false)
    }
  };

  return (
    <Fragment>
      <button
        onClick={(event: any) => handleOpen(event)}
        className="download"
        style={{
          display: "flex",
          padding: "10px 16px",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "8px",
          backgroundColor: "#8B5CFF",
          color: "#FFFFFF",
          borderWidth: "0px",
          fontWeight: "500",
          fontSize: "12px",
          fontFamily: "Poppins",
          transition: "background-color 0.3s",
        }}
        onMouseEnter={(e) => {
          (e.target as HTMLButtonElement).style.backgroundColor = "#6d43e57D";
        }}
        onMouseLeave={(e) => {
          (e.target as HTMLButtonElement).style.backgroundColor = "#8B5CFF";
        }}
      >
        <img
          src={Download}
          alt="Download"
          style={{ height: "12px", width: "8px", marginRight: "10px" }}
        />
        {loading ? <CircularProgress color="inherit" size={25}  /> : 'Export'}
      </button>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Box sx={{ p: 1 }}>
          <MenuItem onClick={SendEmail} sx={{ width: "100%" }}>
            <Typography
              variant="subtitle2"
              sx={{
                color: palette?.common?.menuItem,
                fontWeight: 500,
                lineHeight: "16px",
              }}
            >
              Send Email
            </Typography>
          </MenuItem>
          <MenuItem onClick={ExportToExcel} sx={{ width: "100%" }}>
            <Typography
              variant="subtitle2"
              sx={{
                color: palette?.common?.menuItem,
                fontWeight: 500,
                lineHeight: "16px",
              }}
            >
              Download(.xlsx)
            </Typography>
          </MenuItem>
        </Box>
      </Popover>
    </Fragment>
  );
};

export default DownloadSummary;
