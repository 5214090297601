import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { setUserObject } from "../../store/reducers/AuthReducer";
import axios from "axios";
import { enqueueSnackbar } from "notistack";

function BillingChart() {
  const [billing, setBilling] = useState<{
    total_orders?: number;
    exceeded_orders?: number;
    exceeded_orders_amount?: number;
  } | null>(null);
  const [limit, setLimit] = useState(0);
  const [exceededOrders, setExceededOrders] = useState(0);

  const { user } = useAppSelector((state: any) => state?.Auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    console.log("called", user);
    getExcessBilling();
  }, [user]);

  const handlePastBillDownload = async () => {
    const Usercode = localStorage.getItem("UserID");
    try {
      const response = await HelperModule().getMethod(
        ApiServices.base_URL + ApiServices.past_billing_invoice + Usercode
      );
      // console.log(response)
      window.open(response?.data?.url);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchVendorDetails = async () => {
      // renamed function for clarity
      const Usercode = localStorage.getItem("UserCode");
      try {
        const response = await HelperModule().getMethod(
          ApiServices.base_URL + ApiServices.get_vendor + Usercode
        );
        if (response.status === true) {
          console.log(response?.data);
          // if (response?.data?.vendor_details?.pos_account_status === 0) {
          //   navigate("/linkaccount");
          // }
          dispatch(setUserObject(response?.data?.vendor_details));
          console.log(response?.data, "LINK");
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchVendorDetails(); // call the renamed function
  }, [_.isEmpty(user)]);

  const handleButtonClick = async () => {
    // navigate("/stripe", {
    //   state: {
    //     name: "Billing Amount",
    //     amount: billing?.exceeded_orders_amount,
    //     subscription_id: user?.user_subscription?.subscription_code,
    //     type: "order_limit_exceeded_payment",
    //   },
    // });
    try {
      const UserID = localStorage.getItem("UserID");
      const response = await axios.post(
        `https://api.miniosk.com/api/stripe/payment-link?vendor_id=${UserID}&type=order_exceeded_payment`
      );
      console.log(response);
      if (response?.data?.data?.payment_link) {
        window.location.replace(response?.data?.data?.payment_link);
      } else {
        enqueueSnackbar("Something went wrong, Please try after sometime.", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
      }
      // window.location.replace(response?.data?.url);
    } catch (error: any) {
      console.log(error);
    }
  };

  const getExcessBilling = async () => {
    try {
      const excessBilling = await HelperModule().getMethod(
        ApiServices.base_URL + ApiServices.vendor_billing + `${user?.id}`
      );
      console.log(excessBilling);
      setExceededOrders(excessBilling?.exceeded_orders);
      setLimit(excessBilling?.order_limit);
      setBilling(excessBilling);
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <div style={{ margin: 10 }}>
      <Grid container>
        <Grid xs={12} sm={4} md={4} lg={4} xl={4} item>
          <PieChart
            series={[
              {
                data: [
                  { id: 0, value: exceededOrders, label: "Exceeded Orders" },
                  { id: 1, value: limit, label: "Order Limit" },
                ],
              },
            ]}
            width={500}
            height={200}
          />
          <Typography align="center">
            Total orders: {billing?.total_orders}
          </Typography>
        </Grid>
        <Grid
          xs={12}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="h2" align="center" paragraph={true}>
            $ {billing?.exceeded_orders_amount}
          </Typography>
          <Typography variant="h4" align="center" mt={3}>
            Billing Amount
          </Typography>
        </Grid>
        <Grid
          xs={12}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <Button
            variant="contained"
            color="success"
            disableRipple={true}
            onClick={handleButtonClick}
          >
            Pay bill
          </Button>
          <Button
            variant="contained"
            disableRipple={true}
            onClick={handlePastBillDownload}
          >
            Past billing invoice
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default BillingChart;
