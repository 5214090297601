import React, { FunctionComponent } from 'react';

interface AccountProps {
  title: string;
  button: string;
  description: string;
  onClick: () => void;
}

const Account: FunctionComponent<AccountProps> = ({ title, button = "button", description, onClick }) => {
  return (
    <div style={{ width: '42.45vw', paddingBottom: '24px' }}>
      <h1 style={{ margin: 0, fontFamily: 'Poppins', fontSize: "24px", fontWeight: '600', color: '#201C1C' }}>{title}</h1>
      <p style={{ margin: 0, fontFamily: 'Poppins', fontSize: "14px", fontWeight: '400', color: '#201C1C7A' }}>{description}</p>
      <button
        style={{ marginTop: "16px", justifyContent: 'center', alignItems: 'center' }}
        className='billinginfo-btn'
        onClick={onClick}
      >
        {button}        
      </button>
    </div>
  );
};

export default Account;
