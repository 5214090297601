import React from 'react'
import './Support.css'
import HAVEQUESTION from '../../assets/images/question-img.png'
import VECTOR from '../../assets/images/Vector.png'
import ICON from '../../assets/images/Icon.png'

function Support() {
    const handleFeedbackClick = () => {
        const mailTo = 'mailto:feedback@miniosk.com?subject=Feedback&body=Enter your suggestions here.';
        window.location.href = mailTo;
    };
    const callData = [
        {
            'details': 'Phone Number',
            'type': '+91 8083334932',
            'img': VECTOR
        },
        {
            'details': 'E-mail address',
            'type': 'feedback@miniosk.com',
            'img': ICON
        },
        {
            'details': 'Feedback',
            'type': 'Any Suggestions',
            'img': ICON,
        },
    ]
    return (
        <>
            <h1 className='heading-support'>Support</h1>
            <div className='havequestionImg-container'>
                <div className="main-container-support">
                    <div>
                        <img alt='question' src={HAVEQUESTION} style={{ width: '17vw', height: '35vh' }} />
                    </div>
                    <p className='main-content-support'>We are here to help so please get in touch<br /> with us.</p>
                    <div className="card-container-support">
                        {callData.map((item: any, index: number) => (
                            <div
                                key={index}
                                style={{ flexDirection: 'row', display: 'flex', paddingBottom: '25px', cursor: item.type === 'Any Suggestions' ? 'pointer' : 'auto' }}
                                onClick={item.type === 'Any Suggestions' ? handleFeedbackClick : undefined}
                            >
                                <div className='vectorImg_div'>
                                    <img alt='contact' src={item.img} className='contact-img' style={{ height: '18px', width: '18px' }} />
                                </div>
                                <div>
                                    <p className='contactType'>{item.details}</p>
                                    <p className={item.type === 'Any Suggestions' ? 'contact feedback-tab' : 'contact'}>{item.type}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </>

    )
}

export default Support

