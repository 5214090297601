import { createSlice } from '@reduxjs/toolkit';

interface initialStateProps {
    ModifierList: any,
}

const initialState: initialStateProps = {
    ModifierList: [],
}

const modifierListReducer = createSlice({
    name: 'modifierDetails',
    initialState,
    reducers: {
        setModifierList: (state, action) => {
            state.ModifierList = action.payload
        },
    },
});

export const { setModifierList } = modifierListReducer.actions;
export default modifierListReducer.reducer;