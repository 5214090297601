import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Cross from '../assets/images/Cross.png';
import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useStyles } from '../components/UseStyles';
// import Select from 'react-select';

function EditSellingModel({ open, onClose, addDrp, handleChange, selectedItems, sellingDrp, heading, ListItemId }: any) {
    const classes = useStyles();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const isItemSelected = (item:any) => {
        return selectedItems.some((selectedItem:any) => selectedItem.id === item.id);
    };

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={{
                bottom: '-100%',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '35.72vw',
                height: '247.9px',
                transition: 'height 0.3s ease-in-out',
                bgcolor: 'background.paper', borderRadius: '8px', boxShadow: 24, padding: '16px', borderWidth: 0
            }}>
                <div className='import-title-container'>
                    <h3 className='import-title'>Select {heading} Items</h3>
                    <img onClick={onClose} src={Cross} alt='Cross' style={{ width: '24px', height: '24px', }} />
                </div>
                <div className="menu-details">
                    <h3 className='menu-heading'>Select Items</h3>
                    <div className="dropdown">
                        <FormControl sx={{ m: 1, width: "32.06vw", height: "7.20vh" }}>
                            <InputLabel id="demo-multiple-checkbox-label">Select Items</InputLabel>
                            <Select
                                className={classes.root}
                                label="My Label Hello"
                                multiple
                                value={selectedItems}
                                onChange={handleChange}
                                renderValue={(selected) => (selected.map((item: any) => item.name)).join(', ')}
                                MenuProps={MenuProps}
                                variant="outlined"
                            >
                                {sellingDrp.map((item: any) => (
                                    <MenuItem key={item.id} value={item}>
                                        <Checkbox checked={isItemSelected(item)} />
                                        <ListItemText
                                            primary={
                                                <Typography sx={{ color: '#201C1CB8', fontWeight: '500', fontSize: '10x', fontFamily: 'Poppins' }}>
                                                    {item.name}
                                                </Typography>
                                            }
                                            secondary={
                                                <Typography sx={{ color: '#8B5CFF', fontWeight: '500', fontSize: '14px', fontFamily: 'Poppins' }}>
                                                    {item.description}
                                                </Typography>
                                            }
                                        />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '12px', }}>
                        <button onClick={addDrp} className="common-button-blue">Add</button>
                        <button onClick={onClose} className="common-button-cancel">Cancel</button>
                    </div>
                </div>
            </Box>
        </Modal>
    );
}

export default EditSellingModel;
